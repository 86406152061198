export default {
  'common.all': 'Alle',
  'common.are_you_sure': 'Er du sikker?',
  'common.language.da': 'Dansk',
  'common.language.en': 'Engelsk',
  'common.search': 'Søg...',
  'common.unknown': 'Ukendt',

  'core.possessive_title': 'Salarys',
  'core.title': 'Salary',

  'date.date_format': 'do MMMM yyyy',
  'date.date_interval': '{start} – {end}',
  'date.date_short_format': 'do MMM yyyy',
  'date.date_time_format': 'do MMMM yyyy HH:mm',
  'date.date_time_short_format': 'do MMM yyyy HH:mm',
  'date.day_month': 'do MMMM',
  'date.day_of_month': 'do',
  'date.month_of_year': 'MMMM yyyy',

  'number.currency_suffix': 'kr.',
  'number.decimal_separator': ',',
  'number.thousand_separator': '.',

  'unit.day_count.many': '{count} dage',
  'unit.day_count.one': '{count} dag',
  'unit.day.many': 'dage',
  'unit.day.one': 'dag',
  'unit.employee_count.many': '{count} medarbejdere',
  'unit.employee_count.one': '{count} medarbejder',
  'unit.employee.many': 'medarbejdere',
  'unit.employee.one': 'medarbejder',
  'unit.hour_count.many': '{count} timer',
  'unit.hour_count.one': '{count} time',
  'unit.hour.many': 'timer',
  'unit.hour.one': 'time',
  'unit.minute_count.many': '{count} minutter',
  'unit.minute_count.one': '{count} minut',
  'unit.minute.many': 'minutter',
  'unit.minute.one': 'minut',

  '`employee_search.form.department_id.none`': 'Ingen afdeling',

  'accept_user_invite.button.no': 'Nej',
  'accept_user_invite.button.switch_user': 'Skift bruger',
  'accept_user_invite.button.yes': 'Ja',
  'accept_user_invite.company_group.intro.line_1': 'Vil du knytte din bruger ({email}) til virksomhedsgruppen {name}?',
  'accept_user_invite.company_group.intro.line_2':
    'Hvis det ikke er den rigtige bruger, bør du skifte bruger (logge ud) og logge ind med den rigtige bruger der skal knyttes virksomhedsgruppen.  Det vil også være muligt at oprette en ny bruger, skulle det være nødvendigt.',
  'accept_user_invite.company_group.title': 'Knyt bruger til virksomhedsgruppe',
  'accept_user_invite.company.intro.line_1':
    'Vil du knytte din bruger ({email}) til virksomheden {companyName} ({companyCVR})?',
  'accept_user_invite.company.intro.line_2':
    'Hvis det ikke er den rigtige bruger, bør du skifte bruger (logge ud) og logge ind med den rigtige bruger der skal knyttes virksomheden.  Det vil også være muligt at oprette en ny bruger, skulle det være nødvendigt.',
  'accept_user_invite.company.title': 'Knyt bruger til virksomhed',
  'accept_user_invite.warning.broken_token': 'Invitationsnøglen i linket er ikke gyldigt, prøv igen.',

  'account.add_new_company': 'Tilføj ny virksomhed',
  'account.email': 'E-mail',
  'account.error.mfa_input_code': 'Indtast kode',
  'account.error.mfa_select_method': 'Vælg metode',
  'account.message.email_change_help_text':
    'Når du gemmer, vil der blive sendt en besked til ovenstående e-mail med et link til at bekræfte ændringen.',
  'account.message.email_change_success_text': 'Du vil modtage en e-mail med et link til at bekræfte ændringen.',
  'account.message.mfa_was_saved': 'Opsætning af tofaktorgodkendelse blev gemt',
  'account.mfa': 'Tofaktorgodkendelse',
  'account.mfa.code': 'Kode',
  'account.mfa.confirm': 'Bekræft',
  'account.mfa.continue_to_confirmation': 'Fortsæt til bekræftelse',
  'account.mfa.disable': 'Slå tofaktorgodkendelse fra',
  'account.mfa.enabled_via': 'Slået til via',
  'account.mfa.message.description': 'Vælg enten tofaktorgodkendelse via {sms} eller {totp}.',
  'account.mfa.message.mfa_not_enabled': 'Du har ikke slået tofaktorgodkendelse til.',
  'account.mfa.message.recovery_codes_explanation':
    'Herunder kan du finde engangskoder, der kan bruges, hvis du skulle få problemer med at benytte din tofaktorgodkendelse.',
  'account.mfa.message.sms_confirmation':
    'Du vil indenfor kort tid modtage en SMS-kode. Indtast koden i feltet herunder.',
  'account.mfa.message.totp_confirmation':
    'Scan QR-koden eller indtast nøglen i din Authenticator-app. Indtast herefter koden i feltet herunder.',
  'account.mfa.method': 'Metode',
  'account.mfa.none': 'Ingen',
  'account.mfa.recovery_codes': 'Engangskoder',
  'account.mfa.select_method': 'Vælg metode',
  'account.mfa.setup': 'Opsæt tofaktorgodkendelse',
  'account.mfa.sms': 'SMS',
  'account.mfa.totp': 'Authenticator-app',
  'account.mitid': 'Bekræft identitet med MitID',
  'account.mitid.confirm': 'Bekræft med MitID',
  'account.mitid.error.unable_to_find_url': 'Uventet fejl, kontakt Salary.',
  'account.mitid.identity_already_verified': 'Din identitet er allerede blevet bekræftet',
  'account.mitid.intro': 'For at kunne lave CPR-opslag, skal du bekræfte din identitet med MitID.',
  'account.mitid.logging_note': 'Bemærk at alle opslag i CPR logges.',
  'account.mitid.title': 'Bekræft din identitet med MitID',
  'account.mitid.verified_intro': 'Da du allerede har bekræftet din identitet, kan du lave CPR-opslag.',
  'account.password': 'Adgangskode',
  'account.profile': 'Profil',
  'account.profile.form.language': 'Sprog',
  'account.profile.form.language.required': 'Sprog skal vælges',
  'account.profile.form.name': 'Navn',
  'account.profile.form.name.required': 'Navn skal indtastes',
  'account.profile.form.show_company_list_on_log_in': 'Gå altid til virksomhedsoversigten ved log ind',
  'account.profile.form.show_company_list_on_log_in.help.line_1':
    'Når denne funktion er slået fra, går Salary til den virksomhed du sidst var inde på.',
  'account.profile.form.show_company_list_on_log_in.help.line_2':
    'Med denne funktion slået til, går Salary altid til virksomhedsoversigten ved log ind.',
  'account.profile.form.side_menu': 'Flyt menuen til venstre',
  'account.profile.form.theme': 'Tema',
  'account.profile.form.theme.classic': 'Klassiske Salary-farver',
  'account.profile.form.theme.dark': 'Mørkt',
  'account.profile.form.theme.default': 'Ingen præference',
  'account.profile.form.theme.light': 'Lyst',
  'account.terminate': 'Opsig konto',
  'account.terminate.message.multiple_companies':
    'Hvis du vil opsige din aftale og have slettet dine virksomheder, skal du først vælge den virksomhed der skal slettes, gå ind under Virksomhed - Support og udfylde sletteanmodningen.',
  'account.terminate.message.single_company':
    'Hvis du vil opsige din aftale og have slettet slette din virksomhed, skal du {link} og udfylde sletteanmodningen.',
  'account.terminate.message.single_company.click_here': 'klikke her',
  'account.terminate.title': 'Opsigelse af Salary-konto',

  'accounting_integration.alert.success': 'Din regnskabsintegration er gemt',
  'accounting_integration.connection.back': 'Tilbage',
  'accounting_integration.connection.form.parameter.placeholder': 'Indtast {parameter} fra {integration}',
  'accounting_integration.connection.form.parameter.required': '{parameter} skal udfyldes',
  'accounting_integration.connection.next_step': 'Videre til næste trin',
  'accounting_integration.connection.title': 'Opret forbindelse',
  'accounting_integration.daybooks.form.daybook_id': 'Vælg kassekladde',
  'accounting_integration.daybooks.form.daybook_id.allow_no_daybook_note':
    'Vælger du ingen kassekladde, opretter vi en ny kassekladde hver gang vi bogfører.',
  'accounting_integration.daybooks.form.daybook_id.allow_no_daybook_note.business_central':
    'Hvis der bogføres med flere end 100 posteringslinjer ad gangen, anbefaler vi, at man vælger ingen kassekladde, når man bruger Business Central.',
  'accounting_integration.daybooks.form.daybook_id.no_daybook': 'Ingen kassekladde',
  'accounting_integration.daybooks.form.daybook_id.required': 'Kassekladde skal vælges',
  'accounting_integration.daybooks.form.submit.editing': 'Gem ændring',
  'accounting_integration.daybooks.form.submit.next_step': 'Videre til næste trin',
  'accounting_integration.error_return': 'Vend tilbage',
  'accounting_integration.integration_type.form.integration_type': 'Vælg regnskabsprogram',
  'accounting_integration.integration_type.form.integration_type.placeholder': 'Vælg program',
  'accounting_integration.integration_type.form.integration_type.required': 'Regnskabsprogram skal vælges',
  'accounting_integration.integration_type.intro':
    'Vi arbejder hårdt på at intregrere så mange regnskabsprogrammer som muligt. Hvis du ikke kan finde din på listen, kan du eller din bogholder overføre manuelt med regnskabsudtræk.',
  'accounting_integration.integration_type.next': 'Videre til forbindelse',
  'accounting_integration.integration_type.next.lock': 'Opgradér til Basic, for at bogføre automatisk',
  'accounting_integration.integration_type.title': 'Vælg dit regnskabsprogram',
  'accounting_integration.mapping.back': 'Tilbage',
  'accounting_integration.mapping.form.external_id.no_account': 'Ingen konto',
  'accounting_integration.mapping.form.external_id.no_integration': 'Kontonummer',
  'accounting_integration.mapping.form.external_id.not_active': 'Kontoen er ikke længere tilgængelig',
  'accounting_integration.mapping.form.external_id.not_active.number': 'Kontoen {number} er ikke længere tilgængelig',
  'accounting_integration.mapping.form.external_id.option_not_active': '(ikke længere tilgængelig)',
  'accounting_integration.mapping.form.header.account': 'Konto',
  'accounting_integration.mapping.form.header.text': 'Bogføringstekst',
  'accounting_integration.mapping.form.header.type': 'Bogføringstype',
  'accounting_integration.mapping.form.text': 'Bogføringstekst',
  'accounting_integration.mapping.intro':
    'Her kan du indstille hvilke konti i dit regnskabsprogram Salary skal bruge til at bogføre på. Tjek at alle bogføringstyper bruger de korrekte konti fra din kontoplan. Du har også mulighed for at vælge hvilken tekst der skal bruges ved bogføring.',
  'accounting_integration.mapping.next': 'Forbind til {integration}',
  'accounting_integration.mapping.next.no_integration': 'Gem kontoplan',
  'accounting_integration.mapping.title': 'Godkend eller ret kontoplan',
  'accounting_integration.organization.next_step': 'Videre til næste trin',
  'accounting_integration.organization.organization_id': 'Vælg organisation',
  'accounting_integration.organization.organization_id.required': 'Organisation skal vælges',
  'accounting_integration.organization.save_change': 'Gem ændring',
  'accounting_integration.organization.title': 'Vælg organisation',
  'accounting_integration.steps.choose_daybook': 'Vælg kassekladde',
  'accounting_integration.steps.choose_organization': 'Vælg organisation',
  'accounting_integration.steps.intro': 'Alt regnskabsmæssigt vil helt automatisk overføres til dit regnskabsprogram.',
  'accounting_integration.steps.map_accounts': 'Kontoplan',
  'accounting_integration.steps.need_key': 'Opret forbindelse',
  'accounting_integration.steps.note':
    'Hvis du har problemer med at forbinde dit regnskabsprogram, kan du altid kontakte {link}.',
  'accounting_integration.steps.select_integration': 'Vælg regnskabsprogram',
  'accounting_integration.steps.title': 'Forbind dit regnskabsprogram',

  'accounting_tab.accounting_accounting': 'Regnskab bogføres',
  'accounting_tab.accounting_accounting.automatic': 'Automatisk',
  'accounting_tab.accounting_accounting.manual': 'Manuelt',
  'accounting_tab.accounting_integration.intro': 'Bogføres via',
  'accounting_tab.active_information.back': 'Tilbage',
  'accounting_tab.active_information.field.company': 'Virksomhed',
  'accounting_tab.active_information.field.company.national_id': 'CVR: {national_id}',
  'accounting_tab.active_information.field.external_id': 'Aftalenummer',
  'accounting_tab.active_information.intro': 'Information vedrørende din regnskabsintegration fra dit regnskabssystem',
  'accounting_tab.active_information.title': 'Aktive integrationsdetaljer',
  'accounting_tab.add_accounting_integration': 'Opsæt regnskabsintegration',
  'accounting_tab.alert.success': 'Ændringer til {name} er gemt',
  'accounting_tab.broken_integration.button': 'Genopret forbindelse',
  'accounting_tab.broken_integration.message':
    'Din regnskabsintegration er fejlet. Du bør genoprette din integration. Den fejlede med følgende fejlbesked:',
  'accounting_tab.daybooks.alert.success': 'Regnskabsintegration blev opdateret',
  'accounting_tab.daybooks.back': 'Tilbage',
  'accounting_tab.daybooks.no_daybooks': 'Din regnskabsintegration bruger ikke kassekladder.',
  'accounting_tab.daybooks.no_integration': 'Du har ingen regnskabsintegration, gå tilbage for at oprette en.',
  'accounting_tab.daybooks.title': 'Skift kassekladde',
  'accounting_tab.dimensions.back': 'Tilbage',
  'accounting_tab.dimensions.departments.intro':
    'Normalt samles bogføringslinjer for hele virksomheden, så der f.eks. kun bogføres én stor løn-postering. Men du kan godt få posteringerne inddelt per afdeling i stedet.',
  'accounting_tab.dimensions.departments.title': 'Afdelingsbogføring',
  'accounting_tab.dimensions.title.employee_dimensions': 'Afdelingsbogføring og medarbejderdimensioner',
  'accounting_tab.dimensions.title.no_dimensions': 'Afdelingsbogføring',
  'accounting_tab.dimensions.title.with_dimensions': 'Afdelingsbogføring og dimensioner',
  'accounting_tab.form.booking_rule': 'Hvordan vil du bogføre i {system}?',
  'accounting_tab.form.booking_rule.placeholder': 'Bogføringsmetode',
  'accounting_tab.form.booking_rule.required': 'Bogføringsmetode skal vælges',
  'accounting_tab.form.refresh_account_plan': 'Opdatér kontoplan',
  'accounting_tab.form.select_cost_center.company': 'Virksomhed',
  'accounting_tab.form.select_cost_center.cost_centers': 'Vælg omkostningssted',
  'accounting_tab.form.select_cost_center.departments': 'Vælg afdeling',
  'accounting_tab.header.active_information': 'Integrationsdetaljer',
  'accounting_tab.header.add_integration': 'Skift regnskabsintegration',
  'accounting_tab.header.daybook': 'Skift kassekladde',
  'accounting_tab.header.dimension.employee_dimensions': 'Afdelingsbogføring og medarbejderdimensioner',
  'accounting_tab.header.dimension.has_dimensions': 'Afdelingsbogføring og dimensioner',
  'accounting_tab.header.dimension.no_dimensions': 'Afdelingsbogføring',
  'accounting_tab.header.settings': 'Avanceret',
  'accounting_tab.header.sync': 'Synkronisér',
  'accounting_tab.header.vouchers': 'Se bogføring',
  'accounting_tab.old_dinero_token.message':
    'Din Dinero-nøgle er blevet udfaset. Hvis du ikke fornyer din Dinero-nøgle, kan din bogføring begynde at fejle. Det er Dinero der har besluttet at udfase de gamle nøgler. For at skifte til den nye nøgle, vælg "{link}", vælg Dinero som regnskabssystem og følg vejledningen.',
  'accounting_tab.old_dinero_token.message.link': 'skift regnskabsintegration',
  'accounting_tab.organizations.alert.success': 'Regnskabsintegration blev opdateret',
  'accounting_tab.organizations.back': 'Tilbage',
  'accounting_tab.organizations.has_organization':
    'Adgangen til din regnskabsintegration kan give dig forbindelse til mere end en virksomhed. Hvis du har flere virksomheder i dit regnskabsprogram, vil du kunne vælge hvilken skal tilknyttes {name} i Salary.',
  'accounting_tab.organizations.no_integration': 'Du har ingen regnskabsintegration, gå tilbage for at oprette en.',
  'accounting_tab.organizations.no_organization':
    'Din regnskabsintegration giver ikke adgang til flere virksomheder, så du kan ikke skifte.',
  'accounting_tab.organizations.title': 'Skift virksomhed',
  'accounting_tab.settings.alert.success': 'Bogføringsindstillingerne for {name} er gemt',
  'accounting_tab.settings.back': 'Tilbage',
  'accounting_tab.settings.form.accounting_manual_payment_always_asset':
    'Bogfør manuel overført løn som modtaget på disp.dagen',
  'accounting_tab.settings.form.accounting_manual_payment_always_asset.help.line_1':
    'Når lønnen overføres automatisk (via Overførselsservice), så modposterer vi lønnen direkte på banken.  Er lønnen derimod med manuel overførsel, ved vi af gode grunde ikke hvornår den overføres, og så modposteres den på skyldig løn.  Når lønnen så faktisk er overført, skal du manuelt postere det på skyldig løn og modpostere banken.',
  'accounting_tab.settings.form.accounting_manual_payment_always_asset.help.line_2':
    'Ved at slå denne indstilling til, så lader vi som om at den manuelle overførsel er en automatisk overførsel, og de manuelle lønoverførsler modposteres banken.',
  'accounting_tab.settings.form.accounting_vacation_liability': 'Bogfør feriepengeforpligtelser',
  'accounting_tab.settings.form.add_consolidation_report_to_voucher':
    'Vedhæft lønkvitteringsbilaget på lønkørselsbogføringer',
  'accounting_tab.settings.form.allow_debit_company_accounting_account': 'Tillad anden debetkonto på honorarer',
  'accounting_tab.settings.form.book_transfers_on_payment_date': 'Bogfør betalinger på deres tiltænkte betalingsdato',
  'accounting_tab.settings.form.book_transfers_on_payment_date.help.line_1':
    'Uden denne indstilling sat, bogføres betalinger med datoen for den dag de faktisk betales.',
  'accounting_tab.settings.form.book_transfers_on_payment_date.help.line_2':
    'Når den er sat, bogføres betalinger efter den betalingsdato der står på overførslen.  Dette vil for det meste være dispositionsdatoen, og derfor ofte bankdagen efter.',
  'accounting_tab.settings.form.disable_bank_accounting': 'Bogfør ikke betalinger',
  'accounting_tab.settings.form.disable_bank_accounting.help.line_1':
    'Normalt bogføres betalinger af løn, skat, m.v., ligesom lønkørslerne bogføres. Hvis man ikke er interesseret i, at betalingerne bliver bogført automatisk, kan man slå det fra her.',
  'accounting_tab.settings.form.disable_bank_accounting.help.line_2':
    'Det er dog vigtigt at vide, at når bogføring af betalinger er slået fra, vil {none} af dine betalinger vil blive bogført, og der vil ikke blive oprettet et bogføringsbilag for dem. Det gælder {all} betalinger, bl.a. løn- og skattebetalinger.',
  'accounting_tab.settings.form.disable_bank_accounting.help.line_2.all': 'alle',
  'accounting_tab.settings.form.disable_bank_accounting.help.line_2.none': 'ingen',
  'accounting_tab.settings.form.enable_otp_accounting_text': 'Slå separate bogføringslinjer til',
  'accounting_tab.settings.form.enable_otp_accounting_text.help.line_1':
    'Normalt samles bonuser, udlæg, osv. til enkle linjer i bogføringen, således at f.eks. al bonus givet i en periode er én bogføringslinje.',
  'accounting_tab.settings.form.enable_otp_accounting_text.help.line_2':
    'Men hvis man ønsker muligheden for at gøre en enkelt engangsbetaling (såsom bonus, udlæg, forskud, osv.) til sin helt egen linje i sin bogføring kan man slå dette til her.',
  'accounting_tab.settings.form.enable_otp_accounting_text.help.line_3':
    'Næste gang man f.eks. laver en bonus, får man så muligheden for at skrive en tekst til den særlige bogføringslinje.',
  'accounting_tab.settings.form.skip_unmapped_accounting_accounts': 'Spring ikke-tilknyttede konti over',
  'accounting_tab.settings.form.skip_unmapped_accounting_accounts.help.line_1':
    'Normalt når en konto på kontoplanen ikke er tilknyttet en konto i bogføringssystemet, vil vores bogføring fejle.',
  'accounting_tab.settings.form.skip_unmapped_accounting_accounts.help.line_2':
    'Ønsker du derimod muligheden for at kunne springe konti over, kan du slå denne indstilling til, så vil ikke-tilknyttede konti blive sprunget over.',
  'accounting_tab.settings.form.skip_unmapped_accounting_accounts.help.line_3':
    'Vær opmærksom på, at dette kan øge risikoen for at bogføringen ikke balancere!  Bilaget vil dog stadig indeholde de konti der ikke er tilknyttet.',
  'accounting_tab.settings.title': 'Advancerede bogføringsindstillinger',
  'accounting_tab.title': 'Regnskab',

  'accounting.booking_rule.book': 'Bogfør bilaget for mig',
  'accounting.booking_rule.daybook': 'Overfør til kassekladden, jeg bogfører selv',
  'accounting.no_integration': 'Intet regnskabsprogram',

  'administration_layout.menu.assets': 'Enheder',
  'administration_layout.menu.documents': 'Dokumenter',
  'administration_layout.menu.projects': 'Projekter',

  'advanced_setting.alert.success': 'Ændringer til {name} er gemt',
  'advanced_setting.company_group.retroactive.description':
    'Per standard vil indstillingerne kun gemmes for fremtidige virksomheder tilføjet til gruppen, men med dette flueben kan du sørge for at alle virksomhederne allerede i gruppen får de samme indstillinger.',
  'advanced_setting.company_group.retroactive.title': 'Med tilbagevirkende kraft',
  'advanced_setting.header.accounting': 'Bogføring',
  'advanced_setting.header.additional_features': 'Ekstra funktioner',
  'advanced_setting.header.back': 'Tilbage',
  'advanced_setting.header.benefits': 'Personalegoder',
  'advanced_setting.header.car_allowance': 'Kørsel',
  'advanced_setting.header.company': 'Virksomhedsadministration',
  'advanced_setting.header.employee_app': 'Medarbejderapp',
  'advanced_setting.header.employees': 'Medarbejdere',
  'advanced_setting.header.leave': 'Fravær & ferie',
  'advanced_setting.header.pay': 'Løn',
  'advanced_setting.header.pension': 'Pension',
  'advanced_setting.header.time_registrations': 'Tidsregistrering',
  'advanced_setting.header.title': 'Avancerede indstillinger',
  'advanced_setting.introduction.paragraph_1':
    'Løn er kompliceret, og Salary gør det enkelt. Skulle man alligevel få brug for mere avancerede funktioner til sin løn, kan man slå dem til her.',
  'advanced_setting.introduction.paragraph_2':
    'Hvis du er i tvivl om disse funktioner er noget du har brug for, så {link}.',
  'advanced_setting.introduction.paragraph_2_link': 'skriv til supporten',
  'advanced_setting.setting.allow_app_employee_update_banking.description':
    'Som udgangspunkt kan en ansat ikke selv opdatere bankoplysninger. Med denne indstilling slået til, kan medarbejderen gøre det på eget ansvar. Både arbejdsgiver og ansat vil få en e-mail, når bankoplysningerne er blevet ændret.',
  'advanced_setting.setting.allow_app_employee_update_banking.title':
    'Tillad at ansatte selv kan opdatere bankoplysninger',
  'advanced_setting.setting.allow_approve_only_user.description':
    'Brugertypen "kun løngodkender" er en simpel brugertype, der kun kan godkende løn på sin mobiltelefon. Brugeren får et link via SMS, som tager dem til lønnen de skal godkende.',
  'advanced_setting.setting.allow_approve_only_user.title': 'Tillad brugertypen kun løngodkender',
  'advanced_setting.setting.allow_day_laborer.description':
    'Normalt beregnes ferie til 2,08 feriedage om måneden. Denne funktion er til hvis man har medarbejdere der skal udregnet deres ferie per time eller per dag.',
  'advanced_setting.setting.allow_day_laborer.title': 'Tillad ferieberegning per time/dag',
  'advanced_setting.setting.allow_employee_to_upload_documents.description':
    'Normalt er det kun virksomhedsejeren der kan vedhæfte dokumenter til medarbejderen. Denne funktion tillader medarbejderne til også at gøre det. Du vil kunne se deres dokumenter ligesom normalt.',
  'advanced_setting.setting.allow_employee_to_upload_documents.title':
    'Tillad medarbejderne at gemme dokumenter i appen',
  'advanced_setting.setting.allow_freelancer_cvr.description':
    'Normalt bruger man et CPR-nummer til sine freelancere. Denne funktion er til hvis man har freelancere med eget CVR-nummer.',
  'advanced_setting.setting.allow_freelancer_cvr.title': 'Tillad CVR-nummer til freelancere',
  'advanced_setting.setting.allow_leave_type_employee_selectable.description':
    'En medarbejder kan normalt selv vælge fridag, sygedag og ubetalt fravær i appen. Med denne funktion kan man slå specifikke af disse fra og til. Når funktionen er slået til, kan indstillingssiden tilgås her.',
  'advanced_setting.setting.allow_leave_type_employee_selectable.link': 'Du kan indstille fraværstyperne her',
  'advanced_setting.setting.allow_leave_type_employee_selectable.title':
    'Tillad fravalg af specifikke fraværstyper i appen',
  'advanced_setting.setting.allow_negation_payroll.description':
    'Hvis en lønseddel ikke skulle være gennemført, kan den tilbageføres efter den er kørt. Det opretter en lønkørsel, hvor de tidligere lønsedler bliver tilbageført.',
  'advanced_setting.setting.allow_negation_payroll.title': 'Tilbageføring af lønsedler',
  'advanced_setting.setting.allow_net_pension.description':
    'Pension indbetales normalt af bruttolønnen. Denne funktion tillader 53A pension af nettolønnen.',
  'advanced_setting.setting.allow_net_pension.title': 'Tillad nettopension',
  'advanced_setting.setting.allow_no_atp.description':
    'Alle lønmodtagere i Danmark skal betale ATP. Der er dog undtagelser, hvis man har direktørløn. Denne funktion muliggøre at slå ATP fra for direktørlønnede.',
  'advanced_setting.setting.allow_no_atp.title': 'Tillad at slå ATP fra',
  'advanced_setting.setting.allow_no_cpr.description':
    'En medarbejder skal normalt have et CPR-nummer. Denne funktion er til hvis man har medarbejdere der ikke har dansk CPR-nummer, f.eks. fordi de er udlændinge der arbejder i udlandet.',
  'advanced_setting.setting.allow_no_cpr.title': 'Tillad intet CPR-nummer på medarbejder',
  'advanced_setting.setting.allow_non_salaried_paid_vacation.description':
    'Time- og provisionslønnet har normalt ikke ferie med løn. Denne funktion tillader ferie med løn for time- og provisionslønnet, samt mulighed for manuelt at udbetale deres feriedagsværdi.  Vær opmærksom på, at med denne indstilling alene, så justeres værdien ikke automatisk i henhold til optjente/afholdte feriedage; f.eks. ved opsigelse vil det være den fulde værdi der udbetales (minus det der er udbetalt som bonus).',
  'advanced_setting.setting.allow_non_salaried_paid_vacation.title': 'Tillad ferie med løn for time-/provisionslønnet',
  'advanced_setting.setting.allow_rate_per_car_allowance.description':
    'Normalt bruges standardsatserne fra SKAT for kørselsgodtgørelse.  Man kan også vælge at overskrive denne sats på medarbejderniveau.  Nogle gange er det ikke helt tilstrækkeligt, med denne funktion kan man registrere en sats per registrering.  Feltet kan efterlades blankt, for at falde tilbage til de almindelige satser.',
  'advanced_setting.setting.allow_rate_per_car_allowance.title': 'Tillad sats per kørselsregistrering',
  'advanced_setting.setting.allow_salary_reduction_b_income.description':
    'Normalt laver man bruttotræk på A-indkomst. Har du fastlønnede der har B-indkomst, så kan det være rart at have muligheden for at kunne lave et B-indkomst-bruttotræk en gang i mellem.',
  'advanced_setting.setting.allow_salary_reduction_b_income.title': 'Tillad B-indkomst på bruttotræk',
  'advanced_setting.setting.allow_salary_type_supplement.description':
    'Denne funktion tillader at man kan indbygge tidsbaseret løntillæg på en timesats.',
  'advanced_setting.setting.allow_salary_type_supplement.title': 'Tidsbaseret løntillæg på timesatser',
  'advanced_setting.setting.allow_sh_fritvalg_direct_pay.description':
    'Normalt skal man selv vælge hvornår udbetalingen af de opsparede SH-dage og/eller Fritvalgsværdien skal finde sted. Med denne funktion, kan man slå til at udbetalingen skal ske for hver lønperiode, hvor kontoen så også tømmes.',
  'advanced_setting.setting.allow_sh_fritvalg_direct_pay.title': 'Tillad periodisk udbetaling af SH-dage og Fritvalg',
  'advanced_setting.setting.allow_tp_units_and_rate.description':
    'For engangsbetalinger, såsom diæter og bonus, indtaster man normalt et totalt beløb. Denne funktion muliggør at man kan indtaste en sats og et antal i steddet, hvorfra totalbeløbet beregnes, og antallet og satsen optræder således på lønsedlen.',
  'advanced_setting.setting.allow_tp_units_and_rate.title': 'Tillad sats og antal på engangsbetalinger',
  'advanced_setting.setting.allow_vacation_fund.description':
    'Normalt indberettes ferie til Feriekonto. Denne funktion er til hvis man bruger en anden ferieindberetter, såsom en feriekasse, end Feriekonto.',
  'advanced_setting.setting.allow_vacation_fund.title': 'Tillad anden ferieindberetter',
  'advanced_setting.setting.allow_vacation_rate.description':
    'Normalt får man 12,5% i feriepenge. Med denne funktion kan man vælge mellem 12%, 12,5% eller en højere værdi.',
  'advanced_setting.setting.allow_vacation_rate.title': 'Tillad andet end 12,5% feriepenge',
  'advanced_setting.setting.ask_for_otp_draft_state.description':
    'Engangsbetalinger, såsom diæter og bonus, er normalt godkendt når de oprettes. Denne funktion tillader de kan oprettes som kladder i stedet for at være godkendt.',
  'advanced_setting.setting.ask_for_otp_draft_state.title': 'Tillad kladdetilstand på engangsbetalinger',
  'advanced_setting.setting.contract_templates_enabled.description':
    'Denne funktion gør det muligt at gemme skabeloner for dine medarbejdere, så felterne forudfyldes ved næste oprettelse.',
  'advanced_setting.setting.contract_templates_enabled.title': 'Medarbejderskabeloner',
  'advanced_setting.setting.disable_app_flex_overtime_registration.description':
    'Medarbejdere registrerer normalt fleks eller afspadsering i appen, hvis man har det slået til. Men kan være i konflikt med en fleks-/afspadseringsregistreringsintegration. Denne funktion fjerner fleks- og afspadseringsregistrering i appen.',
  'advanced_setting.setting.disable_app_flex_overtime_registration.title':
    'Fjern registrering af fleks og afspadsering i appen',
  'advanced_setting.setting.disable_app_leave_registration.description':
    'Medarbejdere registrerer normalt fravær i appen, men kan være i konflikt med en fraværsregistreringsintegration. Denne funktion fjerner fraværsregistreringen i appen.',
  'advanced_setting.setting.disable_app_leave_registration.title': 'Fjern registrering af fravær i appen',
  'advanced_setting.setting.disable_app_project_registration.description':
    'Når du bruger projekter, kan dine medarbejdere som udgangspunkt selv registrere deres projekttid i appen. Med denne funktion kan du slå den mulighed fra i appen.',
  'advanced_setting.setting.disable_app_project_registration.title': 'Fjern registrering af projekttid i appen',
  'advanced_setting.setting.disable_app_salary_registration.description':
    'Medarbejdere registrerer normalt løntillægsregistreringer i appen, hvis de har det på deres kontrakt.  Denne funktion fjerner løntillægsregistrering i appen.',
  'advanced_setting.setting.disable_app_salary_registration.title': 'Fjern løntillægsregistrering i appen',
  'advanced_setting.setting.disable_app_time_registration.description':
    'Timelønnede registrerer normalt timer i appen, men kan være i konflikt med en tidsregistreringsintegration. Denne funktion fjerner timetidsregistreringen i appen.',
  'advanced_setting.setting.disable_app_time_registration.title': 'Fjern registrering af timer i appen',
  'advanced_setting.setting.disable_app_upcoming_pay_slip.description':
    'Normalt kan en medarbejder se deres kommende lønsedler i appen (med mindre det er en opsigelseslønseddel). Denne funktion gør at de aldrig kan se kommende lønsedler.',
  'advanced_setting.setting.disable_app_upcoming_pay_slip.title': 'Fjern kommende lønsedler i appen',
  'advanced_setting.setting.disable_car_allowance_registration_method_detailed_on_invite.description':
    'For at kunne registrere kørsel i appen, skal medarbejderens kørselsregistrering være sat til detaljeret. Når medarbejderen bliver inviteret til appen, bliver de automatisk sat til detaljeret. Denne funktion vil slå denne automatiske ændring fra. Du kan altid senere ændre dem til detaljeret.',
  'advanced_setting.setting.disable_car_allowance_registration_method_detailed_on_invite.title':
    'Sæt ikke kørselsregistrering til detaljeret ved invitation til app',
  'advanced_setting.setting.disable_salary_registration_method_detailed_on_invite.description':
    'For at kunne registrere variable løntillæg i appen, skal medarbejderens lønsregistrering være sat til detaljeret. Når medarbejderen bliver inviteret til appen, bliver de automatisk sat til detaljeret. Denne funktion vil slå denne automatiske ændring fra. Du kan altid senere ændre dem til detaljeret.',
  'advanced_setting.setting.disable_salary_registration_method_detailed_on_invite.title':
    'Sæt ikke lønregistrering til detaljeret ved invitation til app',
  'advanced_setting.setting.disable_time_registration_method_detailed_on_invite.description':
    'For at kunne registrere tid i appen, skal medarbejderens tidsregistrering være sat til detaljeret. Når medarbejderen bliver inviteret til appen, bliver de automatisk sat til detaljeret. Denne funktion vil slå denne automatiske ændring fra. Du kan altid senere ændre dem til detaljeret.',
  'advanced_setting.setting.disable_time_registration_method_detailed_on_invite.title':
    'Sæt ikke tidsregistrering til detaljeret ved invitation til app',
  'advanced_setting.setting.enable_app_fee_registration.description':
    'Med denne funktion kan freelancere som er tilmeldt appen selv oprette honorarer, som der bagefter skal godkendes.',
  'advanced_setting.setting.enable_app_fee_registration.title': 'Tillad honoraroprettelse for freelancere i appen',
  'advanced_setting.setting.enable_app_hourly_leave_registration.description':
    'Normalt kan timelønnet ikke registere fravær i medarbejderappen. Denne funktion tillader at de kan registere fravær.',
  'advanced_setting.setting.enable_app_hourly_leave_registration.title':
    'Tillad fraværsregistrering for timelønnet i appen',
  'advanced_setting.setting.enable_e_income_texts.description':
    'Denne funktion tillader at man kan sende tekst til eIndkomst i felt 68.',
  'advanced_setting.setting.enable_e_income_texts.title': 'Tekst til eIndkomst',
  'advanced_setting.setting.enable_employee_dimensions.description':
    'Denne funktion tillader at du kan oprette dimensioner direkte på medarbejderne, enten baseret på dimensioner fra din regnskabsintegration eller brugeroprettede dimensioner, som dog ikke vil blive bogført i regnskabssystemet, men vil skabe en separat linje.',
  'advanced_setting.setting.enable_employee_dimensions.title': 'Tillad medarbejderdimensioner',
  'advanced_setting.setting.enable_flex_and_overtime.description':
    'Denne funktion er til, hvis du vil tillade, at medarbejdere kan registere flekstid og/eller afspadsering.',
  'advanced_setting.setting.enable_flex_and_overtime.title': 'Tillad flekstid og afspadsering',
  'advanced_setting.setting.enable_multiple_work_weeks.description':
    'Normalt har en medarbejder én arbejdsuge, som går igen og igen; men nogen gange kan det være nødvendigt med en cyklus på 2 eller flere uger.',
  'advanced_setting.setting.enable_multiple_work_weeks.title': 'Arbejdscyklus med flere uger',
  'advanced_setting.setting.enable_projects.description':
    'Projekter tillader at registere projekttid for fast-, time-, provisionslønnede og freelancere. Projekter er en del af HR-pakken.',
  'advanced_setting.setting.enable_projects.title': 'Slå projekttidsregistrering til',
  'advanced_setting.setting.enable_proportional_leave_type_vesting.description':
    'Som udgangspunkt vil der blive tildelt det fulde kontraktuelle antal dage fra start af. Med denne indstilling slået til, vil der kun blive tildelt de dage i forhold til hvor langt inde i optjeningsperioden medarbejderen er.',
  'advanced_setting.setting.enable_proportional_leave_type_vesting.title':
    'Forholdsmæssig tildeling af omsorgsdage og feriefridage',
  'advanced_setting.setting.enable_ready_employee_for_payroll.description':
    'Med denne funktion bliver det muligt at markere en medarbejder klar til lønkørsel. Skulle der være en medarbejder der ikke er klar på lønkørsel, vil der optræde en notifikation på lønkørslen. Hver gang der sker en ændring, inklusiv en gennemført lønkørsel, vil markeringen blive fjernet igen.',
  'advanced_setting.setting.enable_ready_employee_for_payroll.title':
    'Funktion til at markere medarbejdere klar til løn',
  'advanced_setting.setting.enable_work_hours_feature.description':
    'Fra 1. juli 2024 er det lovkrav at der skal registeres arbejdstid for fastlønnede.  I Salary kan du og dine medarbejdere registrere arbejdstid.',
  'advanced_setting.setting.enable_work_hours_feature.title': 'Slå arbejdstid til',
  'advanced_setting.setting.great_prayer_day_enable_default.description':
    'Når denne indstilling er slået til, vil Store Bededagstillægget per standard være slået til under oprettelse.  Man kan altid ændre om medarbejderen skal have tillægget.',
  'advanced_setting.setting.great_prayer_day_enable_default.title': 'Vælg Store Bededagstillæg per standard',
  'advanced_setting.setting.great_prayer_day_for_all.description':
    'Som udgangspunkt er Store Bededagstillægget forbeholdt fastlønnede.  Ved at slå denne indstilling til, vil det være muligt at tildele tillægget til timelønnede.',
  'advanced_setting.setting.great_prayer_day_for_all.title': 'Store Bededagstillæg for timelønnede',
  'advanced_setting.setting.great_prayer_day_periodically.description':
    'Som udgangspunkt bliver Store Bededagstillægget udbetalt samtidigt med ferietillægget (maj og august), med denne indstilling slået til, vil udbetalingen ske hver lønperiode.',
  'advanced_setting.setting.great_prayer_day_periodically.title': 'Udbetal Store Bededagstillæg periodisk',
  'advanced_setting.setting.include_benefits_in_pension_basis.description':
    'Værdierne fra personalegoder — såsom fri telefon — indkluderes normalt ikke i pensionsgrundlaget. Denne funktion vil indkludere dem i pensionsgrundlaget.',
  'advanced_setting.setting.include_benefits_in_pension_basis.title':
    'Inkludér værdi fra personalegoder i pensionsgrundlag',
  'advanced_setting.setting.include_gpd_in_pension.description':
    'Normalt bliver store bededagstillægget ikke inkluderet i pensionsberegningen, men med denne indstilling slået til, vil den del af tillægget, som ikke er afhængig af pensionsberegningen fra arbejdsgivers andel, kunne inkluderes.',
  'advanced_setting.setting.include_gpd_in_pension.title': 'Inkludér store bededagstillægget i pensionsberegningen',
  'advanced_setting.setting.include_vacation_supplements_in_pension_basis.description':
    'Denne funktion tillader, at man kan inkludere ferietillægget i pensionsgrundlaget på medarbejderniveau.',
  'advanced_setting.setting.include_vacation_supplements_in_pension_basis.title':
    'Tillad at inkludere ferietillæg i pensionsgrundlag',
  'advanced_setting.setting.more_bonus_options.description':
    'Når man giver sine medarbejdere bonus, har man normalt kun den almindelige og AM-bidragsfri indkomst til rådighed. Denne funktion er til hvis man f.eks. har brug for jubilæumsgratiale eller løn i felt 36, 38 eller 39.',
  'advanced_setting.setting.more_bonus_options.title': 'Flere bonustyper',
  'advanced_setting.setting.more_income_types.description':
    'De fleste virksomheder udbetaler normalt kun A- og B-indkomst. Denne funktion til hvis man f.eks. skal bruge skattefri indkomst eller forskerordningen.',
  'advanced_setting.setting.more_income_types.title': 'Flere indkomsttyper',
  'advanced_setting.setting.register_time_registration_start_end.description':
    'Timer registreres normalt i antal timer, men det passer måske ikke med ens generelle registreringsprocesser. Med denne funktion kan man slå til, at tidsregistreringer skal registeres med fra/til-felter, samt at man kan indtaste pause i antal minutter.',
  'advanced_setting.setting.register_time_registration_start_end.title':
    'Registrér timer med fra/til i stedet for antal',
  'advanced_setting.setting.sh_fritvalg_increase_types.description':
    'SH-dage/Fritvalg optjenes normalt per lønkørsel. Denne funktion er til at give SH-dage/Fritvalg-forøgelse som en bonus, uden medarbejderne har dem på sin ansættelse.',
  'advanced_setting.setting.sh_fritvalg_increase_types.title': 'Forøgelse af SH-dage/Fritvalg som bonustype',
  'advanced_setting.setting.treat_hourly_vacation_as_salaried.description':
    'Normalt ignoreres regnskabet for optjent/afholdt ferie for time-/provisionslønnede når det kommer til værdien af den optjente ferie.  Det kan dog være et problem for dem med ferie med løn.  Med denne funktion, vil Salary behandle time-/provisionslønnedes ferie som om, de er fastlønnede.  Salary vil automatisk reducere værdien af afholdt ferie i forhold til det optjente.',
  'advanced_setting.setting.treat_hourly_vacation_as_salaried.title':
    'Betragt time-/provisionslønnet ferie som fastlønnet',
  'advanced_setting.setting.use_adjusted_work_week_leave_rate.description':
    'Normalt tæller 1 fridag som 1 fridag. Men hvis ens arbejdsuge kun er på 4 dage, så er 1 fridag 1,25 fridage værd. Denne funktion vil automatisk justere værdien af fridage baseret på medarbejdernes arbejdsuge, samt tillade registrering af fravær i weekender, hvis arbejdsugen tillader det.',
  'advanced_setting.setting.use_adjusted_work_week_leave_rate.title': 'Justér fraværsværdi efter arbejdsuge',
  'advanced_setting.setting.use_end_for_time_registration_supplement_overlap.description':
    'Bruger du fra/til på tidsregistreringer, er det muligt at indtaste en pause, og bruger du samtidigt tidsbestemte løntillæg, er det væsentligt hvornår pausen falder. Normalt beregnes pausen til at være i slutningen af tidsregistreringen (f.eks. en tidsregistrering fra 12 til 18 med en pause på 30 minutter, fortolkes som 12:00 til 17:30). Med denne funktion beregnes det omvendt (så i det foregående eksempel vil være 12:30 til 18:00).',
  'advanced_setting.setting.use_end_for_time_registration_supplement_overlap.title':
    'Placér pauser i tidsregistreringer ved starttidspunktet',
  'advanced_setting.setting.use_standard_rates_for_unknown_atp_hours.description':
    'Hvad man betaler i ATP er bestemt ud fra antallet af arbejdstimer om måneden. Denne funktion er til hvis man ikke ved hvor mange timer ens ansatte faktisk arbejder om måneden.',
  'advanced_setting.setting.use_standard_rates_for_unknown_atp_hours.title':
    'Brug standard ATP-satser for ukendt timeantal',
  'advanced_setting.setting.work_hour_lunch_limit.description':
    'Hvis en medarbejder har frokostordning, skal man altid betale for frokost. Denne funktion tillader at justere et minimum antal arbejdstimer per tidsregistrering, der er krævet før man skal betale for frokost. Da det er per tidsregistrering, kan det godt udløse flere frokostbetalinger per dag.',
  'advanced_setting.setting.work_hour_lunch_limit.title':
    'Minimum arbejdstimer per tidsregistrering der udløser frokost',
  'advanced_setting.setting.payslip_import.title': 'Lønseddel import (Beta)',
  'advanced_setting.setting.payslip_import.description':
    'Hvis du aktiverer denne indstilling vil du kunne importere medarbejder lønsedler ind i Salary. Det kan gøres under fanen "Medarbejdere" på knappen "Importer medarbejdere".',

  'api_client.scope.read_all': 'Fuld læseadgang',
  'api_client.scope.read_base_data': 'Læseadgang til basaldata',
  'api_client.scope.read_employments': 'Læseadgang til ansættelser',
  'api_client.scope.read_leave': 'Læseadgang til fravær',
  'api_client.scope.read_personal_data': 'Læseadgang til persondata',
  'api_client.scope.write_all': 'Fuld skriveadgang',

  'api_key.edit.form.description': 'Beskrivelse',
  'api_key.edit.form.validation.description_required': 'Beskrivelse skal indtastes',
  'api_key.edit.title.create': 'Opret API-nøgle',
  'api_key.edit.title.edit': 'Rediger API-nøgle',

  'api_keys.description': 'Læs API-dokumentation {link}.',
  'api_keys.description_link': 'her',
  'api_keys.header.add_api_key': 'Tilføj API-nøgle',
  'api_keys.header.add_api_key_lock': 'Opgradér til Basic, for oprette API-nøgler',
  'api_keys.table.actions.delete': 'Slet API-nøgle',
  'api_keys.table.header.created_at': 'Oprettet',
  'api_keys.table.header.description': 'Beskrivelse',
  'api_keys.table.header.key': 'API-nøgle',
  'api_keys.table.header.scopes': 'Rettigheder',
  'api_keys.title': 'API-nøgler',

  'approve_tab.car_allowances.actions.approve': 'Godkend',
  'approve_tab.car_allowances.actions.approve_all': 'Godkend alle kørselsgodtgørelser',
  'approve_tab.car_allowances.header.date': 'Dato',
  'approve_tab.car_allowances.header.employee': 'Medarbejder',
  'approve_tab.car_allowances.header.kilometers': 'Kilometer',
  'approve_tab.car_allowances.header.reason': 'Formål',
  'approve_tab.car_allowances.header.trip': 'Tur',
  'approve_tab.car_allowances.title': 'Kørselsgodtgørelser',
  'approve_tab.employee_name.freelancer': 'freelancer',
  'approve_tab.employee_name.leader': 'Leder',
  'approve_tab.empty': 'Er der ingenting herover, er alting godkendt.',
  'approve_tab.leave_registrations.actions.approve': 'Godkend',
  'approve_tab.leave_registrations.actions.approve_all': 'Godkend alle fraværsregistreringer',
  'approve_tab.leave_registrations.header.date': 'Dato',
  'approve_tab.leave_registrations.header.days': 'Værdi',
  'approve_tab.leave_registrations.header.employee': 'Medarbejder',
  'approve_tab.leave_registrations.header.leave_type': 'Fraværstype',
  'approve_tab.leave_registrations.title': 'Fraværsregistreringer',
  'approve_tab.notification_toggle': 'Notifikationer',
  'approve_tab.one_time_pays.actions.approve': 'Godkend',
  'approve_tab.one_time_pays.actions.approve_all': 'Godkend alle engangsregistreringer',
  'approve_tab.one_time_pays.header.amount': 'Beløb',
  'approve_tab.one_time_pays.header.date': 'Dato',
  'approve_tab.one_time_pays.header.employee': 'Medarbejder',
  'approve_tab.one_time_pays.header.title': 'Tekst',
  'approve_tab.one_time_pays.header.type': 'Type',
  'approve_tab.one_time_pays.table.amount_unit_rate': '{unit} à {rate}',
  'approve_tab.one_time_pays.title': 'Engangsregistreringer (tillæg, fradrag, honorar, m.fl.)',
  'approve_tab.reimbursements_vouchers.actions.approve': 'Godkend',
  'approve_tab.reimbursements_vouchers.actions.approve_all': 'Godkend alle udlæg',
  'approve_tab.reimbursements_vouchers.header.amount': 'Beløb',
  'approve_tab.reimbursements_vouchers.header.date': 'Kvitteringsdato',
  'approve_tab.reimbursements_vouchers.header.employee': 'Medarbejder',
  'approve_tab.reimbursements_vouchers.header.expense_category': 'Udgiftskategori',
  'approve_tab.reimbursements_vouchers.table.missing_category': 'Mangler kategori',
  'approve_tab.reimbursements_vouchers.table.received': 'Modtaget',
  'approve_tab.reimbursements_vouchers.title': 'Udlæg',
  'approve_tab.salary_registrations.actions.approve': 'Godkend',
  'approve_tab.salary_registrations.actions.approve_all': 'Godkend alle lønregistreringer',
  'approve_tab.salary_registrations.header.date': 'Dato',
  'approve_tab.salary_registrations.header.employee': 'Medarbejder',
  'approve_tab.salary_registrations.header.quantity': 'Antal',
  'approve_tab.salary_registrations.header.rate': 'Sats',
  'approve_tab.salary_registrations.table.rate_display': '{name} à {rate}',
  'approve_tab.salary_registrations.title': 'Lønregistreringer',
  'approve_tab.swipes.actions.approve': 'Godkend',
  'approve_tab.swipes.actions.approve_all': 'Godkend alle Swipes',
  'approve_tab.swipes.header.amount': 'Beløb',
  'approve_tab.swipes.header.date': 'Dato',
  'approve_tab.swipes.header.employee': 'Medarbejder',
  'approve_tab.swipes.table.missing_category': 'Mangler kategori',
  'approve_tab.swipes.table.received': 'Modtaget',
  'approve_tab.swipes.title': 'Swipes',
  'approve_tab.time_registrations.actions.approve': 'Godkend',
  'approve_tab.time_registrations.actions.approve_all': 'Godkend alle tidsregistreringer',
  'approve_tab.time_registrations.header.date': 'Dato',
  'approve_tab.time_registrations.header.employee': 'Medarbejder',
  'approve_tab.time_registrations.header.hours': 'Timer',
  'approve_tab.time_registrations.header.rate': 'Sats',
  'approve_tab.time_registrations.table.rate_display': '{name} à {rate}',
  'approve_tab.time_registrations.title': 'Tidsregistreringer',
  'approve_tab.title': 'Afventer godkendelse',

  'asset_category.edit.form.submit.create': 'Tilføj enhedskategori',
  'asset_category.edit.form.submit.edit': 'Ret enhedskategori',
  'asset_category.edit.form.title': 'Titel',
  'asset_category.edit.form.validation.title_required': 'Titel skal indtastes',
  'asset_category.edit.title.create': 'Opret enhedskategori',
  'asset_category.edit.title.edit': 'Rediger enhedskategori',
  'asset_category.header.add_asset_category': 'Tilføj enhedskategori',
  'asset_category.header.assets': 'Enheder',
  'asset_category.header.title': 'Enhedskategorier',
  'asset_category.table.action.delete': 'Slet enhedskategori',
  'asset_category.table.action.edit': 'Redigér enhedskategori',
  'asset_category.table.title': 'Titel',

  'asset.display.received.future': 'Modtages: {date}.',
  'asset.display.received.past': 'Modtaget: {date}.',
  'asset.display.returned.future': 'Indleveres: {date}.',
  'asset.display.returned.past': 'Indleveret: {date}.',
  'asset.edit.form.category_id': 'Kategori',
  'asset.edit.form.category_id.required': 'Kategori skal vælges',
  'asset.edit.form.description': 'Beskrivelse',
  'asset.edit.form.note': 'Note',
  'asset.edit.form.received_date': 'Udleveringsdato',
  'asset.edit.form.received_date.not_future': 'Udleveringsdato må ikke være i fremtiden',
  'asset.edit.form.return_date': 'Indleveringsdato',
  'asset.edit.form.return_date.before_received': 'Indleveringsdato må ikke være før udleveringsdato',
  'asset.edit.form.submit.create': 'Opret enhed',
  'asset.edit.form.submit.edit': 'Redigér enhed',
  'asset.edit.form.title': 'Enhed',
  'asset.edit.form.title.required': 'Enhed skal indtastes',
  'asset.edit.title.create': 'Opret enhed',
  'asset.edit.title.edit': 'Rediger enhed',
  'asset.tab.confirm.delete': 'Slet. Er du sikker?',
  'asset.tab.confirm.hide': 'Fjern. Er du sikker?',
  'asset.tab.confirm.receive': 'Genudlevering. Er du sikker?',
  'asset.tab.confirm.return': 'Tilbagelevering. Er du sikker?',
  'asset.tab.confirm.returning': 'Tilbagelevering af enhed',
  'asset.tab.header.add_asset': 'Registrér enhed',
  'asset.tab.outstanding.table.actions.delete': 'Slet enhed',
  'asset.tab.outstanding.table.actions.edit': 'Redigér enhed',
  'asset.tab.outstanding.table.actions.return': 'Tilbagelevér enhed',
  'asset.tab.outstanding.table.header.category': 'Kategori',
  'asset.tab.outstanding.table.header.description': 'Beskrivelse',
  'asset.tab.outstanding.table.header.received': 'Modtaget',
  'asset.tab.outstanding.table.header.title': 'Enhed',
  'asset.tab.outstanding.table.not_received': 'Ikke modtaget',
  'asset.tab.returned.table.actions.hide': 'Fjern enhed',
  'asset.tab.returned.table.actions.receive': 'Tilbageudlevér enhed',
  'asset.tab.returned.table.header.category': 'Kategori',
  'asset.tab.returned.table.header.description': 'Beskrivelse',
  'asset.tab.returned.table.header.returned': 'Afleveret',
  'asset.tab.returned.table.header.title': 'Enhed',
  'asset.tab.title.outstanding': 'Enheder',
  'asset.tab.title.returned': 'Tilbageleverede enheder',

  'assets.header.asset_categories': 'Enhedskategorier',
  'assets.standard.title': 'Enheder for medarbejdere',
  'assets.table.header.employee': 'Medarbejder',
  'assets.table.header.received': 'Udlånt',
  'assets.table.header.returned': 'Tilbageleveret',
  'assets.terminated.title': 'Enheder for opsagte medarbejdere',

  'asynchronous_task.schedule_type.leave_registration_export': 'fraværeksport',
  'asynchronous_task.status.executing': 'Igang',
  'asynchronous_task.status.failed': 'Fejlet',
  'asynchronous_task.status.pending': 'Afventer',
  'asynchronous_task.status.success': 'Færdig',

  'balance_adjustment_tab.leave.edit.form.add_valuation': 'Inkludér værdi af feriedagene',
  'balance_adjustment_tab.leave.edit.form.disposition_date': 'Korrektionsdato',
  'balance_adjustment_tab.leave.edit.form.disposition_date.required': 'Korrektionsdato skal vælges',
  'balance_adjustment_tab.leave.edit.form.earned': 'Optjent',
  'balance_adjustment_tab.leave.edit.form.leave_type_name': 'Ferietype',
  'balance_adjustment_tab.leave.edit.form.leave_type_name.required': 'Ferietype skal vælges',
  'balance_adjustment_tab.leave.edit.form.liability': 'Værdi',
  'balance_adjustment_tab.leave.edit.form.liability.suffix': 'kr.',
  'balance_adjustment_tab.leave.edit.form.operation': 'Handling',
  'balance_adjustment_tab.leave.edit.form.operation.increase': 'Forøg',
  'balance_adjustment_tab.leave.edit.form.operation.override': 'Overskriv',
  'balance_adjustment_tab.leave.edit.form.operation.reduce': 'Nedskriv',
  'balance_adjustment_tab.leave.edit.form.operation.required': 'Handling skal vælges',
  'balance_adjustment_tab.leave.edit.form.used': 'Brugt',
  'balance_adjustment_tab.leave.edit.form.valuation.add': 'Værdi af feriedagene',
  'balance_adjustment_tab.leave.edit.form.valuation.format':
    '({rate} × {multiplier}%) / {divider} dage × {earned} {suffix}',
  'balance_adjustment_tab.leave.edit.form.valuation.required': 'Værdi af feriedagene skal indtastes',
  'balance_adjustment_tab.leave.edit.form.valuation.suffix': 'kr.',
  'balance_adjustment_tab.leave.edit.form.value.balance': 'Saldo',
  'balance_adjustment_tab.leave.edit.form.value.need_one': 'Én skal sættes',
  'balance_adjustment_tab.leave.edit.intro':
    'En korrektion af feriesaldoen kan ændres ved at forøge (lægge til), nedskrive (trække fra) eller overskrive værdierne.',
  'balance_adjustment_tab.leave.edit.title.create': 'Opret feriekorrektion',
  'balance_adjustment_tab.leave.edit.title.edit': 'Redigér feriekorrektion',
  'balance_adjustment_tab.leave.header.balances': 'Saldi',
  'balance_adjustment_tab.leave.header.new_adjustment': 'Opret feriesaldokorrektion',
  'balance_adjustment_tab.leave.header.title': 'Feriesaldokorrektioner',
  'balance_adjustment_tab.supplements.edit.form.disposition_date': 'Korrektionsdato',
  'balance_adjustment_tab.supplements.edit.form.disposition_date.required': 'Korrektionsdato skal vælges',
  'balance_adjustment_tab.supplements.edit.form.earned': 'Optjent',
  'balance_adjustment_tab.supplements.edit.form.operation': 'Handling',
  'balance_adjustment_tab.supplements.edit.form.operation.increase': 'Forøg',
  'balance_adjustment_tab.supplements.edit.form.operation.override': 'Overskriv',
  'balance_adjustment_tab.supplements.edit.form.operation.reduce': 'Nedskriv',
  'balance_adjustment_tab.supplements.edit.form.operation.required': 'Handling skal vælges',
  'balance_adjustment_tab.supplements.edit.form.supplement_type_name': 'Tillæg',
  'balance_adjustment_tab.supplements.edit.form.supplement_type_name.required': 'Tillæg skal vælges',
  'balance_adjustment_tab.supplements.edit.form.value.need_one': 'Én skal sættes',
  'balance_adjustment_tab.supplements.edit.intro':
    'En korrektion af tillægssaldoen kan ændres ved at forøge (lægge til), nedskrive (trække fra) eller overskrive værdierne.',
  'balance_adjustment_tab.supplements.edit.title.create': 'Opret tillægskorrektion',
  'balance_adjustment_tab.supplements.edit.title.edit': 'Redigér tillægskorrektion',
  'balance_adjustment_tab.supplements.header.add_adjustment': 'Opret tillægssaldokorrektion',
  'balance_adjustment_tab.supplements.header.balances': 'Saldi',
  'balance_adjustment_tab.supplements.header.title': 'Tillægssaldokorrektioner',
  'balance_adjustment_tab.table.actions.delete': 'Slet korrektion',
  'balance_adjustment_tab.table.actions.edit': 'Redigér korrektion',
  'balance_adjustment_tab.table.header.adjustment': 'Korrektion',
  'balance_adjustment_tab.table.header.date': 'Dato',
  'balance_adjustment_tab.table.header.type_name': 'Type',
  'balance_adjustment_tab.table.operation.increase': 'Forøgelse',
  'balance_adjustment_tab.table.operation.override': 'Overskrivning',
  'balance_adjustment_tab.table.operation.reduce': 'Nedskrivning',
  'balance_adjustment_tab.table.type.complementary': 'Tildelt',
  'balance_adjustment_tab.table.type.earned': 'Optjent',
  'balance_adjustment_tab.table.type.liability': 'Forpligtelse',
  'balance_adjustment_tab.table.type.paid_out': 'Udbetalt',
  'balance_adjustment_tab.table.type.used': 'Brugt',
  'balance_adjustment_tab.table.type.valuation': 'Værdi',
  'balance_adjustment_tab.table.type.written_off': 'Afskrevet',

  'basic_integrations.advisor_service.buttons.offer': 'Find revisor',
  'basic_integrations.advisor_service.buttons.questions': 'Spørgsmål? Skriv til os her',
  'basic_integrations.advisor_service.intro':
    'Salary samarbejder med Ageras, så du nemt kan finde den rette bogholder eller revisor til at hjælpe med din lønadministration. Sammenlign tilbud og vælg selv den ekspert, du gerne vil arbejde sammen med.',
  'basic_integrations.advisor_service.points.0': 'Få tilbud fra revisorer og bogholdere indenfor 24 timer.',
  'basic_integrations.advisor_service.points.1':
    'Let overblik over dine tilbud. Sammenlign priser, kompetencer, anmeldelser m.m.',
  'basic_integrations.advisor_service.points.2':
    'Frihed til at vælge selv, hvilken ekspert, du ønsker at samarbejde med.',
  'basic_integrations.advisor_service.points.3': '100% risikofrit, uforpligtende og gratis.',
  'basic_integrations.advisor_service.points.4': 'Nemt, hurtigt og sikkert.',
  'basic_integrations.advisor_service.points.5': 'Passioneret team, der altid står klar til at hjælpe dig.',
  'basic_integrations.advisor_service.points.6': 'Ageras er din garanti for at finde den rette ekspert.',
  'basic_integrations.advisor_service.quote.line_1':
    '"[...] Jeg opslog mine ønsker søndag aften, blev kontaktet telefonisk dagen efter af Ageras med henblik på at præcisere mine behov. Senere mandag formiddag blev jeg kontaktet af en udbyder med nøjagtig det speciale jeg skulle have hjælp til.',
  'basic_integrations.advisor_service.quote.line_2': 'Venlig og fin service hele vejen i processen hos Ageras"',
  'basic_integrations.advisor_service.title': 'Find din revisor eller bogholder med Ageras',
  'basic_integrations.codan.advise_link': '15 råd der forebygger risikoen for indbrud og skader',
  'basic_integrations.codan.alert.success': 'Ændringer til {name} er gemt',
  'basic_integrations.codan.has_integration.line_1': 'Integration med Codan er kørende.',
  'basic_integrations.codan.has_integration.line_2':
    'Vi sender automatisk jeres til- og afmeldinger af medarbejdere til Codan, som samtidig vil sikre en løbende kontakt til jer for at holde jeres police opdateret.',
  'basic_integrations.codan.no_integration.line_1':
    'Salary samarbejder med Codan om den lovpligtige arbejdsskadeforsikring.',
  'basic_integrations.codan.no_integration.line_2':
    'Hvis I ønsker et uforpligtende tilbud fra Codan, eller hvis I allerede er kunde i Codan, så klik på den grønne knap: Bliv kontaktet af Codan',
  'basic_integrations.codan.no_integration.line_3':
    'Codan ringer jer herefter op for et uforpligtende tilbud. Og er I allerede kunde, så adviserer Salary automatisk Codan hver gang en medarbejder tilføjes eller fjernes. Denne automatik sker straks efter, vi har godkendt jeres kundeforhold i Codan. Herved har I altid det rette antal medarbejdere forsikret på den lovpligtige arbejdsskade.',
  'basic_integrations.codan.pending_integration.line_1':
    'Vi har endnu ikke fået besked fra Codan, om I er kunde. Så snart vi gør, vil til- og afmeldinger af medarbejdere på arbejdsskadeforsikringen automatisk blive opdateret på forsikringspolicen.',
  'basic_integrations.codan.privacy_policy_link': 'Læs Codans persondata- og privatlivspolitik',
  'basic_integrations.codan.start_integration': 'Bliv kontaktet af Codan',
  'basic_integrations.codan.stop_integration.button': 'Stop integration',
  'basic_integrations.codan.stop_integration.title': 'Stop integration',
  'basic_integrations.grandhood.not_completed.buttons.book': 'Spørgsmål? Book møde',
  'basic_integrations.grandhood.not_completed.buttons.sign': 'Underskriv aftale',
  'basic_integrations.grandhood.not_completed.intro':
    'Vi har allerede oprettet en firmaaftale til dig - du mangler bare at underskrive med NemID, og så får du adgang til alle fordelene ved en moderne firmapension:',
  'basic_integrations.grandhood.not_completed.points.0':
    'Fuldt integreret - Slip for at indtaste information i både Salary og dit pensionprogram.',
  'basic_integrations.grandhood.not_completed.points.1':
    'Vi ved, du er digital. Det er vi også. Gratis pensionsrådgivning i vores prisvindende app.',
  'basic_integrations.grandhood.not_completed.points.2':
    'Vi sparer penge ved digital pensionsrådgivning, du sparer penge på opsparing og forsikringer.',
  'basic_integrations.grandhood.not_completed.points.3':
    '12 sekunder fra du ringer, til vi tager telefonen. Hurtigere end den gennemsnitlige teenager.',
  'basic_integrations.grandhood.not_completed.points.4':
    'Grandhood er Danmarks bedst ratede pensionsudbyder på Trustpilot.',
  'basic_integrations.grandhood.not_completed.quote':
    '"Jeg tror, det i høj grad handler om, at vores mål er at skabe en arbejdsplads, hvor folk glæder sig til at gå på arbejde. Sådan har vi det selv, og sådan synes vi alle skulle have det."',
  'basic_integrations.grandhood.not_completed.title': 'Du er kun én underskrift fra at have en firmapension',
  'basic_integrations.grandhood.not_connected.buttons.connect': 'Forbind til Grandhood',
  'basic_integrations.grandhood.not_connected.intro':
    'Vidste du, at {strong}? Det betyder, at du slipper du for at indtaste de samme medarbejderoplysninger i to forskellige systemer. Alt du skal gøre er at trykke "Forbind til Grandhood" og følge guiden. Det tager ikke mere end et par minutter.',
  'basic_integrations.grandhood.not_connected.intro.strong': 'Salary er fuldt integreret med {link}',
  'basic_integrations.grandhood.not_connected.title': 'Undgå dobbeltarbejde',
  'basic_integrations.grandhood.not_registered.buttons.read_more': 'Læs mere',
  'basic_integrations.grandhood.not_registered.buttons.start': 'Opret firmapension',
  'basic_integrations.grandhood.not_registered.intro':
    'Salary er {strong}. Bruger du Grandhood som pensionsudbyder, slipper du for at indtaste samme information i både Salary og dit pensionsprogram, da Grandhood automatisk synkroniserer dine data.',
  'basic_integrations.grandhood.not_registered.intro.strong': 'fuldt integreret med {link}',
  'basic_integrations.grandhood.not_registered.points.0':
    'Fuldt integreret - Slip for at indtaste information i både Salary og dit pensionprogram.',
  'basic_integrations.grandhood.not_registered.points.1':
    'Vi ved, du er digital. Det er vi også. Gratis pensionsrådgivning i vores prisvindende app.',
  'basic_integrations.grandhood.not_registered.points.2':
    'Vi sparer penge ved digital pensionsrådgivning, du sparer penge på opsparing og forsikringer.',
  'basic_integrations.grandhood.not_registered.points.3':
    '12 sekunder fra du ringer, til vi tager telefonen. Hurtigere end den gennemsnitlige teenager.',
  'basic_integrations.grandhood.not_registered.points.4':
    'Grandhood er Danmarks bedst ratede pensionsudbyder på Trustpilot.',
  'basic_integrations.grandhood.not_registered.quote':
    '"Jeg synes, det er fantastisk, at vi som virksomhed kan gå sammen og tilbyde medarbejderne en lang række forsikringer, som ville være dyre og besværlige at få adgang til som enkeltperson"',
  'basic_integrations.grandhood.not_registered.title': 'Tænk pension, bare integreret med dit lønsystem.',
  'basic_integrations.grandhood.registered.buttons.book': 'Spørgsmål? Book møde',
  'basic_integrations.grandhood.registered.buttons.go_to': 'Til Grandhood',
  'basic_integrations.grandhood.registered.intro':
    'Fordi du har integreret Salary med Grandhood, slipper du for at indtaste de samme medarbejderoplysninger i to forskellige systemer. Og så har du selvfølgelig også adgang til alle fordelene ved en moderne firmapension:',
  'basic_integrations.grandhood.registered.points.0':
    'Fuldt integreret - Slip for at indtaste information i både Salary og dit pensionprogram.',
  'basic_integrations.grandhood.registered.points.1':
    'Vi ved, du er digital. Det er vi også. Gratis pensionsrådgivning i vores prisvindende app.',
  'basic_integrations.grandhood.registered.points.2':
    'Vi sparer penge ved digital pensionsrådgivning, du sparer penge på opsparing og forsikringer.',
  'basic_integrations.grandhood.registered.points.3':
    '12 sekunder fra du ringer, til vi tager telefonen. Hurtigere end den gennemsnitlige teenager.',
  'basic_integrations.grandhood.registered.points.4':
    'Grandhood er Danmarks bedst ratede pensionsudbyder på Trustpilot.',
  'basic_integrations.grandhood.registered.title': 'Du er allerede integreret med Grandhood',
  'basic_integrations.min_refusion.buttons.install': 'Forbind til MinRefusion',
  'basic_integrations.min_refusion.buttons.read_more': 'Læs mere',
  'basic_integrations.min_refusion.buttons.api_key': 'API-nøgler',
  'basic_integrations.min_refusion.text.line_1':
    'MinRefusion sikrer dig udbetalingerne under sygdom og barsel. MinRefusion er fremtidens refusionsportal, som indberetter fravær som sygdom og barsel 100% automatisk og fejlfrit på vegne af din virksomhed. Med integrationen til Salary modtager MinRefusion helt automatisk fraværsdata, når du under fraværsregistrering opretter fravær på en medarbejder.',
  'basic_integrations.min_refusion.text.line_2':
    'Når fraværet opfylder de rigtige betingelser, søger MinRefusion refusion fra det offentlige. Du sparer tiden, slipper for besværet og får udbetalingen direkte ind på kontoen.',
  'basic_integrations.min_refusion.text.line_3':
    'MinRefusion er godkendt leverandør til NemRefusion, og vores ekspertise sikrer derfor fejlfrie indberetninger og udbetalinger til din virksomhed.',
  'basic_integrations.min_refusion.text.guide_title': 'Sådan forbinder du til MinRefusion',
  'basic_integrations.min_refusion.text.guide_1':
    '1. Tryk på knappen "API-nøgler" herunder, og i det nye vindue, der åbnes, skal du tilføje en ny API-nøgle. Knappen “Fuld læseadgang” skal være slået til, når du opretter nøglen.',
  'basic_integrations.min_refusion.text.guide_2': '2. Kopier din nye API-nøgle.',
  'basic_integrations.min_refusion.text.guide_3': '3. Tryk på "Forbind til MinRefusion" og følg deres proces.',

  'basic_integrations.min_refusion.title': 'Forbind MinRefusion til Salary og modtag refusion automatisk',
  'basic_integrations.personale_zonen.line_1':
    'Med Medarbejderfordele kan du forkæle dine medarbejdere med adgang til en verden af fordele, hvor de får store besparelser, hos nogle af landets førende leverandører af dagligvarer, benzin, personlig pleje, rejser og meget, meget mere.',
  'basic_integrations.personale_zonen.line_2':
    'Medarbejderfordele er en effektiv måde at belønne jeres ansatte for den gode indsats, og der er noget for enhver smag.',
  'basic_integrations.personale_zonen.sign_up': 'Opret virksomhed hos PersonaleZonen',
  'basic_integrations.rmindr.line_1':
    'Som selvstændig i Danmark kan det være svært at hente information om dine virksomhedsfrister. Det betyder, at der er en risiko for at du som virksomhedsejer kan misse vigtige frister for din virksomhed. Frister, som kan have alvorlige konsekvenser for dig.',
  'basic_integrations.rmindr.line_2': 'Det betyder, at du løbende er ajour med din virksomhedsøkonomi.',
  'basic_integrations.rmindr.line_3': 'Få overblik over følgende i appen:',
  'basic_integrations.rmindr.line_3.points.0': 'Skattekonto',
  'basic_integrations.rmindr.line_3.points.1': 'Årsrapport',
  'basic_integrations.rmindr.line_3.points.2': 'Selvangivelse',
  'basic_integrations.rmindr.line_3.points.3': 'Moms',
  'basic_integrations.rmindr.line_3.points.4': 'A-skat',
  'basic_integrations.rmindr.line_3.points.5': 'Lønsumsafgift',
  'basic_integrations.rmindr.line_4':
    'RMINDR sender dig notifikationer forud for fristens udløb, så du undgår bøder og gebyrer fra de offentlige myndigheder.',
  'basic_integrations.rmindr.line_5':
    'Som Salary.dk-bruger får du 20 % rabat på et pro abonnement hos RMINDR ved at anvende rabatkoden {code}.',
  'basic_integrations.rmindr.sign_up': 'Glem intet med Rmindr i dag!',
  'basic_integrations.rmindr.title': 'Undgå bøder, få overblik over dine frister.',

  'benefit.card.board_lodging.value': '{amount} i skattemæssig værdi',
  'benefit.card.edit.title': 'Redigér personalegoder',
  'benefit.card.employee_association.value': '{amount} i nettotræk per periode',
  'benefit.card.employee_increase.value': '{amount} i udlæg per periode',
  'benefit.card.generic.false': 'Nej',
  'benefit.card.generic.true': 'Ja',
  'benefit.card.items.board_lodging': 'Fri kost og logi',
  'benefit.card.items.employee_association': 'Personaleforening',
  'benefit.card.items.employee_increase': 'Fast udlæg',
  'benefit.card.items.employee_stock': 'Medarbejderaktier',
  'benefit.card.items.forenede_gruppeliv': 'Forenede Gruppeliv',
  'benefit.card.items.free_transport': 'Fri befordring mellem hjem og arbejde',
  'benefit.card.items.health': 'Sundhedsforsikring',
  'benefit.card.items.paid_car': 'Fri bil',
  'benefit.card.items.paid_internet': 'Fri internet',
  'benefit.card.items.paid_lunch': 'Frokostordning',
  'benefit.card.items.paid_telephone': 'Fri telefon',
  'benefit.card.items.permanent_residence': 'Fri helårsbolig',
  'benefit.card.paid_car.managed.acquired': '{prefix} (anskaffet {date})',
  'benefit.card.paid_car.managed.value': '{value} i værdi',
  'benefit.card.paid_car.managed.value_own': '{value} i værdi med {own} egenbetaling',
  'benefit.card.paid_car.simple': '{amount} i beskatning',
  'benefit.card.paid_health.value': '{amount} i skattemæssig værdi',
  'benefit.card.paid_lunch.daily': '{amount} i nettotræk per dag',
  'benefit.card.paid_lunch.regular': '{amount} i nettotræk per periode',
  'benefit.card.permanent_residence.value': '{amount} indberettes',
  'benefit.card.table.header.reduction': 'Træk per periode',
  'benefit.card.table.header.reporting': 'Beløb som indberettes',
  'benefit.card.table.header.title': 'Beskrivelse',
  'benefit.card.table.reduction.gross.pension.false': 'Påvirker {not} pensionsgrundlaget',
  'benefit.card.table.reduction.gross.pension.false.not': 'ikke',
  'benefit.card.table.reduction.gross.pension.true': 'Påvirker pensionsgrundlaget',
  'benefit.card.table.reduction.gross.vacation.false': 'Påvirker {not} feriegrundlaget',
  'benefit.card.table.reduction.gross.vacation.false.not': 'ikke',
  'benefit.card.table.reduction.gross.vacation.true': 'Påvirker feriegrundlaget',
  'benefit.card.table.reduction.number_format': '{amount} {suffix}',
  'benefit.card.table.reduction.number_format.suffix_gross': 'kr. brutto per periode',
  'benefit.card.table.reduction.number_format.suffix_net': 'kr. netto per periode',
  'benefit.card.table.reporting.number_format': '{amount} {suffix}',
  'benefit.card.table.reporting.number_format.suffix': 'kr.',
  'benefit.card.title': 'Personalegoder',
  'benefit.form.board_lodging.intro.line_1':
    'Når du betaler kost og logi for dine ansatte, skal de betale skat af det, med mindre det er på en rejse eller i forbindelse med et midlertidigt arbejdssted.',
  'benefit.form.board_lodging.intro.line_2':
    'Salarys nuværende løsning dækker følgende områder: landbrug m.v., hushjælp, sygehuspersonale, køkken- og serveringspersonale samt private sygeplejersker der har fri kost på ansættelsesstedet.',
  'benefit.form.board_lodging.intro.line_3': 'Satserne kan findes {link}.',
  'benefit.form.board_lodging.intro.line_3.link': 'her',
  'benefit.form.board_lodging.intro.line_4':
    'Du skal skrive det totale beløb i feltet de skal beskattes af i denne måned.',
  'benefit.form.board_lodging.lodging_tax_value': 'Skattemæssig værdi',
  'benefit.form.board_lodging.lodging_tax_value.invalid': 'Skattemæssig værdi er ugyldig',
  'benefit.form.board_lodging.lodging_tax_value.required': 'Skattemæssig værdi skal indtastes',
  'benefit.form.board_lodging.lodging_tax_value.suffix': 'kr. per måned',
  'benefit.form.board_lodging.title': 'Fri kost og logi',
  'benefit.form.car.car_acquisition_date': 'Anskaffelsesdato',
  'benefit.form.car.car_acquisition_date.required': 'Anskaffelsesdato skal indtastes',
  'benefit.form.car.car_first_license_plate_date': 'Første indregistreringsdato',
  'benefit.form.car.car_first_license_plate_date.required': 'Første indregistreringsdato skal indtastes',
  'benefit.form.car.car_majority_shareholder': 'Hovedaktionær',
  'benefit.form.car.car_majority_shareholder.help_text':
    'Hvis medarbejderen er hovedaktionær, skal dette felt slås til, så det indberettes korrekt til SKAT med indtægtsart 61.',
  'benefit.form.car.car_netto_amount': 'Egenbetaling',
  'benefit.form.car.car_netto_amount.invalid': 'Egenbetaling er ugyldig',
  'benefit.form.car.car_netto_amount.suffix': 'kr. per periode',
  'benefit.form.car.car_tax_value': 'Beskatning per periode',
  'benefit.form.car.car_tax_value.invalid': 'Beskatning per periode er ugyldig',
  'benefit.form.car.car_tax_value.required': 'Beskatning per periode skal indtastes',
  'benefit.form.car.car_tax_value.suffix': 'kr.',
  'benefit.form.car.car_tax_value.warning': 'Beregningen af tallet er på eget ansvar.',
  'benefit.form.car.car_valuation': 'Værdi',
  'benefit.form.car.car_valuation.invalid': 'Værdi er ugyldig',
  'benefit.form.car.car_valuation.required': 'Værdi skal indtastes',
  'benefit.form.car.car_valuation.suffix': 'kr.',
  'benefit.form.car.car_yearly_environmental_tax': 'Årlig periodisk afgift',
  'benefit.form.car.car_yearly_environmental_tax.invalid': 'Årlig periodisk afgift er ugyldig',
  'benefit.form.car.car_yearly_environmental_tax.required': 'Årlig periodisk afgift skal indtastes',
  'benefit.form.car.car_yearly_environmental_tax.suffix': 'kr. per år',
  'benefit.form.car.has_easy_car': 'Nem indtastning',
  'benefit.form.car.title': 'Fri bil',
  'benefit.form.employee_association.amount': 'Nettotræk',
  'benefit.form.employee_association.amount.invalid': 'Nettotræk er ugyldig',
  'benefit.form.employee_association.amount.required': 'Nettotræk skal indtastes',
  'benefit.form.employee_association.amount.suffix': 'kr. per periode',
  'benefit.form.employee_stock.stock_text': 'Tekst til medarbejderaktier §7P (Indtægtsart 0101) som indberettes',
  'benefit.form.employee_stock.stock_text.max_58_characters': 'Tekst til medarbejderaktier må maks. være 58 tegn',
  'benefit.form.employee_stock.stock_text.required': 'Tekst til medarbejderaktier som indberettes skal indtastes',
  'benefit.form.fixed_disbursement.add_row.empty': 'Tilføj udlæg',
  'benefit.form.fixed_disbursement.add_row.not_empty': 'Tilføj ny række',
  'benefit.form.fixed_disbursement.header.increase': 'Udlæg per periode',
  'benefit.form.fixed_disbursement.header.title': 'Beskrivelse',
  'benefit.form.fixed_disbursement.increase': 'Beløb',
  'benefit.form.fixed_disbursement.increase.required': 'Beløb skal indtastes',
  'benefit.form.fixed_disbursement.increase.suffix': 'kr. per periode',
  'benefit.form.fixed_disbursement.intro.title': 'Fast udlæg',
  'benefit.form.fixed_disbursement.title': 'Beskrivelse',
  'benefit.form.fixed_disbursement.title.required': 'Beskrivelse skal indtastes',
  'benefit.form.forenede_gruppeliv.code': 'Medlemsnummer',
  'benefit.form.forenede_gruppeliv.code.required': 'Medlemsnummer skal indtastes',
  'benefit.form.forenede_gruppeliv.header.code': 'Medlemsnummer',
  'benefit.form.forenede_gruppeliv.header.tax_value': 'Beløb',
  'benefit.form.forenede_gruppeliv.tax_value': 'Skattemæssig værdi',
  'benefit.form.forenede_gruppeliv.tax_value.invalid': 'Skattemæssig værdi er ugyldig',
  'benefit.form.forenede_gruppeliv.tax_value.required': 'Skattemæssig værdi skal indtastes',
  'benefit.form.forenede_gruppeliv.tax_value.suffix': 'kr. per måned',
  'benefit.form.free_text.add_row.empty': 'Tilføj fritekst',
  'benefit.form.free_text.add_row.not_empty': 'Tilføj ny række',
  'benefit.form.free_text.header.title': 'Tekst',
  'benefit.form.free_text.intro.title': 'Fritekst',
  'benefit.form.free_text.title': 'Tekst',
  'benefit.form.free_text.title.required': 'Tekst skal indtastes',
  'benefit.form.has_board_lodging': 'Fri kost og logi',
  'benefit.form.has_car': 'Fri bil',
  'benefit.form.has_employee_association': 'Personaleforening',
  'benefit.form.has_employee_stock': 'Medarbejderaktier §7P',
  'benefit.form.has_fixed_disbursement': 'Fast udlæg',
  'benefit.form.has_forenede_gruppeliv': 'Forenede Gruppeliv',
  'benefit.form.has_free_text': 'Fritekst',
  'benefit.form.has_free_transport': 'Fri transport til/fra arbejde',
  'benefit.form.has_health': 'Sundhedsforsikring',
  'benefit.form.has_internet': 'Fri internet',
  'benefit.form.has_lunch': 'Frokostordning',
  'benefit.form.has_pay_reductions': 'Fast træk',
  'benefit.form.has_permanent_residence': 'Fri helårsbolig',
  'benefit.form.has_tax_reporting_no_threshold': 'Andre personalegoder uden bagatelgrænse',
  'benefit.form.has_tax_reportings': 'Andre personalegoder',
  'benefit.form.has_telephone': 'Fri telefoni',
  'benefit.form.health.header.health': 'Sundhedsforsikring',
  'benefit.form.health.tax_value': 'Skattemæssig værdi',
  'benefit.form.health.tax_value.invalid': 'Skattemæssig værdi er ugyldig',
  'benefit.form.health.tax_value.required': 'Skattemæssig værdi skal indtastes',
  'benefit.form.health.tax_value.suffix': 'kr. per måned',
  'benefit.form.lunch.has_lunch_daily.false': 'Fast beløb',
  'benefit.form.lunch.has_lunch_daily.true': 'Per dag',
  'benefit.form.lunch.header.lunch': 'Frokostordning',
  'benefit.form.lunch.netto_amount': 'Nettotræk',
  'benefit.form.lunch.netto_amount.invalid': 'Nettotræk er ugyldig',
  'benefit.form.lunch.netto_amount.required': 'Nettotræk skal indtastes',
  'benefit.form.lunch.netto_amount.suffix.daily': 'kr. per dag',
  'benefit.form.lunch.netto_amount.suffix.standard': 'kr. per periode',
  'benefit.form.pay_reduction.add_row.empty': 'Tilføj træk',
  'benefit.form.pay_reduction.add_row.not_empty': 'Tilføj ny række',
  'benefit.form.pay_reduction.header.reduction': 'Træk per periode',
  'benefit.form.pay_reduction.header.title': 'Beskrivelse',
  'benefit.form.pay_reduction.intro.title': 'Fast brutto- eller nettotræk',
  'benefit.form.pay_reduction.is_gross.basis_note': 'Bruttotrækket skal også påvirke følgende grundlag',
  'benefit.form.pay_reduction.is_gross.false': 'Nettotræk',
  'benefit.form.pay_reduction.is_gross.true': 'Bruttotræk',
  'benefit.form.pay_reduction.reduction.gross': 'Bruttotræk',
  'benefit.form.pay_reduction.reduction.net': 'Nettotræk',
  'benefit.form.pay_reduction.reduction.required': '{title} skal indtastes',
  'benefit.form.pay_reduction.reduction.suffix': 'kr. per periode',
  'benefit.form.pay_reduction.title': 'Beskrivelse',
  'benefit.form.pay_reduction.title.required': 'Beskrivelse skal indtastes',
  'benefit.form.pay_reduction.with_pension': 'Pension',
  'benefit.form.pay_reduction.with_vacation': 'Ferie',
  'benefit.form.permanent_residence.intro.line_1':
    'Hvis du stiller en fri helårsbolig helt eller delvis til rådighed for en ansat, skal du i feltet skrive forskellen mellem det beløb, den ansatte betaler for at have fået boligen stillet til rådighed og markedslejen. Betaler du udover den fri bolig også for udgifter, der normalt afholdes af lejeren, f.eks. el og varme, skal du også skrive værdien heraf i feltet. Værdien heraf er den faktiske udgift.',
  'benefit.form.permanent_residence.intro.line_2':
    'De nærmere regler fremgår af Ligningslovens § 16, stk. 3, jf. stk. 7 - 9.',
  'benefit.form.permanent_residence.reporting_amount': 'Beløb som indberettes',
  'benefit.form.permanent_residence.reporting_amount.invalid': 'Beløb som indberettes er ugyldig',
  'benefit.form.permanent_residence.reporting_amount.required': 'Beløb som indberettes skal indtastes',
  'benefit.form.permanent_residence.reporting_amount.suffix': 'kr. per måned',
  'benefit.form.permanent_residence.title': 'Fri helårsbolig',
  'benefit.form.tax_reporting_no_threshold.add_row.empty': 'Tilføj gode',
  'benefit.form.tax_reporting_no_threshold.add_row.not_empty': 'Tilføj ny række',
  'benefit.form.tax_reporting_no_threshold.header.reporting_amount': 'Beløb som indberettes',
  'benefit.form.tax_reporting_no_threshold.header.title': 'Beskrivelse',
  'benefit.form.tax_reporting_no_threshold.intro.line_1':
    'Værdi af andre personalegoder er am-bidragsfri B-indkomst. Personalegoder, der ikke er selvstændigt nævnt andet sted, og ikke omfattet af bagatelgrænserne. Dvs., at der i dette felt skal indberettes værdi af goder, hvor der ikke er nogen bagatelgrænse for, hvornår beskatning skal finde sted.',
  'benefit.form.tax_reporting_no_threshold.intro.line_2': 'Du kan læse mere {link}.',
  'benefit.form.tax_reporting_no_threshold.intro.line_2.link': 'her',
  'benefit.form.tax_reporting_no_threshold.intro.title': 'Andre personalegoder uden bagatelgrænse',
  'benefit.form.tax_reporting_no_threshold.reporting_amount': 'Beløb som indberettes',
  'benefit.form.tax_reporting_no_threshold.reporting_amount.required': 'Beløb som indberettes skal indtastes',
  'benefit.form.tax_reporting_no_threshold.reporting_amount.suffix': 'kr.',
  'benefit.form.tax_reporting_no_threshold.title': 'Beskrivelse',
  'benefit.form.tax_reporting_no_threshold.title.required': 'Beskrivelse skal indtastes',
  'benefit.form.tax_reporting.add_row.empty': 'Tilføj gode',
  'benefit.form.tax_reporting.add_row.not_empty': 'Tilføj ny række',
  'benefit.form.tax_reporting.header.reporting_amount': 'Beløb som indberettes',
  'benefit.form.tax_reporting.header.title': 'Beskrivelse',
  'benefit.form.tax_reporting.intro.line_1':
    'Her skal du skrive beløbet, hvis du har ydet et ikke arbejdsrelateret gode til en ansat, hvor godet er omfattet af bagatelgrænsen i ligningslovens § 16, stk. 3, 3. pkt. og værdien af det enkelte gode overstiger den angivne bagatelgrænse - som er på 1.200 kr. i 2020.',
  'benefit.form.tax_reporting.intro.line_2': 'Eksempler på goder, hvor værdien skal indberettes her:',
  'benefit.form.tax_reporting.intro.line_2.items.1': 'fri motorcykel',
  'benefit.form.tax_reporting.intro.line_2.items.2': 'fri fitness-center',
  'benefit.form.tax_reporting.intro.line_2.items.3': 'fri jagt og udbytte af jagt',
  'benefit.form.tax_reporting.intro.line_2.items.4':
    'fri børnepasning, der ikke foreligger i form af en firmabørnehave og -vuggestue "drevet af arbejdsgiver"',
  'benefit.form.tax_reporting.intro.line_2.items.5':
    'fri multimedier, der ikke opfylder betingelserne for indberetning som A-indkomst i felt 20 og samtidig skal beskattes af en værdi svarende til markedslejen - dvs. der heller ikke er tale om lønnedgang for at modtage godet. Der skal ske månedlig indberetning',
  'benefit.form.tax_reporting.intro.line_2.items.6':
    'fri computer (med tilbehør), der er stillet til rådighed uden relation til arbejdets udførelse, uden lønnedgang, skal beskattes af et beløb svarende til markedslejen af en tilsvarende computer (med tilbehør). Der skal ske mindst månedlig indberetning af en forholdsmæssig andel',
  'benefit.form.tax_reporting.intro.line_3': 'Du kan læse mere {link}.',
  'benefit.form.tax_reporting.intro.line_3.link': 'her',
  'benefit.form.tax_reporting.intro.title': 'Andre personalegoder',
  'benefit.form.tax_reporting.reporting_amount': 'Beløb som indberettes',
  'benefit.form.tax_reporting.reporting_amount.required': 'Beløb som indberettes skal indtastes',
  'benefit.form.tax_reporting.reporting_amount.suffix': 'kr.',
  'benefit.form.tax_reporting.title': 'Beskrivelse',
  'benefit.form.tax_reporting.title.required': 'Beskrivelse skal indtastes',
  'benefit.form.valid_from.at_date': 'Ændringen træder i kraft den {date}.',
  'benefit.form.valid_from.now': 'Ændringen træder i kraft med det samme.',
  'benefit.type.board_and_lodging': 'Fri kost og logi',
  'benefit.type.car': 'Fri bil',
  'benefit.type.fixed_disbursement': 'Fast udlæg',
  'benefit.type.gross_pay_reduction': 'Bruttotræk',
  'benefit.type.gross_pay_reduction_no_pension': 'Bruttotræk (uden pensionsgrundlag)',
  'benefit.type.gross_pay_reduction_no_vacation': 'Bruttotræk (uden feriegrundlag)',
  'benefit.type.gross_pay_reduction_no_vacation_and_pension': 'Bruttotræk (uden ferie- og pensionsgrundlag)',
  'benefit.type.health': 'Sundhedsforsikring',
  'benefit.type.internet': 'Fri internet',
  'benefit.type.lunch': 'Frokostordning',
  'benefit.type.net_pay_reduction': 'Nettotræk',
  'benefit.type.other': 'Anden personalegode',
  'benefit.type.permanent_residence': 'Fri helårsbolig',
  'benefit.type.tax_reporting': 'Anden personalegode',
  'benefit.type.tax_reporting_no_threshold': 'Anden personalegode uden bagatelgrænse',
  'benefit.type.telephone': 'Fri telefoni',

  'bonus.edit.form.amount': 'bonus',
  'bonus.edit.form.amount.anniversary_bonus':
    'De første 8.000 kroner er skattefri (hele beløbet indberettes i felt 69 i eIndkomst).',
  'bonus.edit.form.amount.empty_balance': 'Tøm konto',
  'bonus.edit.form.amount.free_phone': 'fri telefon',
  'bonus.edit.form.amount.paid_vacation': 'Total værdi: {total}, {part}/dag',
  'bonus.edit.form.amount.sh_fritvalg': 'Saldo: {amount}',
  'bonus.edit.form.approved': 'Godkendt?',
  'bonus.edit.form.approved.checked': 'Godkendt',
  'bonus.edit.form.approved.unchecked': 'Kladde',
  'bonus.edit.form.as_in_kind': 'Som natural',
  'bonus.edit.form.as_in_kind.no_title': 'Teksten på lønsedlen vil også blive indberettet til Skattestyrelsen.',
  'bonus.edit.form.atp_hours': 'ATP-timer',
  'bonus.edit.form.atp_hours.help.line_1': 'Engangsregistrering for ATP følger standardsatserne.',
  'bonus.edit.form.atp_hours.help.line_2':
    "Her er det muligt at indtaste et bestemt antal timer, ATP-satsen skal beregnes for.  Sættes værdien til 0, beregnes ATP-timer udfra det antal timer der står på samme lønseddel som ATP'en påføres.",
  'bonus.edit.form.atp_hours.help.line_3': 'ATP-timerne her ellers ingen indflydelse på lønnen eller indberetning.',
  'bonus.edit.form.atp_hours.invalid': 'ATP-timer er ugyldige',
  'bonus.edit.form.atp_hours.suffix': 'timer',
  'bonus.edit.form.category': 'Art',
  'bonus.edit.form.disposition_date': 'Udbetalingsdato',
  'bonus.edit.form.no_amount.free_phone': 'Engangsregistrering for fri telefon følger Skats satser.',
  'bonus.edit.form.overtime_payout_units': 'Antal timer',
  'bonus.edit.form.overtime_payout_units.invalid': 'Antal timer er ugyldigt',
  'bonus.edit.form.overtime_payout_units.less_than_available': 'Antal timer kan ikke være flere end der er tilbage',
  'bonus.edit.form.overtime_payout_units.not_negative': 'Antal timer må ikke være negativ',
  'bonus.edit.form.overtime_payout_units.note.line_1': 'Sats: {rate}/time; timer tilbage: {hours_left}',
  'bonus.edit.form.overtime_payout_units.note.line_2': 'Værdi: {value}',
  'bonus.edit.form.overtime_payout_units.required': 'Antal timer skal indtastes',
  'bonus.edit.form.overtime_payout_units.suffix': 'timer',
  'bonus.edit.form.type': 'Type',
  'bonus.edit.form.type.required': 'Type skal vælges',
  'bonus.edit.form.with_pension': 'Med pension',
  'bonus.edit.form.with_vacation': 'Med ferieberettiget løn',
  'bonus.edit.form.work_hours_units': 'Antal timer',
  'bonus.edit.form.work_hours_units.invalid': 'Antal timer er ugyldigt',
  'bonus.edit.form.work_hours_units.required': 'Antal timer skal indtastes',
  'bonus.edit.form.work_hours_units.suffix': 'timer',
  'bonus.edit.intro': 'Giv din medarbejder en bonus til udbetaling på en fremtidig lønseddel.',
  'bonus.edit.title.create': 'Opret bonus',
  'bonus.edit.title.edit': 'Rediger bonus',
  'bonus.edit.title.view': 'Se bonus',
  'bonus.tab.header.add_bonus': 'Opret bonus',
  'bonus.tab.header.approve_all': 'Godkend alle',
  'bonus.tab.header.hide_history': 'Tilbage',
  'bonus.tab.header.show_history': 'Vis historik',
  'bonus.tab.table.action.approve': 'Godkend',
  'bonus.tab.table.action.delete': 'Slet bonus',
  'bonus.tab.table.action.edit': 'Redigér bonus',
  'bonus.tab.table.action.remove_approve': 'Fjern godkendelse',
  'bonus.tab.table.action.view': 'Gennemse bonus',
  'bonus.tab.table.approved': 'Ja',
  'bonus.tab.table.header.amount': 'Beløb',
  'bonus.tab.table.header.approved': 'Godkendt',
  'bonus.tab.table.header.date': 'Dato',
  'bonus.tab.table.header.title': 'Beskrivelse',
  'bonus.tab.table.header.type': 'Type',
  'bonus.tab.table.not_approved': 'Nej',
  'bonus.tab.table.saving': 'Gemmer...',
  'bonus.tab.title.history': 'Historik for bonus',
  'bonus.tab.title.standard': 'Bonus',

  'calendar.approval_all_button_text': 'Godkend alle for {month}',
  'calendar.legend.employee_holiday': 'Weekend/helligdag',
  'calendar.legend.need_approval': 'Mangler godkendelse',
  'calendar.named_holiday.christ_ascension': 'Kristi himmelfartsdag',
  'calendar.named_holiday.day_after_christ_ascension': 'Dagen efter Kristi himmelfartsdag',
  'calendar.named_holiday.easter_monday': '2. påskedag',
  'calendar.named_holiday.easter_sunday': '1. påskedag',
  'calendar.named_holiday.first_day_of_pentecost': '1. pinsedag',
  'calendar.named_holiday.good_friday': 'Langfredag',
  'calendar.named_holiday.great_prayer_day': 'Store bededag',
  'calendar.named_holiday.maundy_thursday': 'Skærtorsdag',
  'calendar.named_holiday.palm_sunday': 'Palmesøndag',
  'calendar.named_holiday.whit_monday': '2. pinsedag',
  'calendar.navigation.next_month': 'Næste måned',
  'calendar.navigation.previous_month': 'Forrige måned',
  'calendar.navigation.today': 'I dag',
  'calendar.single.edit_registration': 'Ret registrering',
  'calendar.single.new_registration': 'Ny registrering',
  'calendar.table.approve': 'Godkend',
  'calendar.table.cell.click_mode': 'Klik for at oprette {new_type} for {name}',
  'calendar.table.cell.context_menu.new': 'Opret {new_type} for {name}',
  'calendar.table.cell.drag_mode': 'Klik og træk herfra for at oprette {new_type} for {name}',
  'calendar.table.click_to_approve': '{title} - klik for at godkende',
  'calendar.table.click_to_edit': '{title} - klik for at redigere',
  'calendar.table.header.date': 'Dato',
  'calendar.table.header.day': 'Dag',
  'calendar.table.header.week': 'Uge',
  'calendar.week.short.friday': 'F',
  'calendar.week.short.monday': 'M',
  'calendar.week.short.saturday': 'L',
  'calendar.week.short.sunday': 'S',
  'calendar.week.short.thursday': 'T',
  'calendar.week.short.tuesday': 'T',
  'calendar.week.short.wednesday': 'O',

  'car_allowance_rate.edit.form.car_allowance_rate': 'Sats',
  'car_allowance_rate.edit.form.car_allowance_rate.above_limit': 'Sats må ikke være højere end {limit}',
  'car_allowance_rate.edit.form.car_allowance_rate.invalid': 'Sats er ugyldig',
  'car_allowance_rate.edit.form.car_allowance_rate.suffix': 'kr./km',
  'car_allowance_rate.edit.intro.line_1':
    'Udbetal en anden sats end standard for kørselsgodtgørelse til din medarbejder.',
  'car_allowance_rate.edit.intro.line_2': 'Efterlad feltet tomt for at benytte standardsats.',
  'car_allowance_rate.edit.title': 'Rediger sats',

  'car_allowance.car_allowance.many': 'kørselregistreringer',
  'car_allowance.car_allowance.one': 'kørselregistrering',
  'car_allowance.coarse_car_allowance.many': 'simple kørselsregistreringer',
  'car_allowance.coarse_car_allowance.one': 'simpel kørselsregistrering',
  'car_allowance.detailed_car_allowance.many': 'detaljerede kørselsregistreringer',
  'car_allowance.detailed_car_allowance.one': 'detaljeret kørselsregistrering',
  'car_allowance.trip': '{from} – {to}',
  'car_allowance.trip_length': '{amount} km',

  'car_allowances_tab.coarse.alert.success': '{name}s kørselsregistrering er gemt',
  'car_allowances_tab.coarse.form.kilometers': 'Afstand',
  'car_allowances_tab.coarse.form.kilometers.invalid': 'Afstand er ugyldig',
  'car_allowances_tab.coarse.form.kilometers.required': 'Afstand er påkrævet',
  'car_allowances_tab.coarse.form.kilometers.suffix': 'km',
  'car_allowances_tab.coarse.period.no_change': 'Herunder registreres kørsel for hele perioden {from} til {to}.',
  'car_allowances_tab.coarse.period.period_range': '{from} - {to}',
  'car_allowances_tab.coarse.period.select_period': 'Vælg periode',
  'car_allowances_tab.coarse.sub_select.help.line_1':
    'Når man registrerer simpel kørsel, så vil der normalt kun være én registrering per periode, men det kan hænde at man vil opleve der er flere end én.',
  'car_allowances_tab.coarse.sub_select.help.line_2':
    'Når en løn gennemføres låses registreringen, og hvis man bagefter ændrer på registreringen så oprettes en ny i stedet for.',
  'car_allowances_tab.coarse.sub_select.help.line_3':
    'Hvis man derfor vil efterregistrere flere kilometer end man oprindeligt fik gjort, så skal man lave en ny simpel registreringen med forskellen.  F.eks. hvis den rigtige total er 1.000 km, men der kun blev registreret 800 km, så skal man lave en ny på 200 km.',
  'car_allowances_tab.coarse.sub_select.help.title':
    'Hvorfor er der flere simple kørselsregistreringer for samme periode?',
  'car_allowances_tab.coarse.sub_select.immutable': 'Gennemført',
  'car_allowances_tab.coarse.sub_select.intro': 'Der er flere registreringer for perioden',
  'car_allowances_tab.coarse.sub_select.line': '{number}. registrering',
  'car_allowances_tab.coarse.warning':
    'OBS: Hvis du bruger denne løsning, skal du selv kunne dokumentere via en anden kørebog. {link}.',
  'car_allowances_tab.coarse.warning.link': 'Læs mere hos SKAT',
  'car_allowances_tab.detailed.edit.form.add_via_point': 'Tilføj viapunkt',
  'car_allowances_tab.detailed.edit.form.create_return_trip': 'Opret også kørsel retur',
  'car_allowances_tab.detailed.edit.form.create_return_trip.false': 'Nej',
  'car_allowances_tab.detailed.edit.form.create_return_trip.true': 'Ja',
  'car_allowances_tab.detailed.edit.form.date': 'Dato',
  'car_allowances_tab.detailed.edit.form.date.required': 'Dato skal indtastes',
  'car_allowances_tab.detailed.edit.form.kilometer_format': '{kilometers} km',
  'car_allowances_tab.detailed.edit.form.kilometers': 'Afstand',
  'car_allowances_tab.detailed.edit.form.kilometers.required': 'Afstand skal indtastes',
  'car_allowances_tab.detailed.edit.form.kilometers.suffix': 'km',
  'car_allowances_tab.detailed.edit.form.license_plate': 'Nummerplade',
  'car_allowances_tab.detailed.edit.form.license_plate.required': 'Nummerplade skal indtastes',
  'car_allowances_tab.detailed.edit.form.location_from': 'Fra',
  'car_allowances_tab.detailed.edit.form.location_from.required': 'Fra skal indtastes',
  'car_allowances_tab.detailed.edit.form.location_to': 'Til',
  'car_allowances_tab.detailed.edit.form.location_to.required': 'Til skal indtastes',
  'car_allowances_tab.detailed.edit.form.location_via': 'Viapunkt',
  'car_allowances_tab.detailed.edit.form.location_via.required': 'Viapunkt er påkrævet',
  'car_allowances_tab.detailed.edit.form.rate': 'Sats',
  'car_allowances_tab.detailed.edit.form.rate.above_limit': 'Sats må ikke være mere end {limit}',
  'car_allowances_tab.detailed.edit.form.rate.help':
    'Der kan tilføjes en særlig sats per kørselsregistrering.  Efterlades feltet blankt, vil en evt. sats på kontrakten blive brugt, og såfremt den ikke er sat, bruges standardsatserne.',
  'car_allowances_tab.detailed.edit.form.rate.invalid': 'Sats er ugyldig',
  'car_allowances_tab.detailed.edit.form.rate.suffix': 'kr.',
  'car_allowances_tab.detailed.edit.form.reason': 'Formål',
  'car_allowances_tab.detailed.edit.form.reason.required': 'Formål skal indtastes',
  'car_allowances_tab.detailed.edit.form.remove_via_point': 'Fjern viapunkt',
  'car_allowances_tab.detailed.edit.form.suggested.route': 'Foreslået rute',
  'car_allowances_tab.detailed.edit.form.suggested.route.need_input': 'Foreslået rute (indtast til/fra)',
  'car_allowances_tab.detailed.edit.form.via.distance_to_next': 'Afstand til næste punkt:',
  'car_allowances_tab.detailed.edit.form.via.help.line_1':
    'Viapunkter oprettes som individuelle kørsler.  Hvis man indtaster en rute af København til Odense til Aalborg, vil der oprettes to ture, hhv. København til Odense, og Odense til Aalborg.',
  'car_allowances_tab.detailed.edit.form.via.help.line_2':
    'Derfor kan man ikke manuelt indtaste antal kilometer per deltur ved oprettelsen.  Du kan dog godt rette i dem bagefter, da de netop bliver enkle registreringer for sig.',
  'car_allowances_tab.detailed.edit.form.via.help.line_3':
    'Vær opmærksom på, at hvis du vælger at oprette en kørsel retur, så er det hele den lange vej tilbage.  I det overstående eksempel vil det altså være Aalborg til Odense og Odense til København.  Hvis din tur bedre kan beskrives som en lukket ring, bør du slutte hvor du startede.',
  'car_allowances_tab.detailed.edit.title.create': 'Opret kørsel',
  'car_allowances_tab.detailed.edit.title.edit': 'Rediger kørsel',
  'car_allowances_tab.detailed.header.add_registration': 'Registrér kørsel',
  'car_allowances_tab.detailed.header.approve_all': 'Godkend alle',
  'car_allowances_tab.detailed.header.title': 'Antal kilometer, som afventer udbetaling.',
  'car_allowances_tab.detailed.header.unsettled_suffix': 'Kilometer',
  'car_allowances_tab.detailed.history.table.header.date': 'Dato',
  'car_allowances_tab.detailed.history.table.header.kilometers': 'Afstand',
  'car_allowances_tab.detailed.history.table.header.reason': 'Formål',
  'car_allowances_tab.detailed.table.actions.copy': 'Kopiér registrering',
  'car_allowances_tab.detailed.table.actions.delete': 'Slet registrering',
  'car_allowances_tab.detailed.table.actions.edit': 'Redigér registrering',
  'car_allowances_tab.detailed.table.approve': 'Godkend',
  'car_allowances_tab.detailed.table.approved.false': 'Nej',
  'car_allowances_tab.detailed.table.approved.true': 'Ja',
  'car_allowances_tab.detailed.table.header.approved': 'Godkendt',
  'car_allowances_tab.detailed.table.header.date': 'Dato',
  'car_allowances_tab.detailed.table.header.kilometers': 'Afstand',
  'car_allowances_tab.detailed.table.header.reason': 'Formål',
  'car_allowances_tab.detailed.table.kilometer_format': '{amount} km',
  'car_allowances_tab.detailed.table.kilometers_format': '{from} til {to}',
  'car_allowances_tab.detailed.table.remove_approval': 'Fjern godkendelse',
  'car_allowances_tab.detailed.table.saving': 'Gemmer...',
  'car_allowances_tab.detailed.unsettled_kilometers_format.many': '{count} kilometer',
  'car_allowances_tab.detailed.unsettled_kilometers_format.one': '{count} kilometer',
  'car_allowances_tab.header.edit_rate': 'Ret sats',
  'car_allowances_tab.header.hide_history': 'Tilbage',
  'car_allowances_tab.header.show_history': 'Vis historik',
  'car_allowances_tab.header.switch_detailed': 'Detaljeret',
  'car_allowances_tab.map.avoid_ferries': 'Undgå færger',
  'car_allowances_tab.map.no_return_route': 'Der kunne ikke findes en retur-rute mellem de to punkter.',
  'car_allowances_tab.map.no_route': 'Der kunne ikke findes en rute mellem de to punkter.',
  'car_allowances_tab.title.history': 'Historisk kørselsregnskab',
  'car_allowances_tab.title.standard': 'Kørselsregnskab',

  'car_allowances_table.detailed.table.rate_format': '(sats: {rate})',

  'car_allowances.coarse': 'Kørselsregnskab for {name}',
  'car_allowances.coarse.title': 'Simpelt kørselsregnskab',
  'car_allowances.detailed.title': 'Detaljeret kørselsregnskab',
  'car_allowances.table.actions.edit': 'Redigér',
  'car_allowances.table.actions.register': 'Registrér',
  'car_allowances.table.all_approved': 'Al kørsel godkendt',
  'car_allowances.table.approve_all': 'Godkend kørsel',
  'car_allowances.table.header.distance': 'Afstand',
  'car_allowances.table.header.employee': 'Medarbejder',

  'choice.card.table.direct_pay.false': 'Nej',
  'choice.card.table.direct_pay.true': 'Ja',
  'choice.card.table.fixed_suffix': 'kr.',
  'choice.card.table.header.direct_pay': 'Løbende udbetaling',
  'choice.card.table.header.rate': 'Indbetaling',
  'choice.card.table.header.type': 'Type',
  'choice.card.table.percentage_suffix': '%',
  'choice.card.table.rate_format': '{rate} {suffix}',
  'choice.card.title': 'SH-dage og fritvalgskonto',
  'choice.edit.form.add_row': 'Tilføj type',
  'choice.edit.form.compensation_rate.fixed': 'Beløb',
  'choice.edit.form.compensation_rate.invalid_percentage': 'Procent er ugyldig',
  'choice.edit.form.compensation_rate.percentage': 'Procent',
  'choice.edit.form.compensation_rate.required': '{title} skal indtastes',
  'choice.edit.form.direct_pay': 'Med løbende udbetaling',
  'choice.edit.form.has_choice.false': 'Skal ikke have SH-dage eller fritvalgskonto',
  'choice.edit.form.has_choice.true': 'Skal have SH-dage eller fritvalgskonto',
  'choice.edit.form.header.direct_pay': 'Løbende udbetaling',
  'choice.edit.form.header.rate': 'Indbetaling',
  'choice.edit.form.header.type': 'Type',
  'choice.edit.form.suffix.fixed_amount': 'kr.',
  'choice.edit.form.suffix.percentage': '%',
  'choice.edit.form.type': 'Type',
  'choice.edit.form.type.required': 'Type skal vælges',
  'choice.edit.form.valid_from.at_date': 'Ændringen træder i kraft den {date}.',
  'choice.edit.form.valid_from.now': 'Ændringen træder i kraft med det samme.',
  'choice.edit.title': 'Rediger SH-dage og fritvalgskonto',

  'companies_add.alert.success': '{name} er nu oprettet som virksomhed',
  'companies_add.edit.title': 'Udfyld detaljerne for virksomheden',
  'companies_add.employee_user_notice.line_1':
    'Du er allerede tilknyttet en virksomhed som medarbejder, hvis du ønsker at logge ind i medarbejder-appen, så {link}.',
  'companies_add.employee_user_notice.line_1.link': 'gå til medarbejder-siden',
  'companies_add.employee_user_notice.line_2': 'Ellers kan du oprette en virksomhed nedenunder.',
  'companies_add.form.accounting_software': 'Bogføringssystem',
  'companies_add.form.accounting_software.manual': 'Manuel',
  'companies_add.form.accounting_software.other': 'Andet',
  'companies_add.form.accounting_software.required': 'Bogføringssystem skal vælges',
  'companies_add.form.address': 'Adresse',
  'companies_add.form.address.required': 'Adresse skal indtastes',
  'companies_add.form.allow_automatic_zero_tax_report': 'Automatisk nulindberetning',
  'companies_add.form.allow_automatic_zero_tax_report.false': 'Nej, nulindberet ikke automatisk',
  'companies_add.form.allow_automatic_zero_tax_report.required': 'Automatisk nulindberetning skal vælges',
  'companies_add.form.allow_automatic_zero_tax_report.true': 'Ja, indberet automatisk når der ikke laves løn',
  'companies_add.form.city': 'By',
  'companies_add.form.city.required': 'By skal indtastes',
  'companies_add.form.company_role': 'Din rolle i forbindelse med lønadministration',
  'companies_add.form.company_role.option.accountant': 'Jeg er revisor/bogholder, og laver løn for kunden',
  'companies_add.form.company_role.option.company_owner_accountant_runs_pay_roll': 'Min revisor/bogholder laver lønnen',
  'companies_add.form.company_role.option.company_owner_runs_pay_roll': 'Jeg står selv for at lave løn',
  'companies_add.form.company_role.required': 'Din rolle i forbindelse med lønadministration skal vælges',
  'companies_add.form.company_size': 'Virksomhedsstørrelse',
  'companies_add.form.company_size.option.1_2': '1 - 2 medarbejdere',
  'companies_add.form.company_size.option.100_plus': 'Over 100 medarbejdere',
  'companies_add.form.company_size.option.11_20': '11 - 20 medarbejdere',
  'companies_add.form.company_size.option.21_50': '21 - 50 medarbejdere',
  'companies_add.form.company_size.option.3_5': '3 - 5 medarbejdere',
  'companies_add.form.company_size.option.51_100': '51 - 100 medarbejdere',
  'companies_add.form.company_size.option.6_10': '6 - 10 medarbejdere',
  'companies_add.form.company_size.required': 'Virksomhedsstørrelse skal vælges',
  'companies_add.form.default_hourly_monthly_cycle_id': 'Lønperiode for timelønnede',
  'companies_add.form.default_hourly_monthly_cycle_id.no_offset': 'Fra den 1. til sidste dag i måneden',
  'companies_add.form.default_hourly_monthly_cycle_id.placeholder': 'Vælg cyklus',
  'companies_add.form.default_hourly_monthly_cycle_id.required': 'Lønperiode for timelønnede skal vælges',
  'companies_add.form.default_hourly_monthly_cycle_id.with_offset': 'Fra den {from} til den {to} i måneden',
  'companies_add.form.name': 'Navn',
  'companies_add.form.name.required': 'Navn skal indtastes',
  'companies_add.form.postal_code': 'Postnr.',
  'companies_add.form.postal_code.greenland_not_allowed': 'Postnr. må ikke være et grønlandsk postnr.',
  'companies_add.form.postal_code.invalid': 'Postnr. er ugyldigt',
  'companies_add.form.postal_code.required': 'Postnr. skal indtastes',
  'companies_add.form.referred_by': 'Hvor har du hørt om Salary?',
  'companies_add.form.skat_size': 'Virksomhedens størrelse',
  'companies_add.form.skat_size.placeholder': 'Vælg størrelse',
  'companies_add.form.skat_size.required': 'Virksomhedens størrelse skal vælges',
  'companies_add.form.submit': 'Opret virksomhed',
  'companies_add.search.placeholder': 'Søg på CVR-nummer eller virksomhedsnavn',
  'companies_add.search.title': 'Hent oplysninger direkte fra CVR',

  'companies.add_company': 'Tilføj virksomhed',
  'companies.add_company_outside_group': 'Tilføj virksomhed udenfor gruppen',
  'companies.add_company_to_group': 'Tilføj virksomhed til gruppen',
  'companies.group.accounting_integration.note':
    'Lige nu kan man kun synkronisere sine integrationer for hele gruppen, men mere tvær-gruppe-funktionalitet er på vej.',
  'companies.group.accounting_integration.synchronise': 'Synkronisér alle integrationer',
  'companies.group.accounting_integration.title': 'Bogføringsintegrationer',
  'companies.group.reports.form.from': 'Fra',
  'companies.group.reports.form.from.required': 'Fradato skal indtastes',
  'companies.group.reports.form.not_while_group': 'Vælg de virksomheder der skal inkluderes i rapporten.',
  'companies.group.reports.form.report': 'Vælg rapport',
  'companies.group.reports.form.to': 'Til',
  'companies.group.reports.form.to.required': 'Tildato skal indtastes',
  'companies.group.reports.form.while_group': 'Lav rapporten for hele virksomhedsgruppen',
  'companies.group.reports.title': 'Rapporter',
  'companies.group.users.action.delete': 'Fjern bruger',
  'companies.group.users.action.edit_permissions': 'Redigér rettigheder',
  'companies.group.users.add.form.email': 'E-mail',
  'companies.group.users.add.form.email.invalid': 'E-mail er ikke gyldig',
  'companies.group.users.add.form.email.not_same_domain':
    'Du er ved at tilføje en bruger, hvis email-adresse har et andet domæne end din email. Husk på du er ved at tilføje en bruger til {group}, og ikke blot {company}. Du kan ignorere denne besked, hvis det er hensigten.',
  'companies.group.users.add.form.email.not_same_domain.company': 'en virksomhed',
  'companies.group.users.add.form.email.not_same_domain.group': 'en virksomhedsgruppe',
  'companies.group.users.add.form.email.required': 'E-mail skal indtastes',
  'companies.group.users.add.form.group_user_type': 'Rolle',
  'companies.group.users.add.form.group_user_type.placeholder': 'Vælg rolle',
  'companies.group.users.add.form.group_user_type.required': 'Rolle skal vælges',
  'companies.group.users.add.form.permissions.intro':
    'De rettigheder der automatisk skal tilføjes til de virksomheder som er en del af gruppen.',
  'companies.group.users.add.form.permissions.title': 'Automatiske rettigheder til virksomheder',
  'companies.group.users.add.intro':
    'Du kan invitere folk til din virksomhedsgruppe her. Husk på at de får adgang til alle de virksomheder der er i gruppen, og virksomheder som senere bliver tilføjet til gruppen.',
  'companies.group.users.add.submit': 'Send invitation nu',
  'companies.group.users.add.title': 'Invitér ny bruger til virksomhedsgruppe',
  'companies.group.users.alert.success': 'Rettighederne til {name} er gemt',
  'companies.group.users.edit.title': 'Redigér virksomhedsbrugerrettigheder for {name}',
  'companies.group.users.header.add': 'Tilføj bruger',
  'companies.group.users.invitations.title.many': '{count} invitationer',
  'companies.group.users.invitations.title.one': '{count} invitation',
  'companies.group.users.invites.table.action.delete': 'Slet invitation',
  'companies.group.users.invites.table.header.created_at': 'Sendt',
  'companies.group.users.invites.table.header.email': 'E-mail',
  'companies.group.users.table.header.permissions': 'Automatiske rettigheder på nye virksomheder',
  'companies.group.users.table.header.type': 'Type',
  'companies.group.users.table.header.user_name': 'Bruger',
  'companies.group.users.title': 'Brugere',
  'companies.header.group': 'Virksomhedsgruppe',
  'companies.menu.accounting': 'Bogføringsintegrationer',
  'companies.menu.companies': 'Virksomheder',
  'companies.menu.reports': 'Rapporter',
  'companies.menu.settings': 'Indstillinger',
  'companies.menu.users': 'Brugere',
  'companies.search.companies.many': '{count} virksomheder',
  'companies.search.companies.one': '{count} virksomhed',
  'companies.search.searching_for': '(søger på "{query}")',
  'companies.settings.advanced.intro':
    'Hvis du bruger avancerede indstillinger på dine virksomheder, behøver du ikke slå indstillingerne til hver gang du får tilføjet en ny til gruppen. Her kan du indstille hvilke indstillinger virksomhederne skal have når de bliver oprettet.',
  'companies.settings.advanced.title': 'Avancerede virksomhedsindstillinger',
  'companies.settings.alert.success': 'Ændringer til {name} er gemt',
  'companies.settings.form.default_pricing_package_id': 'Prispakke til nye virksomheder i gruppen',
  'companies.settings.form.default_pricing_package_id.note':
    'Når en ny virksomhed bliver tilføjet til gruppen, vil den automatisk få denne prispakke sat. Man kan altid derefter justere hvilken prispakke virksomheden skal have.',
  'companies.settings.form.name': 'Navn',
  'companies.settings.form.name.required': 'Navn skal indtastes',
  'companies.settings.title': 'Virksomhedsgruppeindstillinger',
  'companies.table.add_company_to_group': 'Tilføj til gruppen',
  'companies.table.company': 'Virksomhed',
  'companies.table.employees': 'Medarbejdere',
  'companies.table.go_to_company': 'Gå til virksomhed',
  'companies.table.in_group': 'I gruppe?',
  'companies.table.in_group.false': 'Nej',
  'companies.table.in_group.true': 'Ja',
  'companies.table.national_id': 'CVR',
  'companies.table.remove_company_from_group': 'Fjern virksomhed fra gruppen',
  'companies.table.see_user_list': 'Se brugerliste',
  'companies.table.users': 'Brugere',

  'company_bank_account.alert.success': 'Ændringer til {name} er gemt',
  'company_bank_account.description.link': 'MasterCards Overførselsservice-side',
  'company_bank_account.description.text':
    'Det hurtigste er at gå ind på {link} og oprette en Overførselsservice-aftale. Følg vejledningen og vælg Salarys CVR nummer ({cvr}) som Dataleverandør.',
  'company_bank_account.header.back': 'Tilbage',
  'company_bank_account.header.title': 'Opret Overførselsservice-aftale',
  'company_bank_account.request.form.bank_account_number': 'Virksomhedens bankkontonummer',
  'company_bank_account.request.form.bank_registration_number': 'Virksomhedens bankregistreringsnummer',
  'company_bank_account.request.form.contact_email': 'E-mail på underskriver',
  'company_bank_account.request.form.contact_name': 'Navn på underskriver',
  'company_bank_account.request.form.contact_phone': 'Telefonnummer på underskriver',
  'company_bank_account.request.form.submit': 'Opret aftale',
  'company_bank_account.request.form.validation.bank_account_number.invalid':
    'Virksomhedens bankkontonummer er ugyldigt',
  'company_bank_account.request.form.validation.bank_account_number.required':
    'Virksomhedens bankkontonummer skal udfyldes',
  'company_bank_account.request.form.validation.bank_registration_number.invalid':
    'Virksomhedens bankregistreringsnummer er ugyldigt',
  'company_bank_account.request.form.validation.bank_registration_number.required':
    'Virksomhedens bankregistreringsnummer skal udfyldes',
  'company_bank_account.request.form.validation.contact_email.invalid': 'E-mail på underskriver er ugyldig',
  'company_bank_account.request.form.validation.contact_email.required': 'E-mail på underskriver er påkrævet',
  'company_bank_account.request.form.validation.contact_name.required': 'Navn på underskriver er påkrævet',
  'company_bank_account.request.form.validation.contact_phone.invalid': 'Telefonnummer på underskriver er ugyldigt',
  'company_bank_account.request.form.validation.contact_phone.required': 'Telefonnummer på underskriver skal udfyldes',
  'company_bank_account.request.line_1':
    'Alternativt kan du bruge denne formular til at oprette en Overførselsservice-aftale, det er især brugbart hvis du opretter den på vegne af andre.',
  'company_bank_account.request.line_2':
    'Vedkommende der skal underskrive vil modtage en e-mail med vejledning desangående.',
  'company_bank_account.request.title': 'Anmod om Overførselsservice-aftale',

  'company_dashboards.header.add_company': 'Tilføj virksomhed',
  'company_dashboards.header.search': 'Søg...',
  'company_dashboards.table.company_info.employees.many': '{count} ansatte',
  'company_dashboards.table.company_info.employees.one': '{count} ansat',
  'company_dashboards.table.company_info.name': 'Navn',
  'company_dashboards.table.company_info.national_id': 'CVR',
  'company_dashboards.table.company_info.users.many': '{count} brugere',
  'company_dashboards.table.company_info.users.one': '{count} bruger',
  'company_dashboards.table.header.company_info': 'Virksomhedsinfo',
  'company_dashboards.table.header.next_pay_roll': 'Næste løn',
  'company_dashboards.table.header.pay_roll_settings': 'Lønopsætning',
  'company_dashboards.table.header.registrations': 'Lønregistreringer',
  'company_dashboards.table.next_pay_roll.none': 'Der er ingen aktive lønkørsler',
  'company_dashboards.table.pay_roll_settings.accounting_integration': 'Bogføring',
  'company_dashboards.table.pay_roll_settings.approval': 'Godkendelse',
  'company_dashboards.table.pay_roll_settings.payment_integration': 'Overførsler',
  'company_dashboards.table.registrations.car_allowance': 'Kørsel',
  'company_dashboards.table.registrations.car_allowance.format': '{number} km til godkendelse',
  'company_dashboards.table.registrations.hours': 'Timer',
  'company_dashboards.table.registrations.hours.format': '{number} til godkendelse',
  'company_dashboards.table.registrations.leave': 'Feriedage',
  'company_dashboards.table.registrations.leave.format': '{number} til godkendelse',
  'company_dashboards.table.registrations.notifications': 'Notifikationer',
  'company_dashboards.table.registrations.reimbursements': 'Udlæg',
  'company_dashboards.table.registrations.reimbursements.format': '{number} til godkendelse',
  'company_dashboards.table.registrations.swipe': 'Swipe',
  'company_dashboards.table.registrations.swipe.format': '{number} til godkendelse',
  'company_dashboards.table.status.awaiting_approval': 'Afventer godkendelse',
  'company_dashboards.table.status.deadline_format': 'senest {time}',
  'company_dashboards.title.companies.many': '{count} virksomheder',
  'company_dashboards.title.companies.one': '{count} virksomhed',
  'company_dashboards.title.search_format': ' (søger på "{query}")',

  'company_data_integration.alert.success': 'Din integration er gemt',
  'company_data_integration.connection_form.form.back': 'Tilbage',
  'company_data_integration.connection_form.form.parameter.placeholder': 'Indtast {parameter} fra {integration}',
  'company_data_integration.connection_form.form.parameter.required': '{parameter} skal udfyldes',
  'company_data_integration.connection_form.form.submit': 'Videre til næste trin',
  'company_data_integration.connection_form.title': 'Opret forbindelse',
  'company_data_integration.integration_type_form.features.export.leave_registrations': 'fraværsregistreringer',
  'company_data_integration.integration_type_form.features.format.both':
    'Import af {import_features} og eksport af {export_features}',
  'company_data_integration.integration_type_form.features.format.export': 'Eksport af {export_features}',
  'company_data_integration.integration_type_form.features.format.import': 'Import af {import_features}',
  'company_data_integration.integration_type_form.features.import.car_allowances': 'kørsel',
  'company_data_integration.integration_type_form.features.import.employees': 'medarbejdere',
  'company_data_integration.integration_type_form.features.import.leave_registrations': 'fravær',
  'company_data_integration.integration_type_form.features.import.pay_roll': 'løndata',
  'company_data_integration.integration_type_form.features.import.reimbursements': 'udlæg',
  'company_data_integration.integration_type_form.form.integration_type': 'Vælg dataudbyder',
  'company_data_integration.integration_type_form.form.integration_type.required': 'Dataudbyder skal vælges',
  'company_data_integration.integration_type_form.intro':
    'Vi arbejder hårdt på at intregrere med så mange dataudbydere som muligt. Hvis du ikke kan finde din på listen, kan du kontakte support på {email}.',
  'company_data_integration.integration_type_form.submit': 'Videre til forbindelse',
  'company_data_integration.integration_type_form.submit.lock':
    'Opgradér til Basic, for at integrere med din dataudbyder',
  'company_data_integration.integration_type_form.title': 'Vælg din dataudbyder',
  'company_data_integration.steps.help_note':
    'Hvis du har problemer med at forbinde din dataudbyder, kan du altid kontakte {email}.',
  'company_data_integration.steps.intro':
    'Medarbejdere, tidsregistreringer og/eller fraværsregisreringer kan importeres fra en dataudbyder, såsom et tidsregistreringssystem eller et personalehåndteringssystem.',
  'company_data_integration.steps.point.0': 'Vælg dataudbyder',
  'company_data_integration.steps.point.1': 'Opret forbindelse',
  'company_data_integration.steps.title': 'Forbind til din dataudbyder',

  'company_group.user_type.admin': 'Administrator',
  'company_group.user_type.regular': 'Almindelig',

  'company_pay_roll.alert.success': 'Ændringer til {name} er gemt',
  'company_pay_roll.approval_code.approval_code': 'Godkendelseskode',
  'company_pay_roll.approval_code.blocked':
    'Du kan ikke slå løngodkendelseskode til, så længe du har automatisk løngodkendelse.',
  'company_pay_roll.approval_code.button.blocked': 'Luk',
  'company_pay_roll.approval_code.button.cancel': 'Annullér',
  'company_pay_roll.approval_code.button.submit': 'Gem',
  'company_pay_roll.approval_code.intro.line_1':
    'En løngodkendelseskode er en kode der er påkrævet for at kunne godkende en lønkørsel.  Når koden er sat, skal der for alle løngodkendelser indtastes den korrekte kode.  Koden er på virksomhedsniveau, og alle brugere skal indtaste den samme kode.',
  'company_pay_roll.approval_code.intro.line_2':
    'Vær opmærksom på, at du ikke kan ændre eller fjerne koden når den er sat.  Bliver det nødvendigt, skal du skrive til support for at få den fjernet.',
  'company_pay_roll.approval_code.title': 'Løngodkendelseskode',
  'company_pay_roll.form.allow_automatic_zero_tax_report': 'Lav automatisk 0-indberetninger til SKAT',
  'company_pay_roll.form.allow_automatic_zero_tax_report.lock':
    'Opgradér til Basic, for at lave automatiske 0-indberetninger',
  'company_pay_roll.form.allow_automatic_zero_tax_report.note':
    'OBS: Salary laver nulindberetninger for dig selvom du ikke har kørt en løn igennem os.',
  'company_pay_roll.form.allow_empty_tax_card_mail':
    'Giv besked til medarbejderen, hvis der er modtaget et tomt skattekort fra SKAT.',
  'company_pay_roll.form.approval_notifications_note':
    'Du kan justere hvornår der skal modtages notifikationer om at der er ting der skal godkendes. Brugerne kan få op til to notifikationer, du vælger selv hvor mange dage før de seneste skal være godkendt, at notifikationen skal komme. Er værdierne sat til 0, kommer der ingen notifikation.',
  'company_pay_roll.form.approvers_reviewers.auto_approve_note':
    'Automatisk godkendelse af løn er slået til. Hvis du vil bruge flere godkendere af lønkørsler, skal du skifte til manuel godkendelse.',
  'company_pay_roll.form.approvers_reviewers.note.line_1':
    'Når du har flere brugere tilknyttet en virksomhed, kan du sætte regler for hvor mange godkendere der skal til en lønkørsel. Normalt skal der kun én godkender til en lønkørsel.',
  'company_pay_roll.form.approvers_reviewers.note.line_2':
    'Hvis der f.eks. kræves to godkendere til en lønkørsel køres den først når to af brugerne tilknyttet virksomheden har godkendt den. Efter den første har godkendt den, står den i en »delvist godkendt«-tilstand og vil være fastlåst. Når lønnen genåbnes, så skal den igen godkendes to gange.',
  'company_pay_roll.form.approvers_reviewers.note.line_3':
    'Hvis man f.eks. vil have sin bogholder til at kigge ens lønkørsel igennem, kan man slå det til at lønnen kan vurderes klar til godkendelse. Når en lønkørsel er klargjort, låses den. Selv hvis en løn ikke er klargjort, kan den stadig godkendes.',
  'company_pay_roll.form.approvers_reviewers.note.line_4':
    'Husk på, at en løn der er låst stadig kan genåbnes så længe der er tid til at lønnen skal køres.',
  'company_pay_roll.form.department_approver_notification_days_before_1': 'Første notifikation',
  'company_pay_roll.form.department_approver_notification_days_before_1.invalid': 'Første notifikation er ugyldig',
  'company_pay_roll.form.department_approver_notification_days_before_1.suffix': 'bankdage',
  'company_pay_roll.form.department_approver_notification_days_before_2': 'Anden notifikation',
  'company_pay_roll.form.department_approver_notification_days_before_2.invalid': 'Anden notifikation er ugyldig',
  'company_pay_roll.form.department_approver_notification_days_before_2.suffix': 'bankdage',
  'company_pay_roll.form.number_of_pay_roll_approvers':
    'Antallet af personer der skal godkende en løn før den kan køres',
  'company_pay_roll.form.number_of_pay_roll_approvers.not_enough':
    'Der skal være mindst to tilknyttet virksomheden som kan godkende løn før du kan slå flere godkendere til.',
  'company_pay_roll.form.number_of_pay_roll_reviewers': 'Tillad klargørelse af løn for dem med rettigheden.',
  'company_pay_roll.form.number_of_pay_roll_reviewers.not_enough':
    'Der skal være mindst én tilknyttet virksomheden som kan klargøre løn til godkendelse før du kan slå det til.',
  'company_pay_roll.form.rule_pay_roll_run_approval': 'Hvordan skal løn godkendes?',
  'company_pay_roll.form.rule_pay_roll_run_approval.lock': 'Opgradér til Basic, for automatisk at godkende løn',
  'company_pay_roll.form.rule_pay_roll_run_approval.payment_card_note':
    'Du kan ikke vælge automatisk lønkørsel uden en betalingskortaftale. Gå til {link}.',
  'company_pay_roll.form.rule_pay_roll_run_approval.payment_card_note.link':
    'fakturaer for at oprette en betalingskortaftale',
  'company_pay_roll.form.rule_pay_roll_run_approval.payroll_approval_code_lock':
    'Du kan ikke slå automatisk godkendelse til, når du har løngodkendelseskode.  Du skal skrive til support for at fjerne godkendelseskoden.',
  'company_pay_roll.form.rule_pay_roll_run_approval.required': 'Godkendelsestype skal vælges',
  'company_pay_roll.form.section.approval_notifications': 'Godkendelsesnotifikationer',
  'company_pay_roll.form.section.approvers_reviewers': 'Flere godkendere & klargørelse af løn',
  'company_pay_roll.form.section.income_reports': 'Indberetninger',
  'company_pay_roll.form.section.notifications': 'Notifikationer',
  'company_pay_roll.header.approval_code.button': 'Godkendelseskode',
  'company_pay_roll.header.approval_code.enabled': 'Godkendelseskode er slået til',
  'company_pay_roll.header.deviations': 'Afvigelser',
  'company_pay_roll.header.nets_messages': 'Se beskeder fra Overførselsservice',
  'company_pay_roll.header.salary_types': 'Ret timesatser',
  'company_pay_roll.header.user_notifications': 'Notifikationer',
  'company_pay_roll.title': 'Løn',

  'company_tax_cards.table.header.deduction_monthly': 'Md. fradrag',
  'company_tax_cards.table.header.employee_number': 'Medarbejdernr.',
  'company_tax_cards.table.header.max_aincome_free_card': 'Fribeløb',
  'company_tax_cards.table.header.name': 'Navn',
  'company_tax_cards.table.header.national_id': 'CPR-nummer',
  'company_tax_cards.table.header.tax_card_type': 'Type',
  'company_tax_cards.table.header.tax_percentage': 'Trækprocent',
  'company_tax_cards.title': 'Aktive skattekort',

  'company_users.add.error.no_departments': 'Vælg mindst én afdeling for at invitere en afdelingsleder.',
  'company_users.add.form.departments.intro':
    'Adgang til en afdeling giver adgang til at se afdelingens medarbejdere og godkende deres timer, kørsel og udlæg.',
  'company_users.add.form.departments.title': 'Afdelinger',
  'company_users.add.form.email': 'E-mail',
  'company_users.add.form.email.invalid': 'E-mailadressen er ikke gyldig',
  'company_users.add.form.email.required': 'E-mail skal indtastes',
  'company_users.add.form.name': 'Navn',
  'company_users.add.form.name.required': 'Navn skal indtastes',
  'company_users.add.form.permissions.intro': 'Ved ingen rettigheder, kan brugeren ikke godkende løn.',
  'company_users.add.form.permissions.title': 'Ekstra rettigheder',
  'company_users.add.form.read_only_note':
    'En bruger med kun læseadgang, kan se alle dele af Salary, men ikke foretage nogle ændringer.',
  'company_users.add.form.submit.approve_only': 'Opret bruger',
  'company_users.add.form.submit.regular': 'Send invitation nu',
  'company_users.add.form.user_type': 'Rolle',
  'company_users.add.form.user_type.placeholder': 'Vælg rolle',
  'company_users.add.form.user_type.required': 'Rolle skal vælges',
  'company_users.add.intro':
    'Hvis du ønsker at give en revisor, bogholder eller lignende adgang til din virksomhed, kan du sende en invitation til deres mail her.',
  'company_users.add.title': 'Invitér ny bruger',
  'company_users.edit_departments.alert.success': 'Ændringerne til {name} er gemt',
  'company_users.edit_departments.form.can_write.with_see_employment': 'Oprette/redigere timer, løn, m.fl.',
  'company_users.edit_departments.form.can_write.without_see_employment': 'Oprette/redigere timer, m.fl.',
  'company_users.edit_departments.form.departments': 'Afdelinger',
  'company_users.edit_departments.form.has_access': '(godkend timer, fravær & udlæg)',
  'company_users.edit_departments.form.see_employment': 'Se ansættelseskontrakt og lønvilkår',
  'company_users.edit_departments.title': 'Afdelingsadgang for {name}',
  'company_users.edit.title': 'Rettigheder for {name}',
  'company_users.header.add_user': 'Tilføj bruger',
  'company_users.invite_table.actions.delete': 'Slet invitation',
  'company_users.invite_table.header.created_at': 'Sendt',
  'company_users.invite_table.header.email': 'E-mail',
  'company_users.invite_table.title.many': '{count} invitationer',
  'company_users.invite_table.title.one': '{count} invitation',
  'company_users.table.actions.delete_access': 'Slet adgang',
  'company_users.table.actions.edit_departments': 'Redigér afdelinger',
  'company_users.table.actions.edit_permissions': 'Redigér rettigheder',
  'company_users.table.department_manager': 'Afdelingsleder',
  'company_users.table.departments.many': '{count} afdelinger',
  'company_users.table.departments.one': '{count} afdeling',
  'company_users.table.header.created_at': 'Bruger siden',
  'company_users.table.header.email': 'E-mail',
  'company_users.table.header.name': 'Navn',
  'company_users.table.header.permissions': 'Rettigheder',
  'company_users.table.title.many': '{count} brugere',
  'company_users.table.title.one': '{count} bruger',

  'company.company_size.large': 'Stor (A-skat over kr. 1.000.000 eller AM-bidrag over kr. 250.000 pr. år)',
  'company.company_size.small': 'Lille (A-skat under kr. 1.000.000 og AM-bidrag under kr. 250.000 pr. år)',
  'company.disposition_date_rule.next_friday': 'Fredag efter lønperioden',
  'company.disposition_date_rule.next_monday': 'Mandag efter lønperioden',
  'company.disposition_date_rule.next_thursday': 'Torsdag efter lønperioden',
  'company.disposition_date_rule.next_tuesday': 'Tirsdag efter lønperioden',
  'company.disposition_date_rule.next_wednesday': 'Onsdag efter lønperioden',
  'company.disposition_date_rule.period_end': 'Sidste bankdag i lønperioden',
  'company.edit.alert.success': 'Ændring til {name} er gemt',
  'company.edit.form.address': 'Adresse',
  'company.edit.form.auto_approve_car_allowances': 'Autogodkend al kørsel indberettet fra medarbejdere',
  'company.edit.form.auto_approve_compensations': 'Autogodkend alle honorarer indberettet fra freelancere',
  'company.edit.form.auto_approve_description':
    'OBS: Du kan stadig slette indberetninger du ikke mener skal udbetales.',
  'company.edit.form.auto_approve_reimbursement_vouchers': 'Autogodkend alle udlæg indberettet fra medarbejdere',
  'company.edit.form.auto_approve_salary_registrations':
    'Autogodkend alle lønregistreringer indberettet fra medarbejdere',
  'company.edit.form.auto_approve_time_registration_hours': 'Autogodkend alle timer indberettet fra medarbejdere',
  'company.edit.form.auto_approve_time_registration_leave': 'Autogodkend alt fravær indberettet fra medarbejdere',
  'company.edit.form.city': 'By',
  'company.edit.form.default_biweekly_cycle_id': 'Lønperiode for 14-dageslønnede',
  'company.edit.form.default_biweekly_cycle_id.placeholder': 'Vælg cyklus',
  'company.edit.form.default_hourly_monthly_cycle_id': 'Lønperiode for timelønnede',
  'company.edit.form.default_hourly_monthly_cycle_id.option.no_offset': 'Fra den 1. til sidste dag i måneden',
  'company.edit.form.default_hourly_monthly_cycle_id.option.with_offset': 'Fra den {from} til den {to} i måneden',
  'company.edit.form.default_hourly_monthly_cycle_id.placeholder': 'Vælg cyklus',
  'company.edit.form.family_leave_fund': 'Barsel',
  'company.edit.form.name': 'Navn',
  'company.edit.form.national_id': 'CVR-nummer',
  'company.edit.form.offset_disposition_biweekly': 'Dispositionsdag for 14-dageslønnede',
  'company.edit.form.offset_disposition_biweekly.select': 'Vælg',
  'company.edit.form.offset_disposition_monthly': 'Dispositionsdag for månedslønnede',
  'company.edit.form.offset_disposition_monthly.rule.0': 'Sidste bankdag i måneden',
  'company.edit.form.offset_disposition_monthly.rule.after.many': '{count} bankdage efter sidste bankdag i måneden',
  'company.edit.form.offset_disposition_monthly.rule.after.one': '{count} bankdag efter sidste bankdag i måneden',
  'company.edit.form.offset_disposition_monthly.rule.before.many': '{count} bankdage før sidste bankdag i måneden',
  'company.edit.form.offset_disposition_monthly.rule.before.one': '{count} bankdag før sidste bankdag i måneden',
  'company.edit.form.offset_disposition_monthly.select': 'Vælg',
  'company.edit.form.offset_disposition_weekly': 'Dispositionsdag for ugelønnede',
  'company.edit.form.offset_disposition_weekly.select': 'Vælg',
  'company.edit.form.offset_disposition.description':
    'Når du ændrer på de generelle dispositionsdatoer på virksomhedsniveau, så får det først effekt ved næste lønperiode. Dispositionsdatoen på de igangværende lønkørsler bliver ikke rettet. Dem skal du manuelt rette bagefter.',
  'company.edit.form.pay_slip_transport_default': 'Standard for lønseddelslevering',
  'company.edit.form.pay_slip_transport_default.batch_note':
    'Du kan bruge en {link} for at flytte alle dine medarbejdere til en anden digital postkasse.',
  'company.edit.form.pay_slip_transport_default.batch_note_link': 'massehandling',
  'company.edit.form.pay_slip_transport_default.eboks': 'e-Boks',
  'company.edit.form.pay_slip_transport_default.email': 'E-mail',
  'company.edit.form.pay_slip_transport_default.mit_dk': 'mit.dk',
  'company.edit.form.pay_slip_transport_default.none': 'Ingen',
  'company.edit.form.postal_code': 'Postnr.',
  'company.edit.form.tax_size': 'Virksomhedens størrelse',
  'company.edit.form.tax_size.select': 'Vælg størrelse',
  'company.edit.form.validation.address.required': 'Adresse skal indtastes',
  'company.edit.form.validation.city.required': 'By skal indtastes',
  'company.edit.form.validation.default_biweekly_cycle_id.required': 'Lønperiode for 14-dageslønnede skal vælges',
  'company.edit.form.validation.default_hourly_monthly_cycle_id.required': 'Lønperiode for timelønnede skal vælges',
  'company.edit.form.validation.name.required': 'Navn skal indtastes',
  'company.edit.form.validation.national_id.in_use': 'CVR-nummer er i brug',
  'company.edit.form.validation.national_id.invalid': 'CVR-nummer er ugyldigt',
  'company.edit.form.validation.national_id.required': 'CVR-nummer skal indtastes',
  'company.edit.form.validation.offset_disposition_biweekly.required':
    'Dispositionsdag for 14-dageslønnede skal vælges',
  'company.edit.form.validation.offset_disposition_monthly.invalid': 'Dispositionsdag for månedslønnede er ugyldig',
  'company.edit.form.validation.offset_disposition_weekly.required': 'Dispositionsdag for ugelønnede skal vælges',
  'company.edit.form.validation.postal_code.invalid': 'Postnr. er ugyldigt',
  'company.edit.form.validation.postal_code.required': 'Postnr. skal indtastes',
  'company.edit.form.validation.tax_size.required': 'Virksomhedens størrelse skal vælges',
  'company.edit.header.advanced_settings': 'Avanceret',
  'company.edit.header.pay_slip_fields': 'Ret lønseddeludseende',
  'company.edit.header.production_units': 'Produktionsenheder',
  'company.edit.header.title': 'Virksomhed',
  'company.edit.header.users': 'Administrer brugere',
  'company.edit.header.vacation_settings': 'Ferieindstillinger',
  'company.menu.api_keys': 'API-nøgler',
  'company.menu.club': 'Fordelsklub',
  'company.menu.company': 'Virksomhed',
  'company.menu.departments': 'Afdelinger',
  'company.menu.invoices': 'Fakturaer',
  'company.menu.pay': 'Lønindstillinger',
  'company.menu.pricing': 'Prispakke',
  'company.menu.reports': 'Rapporter',
  'company.menu.support': 'Support',
  'company.menu.transfers': 'Overførsler',

  'contract_book.alert.success': 'Ændringer til {name} er gemt',
  'contract_book.form.add_integration': 'Tilføj ContractBook-integration',
  'contract_book.form.api_key': 'API-nøgle',
  'contract_book.form.callback_url': 'Svarsti',
  'contract_book.form.delete': 'Fjern',
  'contract_book.form.header.api_key': 'API-nøgle',
  'contract_book.form.header.callback_url': 'Svarsti',
  'contract_book.form.header.delete': 'Fjern?',
  'contract_book.table.actions.delete': 'Skjul kontrakt',
  'contract_book.table.header.contract_name': 'Kontrakt',
  'contract_book.table.header.signee_name': 'Medarbejder',
  'contract_book.table.header.updated_at': 'Opdateringsdato',
  'contract_book.unsigned_contracts': 'Underskrevne kontrakter',

  'contract_delete.introduction.end_date': 'Er du sikker på du vil slette kontrakten der gælder fra {from} til {to}?',
  'contract_delete.introduction.no_end_date': 'Er du sikker på du vil slette kontrakten der gælder fra {from}?',
  'contract_delete.submit': 'Slet kontrakt',
  'contract_delete.title.future': 'Slet denne fremtidige kontrakt?',
  'contract_delete.title.ordinary': 'Slet denne kontrakt?',
  'contract_delete.warning': 'Kontrakten bliver slettet med det samme.',
  'contract_delete.warning.no_previous_contract':
    'Da der ikke er nogen kontrakt umiddelbart før denne, så har dens sletning ingen indflydelse på andre kontrakter.',
  'contract_delete.warning.previous_contract.end_date_changed':
    'Kontrakten der udløber dagen før denne begynder, som gælder fra {from} til {to}, får sin udløbsdato ændret til {to_new}.',
  'contract_delete.warning.previous_contract.end_date_removed':
    'Kontrakten der udløber dagen før denne begynder, som gælder fra {from} til {to}, får sin udløbsdato fjernet.',

  'contract_delta.field.car_allowance_rate': 'Kørselsgodtgørelsesrate',
  'contract_delta.field.car_allowance_rate.format': '{amount}/km',
  'contract_delta.field.car_allowance_registration_method': 'Kørselsregistreringsmetode',
  'contract_delta.field.car_allowance_registration_method.coarse': 'Simpel',
  'contract_delta.field.car_allowance_registration_method.detailed': 'Detaljeret',
  'contract_delta.field.extra_tax_percentage': 'Ekstra skatteprocent',
  'contract_delta.field.family_leave_fund': 'Barsel',
  'contract_delta.field.period_work_hours': 'Timer indberettet',
  'contract_delta.field.position': 'Stilling',
  'contract_delta.field.salary_cycle_id': 'Lønperiode',
  'contract_delta.field.time_registration_method_type': 'Tidsregistreringsmetode',
  'contract_delta.field.time_registration_method_type.coarse': 'Simpel',
  'contract_delta.field.time_registration_method_type.detailed': 'Detaljeret',
  'contract_delta.field.type': 'Ansættelse',
  'contract_delta.field.type.ordinary': 'Fastansættelse',
  'contract_delta.field.vacation_type_change': 'Ændret til ny Ferielov',
  'contract_delta.field.weekly_hours': 'Ugentlige arbejdstimer',
  'contract_delta.field.work_cycle': 'Arbejdsuge',
  'contract_delta.field.work_cycle_anchor_date': 'Arbejdsugeankerdato',

  'contract_history.actions.new_future_contract': 'Opret fremtidig kontrakt',
  'contract_history.actions.remove_leave': 'Fjern',
  'contract_history.history.change.no_change': 'Ingen ændring',
  'contract_history.history.leave': 'Orlov',
  'contract_history.history.range_format': '{from} - {to}',
  'contract_history.history.state.active': 'Gældende',
  'contract_history.history.state.expired': 'Historisk',
  'contract_history.history.state.future': 'Fremtidig',
  'contract_history.history.state.initial': 'første kontrakt',
  'contract_history.history.state.last_edit': 'senest ændret af {name}',
  'contract_history.title': 'Ansættelseshistorik',

  'contract_template.add.select.form.contract_id': 'Startkontrakt',
  'contract_template.add.select.form.contract_id.option.active': '{from} - {to} (gældende)',
  'contract_template.add.select.form.contract_id.option.other': '{from} - {to}',
  'contract_template.add.select.form.contract_id.placeholder': 'Vælg startkontrakt',
  'contract_template.add.select.form.contract_id.required': 'Startkontrakt skal vælges',
  'contract_template.add.select.form.submit': 'Vælg kontrakt',
  'contract_template.add.step.0.buttons.back': 'Tilbage',
  'contract_template.add.step.0.employee_select': 'Vælg',
  'contract_template.add.step.0.intro':
    'For at lave en skabelon, skal du først vælge en medarbejder at basere skabelonen på.  Bagefter kan du gennemse skabelonen før du opretter den.',
  'contract_template.add.step.0.title': 'Trin 1',
  'contract_template.add.step.1.intro':
    'Medarbejderen har en kontrakthistorik, vælg startkontrakten at tage udgangspunkt i.  Det vil efterfølgende være muligt at vælge om kontrakter efter startkontrakten skal inkluderes i skabelonen som planlagte ændringer.',
  'contract_template.add.step.1.title': 'Trin 2',
  'contract_template.add.step.2.intro.line_1':
    'Gennemse skabelonen og sørg for at den er som forventet. Er den ikke, så vælg en anden medarbejder, eller {link}, og kom her tilbage for at oprette skabelonen igen.',
  'contract_template.add.step.2.intro.line_1.link': 'ret på denne medarbejder',
  'contract_template.add.step.2.intro.line_2':
    'Alle felterne beskrevet nedenfor er dem der vil være med på skabelonen, men efter en medarbejder er oprettet, kan man altid justere felterne som normalt.',
  'contract_template.add.step.2.title': 'Trin 3',
  'contract_template.add.steps.0': 'Vælg medarbejder',
  'contract_template.add.steps.1': 'Vælg startkontrakt',
  'contract_template.add.steps.2': 'Gennemse skabelon',
  'contract_template.add.steps.intro': 'Efter blot tre trin, har du oprettet en medarbejderskabelon',
  'contract_template.add.steps.title': 'Ny medarbejderskabelon',
  'contract_template.alert.success': 'Medarbejderskabelonen er gemt',
  'contract_template.back': 'Tilbage',
  'contract_template.edit.back': 'Tilbage',
  'contract_template.edit.delete': 'Slet skabelon',
  'contract_template.edit.intro':
    'Her kan du se en eksisterende skabelon. Der er ingen mulighed for at rette i en skabelon. Hvis du vil lave den om, slet den og lav en ny baseret på en medarbejder med de korrekte værdier.',
  'contract_template.edit.title': 'Medarbejderskabelon',
  'contract_template.form.back': 'Tilbage',
  'contract_template.form.description': 'Beskrivelse',
  'contract_template.form.description.required': 'Beskrivelse skal indtastes',
  'contract_template.form.false': 'Nej',
  'contract_template.form.fields.benefits.none': 'Ingen',
  'contract_template.form.fields.benefits.title': 'Personalegoder',
  'contract_template.form.fields.benefits.value': 'Værdi',
  'contract_template.form.fields.contract.car_allowance_registration_method_type': 'Kørselsregistrering',
  'contract_template.form.fields.contract.car_allowance_registration_method_type.coarse': 'Simpel',
  'contract_template.form.fields.contract.car_allowance_registration_method_type.detailed': 'Detaljeret',
  'contract_template.form.fields.contract.day_laborer': 'Anden ferieberegning',
  'contract_template.form.fields.contract.day_laborer.days': 'Per dag',
  'contract_template.form.fields.contract.day_laborer.hours': 'Per time',
  'contract_template.form.fields.contract.position': 'Stilling',
  'contract_template.form.fields.contract.salary_cycle': 'Løncyklus',
  'contract_template.form.fields.contract.time_registration_method_type': 'Tidsregistrering',
  'contract_template.form.fields.contract.time_registration_method_type.coarse': 'Simpel',
  'contract_template.form.fields.contract.time_registration_method_type.detailed': 'Detaljeret',
  'contract_template.form.fields.contract.title': 'Ansættelsesforhold',
  'contract_template.form.fields.contract.work_week': 'Arbejdsuge',
  'contract_template.form.fields.employee.department': 'Afdeling',
  'contract_template.form.fields.employee.department.none': 'Ingen',
  'contract_template.form.fields.employee.director': 'Direktørløn?',
  'contract_template.form.fields.employee.employee_type': 'Medarbejdertype',
  'contract_template.form.fields.employee.income_type': 'Indkomsttype',
  'contract_template.form.fields.employee.language': 'Sprog',
  'contract_template.form.fields.employee.pay_slip_transport': 'Lønseddelslevering',
  'contract_template.form.fields.employee.pay_slip_transport.e_boks': 'e-Boks',
  'contract_template.form.fields.employee.pay_slip_transport.email': 'E-mail',
  'contract_template.form.fields.employee.pay_slip_transport.mit_dk': 'mit.dk',
  'contract_template.form.fields.employee.pay_slip_transport.sms': 'SMS',
  'contract_template.form.fields.employee.preferred_tax_card_type': 'Primært skattekort',
  'contract_template.form.fields.employee.title': 'Medarbejderniveau',
  'contract_template.form.fields.employee.transport_destination_type': 'Kontooverførsel',
  'contract_template.form.fields.leave.none': 'Ingen',
  'contract_template.form.fields.leave.title': 'Ferie',
  'contract_template.form.fields.pension.none': 'Ingen',
  'contract_template.form.fields.pension.title': 'Pension',
  'contract_template.form.fields.remuneration.header.include': 'Medtag',
  'contract_template.form.fields.remuneration.header.rate': 'Sats',
  'contract_template.form.fields.remuneration.header.type': 'Lønlinje',
  'contract_template.form.fields.remuneration.intro':
    'Lønlinjerne er per standard {not} med på en skabelon, men de kan vælges til, ved at sætte hak i "medtag".',
  'contract_template.form.fields.remuneration.intro.not': 'ikke',
  'contract_template.form.fields.remuneration.title': 'Løn',
  'contract_template.form.fields.supplements.none': 'Ingen',
  'contract_template.form.fields.supplements.title': 'Tillæg',
  'contract_template.form.frequency.bi_weekly': '14-dags-perioder',
  'contract_template.form.frequency.default': 'lønperioder',
  'contract_template.form.frequency.monthly': 'måneder',
  'contract_template.form.frequency.weekly': 'uger',
  'contract_template.form.future.change.include': 'Inkludér',
  'contract_template.form.future.change.title': '{number} ændring',
  'contract_template.form.future.change.type.base': 'Ændret',
  'contract_template.form.future.change.type.decrease': 'Nedsat',
  'contract_template.form.future.change.type.increase': 'Forøget',
  'contract_template.form.future.change.type.remove': 'Fjernet',
  'contract_template.form.future.change.valid_from': 'Gyldig efter',
  'contract_template.form.future.change.valid_from.invalid': 'Gyldig efter er ugyldig',
  'contract_template.form.future.change.valid_from.note.from_employment': 'Efter {number} {period} fra ansættelsen.',
  'contract_template.form.future.change.valid_from.note.from_last_change':
    'Efter {number} {period} fra seneste ændring.',
  'contract_template.form.future.change.valid_from.required': 'Gyldig efter skal indtastes',
  'contract_template.form.future.header.changes': 'Ændringer',
  'contract_template.form.future.header.due_date': 'Ikrafttrædelse',
  'contract_template.form.future.intro':
    'Medarbejderen har en til flere ansættelsesændringer, som du kan tilknytte som en del af skabelonens planlagte ændringer. Vælg her hvilke ændringer der skal med, og efter hvor lang tid de skal træde i kraft.',
  'contract_template.form.future.title': 'Planlagte ændringer',
  'contract_template.form.submit.create': 'Opret',
  'contract_template.form.submit.update': 'Opdatér',
  'contract_template.form.title': 'Titel',
  'contract_template.form.title.required': 'Titel skal indtastes',
  'contract_template.form.true': 'Ja',
  'contract_template.header.new_template': 'Ny medarbejderskabelon',
  'contract_template.intro':
    'Med medarbejderskabeloner kan du gemme forudindtastede oplysninger for en medarbejder og deres ansættelser, så næste gang du opretter en medarbejder kan du vælge at forudfylde felterne fra en skabelon. Du kan som altid justere felterne individuelt bagefter. Alle felter der normalt er en del af en ansættelse - som ikke er personlige - kan gemmes.',
  'contract_template.table.actions.delete': 'Slet',
  'contract_template.table.actions.details': 'Vis detaljer',
  'contract_template.table.header.description': 'Beskrivelse',
  'contract_template.table.header.title': 'Titel',
  'contract_template.title': 'Medarbejderskabeloner',

  'contract_valid_from_form.valid_from': 'Gældende fra',
  'contract_valid_from_form.valid_from.invalid': 'Gældende fra er ugyldig',
  'contract_valid_from_form.valid_from.not_after_termination_date':
    'Gældende fra kan ikke være efter opsigelsesdatoen ({date})',
  'contract_valid_from_form.valid_from.not_before_date': 'Gældende fra kan ikke være før {date}',
  'contract_valid_from_form.valid_from.not_before_employment_date': 'Gældende fra kan ikke være før ansættelsesdatoen',
  'contract_valid_from_form.valid_from.not_before_start_of_last_year':
    'Gældende fra kan ikke være før starten af sidste år',
  'contract_valid_from_form.valid_from.not_same_or_after_valid_to':
    'Gældende fra kan ikke være samme eller efter kontraktens gældende til ({date})',
  'contract_valid_from_form.valid_from.required': 'Gældende fra skal vælges',

  'contract.first_salary_period.valid_from': 'Første lønperiode',
  'contract.first_salary_period.valid_from.not_after_end_date':
    'Første lønperiode kan ikke være efter opsigelsesdatoen',
  'contract.first_salary_period.valid_from.placeholder': 'Vælg periode',
  'contract.first_salary_period.valid_from.required': 'Første lønperiode skal vælges',
  'contract.position.employment_position_id': 'Stilling (DISCO-08)',
  'contract.position.position': 'Stillingsbetegnelse',
  'contract.position.position.required': 'Stillingsbetegnelse skal indtastes',
  'contract.workplace.production_unit_id': 'Produktionsenhed',
  'contract.workplace.production_unit_id.placeholder': 'Vælg produktionsenhed',
  'contract.workplace.production_unit_id.required': 'Produktionsenhed skal vælges',

  'contracts_add.alert.success': '{name} har nu en ansættelse',
  'contracts_add.benefits_step.back': 'Tilbage',
  'contracts_add.benefits_step.submit': 'Videre til ferie',
  'contracts_add.benefits_step.title': 'Personalegoder',
  'contracts_add.employment_step.intro':
    'Bemærk at dette ikke er den gældende kontrakt for medarbejderen. Du bedes udfylde detaljerne fra en gældende aftale med medarbejderen. Du kan altid rette oplysningerne senere, eller oprette en ny kontrakt for medarbejderen.',
  'contracts_add.employment_step.submit': 'Videre til løn',
  'contracts_add.employment_step.title': 'Udfyld detaljerne fra ansættelseskontrakten',
  'contracts_add.future_contract_step.back': 'Tilbage',
  'contracts_add.future_contract_step.form.include': 'Medtag',
  'contracts_add.future_contract_step.intro':
    'Der er fremtidige ændringer på denne skabelon. Husk på du kan altid senere manuelt rette i de fremtidige ændringer.',
  'contracts_add.future_contract_step.submit': 'Gem ansættelse',
  'contracts_add.future_contract_step.title': 'Fremtidige ændringer',
  'contracts_add.pay_step.back': 'Tilbage',
  'contracts_add.pay_step.submit': 'Videre til personalegoder',
  'contracts_add.pay_step.title': 'Hvor meget skal {name} have i løn?',
  'contracts_add.pension_step.back': 'Tilbage',
  'contracts_add.pension_step.choice.title': 'SH-dage og fritvalgskonto',
  'contracts_add.pension_step.pension.title': 'Pension',
  'contracts_add.pension_step.submit.final': 'Gem ansættelse',
  'contracts_add.pension_step.submit.to_future': 'Videre til fremtidige ændringer',
  'contracts_add.pension_step.title': 'Tilføj pensionsordning og -aftale',
  'contracts_add.step_0.title': 'Trin 1',
  'contracts_add.step_1.title': 'Trin 2',
  'contracts_add.step_2.title': 'Trin 3',
  'contracts_add.step_3.title': 'Trin 4',
  'contracts_add.step_4.title': 'Trin 5',
  'contracts_add.step_5.title': 'Trin 6',
  'contracts_add.steps.0': 'Ansættelse',
  'contracts_add.steps.1': 'Løn',
  'contracts_add.steps.2': 'Personalegoder',
  'contracts_add.steps.3': 'Ferie',
  'contracts_add.steps.4': 'Pension',
  'contracts_add.steps.5': 'Fremtidige ændringer',
  'contracts_add.steps.contract_book.intro': 'Herunder finder du de felter, vi ikke automatisk kunne udfylde.',
  'contracts_add.steps.contract_book.missing': '[ikke udfyldt]',
  'contracts_add.steps.contract_book.title': 'ContractBook-felter',
  'contracts_add.steps.help_note':
    'Hvis du har problemer med at oprette en ansættelse, kan du altid kontakte {email}. Vi vil med glæde hjælpe dig!',
  'contracts_add.steps.intro': 'Efter disse {steps} trin, har du oprettet en ansættelse for {name}',
  'contracts_add.steps.title': 'Oprettelse af ansættelse',
  'contracts_add.vacation_step.back': 'Tilbage',
  'contracts_add.vacation_step.submit': 'Videre til pension',
  'contracts_add.vacation_step.title': 'Ferie',

  'country_code.ad': 'Andorra',
  'country_code.ae': 'Forenede Arabiske Emirater',
  'country_code.af': 'Afghanistan',
  'country_code.ag': 'Antigua og Barbuda',
  'country_code.ai': 'Anguilla',
  'country_code.al': 'Albanien',
  'country_code.am': 'Armenien',
  'country_code.ao': 'Angola',
  'country_code.aq': 'Antarktika',
  'country_code.ar': 'Argentina',
  'country_code.as': 'Amerikansk Samoa',
  'country_code.at': 'Østrig',
  'country_code.au': 'Australien',
  'country_code.aw': 'Aruba',
  'country_code.ax': 'Åland',
  'country_code.az': 'Azerbaijan',
  'country_code.ba': 'Bosnien-Hercegovina',
  'country_code.bb': 'Barbados',
  'country_code.bd': 'Bangladesh',
  'country_code.be': 'Belgien',
  'country_code.bf': 'Burkina Faso',
  'country_code.bg': 'Bulgarien',
  'country_code.bh': 'Bahrain',
  'country_code.bi': 'Burundi',
  'country_code.bj': 'Benin',
  'country_code.bl': 'Saint Barthélemy',
  'country_code.bm': 'Bermuda',
  'country_code.bn': 'Brunei',
  'country_code.bo': 'Bolivia',
  'country_code.bq': 'Bonaire, Sankt Eustatius og Saba',
  'country_code.br': 'Brasilien',
  'country_code.bs': 'Bahamas',
  'country_code.bt': 'Bhutan',
  'country_code.bv': 'Bouvet Island',
  'country_code.bw': 'Botswana',
  'country_code.by': 'Hviderusland',
  'country_code.bz': 'Belize',
  'country_code.ca': 'Canada',
  'country_code.cc': 'Cocosøerne (eller Keelingøerne)',
  'country_code.cd': 'Demokratiske republik Congo',
  'country_code.cf': 'Centralafrikanske Republik',
  'country_code.cg': 'Congo',
  'country_code.ch': 'Schweiz',
  'country_code.ci': 'Elfenbenskysten',
  'country_code.ck': 'Cookøerne',
  'country_code.cl': 'Chile',
  'country_code.cm': 'Cameroon',
  'country_code.cn': 'Kina',
  'country_code.co': 'Colombia',
  'country_code.cr': 'Costa Rica',
  'country_code.cu': 'Cuba',
  'country_code.cv': 'Cabo Verde',
  'country_code.cw': 'Curaçao',
  'country_code.cx': 'Juleøen',
  'country_code.cy': 'Kypern',
  'country_code.cz': 'Tjekkiet',
  'country_code.de': 'Tyskland',
  'country_code.dj': 'Djibouti',
  'country_code.dk': 'Danmark',
  'country_code.dm': 'Dominica',
  'country_code.do': 'Dominikanske Republik',
  'country_code.dz': 'Algeriet',
  'country_code.ec': 'Ecuador',
  'country_code.ee': 'Estland',
  'country_code.eg': 'Ægypten',
  'country_code.eh': 'Vestsahara',
  'country_code.er': 'Eritrea',
  'country_code.es': 'Spanien',
  'country_code.et': 'Ethiopia',
  'country_code.fi': 'Finland',
  'country_code.fj': 'Fiji',
  'country_code.fk': 'Falklandsøerne',
  'country_code.fm': 'Mikronesien',
  'country_code.fo': 'Færøerne',
  'country_code.fr': 'Frankrig',
  'country_code.ga': 'Gabon',
  'country_code.gb': 'Forenede Kongerige (UK)',
  'country_code.gd': 'Grenada',
  'country_code.ge': 'Georgien',
  'country_code.gf': 'Fransk Guiana',
  'country_code.gg': 'Guernsey',
  'country_code.gh': 'Ghana',
  'country_code.gi': 'Gibraltar',
  'country_code.gl': 'Grønland',
  'country_code.gm': 'Gambia',
  'country_code.gn': 'Guinea',
  'country_code.gp': 'Guadeloupe',
  'country_code.gq': 'Equatorial Guinea',
  'country_code.gr': 'Grækenland',
  'country_code.gs': 'South Georgia og de Sydlige Sandwichøer',
  'country_code.gt': 'Guatemala',
  'country_code.gu': 'Guam',
  'country_code.gw': 'Guinea-Bissau',
  'country_code.gy': 'Guyana',
  'country_code.hk': 'Hongkong',
  'country_code.hm': 'Heardøen og McDonaldøerne',
  'country_code.hn': 'Honduras',
  'country_code.hr': 'Kroatien',
  'country_code.ht': 'Haiti',
  'country_code.hu': 'Ungarn',
  'country_code.id': 'Indonesien',
  'country_code.ie': 'Irland',
  'country_code.il': 'Israel',
  'country_code.im': 'Isle of Man',
  'country_code.in': 'Indien',
  'country_code.io': 'British Indian Ocean Territory',
  'country_code.iq': 'Irak',
  'country_code.ir': 'Iran',
  'country_code.is': 'Island',
  'country_code.it': 'Italien',
  'country_code.je': 'Jersey',
  'country_code.jm': 'Jamaica',
  'country_code.jo': 'Jordan',
  'country_code.jp': 'Japan',
  'country_code.ke': 'Kenya',
  'country_code.kg': 'Kyrgyzstan',
  'country_code.kh': 'Cambodia',
  'country_code.ki': 'Kiribati',
  'country_code.km': 'Comoros',
  'country_code.kn': 'Saint Kitts og Nevis',
  'country_code.kp': 'Nordkorea',
  'country_code.kr': 'Sydkorea',
  'country_code.kw': 'Kuwait',
  'country_code.ky': 'Caymanøerne',
  'country_code.kz': 'Kasakhstan',
  'country_code.la': 'Laos',
  'country_code.lb': 'Lebanon',
  'country_code.lc': 'Saint Lucia',
  'country_code.li': 'Liechtenstein',
  'country_code.lk': 'Sri Lanka',
  'country_code.lr': 'Liberia',
  'country_code.ls': 'Lesotho',
  'country_code.lt': 'Lithauen',
  'country_code.lu': 'Luxembourg',
  'country_code.lv': 'Letland',
  'country_code.ly': 'Libyen',
  'country_code.ma': 'Morocco',
  'country_code.mc': 'Monaco',
  'country_code.md': 'Moldova',
  'country_code.me': 'Montenegro',
  'country_code.mf': 'Saint Martin (Fransk del)',
  'country_code.mg': 'Madagascar',
  'country_code.mh': 'Marshall-øerne',
  'country_code.mk': 'Makedonien',
  'country_code.ml': 'Mali',
  'country_code.mm': 'Myanmar',
  'country_code.mn': 'Mongoliet',
  'country_code.mo': 'Macao',
  'country_code.mp': 'Nordmarianerne',
  'country_code.mq': 'Martinique',
  'country_code.mr': 'Mauretanien',
  'country_code.ms': 'Montserrat',
  'country_code.mt': 'Malta',
  'country_code.mu': 'Mauritius',
  'country_code.mv': 'Maldiverne',
  'country_code.mw': 'Malawi',
  'country_code.mx': 'Mexico',
  'country_code.my': 'Malaysia',
  'country_code.mz': 'Mozambique',
  'country_code.na': 'Namibia',
  'country_code.nc': 'Ny Caledonien',
  'country_code.ne': 'Niger',
  'country_code.nf': 'Norfolk Island',
  'country_code.ng': 'Nigeria',
  'country_code.ni': 'Nicaragua',
  'country_code.nl': 'Nederlandene',
  'country_code.no': 'Norge',
  'country_code.np': 'Nepal',
  'country_code.nr': 'Nauru',
  'country_code.nu': 'Niue',
  'country_code.nz': 'New Zealand',
  'country_code.om': 'Oman',
  'country_code.pa': 'Panama',
  'country_code.pe': 'Peru',
  'country_code.pf': 'Fransk Polynesia',
  'country_code.pg': 'Papua Ny Guinea',
  'country_code.ph': 'Filippinerne',
  'country_code.pk': 'Pakistan',
  'country_code.pl': 'Polen',
  'country_code.pm': 'St. Pierre og Miquelon',
  'country_code.pn': 'Pitcairn',
  'country_code.pr': 'Puerto Rico',
  'country_code.ps': 'Palæstina',
  'country_code.pt': 'Portugal',
  'country_code.pw': 'Palau',
  'country_code.py': 'Paraguay',
  'country_code.qa': 'Qatar',
  'country_code.re': 'Réunion',
  'country_code.ro': 'Rumænien',
  'country_code.rs': 'Serbien',
  'country_code.ru': 'Rusland',
  'country_code.rw': 'Rwanda',
  'country_code.sa': 'Saudi-Arabien',
  'country_code.sb': 'Solomon Islands',
  'country_code.sc': 'Seychelles',
  'country_code.sd': 'Sudan',
  'country_code.se': 'Sverige',
  'country_code.sg': 'Singapore',
  'country_code.sh': 'Saint Helena, Ascension og Tristan da Cunha',
  'country_code.si': 'Slovenien',
  'country_code.sj': 'Svalbard og Jan Mayen',
  'country_code.sk': 'Slovakiet',
  'country_code.sl': 'Sierra Leone',
  'country_code.sm': 'San Marino',
  'country_code.sn': 'Senegal',
  'country_code.so': 'Somalia',
  'country_code.sr': 'Suriname',
  'country_code.ss': 'Sydsudan',
  'country_code.st': 'Sao Tome og Principe',
  'country_code.sv': 'El Salvador',
  'country_code.sx': 'Sint Maarten (Nederlandsk del)',
  'country_code.sy': 'Syrien',
  'country_code.sz': 'Eswatini',
  'country_code.tc': 'Turks- og Caicosøerne',
  'country_code.td': 'Tchad',
  'country_code.tf': 'Franske besiddelser i det sydlige Indiske Ocean',
  'country_code.tg': 'Togo',
  'country_code.th': 'Thailand',
  'country_code.tj': 'Tajikistan',
  'country_code.tk': 'Tokelau',
  'country_code.tl': 'Timor-Leste',
  'country_code.tm': 'Turkmenistan',
  'country_code.tn': 'Tunisien',
  'country_code.to': 'Tonga',
  'country_code.tr': 'Tyrkiet',
  'country_code.tt': 'Trinidad og Tobago',
  'country_code.tv': 'Tuvalu',
  'country_code.tw': 'Taiwan',
  'country_code.tz': 'Tanzania',
  'country_code.ua': 'Ukraine',
  'country_code.ug': 'Uganda',
  'country_code.um': 'USAs mindre øbesiddelser',
  'country_code.us': 'Amerikas Forenede Stater (USA)',
  'country_code.uy': 'Uruguay',
  'country_code.uz': 'Uzbekistan',
  'country_code.va': 'Vatikanstaten',
  'country_code.vc': 'St. Vincent og Grenadinerne',
  'country_code.ve': 'Venezuela',
  'country_code.vg': 'Jomfruøerne (Britisk)',
  'country_code.vi': 'Jomfruøerne (Amerikansk)',
  'country_code.vn': 'Vietnam',
  'country_code.vu': 'Vanuatu',
  'country_code.wf': 'Wallis og Futuna',
  'country_code.ws': 'Samoa',
  'country_code.x1': 'Kun dansk kontaktadresse',
  'country_code.x2': 'Ingen kendt adresse',
  'country_code.xi': 'Nordirland',
  'country_code.xu': 'Storbritannien (det Forenede Kongerige (UK) uden Nordirland)',
  'country_code.ye': 'Yemen',
  'country_code.yt': 'Mayotte',
  'country_code.za': 'Sydafrika',
  'country_code.zm': 'Zambia',
  'country_code.zw': 'Zimbabwe',

  'create_future_contract.form.contract_id':
    'Kopiér en eksisterende kontrakt til at være grundlaget for den fremtidige kontrakt',
  'create_future_contract.form.contract_id.contract_state.active': '(gældende)',
  'create_future_contract.form.contract_id.contract_state.active_viewing': '(gældende, valgt kontrakt)',
  'create_future_contract.form.contract_id.contract_state.viewing': '(valgt kontrakt)',
  'create_future_contract.form.contract_id.invalid': 'Kontraktskabelon er ugyldig',
  'create_future_contract.form.contract_id.placeholder': 'Kontraktskabelon',
  'create_future_contract.form.contract_id.required': 'Kontraktskabelon skal vælges',
  'create_future_contract.form.submit': 'Opret fremtidig kontrakt',
  'create_future_contract.form.valid_from': 'Dato den fremtidige kontrakt skal gælde fra',
  'create_future_contract.form.valid_from.invalid': 'Fradato er ugyldig',
  'create_future_contract.form.valid_from.not_before_or_same_as_active':
    'Fradato kan ikke være før eller samtidigt med den gældende kontrakts start',
  'create_future_contract.form.valid_from.placeholder': 'Fradato',
  'create_future_contract.form.valid_from.required': 'Fradato skal vælges',
  'create_future_contract.form.warning.line_1': 'Den fremtidige kontrakt bliver gemt med det samme.',
  'create_future_contract.form.warning.line_2':
    'Du kan ændre i den fremtidige kontrakt umiddelbart efter du har oprettet den, eller senere ved at vælge den i "Vælg kontrakt"-listen.',
  'create_future_contract.title': 'Opret fremtidig kontrakt',

  'dashboard.anniversaries.anniversary': 'jubilæum',
  'dashboard.anniversaries.anniversary_wrapper': '{year}-års{word}',
  'dashboard.anniversaries.birthday': 'fødselsdag',
  'dashboard.anniversaries.birthday_wrapper': '{year}-års{word}',
  'dashboard.anniversaries.empty': 'Der er ingen kommende fødselsdage eller jubilæer',
  'dashboard.anniversaries.load_more': 'Indlæs {number} flere',
  'dashboard.anniversaries.load_more_out_of': 'ud af {number} flere',
  'dashboard.anniversaries.load_more.five': 'fem',
  'dashboard.anniversaries.title': 'Fødselsdage og jubilæer',
  'dashboard.main_boxes.average_transfers_last_year': 'Månedlig lønomk. (gns.)',
  'dashboard.main_boxes.employee_awaiting.many': 'Medarbejdere afventer ansættelse',
  'dashboard.main_boxes.employee_awaiting.one': 'Medarbejder afventer ansættelse',
  'dashboard.main_boxes.employee.many': 'Medarbejdere',
  'dashboard.main_boxes.employee.one': 'Medarbejder',
  'dashboard.main_boxes.total_transfers_this_year': 'Lønomkostninger i år',
  'dashboard.notifications.fix_credit_card_integration': 'Kortbetaling fejlet',
  'dashboard.notifications.invalid_national_id': 'Ugyldigt CPR-nummer for {name}',
  'dashboard.notifications.missing_contract': 'Manglende ansættelse for {name}',
  'dashboard.notifications.nats_onboarding_failed': 'Opsætning af Overførselsservice fejlede',
  'dashboard.notifications.not_registered_as_employer': 'Manglende registrering som arbejdsgiver',
  'dashboard.notifications.title': 'Notifikationer',
  'dashboard.notifications.voucher_booking_failed': 'Bilag kunne ikke bogføres',
  'dashboard.onboarding.choose_pay_roll_automation.description':
    'Salary kan automatisk køre din løn og underrette dig undervejs',
  'dashboard.onboarding.choose_pay_roll_automation.title': 'Automatisk lønudbetaling',
  'dashboard.onboarding.click_here': 'Klik her',
  'dashboard.onboarding.configure_expense_category_accounts.description':
    'Når du bruger udlægshåndtering, bør du opsætte bogføringskonti på dine udlægskategorier',
  'dashboard.onboarding.configure_expense_category_accounts.title': 'Opsæt konti på udlægskategorier',
  'dashboard.onboarding.create_employee.description': 'Start med at tilføje dig selv eller en af dine medarbejdere',
  'dashboard.onboarding.create_employee.title': 'Tilføj medarbejder',
  'dashboard.onboarding.employer_registration.description':
    'Ellers kan vi ikke indberette løn for dig til SKAT - hvis du er registreret, kan du fjerne denne',
  'dashboard.onboarding.employer_registration.title': 'Registrér dig som arbejdsgiver hos virk.dk',
  'dashboard.onboarding.grandhood.description': 'Læs mere om Grandhood',
  'dashboard.onboarding.grandhood.title': 'Gør det nemt at spare op til pension',
  'dashboard.onboarding.missing_accounting_integration.description':
    'For at Salary automatisk kan bogføre din løn i dit bogføringssystem skal du oprette en integration',
  'dashboard.onboarding.missing_accounting_integration.title': 'Tilslut regnskabsprogram',
  'dashboard.onboarding.payment_flow_not_started.description':
    'For at udbetale penge til dine medarbejdere skal du give Salary adgang til Overførselsservice',
  'dashboard.onboarding.payment_flow_not_started.title': 'Opret Overførselsservice-aftale',
  'dashboard.pay_rolls.awaiting_approval': 'Afventer godkendelse',
  'dashboard.pay_rolls.awaiting_approval_deadline': 'Afventer godkendelse ({date})',
  'dashboard.pay_rolls.awaiting_approval_deadline_tooltip': 'senest {date}',
  'dashboard.pay_rolls.empty': 'Der er endnu ikke forberedt eller udbetalt løn',
  'dashboard.pay_rolls.title': 'Kalender',
  'dashboard.registrations.header.need_approval': 'Skal godkendes',
  'dashboard.registrations.header.sum': 'I alt',
  'dashboard.registrations.table.car_allowance': 'Kørsel',
  'dashboard.registrations.table.hours': 'Timer',
  'dashboard.registrations.table.leave': 'Feriedage',
  'dashboard.registrations.table.reimbursement': 'Udlæg',
  'dashboard.registrations.table.swipe': 'Swipe',
  'dashboard.registrations.title': 'Registreringer',
  'dashboard.reports.description': 'Gå til {link} for at hente rapporter.',
  'dashboard.reports.link': 'Rapporter',
  'dashboard.reports.title': 'Lønrapport',
  'dashboard.support.chat_alternative': 'Eller skrive i chatten som normalt.',
  'dashboard.support.phone_introduction': 'Du kan ringe til supporten alle hverdage mellem 9 og 15 på',
  'dashboard.support.title': 'Support',
  'dashboard.transfers.empty': 'Der er ingen planlagte overførsler',
  'dashboard.transfers.header.amount': 'Beløb',
  'dashboard.transfers.header.method': 'Metode',
  'dashboard.transfers.header.type': 'Type',
  'dashboard.transfers.title': 'Næste overførsler',

  'data_integration.async_schedule.edit.delete': 'Slet planlægning af {title}',
  'data_integration.async_schedule.edit.form.day_frequency': 'Hvor tit skal {title} udføres?',
  'data_integration.async_schedule.edit.form.day_frequency.most_60_days':
    'Frekvens for {title} må ikke være mere end 60 dage',
  'data_integration.async_schedule.edit.form.day_frequency.positive_integer':
    'Frekvens for {title} skal være et positivt heltal',
  'data_integration.async_schedule.edit.form.day_frequency.prefix': 'Hver',
  'data_integration.async_schedule.edit.form.day_frequency.required': 'Frekvens for {title} er påkrævet',
  'data_integration.async_schedule.edit.form.day_frequency.suffix': 'dag',
  'data_integration.async_schedule.edit.form.hour_stamp': 'Hvornår på dagen skal {title} udføres?',
  'data_integration.async_schedule.edit.form.hour_stamp.invalid':
    'Klokkeslet skal være mellem 00 og 23, begge inklusiv',
  'data_integration.async_schedule.edit.form.hour_stamp.required': 'Klokkeslet for {title} skal vælges',
  'data_integration.async_schedule.edit.intro':
    'Du har en igangværende planlægning af {title}. Den næste kørsel vil finde sted',
  'data_integration.async_schedule.edit.title': 'Opsæt planlagt {title}',
  'data_integration.async_task_select.format.not_started_at': 'Ikke startet endnu',
  'data_integration.async_task_select.format.started_at': 'Påbegyndt {date}',
  'data_integration.async_task_select.format.with_finished_at': '{started_at}, hentet {time}',
  'data_integration.async_task_select.format.without_finished_at': '{started_at}',
  'data_integration.async_task_select.title': 'Vælg en tidligere indlæsning af data',
  'data_integration.async_task_status.started': 'Startet',
  'data_integration.async_task_status.started.false': 'Ikke endnu',
  'data_integration.car_allowance_import.actions.save_import': 'Gem data',
  'data_integration.car_allowance_import.actions.start_import': 'Hent data',
  'data_integration.car_allowance_import.actions.start_import_again': 'Hent data igen',
  'data_integration.car_allowance_import.alert.success': 'Kørsel blev importeret',
  'data_integration.car_allowance_import.data_imported': 'Data samlet {date}',
  'data_integration.car_allowance_import.error.employees_cannot_be_imported.many':
    '{count} medarbejdere kan ikke importeres',
  'data_integration.car_allowance_import.error.employees_cannot_be_imported.one':
    '{count} medarbejder kan ikke importeres',
  'data_integration.car_allowance_import.error.no_period': 'Kunne ikke finde en lønperiode fra lønkørsler',
  'data_integration.car_allowance_import.importing': 'Indlæser...',
  'data_integration.car_allowance_import.status.importing.description':
    'Import igangsat. Når importen er klar, vil resultatet vises her. Du kan i mellemtiden bruge resten af Salary, resultatet vil også være der når du vender tilbage.',
  'data_integration.car_allowance_import.status.importing.title': 'Importstatus',
  'data_integration.car_allowance_import.status.saving.description':
    'Kørselsregistreringerne er ved at blive gemt. Du kan i mellemtiden bruge resten af Salary.',
  'data_integration.car_allowance_import.status.saving.title': 'Lagringsstatus',
  'data_integration.car_allowance_import.table.external_id': 'Eksternt ID',
  'data_integration.car_allowance_import.table.header.amount': 'Total antal kilometer',
  'data_integration.car_allowance_import.table.header.name': 'Navn',
  'data_integration.car_allowance_import.table.header.quantity': 'Antal',
  'data_integration.car_allowance_import.title': 'Importér kørsel fra {displayName}',
  'data_integration.car_allowance_import.warning.already_used': 'Denne indlæsning af data er allerede blevet gemt',
  'data_integration.custom_field_mapping.alert.success': 'Eksterne felter er gemt',
  'data_integration.custom_field_mapping.form.field_description.boolean': '{field}.',
  'data_integration.custom_field_mapping.form.field_description.boolean.selector':
    '{field}. Vælg værdien for når feltet er afkrydset.',
  'data_integration.custom_field_mapping.form.field_description.company_id':
    '{field}. Er feltet valgt, vil kun de medarbejdere, der er i den valgte virksomhed, blive taget i betragtning.',
  'data_integration.custom_field_mapping.form.field_description.company_name':
    '{field}. Er feltet valgt, vil kun de medarbejdere, hvis værdi i feltet matcher, blive taget i betragtning.',
  'data_integration.custom_field_mapping.form.field_description.company_national_id':
    '{field}. Er feltet valgt, vil kun de medarbejdere, hvis værdi i feltet matcher virksomhedens CVR-nummer, blive taget i betragtning.',
  'data_integration.custom_field_mapping.form.field_description.date': '{field}. Værdien skal indeholde en dato.',
  'data_integration.custom_field_mapping.form.field_description.department_id_limit':
    '{field}. Er feltet valgt, vil kun de medarbejdere, der er i den valgte afdeling, blive taget i betragtning.',
  'data_integration.custom_field_mapping.form.field_description.leave_type': '{field}.',
  'data_integration.custom_field_mapping.form.field_description.multi':
    '{field}. Vælg værdien der afgør om dette felt er gyldigt.',
  'data_integration.custom_field_mapping.form.field_description.number':
    '{field}. Værdien i feltet må kun indeholde et tal.',
  'data_integration.custom_field_mapping.form.field_description.text': '{field}.',
  'data_integration.custom_field_mapping.form.field_description.text_selector':
    '{field}.  Feltet bruges kun når det har en bestemt værdi.',
  'data_integration.custom_field_mapping.form.field_required': '(påkrævet)',
  'data_integration.custom_field_mapping.form.field_type.boolean': 'Afkrydsningsfelt',
  'data_integration.custom_field_mapping.form.field_type.date': 'Datofelt',
  'data_integration.custom_field_mapping.form.field_type.leave_type': 'Fraværstype',
  'data_integration.custom_field_mapping.form.field_type.multi': 'Listefelt',
  'data_integration.custom_field_mapping.form.field_type.number': 'Talfelt',
  'data_integration.custom_field_mapping.form.field_type.text': 'Tekstfelt',
  'data_integration.custom_field_mapping.form.field_type.text_selector': 'Felt med bestemt værdi',
  'data_integration.custom_field_mapping.form.field.address': 'Adresse',
  'data_integration.custom_field_mapping.form.field.bank_account_number': 'Kontonummer',
  'data_integration.custom_field_mapping.form.field.bank_registration_number': 'Registreringsnummer',
  'data_integration.custom_field_mapping.form.field.benefit_free_phone': 'Fri telefon',
  'data_integration.custom_field_mapping.form.field.benefit_health': 'Værdi af sundhedsforsikring',
  'data_integration.custom_field_mapping.form.field.benefit_lunch': 'Værdi af frokostordning',
  'data_integration.custom_field_mapping.form.field.city': 'By',
  'data_integration.custom_field_mapping.form.field.company_id': 'Virksomhed',
  'data_integration.custom_field_mapping.form.field.company_name': 'Virksomhedsnavn',
  'data_integration.custom_field_mapping.form.field.company_national_id': 'CVR-nummer',
  'data_integration.custom_field_mapping.form.field.department_id_limit': 'Begræns til afdeling',
  'data_integration.custom_field_mapping.form.field.email': 'E-mail',
  'data_integration.custom_field_mapping.form.field.employee_number': 'Medarbejdernummer',
  'data_integration.custom_field_mapping.form.field.leave_day_off': 'Fridag',
  'data_integration.custom_field_mapping.form.field.leave_extra_day': 'Feriefridag',
  'data_integration.custom_field_mapping.form.field.leave_personal_day': 'Omsorgsdag',
  'data_integration.custom_field_mapping.form.field.leave_sick': 'Sygedag',
  'data_integration.custom_field_mapping.form.field.leave_unpaid': 'Ubetalt fravær',
  'data_integration.custom_field_mapping.form.field.leave_vacation_accrual': 'Feriedag med løn',
  'data_integration.custom_field_mapping.form.field.leave_vacation_fund': 'Feriedag uden løn',
  'data_integration.custom_field_mapping.form.field.national_id': 'CPR-nummer',
  'data_integration.custom_field_mapping.form.field.postal_code': 'Postnummer',
  'data_integration.custom_field_mapping.form.field.salary_fixed': 'Månedsløn for fastlønnede',
  'data_integration.custom_field_mapping.form.field.salary_fixed.multi': 'Afgør de er fastlønnede',
  'data_integration.custom_field_mapping.form.field.salary_hourly': 'Timeløn',
  'data_integration.custom_field_mapping.form.field.salary_hourly.multi': 'Afgør de er timelønnede',
  'data_integration.custom_field_mapping.form.field.salary_rate': 'Lønsats',
  'data_integration.custom_field_mapping.form.field.start_date': 'Ansættelsesdato',
  'data_integration.custom_field_mapping.form.field.tax_card_primary': 'Brug hovedkort',
  'data_integration.custom_field_mapping.form.field.tax_card_secondary': 'Brug bikort',
  'data_integration.custom_field_mapping.form.header.external_field': '{title}-felt',
  'data_integration.custom_field_mapping.form.header.salary_field': 'Salary-felt',
  'data_integration.custom_field_mapping.form.selector.none': 'Intet felt',
  'data_integration.custom_field_mapping.form.selector.required': '{title} skal vælges',
  'data_integration.custom_field_mapping.form.value': 'Værdi for {title}',
  'data_integration.custom_field_mapping.form.value.note': 'Skriv værdien præcist som den står i {title}.',
  'data_integration.custom_field_mapping.form.value.required': 'Værdi for {title} skal vælges',
  'data_integration.custom_field_mapping.intro':
    '{title} har brugerdefineret felter. Det betyder at du manuelt skal tilknytte disse felter til deres tilsvarende felter i Salary. Stamdatafelter er håndteret, og behøver ikke manuel tilknytning.',
  'data_integration.custom_field_mapping.title': 'Tilknyt {title}s felter til Salarys felter',
  'data_integration.employees.actions.save_import': 'Gem data',
  'data_integration.employees.actions.start_import': 'Hent medarbejdere',
  'data_integration.employees.actions.start_import_again': 'Hent data igen',
  'data_integration.employees.actions.start_new_import': 'Hent en ny portion data',
  'data_integration.employees.alert.success': 'Medarbejderne er gemt',
  'data_integration.employees.button.uncheck_all': 'Fravælg alle',
  'data_integration.employees.checkbox.check_all': 'Vælg alle der kan importeres',
  'data_integration.employees.checkbox.check_only_final': 'Vælg kun dem uden mangler',
  'data_integration.employees.header.address': 'Adresse',
  'data_integration.employees.header.bank_info': 'Kontonummer',
  'data_integration.employees.header.benefits': 'Personalegoder',
  'data_integration.employees.header.email': 'E-mail',
  'data_integration.employees.header.name': 'Navn',
  'data_integration.employees.header.national_id': 'CPR-nummer',
  'data_integration.employees.header.phone_number': 'Telefonnummer',
  'data_integration.employees.header.remuneration': 'Løn',
  'data_integration.employees.importing': 'Indlæser...',
  'data_integration.employees.intro.line_1':
    'Medarbejdere med rødt bliver ikke oprettet.  Dem med gult bliver oprettet i kladdetilstand, og du bliver nødt til at udfylde deres manglende felter før de er klar til løn.  Eksisterende medarbejdere bliver kun overskrevet med de felter der er sat.',
  'data_integration.employees.intro.line_2.many': 'Du er ved at importere {amount} medarbejdere.',
  'data_integration.employees.intro.line_2.one': 'Du er ved at importere {amount} medarbejder.',
  'data_integration.employees.mapping_required':
    'Din integration kræver du tilknytter vise {link} til felter i Salary. Det skal ordnes før du kan importere fra det eksterne system.',
  'data_integration.employees.mapping_required.link': 'eksterne felter',
  'data_integration.employees.status.importing.description':
    'Import igangsat. Når importen er klar, vil resultatet vises her. Du kan i mellemtiden bruge resten af Salary, resultatet vil også være der når du vender tilbage.',
  'data_integration.employees.status.importing.title': 'Importstatus',
  'data_integration.employees.status.saving.description':
    'Medarbejderne er ved at blive gemt. Du kan i mellemtiden bruge resten af Salary.',
  'data_integration.employees.status.saving.title': 'Lagringsstatus',
  'data_integration.employees.tax_card_selector': 'Foretrukne skattekort:',
  'data_integration.employees.title': 'Importér medarbejdere fra {name}',
  'data_integration.employees.warning.already_used': 'Denne indlæsning af data er allerede blevet gemt',
  'data_integration.employees.warning.errors_on_employees.many': 'Der var fejl på {count} medarbejdere.',
  'data_integration.employees.warning.errors_on_employees.one': 'Der var fejl på {count} medarbejder.',
  'data_integration.employees.warning.more_than_24_hours':
    'Data er mere end 24 timer gammel, du bør hente data igen før du gemmer.',
  'data_integration.integration.header.change': 'Skift dataudbyder',
  'data_integration.integration.header.delete': 'Slet integration',
  'data_integration.integration.saving': 'Gemmer...',
  'data_integration.integration.sheet.car_allowances_import': 'Kørsel',
  'data_integration.integration.sheet.employees_import': 'Medarbejdere',
  'data_integration.integration.sheet.field_mapping': 'Eksterne felter',
  'data_integration.integration.sheet.leave_registrations_export': 'Fraværseksport',
  'data_integration.integration.sheet.leave_registrations_import': 'Fraværsregistreringer',
  'data_integration.integration.sheet.pay_roll_import': 'Lønimport',
  'data_integration.integration.sheet.reimbursements_import': 'Udlæg',
  'data_integration.integration.summary.name': 'Integreret med',
  'data_integration.integration.title': 'Dataudbyder',
  'data_integration.leave_export.actions.collect': 'Saml data',
  'data_integration.leave_export.actions.collect_again': 'Saml data igen',
  'data_integration.leave_export.actions.save_export': 'Eksportér',
  'data_integration.leave_export.actions.schedule': 'Planlægning',
  'data_integration.leave_export.alert.success': 'Fraværsregistreringer er eksporteret',
  'data_integration.leave_export.collecting': 'Samler...',
  'data_integration.leave_export.data_collected': 'Data samlet {date}',
  'data_integration.leave_export.header.date': 'Dato',
  'data_integration.leave_export.header.leave_type': 'Fraværstype',
  'data_integration.leave_export.header.name': 'Navn',
  'data_integration.leave_export.header.rate': 'Sats',
  'data_integration.leave_export.status.gathering.description':
    'Klargøring igangsat. Når klargøringen er klar, vil resultatet vises her. Du kan i mellemtiden bruge resten af Salary, resultatet vil også være der når du vender tilbage.',
  'data_integration.leave_export.status.gathering.title': 'Klargøringstatus',
  'data_integration.leave_export.status.saving.description': 'Fraværsregistreringer er ved at blive eksporteret.',
  'data_integration.leave_export.status.saving.title': 'Eksportingsstatus',
  'data_integration.leave_export.title': 'Eksportér fravær til {name}',
  'data_integration.leave_import.actions.save_import': 'Gem data',
  'data_integration.leave_import.actions.start_import': 'Hent data',
  'data_integration.leave_import.actions.start_import_again': 'Hent data igen',
  'data_integration.leave_import.alert.success': 'Fraværsregistreringer er gemt',
  'data_integration.leave_import.data_imported': 'Data samlet {date}',
  'data_integration.leave_import.error.employees_cannot_be_imported.many': '{count} medarbejdere kan ikke importeres',
  'data_integration.leave_import.error.employees_cannot_be_imported.one': '{count} medarbejder kan ikke importeres',
  'data_integration.leave_import.error.no_period': 'Kunne ikke finde en lønperiode fra lønkørsler',
  'data_integration.leave_import.ignore_unknowns': 'Ignorér ukendte fraværstyper',
  'data_integration.leave_import.ignore_unknowns.help.line_1':
    'Fraværsregistreringer med typer der ikke er tilknyttet en type i Salary er anset som en registrering med ukendt fraværstype.  Normalt vil disse blive markeret som fejl, og de kan ikke importeres.',
  'data_integration.leave_import.ignore_unknowns.help.line_2':
    'Det kan dog være man bruger fraværstyper i det eksterne system som ikke giver mening at tilknytte en type i Salary, i såfald bør man ignorere den med denne indstilling.',
  'data_integration.leave_import.ignore_unknowns.help.line_3':
    'Vær dog opmærksom på, at det også ignorerer registreringer med fraværstyper du bare har glemt at fået tilknyttet.  Så tjek lige først resultatet uden indstillingen, for at være sikker på at dem der er tilbage er dem du ikke er ineteresseret i.',
  'data_integration.leave_import.importing': 'Indlæser...',
  'data_integration.leave_import.status.importing.description':
    'Import igangsat. Når importen er klar, vil resultatet vises her. Du kan i mellemtiden bruge resten af Salary, resultatet vil også være der når du vender tilbage.',
  'data_integration.leave_import.status.importing.title': 'Importstatus',
  'data_integration.leave_import.status.saving.description':
    'Fraværsregistreringer er ved at blive gemt. Når registreringer er gemt, bliver du videresendt til fraværsregistreringssiden. Du kan i mellemtiden bruge resten af Salary. Gør du det, vil du ikke blive videresendt.',
  'data_integration.leave_import.status.saving.title': 'Lagringsstatus',
  'data_integration.leave_import.table.external_id': 'Eksternt ID',
  'data_integration.leave_import.table.header.days': 'Antal',
  'data_integration.leave_import.table.header.leave_type': 'Fraværstype',
  'data_integration.leave_import.table.header.name': 'Navn',
  'data_integration.leave_import.title': 'Importér fravær fra {displayName}',
  'data_integration.leave_import.warning.already_used': 'Denne indlæsning af data er allerede blevet gemt',
  'data_integration.no_integration.button.create': 'Opsæt forbindelse til dataudbyder',
  'data_integration.no_integration.intro.line_1':
    'Du har ingen aktiv dataudbyderintegration, såsom tidsregistrerings-, fraværsregistrerings- og/eller medarbejderimport. Opsæt integration ved at trykke på knappen herunder.',
  'data_integration.no_integration.intro.line_2':
    'Såfremt din integration mangler, skriv til supporten på {link}, så kigger vi på, om den kan laves.',
  'data_integration.no_integration.intro.title': 'Dataudbyder',
  'data_integration.no_integration.title': 'Dataudbyder',
  'data_integration.pay_roll.actions.save_import': 'Gem data',
  'data_integration.pay_roll.actions.start_import': 'Hent data',
  'data_integration.pay_roll.actions.start_import_again': 'Hent data igen',
  'data_integration.pay_roll.actions.start_new_import': 'Hent en ny portion data',
  'data_integration.pay_roll.alert.success.hourly': 'Tidsregistreringer er gemt',
  'data_integration.pay_roll.alert.success.salaried': 'Lønregistreringer er gemt',
  'data_integration.pay_roll.data_finished_at': 'Data hentet {date}',
  'data_integration.pay_roll.error.employees_cannot_import.many': '{count} medarbejdere kan ikke importeres',
  'data_integration.pay_roll.error.employees_cannot_import.one': '{count} medarbejder kan ikke importeres',
  'data_integration.pay_roll.error.no_pay_roll_period': 'Kunne ikke finde en lønperiode fra lønkørsler',
  'data_integration.pay_roll.importing': 'Indlæser...',
  'data_integration.pay_roll.mode.hourly': 'Timelønnede',
  'data_integration.pay_roll.mode.salaried': 'Fastlønnede',
  'data_integration.pay_roll.status.importing.description':
    'Import igangsat. Når importen er klar, vil resultatet vises her. Du kan i mellemtiden bruge resten af Salary, resultatet vil også være der når du vender tilbage.',
  'data_integration.pay_roll.status.importing.title': 'Importstatus',
  'data_integration.pay_roll.status.saving.description.hourly':
    'Løndata er ved at blive gemt. Når de er gemt, bliver du videresendt til tidsregistreringssiden. Du kan i mellemtiden bruge resten af Salary. Gør du det, vil du ikke blive videresendt.',
  'data_integration.pay_roll.status.saving.description.salaried':
    'Løndata er ved at blive gemt. Når de er gemt, bliver du videresendt til lønregistreringssiden. Du kan i mellemtiden bruge resten af Salary. Gør du det, vil du ikke blive videresendt.',
  'data_integration.pay_roll.status.saving.title': 'Lagringsstatus',
  'data_integration.pay_roll.summary.total_gross': 'Samlet bruttotøn',
  'data_integration.pay_roll.summary.total_gross.can_be_imported': ' (hvoraf {amount} kan importeres)',
  'data_integration.pay_roll.summary.total_hours': 'Samlet antal timer',
  'data_integration.pay_roll.summary.total_hours.can_be_imported': ' (hvoraf {amount} kan importeres)',
  'data_integration.pay_roll.table.external_id': 'Eksternt ID',
  'data_integration.pay_roll.table.header.hours': 'Timer',
  'data_integration.pay_roll.table.header.name': 'Navn',
  'data_integration.pay_roll.table.header.rate': 'Sats',
  'data_integration.pay_roll.table.header.remuneration': 'Løntype',
  'data_integration.pay_roll.table.note.remuneration_added': 'Løntypen vil blive tilføjet til ansættelsen',
  'data_integration.pay_roll.table.note.remuneration_changed': 'Lønlinjen på kontrakten ændres',
  'data_integration.pay_roll.table.note.remuneration_created': 'Løntypen vil blive oprettet',
  'data_integration.pay_roll.table.quantity_format.many': '{count} stk.',
  'data_integration.pay_roll.table.quantity_format.one': '{count} stk.',
  'data_integration.pay_roll.warning.already_used': 'Denne indlæsning af data er allerede blevet gemt',
  'data_integration.pay_roll.warning.error_on_employees.many': 'Der var fejl på {count} medarbejdere.',
  'data_integration.pay_roll.warning.error_on_employees.one': 'Der var fejl på {count} medarbejder.',
  'data_integration.period_selector.no_payrolls': 'Der er ingen åbne lønkørsler, så du kan ikke vælge en periode.',
  'data_integration.reimbursement_import.actions.save_import': 'Gem data',
  'data_integration.reimbursement_import.actions.start_import': 'Hent data',
  'data_integration.reimbursement_import.actions.start_import_again': 'Hent data igen',
  'data_integration.reimbursement_import.alert.success': 'Udlæggene blev importeret',
  'data_integration.reimbursement_import.data_imported': 'Data samlet {date}',
  'data_integration.reimbursement_import.error.employees_cannot_be_imported.many':
    '{count} medarbejdere kan ikke importeres',
  'data_integration.reimbursement_import.error.employees_cannot_be_imported.one':
    '{count} medarbejder kan ikke importeres',
  'data_integration.reimbursement_import.error.no_period': 'Kunne ikke finde en lønperiode fra lønkørsler',
  'data_integration.reimbursement_import.importing': 'Indlæser...',
  'data_integration.reimbursement_import.status.importing.description':
    'Import igangsat. Når importen er klar, vil resultatet vises her. Du kan i mellemtiden bruge resten af Salary, resultatet vil også være der når du vender tilbage.',
  'data_integration.reimbursement_import.status.importing.title': 'Importstatus',
  'data_integration.reimbursement_import.status.saving.description':
    'Udlæggene er ved at blive gemt. Du kan i mellemtiden bruge resten af Salary.',
  'data_integration.reimbursement_import.status.saving.title': 'Lagringsstatus',
  'data_integration.reimbursement_import.table.external_id': 'Eksternt ID',
  'data_integration.reimbursement_import.table.header.amount': 'Total beløb',
  'data_integration.reimbursement_import.table.header.name': 'Navn',
  'data_integration.reimbursement_import.table.header.quantity': 'Antal',
  'data_integration.reimbursement_import.title': 'Importér udlæg fra {displayName}',
  'data_integration.reimbursement_import.warning.already_used': 'Denne indlæsning af data er allerede blevet gemt',

  'data_provider_terms.download': 'Hent databehandleraftalen som PDF',
  'data_provider_terms.not_signed': 'Databehandleraftalen er ikke accepteret.',
  'data_provider_terms.signed_at': 'Databehandleraftalen blev accepteret {time}.',
  'data_provider_terms.title': 'Databehandleraftale',

  'date_picker.range.select_end_date': 'Vælg slutdato',
  'date_picker.range.select_start_date': 'Vælg startdato',
  'date_picker.today': 'I dag',

  'day.friday': 'Fredag',
  'day.holiday': 'Helligdag',
  'day.monday': 'Mandag',
  'day.saturday': 'Lørdag',
  'day.sunday': 'Søndag',
  'day.thursday': 'Torsdag',
  'day.tuesday': 'Tirsdag',
  'day.wednesday': 'Onsdag',

  'department.edit.form.name': 'Beskrivelse',
  'department.edit.form.validation.name.required': 'Beskrivelse skal indtastes',
  'department.edit.title.create': 'Opret afdeling',
  'department.edit.title.edit': 'Rediger afdeling',

  'departments.header.add_department': 'Tilføj afdeling',
  'departments.header.title': 'Afdelinger',
  'departments.table.actions.delete': 'Slet afdeling',
  'departments.table.actions.edit': 'Redigér afdeling',
  'departments.table.header.dimension': 'Dimension',
  'departments.table.header.employee_count': 'Størrelse',
  'departments.table.header.name': 'Beskrivelse',

  'deviation.alert.success': 'Ændringer til {name} er gemt',
  'deviation.edit.form.gross_pay_reduction.intro':
    'Når grundlaget for bruttolønnen ændrer sig (f.eks. hvis de bliver ansat eller opsagt i løbet af lønperioden).',
  'deviation.edit.form.gross_pay.description':
    'Vælg en tærskel i procent for hvor stor afvigelsen skal være, før det fremhæves. Dernæst kan du vælge antallet af lønkørsler der skal tjekkes tilbage i tid. Af disse tidligere lønkørsler tages gennemsnittet af bruttolønnen per medarbejder, og der sammenlignes med den nuværende lønkørsel; er afvigelsen større end tærsklen fremhæves den på lønkørslen.',
  'deviation.edit.form.gross_pay.intro': 'Når bruttolønnen på en medarbejder ændrer sig væsentligt.',
  'deviation.edit.form.gross_pay.periods': 'Antal lønkørsler i fortiden',
  'deviation.edit.form.gross_pay.periods.suffix': 'lønkørsler',
  'deviation.edit.form.gross_pay.threshold': 'Tærskel for afvigelse',
  'deviation.edit.form.header.enable': 'Slå til',
  'deviation.edit.form.header.settings': 'Indstillinger',
  'deviation.edit.form.header.type': 'Afvigelsestype',
  'deviation.edit.form.hours.description':
    'Vælg en tærskel i procent for hvor stor afvigelsen skal være, før det fremhæves. Dernæst kan du vælge antallet af lønkørsler der skal tjekkes tilbage i tid. Af disse tidligere lønkørsler tages gennemsnittet af registerede timer per medarbejder, og der sammenlignes med den nuværende lønkørsel; er afvigelsen større end tærsklen fremhæves den på lønkørslen.',
  'deviation.edit.form.hours.intro': 'Når antallet af registreret timer på en medarbejder ændrer sig væsentligt.',
  'deviation.edit.form.hours.periods': 'Antal lønkørsler i fortiden',
  'deviation.edit.form.hours.periods.suffix': 'lønkørsler',
  'deviation.edit.form.hours.threshold': 'Tærskel for afvigelse',
  'deviation.edit.form.pay_check.description':
    'Vælg en tærskel i procent for hvor stor afvigelsen skal være, før det fremhæves. Dernæst kan du vælge antallet af lønkørsler der skal tjekkes tilbage i tid. Af disse tidligere lønkørsler tages gennemsnittet af nettolønnen per medarbejder, og der sammenlignes med den nuværende lønkørsel; er afvigelsen større end tærsklen fremhæves den på lønkørslen.',
  'deviation.edit.form.pay_check.intro': 'Når nettolønnen på en medarbejder ændrer sig væsentligt.',
  'deviation.edit.form.pay_check.periods': 'Antal lønkørsler i fortiden',
  'deviation.edit.form.pay_check.periods.suffix': 'lønkørsler',
  'deviation.edit.form.pay_check.threshold': 'Tærskel for afvigelse',
  'deviation.edit.form.submit': 'Gem afvigelsesindstillinger',
  'deviation.edit.form.tax_card.intro': 'Når en medarbejders skattekort skifter type (f.eks. hovedkort til bikort).',
  'deviation.header.back': 'Tilbage',
  'deviation.header.title': 'Opsætning af afvigelser på lønkørsler',
  'deviation.introduction':
    'Når der sker en væsentlig afvigelse fra en lønkørsel til en anden, kan du få en besked på lønkørslen om at der er sket en afvigelse. Her kan du vælge hvilke typer af afvigelse du vil noteres om, samt ekstra indstillinger for dem.',
  'deviation.type.gross_pay': 'Bruttoløn',
  'deviation.type.gross_pay_reduction': 'Bruttolønsreduktion',
  'deviation.type.hours': 'Timer',
  'deviation.type.pay_check': 'Nettoløn',
  'deviation.type.tax_card': 'Skattekort',

  'dimensions_tab.card.form.dimension_id': 'Vælg dimension',
  'dimensions_tab.card.form.intro.line_1.cost_centers':
    'Din regnskabsintegration bruger dimensioner. Her kan du knytte dine omkostningssteder til dine eksterne dimensioner.',
  'dimensions_tab.card.form.intro.line_1.departments':
    'Din regnskabsintegration bruger dimensioner. Her kan du knytte dine afdelinger til dine eksterne dimensioner.',
  'dimensions_tab.card.form.intro.line_2.cost_centers':
    'Vælg den dimension du vil bruge, for at knytte dimensionsværdier til omkostningssteder. Du kan sagtens efterfølgende knytte fra andre dimensioner ved at vælge en anden dimension.',
  'dimensions_tab.card.form.intro.line_2.departments':
    'Vælg den dimension du vil bruge, for at knytte dimensionsværdier til afdelinger. Du kan sagtens efterfølgende knytte fra andre dimensioner ved at vælge en anden dimension.',
  'dimensions_tab.card.form.row.cost_center_id.cost_center': 'Omkostningssted',
  'dimensions_tab.card.form.row.cost_center_id.deactivated_note': '{name} (deaktiveret)',
  'dimensions_tab.card.form.row.cost_center_id.deactivated.cost_center': 'Omkostningsstedet er deaktiveret',
  'dimensions_tab.card.form.row.cost_center_id.deactivated.department': 'Afdelingen er deaktiveret',
  'dimensions_tab.card.form.row.cost_center_id.department': 'Afdeling',
  'dimensions_tab.card.form.row.cost_center_id.new.cost_center': 'Opret nyt omkostingssted',
  'dimensions_tab.card.form.row.cost_center_id.new.department': 'Opret ny afdeling',
  'dimensions_tab.card.form.row.cost_center_id.required.cost_center': 'Omkostningssted skal vælges',
  'dimensions_tab.card.form.row.cost_center_id.required.department': 'Afdeling skal vælges',
  'dimensions_tab.card.form.table.header.active': 'Aktiv',
  'dimensions_tab.card.form.table.header.cost_center': 'Omkostningssted',
  'dimensions_tab.card.form.table.header.department': 'Afdeling',
  'dimensions_tab.card.form.table.header.dimension': 'Dimension',
  'dimensions_tab.card.form.table.header.dimension_value': 'Dimensionsværdi',
  'dimensions_tab.card.form.table.show_with_cost_center.cost_centers.many':
    'Vis de {count} dimensioner med omkostingesteder',
  'dimensions_tab.card.form.table.show_with_cost_center.cost_centers.one':
    'Vis den {count} dimension med omkostingested',
  'dimensions_tab.card.form.table.show_with_cost_center.departments.many': 'Vis de {count} dimensioner med afdelinger',
  'dimensions_tab.card.form.table.show_with_cost_center.departments.one': 'Vis den {count} dimension med afdeling',
  'dimensions_tab.card.form.table.with_cost_center.intro.cost_centers': 'Dimensioner med omkostingssteder',
  'dimensions_tab.card.form.table.with_cost_center.intro.departments': 'Dimensioner med afdelinger',
  'dimensions_tab.card.form.table.without_cost_center.button.cost_centers':
    'Opret et nyt omkostingssted for alle dimensioner, der ikke allerede har et',
  'dimensions_tab.card.form.table.without_cost_center.button.departments':
    'Opret en ny afdeling for alle dimensioner, der ikke allerede har en',
  'dimensions_tab.card.form.table.without_cost_center.intro.cost_centers': 'Dimensioner uden omkostingssteder',
  'dimensions_tab.card.form.table.without_cost_center.intro.departments': 'Dimensioner uden afdelinger',
  'dimensions_tab.card.title': 'Dimensioner',
  'dimensions_tab.departments.form.cost_center_accounting': 'Bogfør løn i afdelinger',
  'dimensions_tab.departments.form.cost_center_accounting.cost_centers': 'På omkostningssteder',
  'dimensions_tab.departments.form.cost_center_accounting.departments': 'Ja',
  'dimensions_tab.departments.form.cost_center_accounting.departments.has_dimensions': 'På afdelinger',
  'dimensions_tab.departments.form.cost_center_accounting.lock':
    'Opgradér til Basic, for at tillade afdelingsbogføring',
  'dimensions_tab.departments.form.cost_center_accounting.off': 'Nej',
  'dimensions_tab.departments.form.time_registration_department.cost_centers':
    'Muliggør omkostningssteder på tids-, løn- og kørselsregistreringer',
  'dimensions_tab.departments.form.time_registration_department.departments':
    'Muliggør afdelinger på tids-, løn- og kørselsregistreringer',
  'dimensions_tab.departments.form.time_registration_department.note.cost_centers':
    'Når man har omkostningsstedsbogføring, kan man tillade at tids-, løn- og kørselsregistreringer bliver tilknyttet et omkostningssted.',
  'dimensions_tab.departments.form.time_registration_department.note.departments':
    'Når man har afdelingsbogføring, kan man tillade at tids-, løn- og kørselsregistreringer bliver tilknyttet en anden afdeling end den afdeling medarbejderen er tilknyttet.',
  'dimensions_tab.employee.form.active': 'Aktiv',
  'dimensions_tab.employee.form.add_dimension': 'Tilføj medarbejderdimension',
  'dimensions_tab.employee.form.dimension_code': 'Dimensionskode',
  'dimensions_tab.employee.form.dimension_code.required': 'Dimensionskode skal indtastes',
  'dimensions_tab.employee.form.external_id.false': 'Nej',
  'dimensions_tab.employee.form.external_id.true': 'Ja',
  'dimensions_tab.employee.form.table.header.accounting_integration': 'Vil blive bogført i regnsskabsprogrammet?',
  'dimensions_tab.employee.form.table.header.active': 'Aktiv',
  'dimensions_tab.employee.form.table.header.dimension': 'Dimension',
  'dimensions_tab.employee.form.table.header.dimension_value': 'Dimensionværdier',
  'dimensions_tab.employee.form.value.add': 'Tilføj værdi',
  'dimensions_tab.employee.form.value.first': 'Startværdi',
  'dimensions_tab.employee.form.value.later': 'Ekstra værdi',
  'dimensions_tab.employee.intro':
    'Medarbejderdimensioner gør det muligt at differentere medarbejderne på ekstra felter. For hver medarbejderdimension, vil et ekstra felt optræde når du retter på en medarbejder.',
  'dimensions_tab.employee.title': 'Medarbejderdimensioner',
  'dimensions_tab.employee.with_integration.cost_centers':
    'Du kan ikke bruge dimensioner du allerede bruger på omkostingssteder. Du kan tilføje ekstra dimensioner her, men de vi ville ikke blive bogført, da de ikke er tilknyttet en dimension i dit regnskabsprogram.',
  'dimensions_tab.employee.with_integration.departments':
    'Du kan ikke bruge dimensioner du allerede bruger på afdelinger. Du kan tilføje ekstra dimensioner her, men de vi ville ikke blive bogført, da de ikke er tilknyttet en dimension i dit regnskabsprogram.',

  'document_category.edit.form.name': 'Beskrivelse',
  'document_category.edit.form.name.required': 'Beskrivelse skal indtastes',
  'document_category.edit.title.create': 'Opret kategori',
  'document_category.edit.title.edit': 'Rediger kategori',
  'document_category.header.add_category': 'Tilføj kategori',
  'document_category.header.documents': 'Til dokumentoversigt',
  'document_category.header.title': 'Dokumentkategorier',
  'document_category.table.actions.delete': 'Slet kategori',
  'document_category.table.actions.edit': 'Redigér kategori',
  'document_category.table.actions.reactivate': 'Genaktivér kategori',
  'document_category.table.active.false': 'Nej',
  'document_category.table.active.true': 'Ja',
  'document_category.table.header.active': 'Aktiv',
  'document_category.table.header.name': 'Beskrivelse',

  'document_tab.confirm.archive': 'Er du sikker på du vil arkiveret dokumentet?',
  'document_tab.confirm.remove': 'Er du sikker på du vil slette dokumentet?',
  'document_tab.edit.form.document_category_id': 'Kategori',
  'document_tab.edit.form.document_category_id.required': 'Kategori skal vælges',
  'document_tab.edit.form.employee.placeholder': 'Medarbejder',
  'document_tab.edit.form.employee.required': 'Medarbejder skal vælges',
  'document_tab.edit.form.employee.terminated': '{name} (opsagt)',
  'document_tab.edit.form.name': 'Beskrivelse',
  'document_tab.edit.form.name.required': 'Beskrivelse skal vælges',
  'document_tab.edit.form.submit.create': 'Opret dokument',
  'document_tab.edit.form.visible_for_employee': 'Synlig for medarbejder',
  'document_tab.edit.form.visible_for_employee.false': 'Nej',
  'document_tab.edit.form.visible_for_employee.true': 'Ja',
  'document_tab.edit.title.create': 'Tilføj dokument',
  'document_tab.edit.title.edit': 'Redigér dokument',
  'document_tab.header.add_document': 'Tilføj dokument',
  'document_tab.header.document_categories': 'Dokumentkategorier',
  'document_tab.header.title': 'Dokumenter',
  'document_tab.signer.table.header.language': 'Sprog',
  'document_tab.signer.table.header.name': 'Navn',
  'document_tab.signer.table.header.phone_number': 'Telefonnummer',
  'document_tab.signer.table.header.signed': 'Underskrevet?',
  'document_tab.signer.table.signed.false': 'Nej',
  'document_tab.signer.table.signed.true': 'Ja',
  'document_tab.signer.table.signed.true_with_date': 'Ja, {date}',
  'document_tab.signer.table.title': 'Underskrivere',
  'document_tab.table.actions.archive': 'Arkivér dokument',
  'document_tab.table.actions.delete': 'Slet dokument',
  'document_tab.table.actions.download': 'Hent dokument',
  'document_tab.table.actions.edit': 'Redigér dokument',
  'document_tab.table.actions.refresh': 'Gendan dokument',
  'document_tab.table.archived.false': 'Nej',
  'document_tab.table.archived.true': 'Ja',
  'document_tab.table.created_at': 'Oprettet {date}',
  'document_tab.table.created_by_system': 'Systemet',
  'document_tab.table.document_signed': 'Dette dokument er færdigunderskrevet',
  'document_tab.table.document_signed_at': 'Dette dokument blev færdigunderskrevet den {date}',
  'document_tab.table.header.archived': 'Arkiveret',
  'document_tab.table.header.category': 'Kategori',
  'document_tab.table.header.created_by': 'Oprettet af',
  'document_tab.table.header.filename': 'Filnavn',
  'document_tab.table.header.name': 'Beskrivelse',
  'document_tab.table.header.visible_for_employee': 'Synlig',
  'document_tab.table.visible_for_employee.false': 'Nej',
  'document_tab.table.visible_for_employee.true': 'Ja',

  'documents.attach.form.employee_id': 'Medarbejder',
  'documents.attach.form.employee_id.required': 'Medarbejder skal vælges',
  'documents.attach.form.submit': 'Tilknyt medarbejder',
  'documents.attach.title': 'Tilknyt et underskrevet dokument til en medarbejder',
  'documents.company_document_edit.form.document_category_id': 'Kategori',
  'documents.company_document_edit.form.document_category_id.required': 'Kategori skal vælges',
  'documents.company_document_edit.form.name': 'Beskrivelse',
  'documents.company_document_edit.form.name.required': 'Beskrivelse skal indtastes',
  'documents.company_document_edit.form.submit.create': 'Opret dokument',
  'documents.company_document_edit.title.create': 'Tilføj skabelon',
  'documents.company_document_edit.title.edit': 'Redigér skabelon',
  'documents.company.confirm.delete_document': 'Er du sikker på du vil slette dokumentet?',
  'documents.company.header.back': 'Tilbage',
  'documents.company.header.new_document': 'Ny skabelon',
  'documents.company.header.new_document.lock': 'Opgradér til Premium for at kunne lave dokumentskabeloner',
  'documents.company.header.title': 'Skabeloner',
  'documents.company.table.actions.delete': 'Slet dokument',
  'documents.company.table.actions.download': 'Hent dokument',
  'documents.company.table.actions.edit': 'Redigér dokument',
  'documents.company.table.header.category': 'Kategori',
  'documents.company.table.header.filename': 'Filnavn',
  'documents.company.table.header.name': 'Beskrivelse',
  'documents.confirm.archive': 'Er du sikker på du vil arkiveret dokumentet?',
  'documents.confirm.remove': 'Er du sikker på du vil slette dokumentet?',
  'documents.employee_table.actions.archive': 'Arkivér dokument',
  'documents.employee_table.actions.delete': 'Slet dokument',
  'documents.employee_table.actions.download': 'Hent dokument',
  'documents.employee_table.actions.edit': 'Redigér dokument',
  'documents.employee_table.actions.un_archive': 'Gendan dokument',
  'documents.employee_table.created_at': 'Oprettet {date}',
  'documents.employee_table.header.category': 'Kategori',
  'documents.employee_table.header.employee': 'Medarbejder',
  'documents.employee_table.header.filename': 'Filnavn',
  'documents.employee_table.header.name': 'Beskrivelse',
  'documents.employee_table.header.visible_for_employee': 'Synlig',
  'documents.employee_table.signed': 'Dette dokument blev færdigunderskrevet {signed_at} med {signed_with}',
  'documents.employee_table.visible_for_employee.false': 'Nej',
  'documents.employee_table.visible_for_employee.true': 'Ja',
  'documents.error.must_be_pdf': 'Filen skal være en PDF',
  'documents.header.categories': 'Dokumentkategorier',
  'documents.header.category_select.placeholder': 'Kategori',
  'documents.header.hide_history': 'Tilbage',
  'documents.header.new_document': 'Nyt dokument',
  'documents.header.new_document.lock': 'Opgradér til Premium for at kunne håndtere dokumenter',
  'documents.header.new_signing_document': 'Nyt dokument til underskrift',
  'documents.header.new_signing_document.lock': 'Opgradér til Premium for at kunne underskrive dokumenter',
  'documents.header.search': 'Søg...',
  'documents.header.show_history': 'Arkiv',
  'documents.header.templates': 'Skabeloner',
  'documents.signing_title': 'Dokumenter til underskrift',
  'documents.signing.edit.form.document_category_id': 'Kategori',
  'documents.signing.edit.form.document_category_id.required': 'Kategori skal vælges',
  'documents.signing.edit.form.name': 'Beskrivelse',
  'documents.signing.edit.form.name.required': 'Beskrivelse skal indtastes',
  'documents.signing.edit.form.signed_with': 'Underskriv med',
  'documents.signing.edit.form.signed_with.criipto': 'MitID (koster {price} per dokument)',
  'documents.signing.edit.form.signed_with.criipto.lock':
    'Kontakt supporten for at høre om muligheden for at bruge MitID til underskrift',
  'documents.signing.edit.form.signed_with.sms': 'SMS',
  'documents.signing.edit.form.signers.add': 'Tilføj underskriver',
  'documents.signing.edit.form.signers.email': 'E-mail på undeskriver',
  'documents.signing.edit.form.signers.email.invalid': 'E-mail på underskriver er ugyldig',
  'documents.signing.edit.form.signers.email.required': 'E-mail på underskriver skal indtastes',
  'documents.signing.edit.form.signers.language': 'Sprog',
  'documents.signing.edit.form.signers.multiple_signers_note':
    'Dokumentet sendes først til anden underskriver, når første underskriver har underskrevet dokumentet.',
  'documents.signing.edit.form.signers.multiple_signers_note.2_plus':
    'Dokumentet sendes først til anden underskriver, når første underskriver har underskrevet dokumentet. Og så videre, således at dokumentet skal underskrives i den rækkefølge man vælger sine underskrivere.',
  'documents.signing.edit.form.signers.name': 'Navn på underskriver',
  'documents.signing.edit.form.signers.name.required': 'Navn på underskriver skal indtastes',
  'documents.signing.edit.form.signers.refresh': 'Gensend e-mail til underskrift',
  'documents.signing.edit.form.signers.remove': 'Fjern underskriver',
  'documents.signing.edit.form.signers.title': '{number}. underskriver',
  'documents.signing.edit.form.submit.create': 'Opret dokument',
  'documents.signing.edit.title.create': 'Tilføj dokument til underskrift',
  'documents.signing.edit.title.edit': 'Redigér dokument til underskrift',
  'documents.table.actions.attach': 'Tildel medarbejder',
  'documents.table.actions.attach_create': 'Opret medarbejder',
  'documents.table.actions.delete': 'Slet dokument',
  'documents.table.actions.download': 'Hent dokument',
  'documents.table.actions.edit': 'Redigér dokument',
  'documents.table.header.category': 'Kategori',
  'documents.table.header.name': 'Beskrivelse',
  'documents.table.header.signed': 'Underskrevet',
  'documents.table.header.signer': 'Underskriver',
  'documents.table.signed.false': 'Nej',
  'documents.table.signed.true': 'Ja',
  'documents.title.history': 'Arkiverede dokumenter',
  'documents.title.standard': 'Dokumenter',

  'early_payments.edit.description':
    'Normalt udbetales Skat, ATP, feriepenge, m.fl. efter fast dato for en periode. Men du kan også vælge at få foretaget udbetalingerne så snart som muligt.',
  'early_payments.edit.form.enable_early_payments': 'Tidlig overførsel af Skat m.fl.',
  'early_payments.edit.note':
    '* Husk at hæve det beløb der måtte stå på din skattekonto for at undgå Skat tilbagefører pengene.',
  'early_payments.edit.title': 'Tidlig overførsel af Skat, m.fl.',

  'employee_batch.message.failure.company_feature_missing': 'Funktion ikke tilgængelig for virksomhed',
  'employee_batch.message.failure.company_not_found': 'Ukendt virksomhed',
  'employee_batch.message.failure.email_not_found': 'Medarbejderen har ingen e-mail',
  'employee_batch.message.failure.employee_contract_invalid': 'Medarbejderens ansættelse er ugyldig',
  'employee_batch.message.failure.employee_contract_invalid.create_employee_contract':
    'Ansættelsesændringen vil være ugyldig',
  'employee_batch.message.failure.employee_contract_invalid.update_employee_contract':
    'Ansættelsesændringen vil være ugyldig',
  'employee_batch.message.failure.employee_contract_invalid.validate_create_contract':
    'En ansættelsesændring kunne ikke foretages',
  'employee_batch.message.failure.employee_contract_not_found': 'Medarbejderen har ingen ansættelse',
  'employee_batch.message.failure.employee_is_freelancer': 'Medarbejderen er freelancer',
  'employee_batch.message.failure.generic': 'Der opstod en uventet fejl under opdateringen af medarbejderen',
  'employee_batch.message.failure.invalid_national_id': 'Medarbejderen har et ugyldigt CPR-/CVR-nummer',
  'employee_batch.message.failure.invalid_national_id.dk_no_cpr': 'Medarbejderen har intet CPR- eller CVR-nummer',
  'employee_batch.message.failure.invalid_national_id.must_have_cpr': 'Medarbejderen skal have et CPR-nummer',
  'employee_batch.message.failure.leave_type_not_found': 'Fraværstypen kunne ikke findes',
  'employee_batch.message.failure.pension_definition_invalid': 'Pensionsoplysningerne er ugyldige',
  'employee_batch.message.failure.phone_number_not_set': 'Medarbejderen har intet telefonnummer',
  'employee_batch.message.failure.salary_definition_not_found': 'Medarbejderen mangler løntypen på sin ansættelse',
  'employee_batch.message.great_prayer_day_not_available_yet': 'Store Bededagstillægget er ikke tilgængeligt endnu',
  'employee_batch.message.great_prayer_day_not_enabled_for_all_employees': 'Store Bededagstillægget kan ikke ændres',
  'employee_batch.message.no_change.already_did_not_have_pay_slip_transport_type':
    'Medarbejderen får allerede ikke sin lønseddel via denne metode',
  'employee_batch.message.no_change.already_had_pay_slip_transport_type':
    'Medarbejderen får allerede sin lønseddel via denne metode',
  'employee_batch.message.no_change.employee_already_had_preferred_tax_card':
    'Medarbejderens skattekort var allerede sat',
  'employee_batch.message.no_change.employee_already_in_department': 'Medarbejderen var allerede i afdelingen',
  'employee_batch.message.no_change.employee_already_invited': 'Medarbejderen er allerede inviteret',
  'employee_batch.message.no_change.employee_already_not_in_department':
    'Medarbejderen var ikke allerede i en afdeling',
  'employee_batch.message.no_change.employee_already_set_to_language': 'Medarbejderens sprog var allerede sat',
  'employee_batch.message.no_change.employee_contract_needed_no_update':
    'Medarbejderens ansættelse krævede ikke opdatering',
  'employee_batch.message.no_change.generic': 'Medarbejderen krævede ikke ændringer',
  'employee_batch.message.success.employee_contract_updated': 'Medarbejderens ansættelse blev opdateret',
  'employee_batch.message.success.employee_invited': 'Medarbejderen er inviteret',
  'employee_batch.message.success.employee_terminated': 'Medarbejder opsagt',
  'employee_batch.message.success.employee_termination_scheduled': 'Medarbejderens opsigelse er planlagt',
  'employee_batch.message.success.employee_updated': 'Medarbejderen blev opdateret',
  'employee_batch.message.success.generic': 'Medarbejderen blev korrekt opdateret',
  'employee_batch.search_form.continue': 'Videre til handling',
  'employee_batch.search_form.title': 'Find medarbejdere',
  'employee_batch.update.form.accrual': 'Optjeningsperiode',
  'employee_batch.update.form.accrual.false': 'Årlig',
  'employee_batch.update.form.accrual.true': 'Månedlig',
  'employee_batch.update.form.action': 'Handling',
  'employee_batch.update.form.action.app_invite': 'Invitér til medarbejderapp',
  'employee_batch.update.form.action.asset': 'Tilføj/fjern enhed',
  'employee_batch.update.form.action.convert_employment': 'Gør freelancere til medarbejdere',
  'employee_batch.update.form.action.delete': 'Slet medarbejdere',
  'employee_batch.update.form.action.department': 'Tilføj til/fjern fra afdeling',
  'employee_batch.update.form.action.employee_association_benefit': 'Tilføj personaleforening',
  'employee_batch.update.form.action.great_prayer_day_supplement': 'Tilføj/fjern Store Bededagstillæg',
  'employee_batch.update.form.action.health_benefit': 'Tilføj/fjern sundhedsforsikring',
  'employee_batch.update.form.action.language': 'Sæt sprog på lønseddel',
  'employee_batch.update.form.action.lunch_benefit': 'Tilføj/fjern frokostordning',
  'employee_batch.update.form.action.net_pay_reduction_benefit': 'Tilføj/fjern nettotræk',
  'employee_batch.update.form.action.one_time_pay': 'Giv bonus',
  'employee_batch.update.form.action.optional_days': 'Set antal feriefridage',
  'employee_batch.update.form.action.pay_slip_transport': 'Ret lønseddelslevering',
  'employee_batch.update.form.action.payslip_text': 'Tekst på lønseddel',
  'employee_batch.update.form.action.pension': 'Tilføj/fjern pension',
  'employee_batch.update.form.action.personal_days': 'Set antal omsorgsdage',
  'employee_batch.update.form.action.placeholder': 'Hvad vil du gøre?',
  'employee_batch.update.form.action.position': 'Ret stilling',
  'employee_batch.update.form.action.preferred_tax_card': 'Skift skattekort',
  'employee_batch.update.form.action.production_unit': 'Ret produktionsenhed',
  'employee_batch.update.form.action.salary': 'Tilføj/fjern/ret løn (timeløn, løntillæg, m.fl.)',
  'employee_batch.update.form.action.salary_cycle': 'Ret løncyklus',
  'employee_batch.update.form.action.supplement': 'Tilføj/fjern SH-dage/Fritvalg',
  'employee_batch.update.form.action.telephone_benefit': 'Tilføj/fjern fri telefon',
  'employee_batch.update.form.action.terminate': 'Opsig medarbejdere',
  'employee_batch.update.form.amount.bonus': 'Beløb',
  'employee_batch.update.form.amount.health_benefit': 'Beløb til sunhedsforsikring',
  'employee_batch.update.form.amount.lunch_benefit': 'Beløb til frokostordning',
  'employee_batch.update.form.amount.net_pay_reduction_benefit': 'Nettotræk',
  'employee_batch.update.form.amount.optional_days': 'Antal feriefridage',
  'employee_batch.update.form.amount.personal_days': 'Antal omsorgsdage',
  'employee_batch.update.form.amount.placeholder': 'Beløb',
  'employee_batch.update.form.amount.required': 'Beløb skal indtastes',
  'employee_batch.update.form.amount.required.optional_days': 'Antal feriefridage skal indtastes',
  'employee_batch.update.form.amount.required.personal_days': 'Antal omsorgsdage skal indtastes',
  'employee_batch.update.form.amount.salary.fixed': 'Grundløn',
  'employee_batch.update.form.amount.salary.hourly': 'Timeløn',
  'employee_batch.update.form.amount.salary.required.fixed': 'Grundløn skal indtastes',
  'employee_batch.update.form.amount.salary.required.hourly': 'Timeløn skal indtastes',
  'employee_batch.update.form.amount.salary.required.supplement': 'Løntillæg skal indtastes',
  'employee_batch.update.form.amount.salary.required.supplement_varied': 'Variabelt løntillæg skal indtastes',
  'employee_batch.update.form.amount.salary.supplement': 'Løntillæg',
  'employee_batch.update.form.amount.salary.supplement_varied': 'Variabelt løntillæg',
  'employee_batch.update.form.amount.suffix': 'kr.',
  'employee_batch.update.form.amount.suffix.lunch.daily': 'kr. per dag',
  'employee_batch.update.form.amount.suffix.lunch.standard': 'kr. per periode',
  'employee_batch.update.form.asset_category_id': 'Enhedskategori',
  'employee_batch.update.form.asset_category_id.required': 'Enhedskategori skal vælges',
  'employee_batch.update.form.assets_to_return.intro': 'Vælg de enheder der skal returneres fra medarbejderne.',
  'employee_batch.update.form.assets_to_return.none': 'Der er ingen enheder at fjerne...',
  'employee_batch.update.form.back': 'Tilbage',
  'employee_batch.update.form.delete_note.line_1.many': 'Du er ved at slette {count} medarbejdere!',
  'employee_batch.update.form.delete_note.line_1.one': 'Du er ved at slette {count} medarbejder!',
  'employee_batch.update.form.delete_note.line_2':
    'Når medarbejderne er slettet, kan det {not} fortrydes. Medarbejderne skal manuelt genoprettes, hvis du fortryder.',
  'employee_batch.update.form.delete_note.line_2.not': 'ikke',
  'employee_batch.update.form.delete_note.line_3':
    'Medarbejdere med kørt løn kan ikke slettes, og der vil komme en fejlbesked nedenfor.',
  'employee_batch.update.form.department_id': 'Hvilken afdeling',
  'employee_batch.update.form.department_id.placeholder': 'Afdeling',
  'employee_batch.update.form.department_id.required': 'Afdeling skal vælges',
  'employee_batch.update.form.description.asset': 'Beskrivelse på enhed',
  'employee_batch.update.form.description.required': 'Beskrivelse skal indtastes',
  'employee_batch.update.form.employee_list.note.after.many': 'På følgende {count} medarbejdere blev handlingen udført',
  'employee_batch.update.form.employee_list.note.after.one': 'På følgende medarbejder blev handlingen udført',
  'employee_batch.update.form.employee_list.note.before.many':
    'På følgende {count} medarbejdere vil der blive udført handling',
  'employee_batch.update.form.employee_list.note.before.one': 'På følgende medarbejder vil der blive udført handling',
  'employee_batch.update.form.finish': 'Afslut',
  'employee_batch.update.form.has_lunch_daily.false': 'Fast beløb',
  'employee_batch.update.form.has_lunch_daily.true': 'Per dag',
  'employee_batch.update.form.language': 'Sprog',
  'employee_batch.update.form.one_time_pay_type': 'Bonustype',
  'employee_batch.update.form.one_time_pay_type.required': 'Bonustype skal angives',
  'employee_batch.update.form.operation': 'Tilføj/fjern?',
  'employee_batch.update.form.operation.checked': 'Tilføj',
  'employee_batch.update.form.operation.unchecked': 'Fjern',
  'employee_batch.update.form.pay_slip_transport_setting': 'Hvilken leveringsmetode skal slås til eller fra?',
  'employee_batch.update.form.pay_slip_transport_setting.e_boks': 'e-Boks',
  'employee_batch.update.form.pay_slip_transport_setting.e_boks.lock': 'Opgradér til Basic, for at sende til e-Boks',
  'employee_batch.update.form.pay_slip_transport_setting.email': 'E-mail',
  'employee_batch.update.form.pay_slip_transport_setting.mit_dk': 'mit.dk',
  'employee_batch.update.form.pay_slip_transport_setting.mit_dk.lock': 'Opgradér til Basic, for at sende til mit.dk',
  'employee_batch.update.form.pay_slip_transport_setting.national_inbox_note':
    'Når du skifter medarbejderne til mit.dk eller e-Boks, slår vi automatisk den anden digitale postkasse fra.',
  'employee_batch.update.form.pay_slip_transport_setting.sms': 'SMS',
  'employee_batch.update.form.pay_slip_transport_setting.sms.lock': 'Opgradér til Basic, for at sende sms',
  'employee_batch.update.form.pension_company_id': 'Pensionsselskab',
  'employee_batch.update.form.pension_company_id.required': 'Pensionsselskab skal vælges',
  'employee_batch.update.form.pension_customer_id': 'Kundenummer',
  'employee_batch.update.form.pension_customer_id.max_15_characters': 'Kundenummer må maks. være 15 tegn',
  'employee_batch.update.form.pension_customer_id.required': 'Kundenummer skal indtastes',
  'employee_batch.update.form.pension_employee_amount': 'Medarbejderbetalt pension',
  'employee_batch.update.form.pension_employee_amount.percentage_invalid': 'Procent er ugyldig',
  'employee_batch.update.form.pension_employee_amount.required.amount': 'Beløb skal indtastes',
  'employee_batch.update.form.pension_employee_amount.required.percentage': 'Procent skal indtastes',
  'employee_batch.update.form.pension_employee_amount.suffix.amount': 'kr.',
  'employee_batch.update.form.pension_employee_amount.suffix.percentage': '%',
  'employee_batch.update.form.pension_employee_percentage.false': 'Fast beløb',
  'employee_batch.update.form.pension_employee_percentage.true': 'Procent',
  'employee_batch.update.form.pension_employer_amount': 'Arbejdsgiverbetalt pension',
  'employee_batch.update.form.pension_employer_amount.percentage_invalid': 'Procent er ugyldig',
  'employee_batch.update.form.pension_employer_amount.required.amount': 'Beløb skal indtastes',
  'employee_batch.update.form.pension_employer_amount.required.percentage': 'Procent skal indtastes',
  'employee_batch.update.form.pension_employer_amount.suffix.amount': 'kr.',
  'employee_batch.update.form.pension_employer_amount.suffix.percentage': '%',
  'employee_batch.update.form.pension_employer_percentage.false': 'Fast beløb',
  'employee_batch.update.form.pension_employer_percentage.true': 'Procent',
  'employee_batch.update.form.pension_operation': 'Tilføj/fjern pensionsordning?',
  'employee_batch.update.form.pension_operation.checked': 'Tilføj',
  'employee_batch.update.form.pension_operation.note':
    'Hvis en medarbejder allerede har pension, og du efterlader et af felterne tomt, så bliver pensionen for det blanke felt fjernet.',
  'employee_batch.update.form.pension_operation.unchecked': 'Fjern',
  'employee_batch.update.form.pension_union_agreement_number': 'Overenskomst',
  'employee_batch.update.form.pension_union_agreement_number.5_characters':
    'Overenskomstnummer skal være fem tegn langt',
  'employee_batch.update.form.pension_union_agreement_number.tooltip': 'Overenskomstnummer er ikke påkrævet',
  'employee_batch.update.form.preferred_tax_card': 'Foretrukket skattekort',
  'employee_batch.update.form.preferred_tax_card.placeholder': 'Vælg skattekort',
  'employee_batch.update.form.production_unit_id': 'Produktionsenhed',
  'employee_batch.update.form.production_unit_id.required': 'Produktionsenhed skal vælges',
  'employee_batch.update.form.quantity': 'Antal',
  'employee_batch.update.form.quantity.required': 'Antal skal angives',
  'employee_batch.update.form.rate': 'Sats',
  'employee_batch.update.form.rate.required': 'Sats skal angives',
  'employee_batch.update.form.rate.suffix': 'kr.',
  'employee_batch.update.form.salary_class': 'Løntype?',
  'employee_batch.update.form.salary_class.fixed': 'Fastløn',
  'employee_batch.update.form.salary_class.hourly': 'Timeløn',
  'employee_batch.update.form.salary_class.required': 'Løntype er påkrævet',
  'employee_batch.update.form.salary_class.supplement': 'Løntillæg',
  'employee_batch.update.form.salary_class.supplement_varied': 'Variabelt løntillæg',
  'employee_batch.update.form.salary_cycle_id': 'Løncyklus',
  'employee_batch.update.form.salary_cycle_id.placeholder': 'Vælg løncyklus',
  'employee_batch.update.form.salary_cycle_id.required': 'Løncyklus skal vælges',
  'employee_batch.update.form.salary_type_id.hourly': 'Timelønstype',
  'employee_batch.update.form.salary_type_id.required.hourly': 'Timelønstype skal vælges',
  'employee_batch.update.form.salary_type_id.required.supplement': 'Løntillægstype skal vælges',
  'employee_batch.update.form.salary_type_id.required.supplement_varied': 'Variabelt løntillægstype skal vælges',
  'employee_batch.update.form.salary_type_id.supplement': 'Løntillægstype',
  'employee_batch.update.form.salary_type_id.supplement_varied': 'Variabelt løntillægstype',
  'employee_batch.update.form.submit': 'Kør',
  'employee_batch.update.form.supplement_compensation_rate': 'Indbetaling',
  'employee_batch.update.form.supplement_compensation_rate.percentage_invalid': 'Procent er ugyldig',
  'employee_batch.update.form.supplement_compensation_rate.required.amount': 'Beløb skal indtastes',
  'employee_batch.update.form.supplement_compensation_rate.required.percentage': 'Procent skal indtastes',
  'employee_batch.update.form.supplement_compensation_rate.suffix.amount': 'kr.',
  'employee_batch.update.form.supplement_compensation_rate.suffix.percentage': '%',
  'employee_batch.update.form.supplement_percentage.false': 'Fast beløb',
  'employee_batch.update.form.supplement_percentage.true': 'Procent',
  'employee_batch.update.form.supplement_type_title': 'SH-dage/Fritvalg',
  'employee_batch.update.form.supplement_type_title.placeholder': 'Vælg SH-dage/Fritvalg-type',
  'employee_batch.update.form.terminate_note.line_1.many': 'Du er ved at opsige {count} medarbjedere!',
  'employee_batch.update.form.terminate_note.line_1.one': 'Du er ved at opsige {count} medarbjeder!',
  'employee_batch.update.form.terminate_note.line_2': 'Når medarbejderne er opsagt, kan de kun manuelt genansættes.',
  'employee_batch.update.form.title.asset': 'Titel på enhed',
  'employee_batch.update.form.title.net_pay_reduction_benefit': 'Titel på nettotræk',
  'employee_batch.update.form.title.one_time_pay': 'Titel på bonus',
  'employee_batch.update.form.title.payslip_text': 'Tekst på lønseddel',
  'employee_batch.update.form.title.required': 'Titel skal udfyldes',
  'employee_batch.update.form.title.required.payslip_text': 'Tekst på lønseddel skal indtastes',
  'employee_batch.update.form.title.salary.fixed': 'Tekst på grundløn',
  'employee_batch.update.form.title.salary.hourly': 'Tekst på timeløn',
  'employee_batch.update.form.title.salary.required.supplement': 'Tekst på løntillæg skal indtastes',
  'employee_batch.update.form.title.salary.supplement': 'Tekst på løntillæg',
  'employee_batch.update.form.title.salary.supplement_varied': 'Tekst på variabelt løntillæg',
  'employee_batch.update.form.valid_from': 'Med virkning fra',
  'employee_batch.update.form.valid_from_picker.required.terminate': 'Sidste arbejdsdag skal vælges',
  'employee_batch.update.form.valid_from_picker.terminate': 'Sidste arbejdsdag',
  'employee_batch.update.form.valid_from.now': 'Med det samme',
  'employee_batch.update.loading.no_task': 'Massehandling igangsat',
  'employee_batch.update.loading.task':
    'Massehandling igangsat og lagt i baggrunden.  Hvis du navigerer væk fra denne side, vil massehandlingen stadig blive udført, men du kan ikke efterfølgende se resultatet.',
  'employee_batch.update.title': 'Massehandling',
  'employee_batch.wizard.step.0.title': 'Trin 1',
  'employee_batch.wizard.step.1.title': 'Trin 2',
  'employee_batch.wizard.steps.0': 'Find medarbejdere',
  'employee_batch.wizard.steps.1': 'Massehandling',
  'employee_batch.wizard.steps.intro':
    'Efter blot to trin, har du foretaget en massehandling på en gruppe af medarbejdere',
  'employee_batch.wizard.steps.title': 'Medarbejdermassehandling',

  'employee_delete.alert.success': '{name} er blevet slettet',
  'employee_delete.button.employee': 'Slet medarbejderen {name}',
  'employee_delete.button.freelancer': 'Slet freelanceren {name}',
  'employee_delete.intro.employee.line_1': 'Slet medarbejderen permanent, og stop al fremtidig betaling af løn m.v.',
  'employee_delete.intro.employee.line_2':
    'Medarbejderen har ikke haft udbetalt løn, så denne medarbejder kan slettes.',
  'employee_delete.intro.freelancer.line_1': 'Slet freelanceren permanent, og stop al fremtidig betaling af løn m.v.',
  'employee_delete.intro.freelancer.line_2':
    'Freelanceren har ikke haft udbetalt løn, så denne freelancer kan slettes.',
  'employee_delete.title.employee': 'Slet medarbejder',
  'employee_delete.title.freelancer': 'Slet freelancer',

  'employee_filter.department': 'Afdeling',
  'employee_filter.department.none': 'Ingen afdeling',
  'employee_filter.search': 'Søg...',
  'employee_filter.searching_for': 'søger på "{query}"',
  'employee_filter.searching_in_department': 'i afdeling {department}',
  'employee_filter.searching_in_no_department': 'uden afdeling',

  'employee_leave.form.end_date': 'Orlov til',
  'employee_leave.form.end_date.required': 'Orlov til skal vælges',
  'employee_leave.form.invalid_period': 'Orlovsperioden er ikke gyldig',
  'employee_leave.form.start_date': 'Orlov fra',
  'employee_leave.form.start_date.required': 'Orlov fra skal vælges',
  'employee_leave.form.submit': 'Giv medarbejder orlov',
  'employee_leave.title.create': 'Giv medarbejder orlov',
  'employee_leave.title.delete': 'Annullér medarbejders orlov',
  'employee_leave.title.delete.button': 'Annullér orlov',

  'employee_no_contract.button': 'Tilføj ansættelse',
  'employee_no_contract.text': 'Tilføj en ansættelse for at kunne udbetale løn til medarbejderen.',
  'employee_no_contract.title': 'Denne medarbejder har ingen ansættelse',

  'employee_no_employment.link': 'Skriv til support',
  'employee_no_employment.text': 'Kontakt support for at genansætte medarbejderen.',
  'employee_no_employment.title': 'Denne medarbejder er opsagt',

  'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id': 'Bogføringskonto',
  'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_account': 'Ingen konto',
  'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_longer_available.generic':
    'Kontoen er ikke længere tilgængelig',
  'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_longer_available.option_note':
    '(ikke længere tilgængelig)',
  'employee_nondisburseable_account.edit.form.nondisburseable_accounting_account_id.no_longer_available.specific':
    'Kontoen {account} er ikke længere tilgængelig',
  'employee_nondisburseable_account.edit.title': 'Rediger firmakortkontoen for {name}',

  'employee_search.form.created_before': 'Kun medarbejdere oprettet før dato',
  'employee_search.form.department_id': 'Afdeling',
  'employee_search.form.department_id.all': 'Alle afdelinger',
  'employee_search.form.department_id.placeholder': 'Alle afdelinger',
  'employee_search.form.employment_type': 'Ansættelsestype',
  'employee_search.form.employment_type.all': 'Alle slags medarbejdere',
  'employee_search.form.employment_type.placeholder': 'Alle slags medarbejdere',
  'employee_search.form.employment_types.commissioned': 'Provisionslønnet',
  'employee_search.form.employment_types.freelancer': 'Freelancer',
  'employee_search.form.employment_types.full_time': 'Fuldtid',
  'employee_search.form.employment_types.hourly': 'Timelønnet',
  'employee_search.form.employment_types.part_time': 'Deltid',
  'employee_search.form.not_selected': 'Ikke valgte medarbejdere',
  'employee_search.form.not_selected.footer.many': '{count} medarbejdere.',
  'employee_search.form.not_selected.footer.one': '{count} medarbejder.',
  'employee_search.form.not_selected.select': 'Vælg medarbejder',
  'employee_search.form.not_selected.select_all': 'Vælg alle',
  'employee_search.form.only_draft': 'Kun medarbejdere i kladdetilstand',
  'employee_search.form.only_mutable': 'Kun nyansatte (dem for hvem der {not} er gennemført en løn endnu)',
  'employee_search.form.only_mutable.not': 'ikke',
  'employee_search.form.reset_fields': 'Nulstil filtre',
  'employee_search.form.salary_cycle_id': 'Periode',
  'employee_search.form.salary_cycle_id.all': 'Alle perioder',
  'employee_search.form.salary_cycle_id.placeholder': 'Alle perioder',
  'employee_search.form.search_query': 'Søg',
  'employee_search.form.search_query.placeholder': 'Fritekst',
  'employee_search.form.selected': 'Valgte medarbejdere',
  'employee_search.form.selected.footer.many': '{count} medarbejdere.',
  'employee_search.form.selected.footer.one': '{count} medarbejder.',
  'employee_search.form.selected.select': 'Fravælg medarbejder',
  'employee_search.form.selected.select_all': 'Fravælg alle',

  'employee_settings.alert.success': 'Indstillingerne for {name} er gemt.',
  'employee_settings.form.employee_automatic_optional_vacation_transfer_limit':
    'Medarbejderen må få overført feriefri-/omsorgsdage',
  'employee_settings.form.employee_automatic_vacation_transfer_limit': 'Medarbejderen må få overført feriedage',
  'employee_settings.form.employee_enable_reimbursement_voucher': 'Medarbejderen må oprette udlæg',
  'employee_settings.form.employee_enable_work_hours': 'Medarbejderen kan registere arbejdstid',
  'employee_settings.form.employee_registrations_never_approved_from_employee_app':
    'Registreringer oprettet i medarbejderapp er aldrig autogodkendt',
  'employee_settings.form.employee_vacation_excess_limit': 'Medarbejderen må låne feriedage',
  'employee_settings.intro.line_1': 'Herfra kan du tilgå og rette i visse generelle indstillinger på medarbejderen.',
  'employee_settings.intro.line_2':
    'Er der ingen indstillinger på siden, skyldes det at du ikke bruger funktionerne de berør.',
  'employee_settings.title': 'Medarbejderindstillinger',

  'employee_single.alert.leave.success': '{name} har fået orlov',
  'employee_single.alert.rehire.success': '{name} er blevet genansat',
  'employee_single.menu.car_allowance': 'Kørselsregnskab',
  'employee_single.menu.contract_history': 'Ansættelseshistorik',
  'employee_single.menu.employment': 'Ansættelse',
  'employee_single.menu.leave': 'Fravær',
  'employee_single.menu.pay_slips': 'Lønsedler',
  'employee_single.menu.profile': 'Profil',
  'employee_single.menu.project_registration': 'Tidsregistrering',
  'employee_single.menu.salary_registration': 'Lønregistrering',
  'employee_single.menu.settings': 'Indstillinger',
  'employee_single.menu.start_balances': 'Startsaldi',
  'employee_single.menu.tax_cards': 'Skattekort',
  'employee_single.menu.time_box_registration.flex': 'Flekstid',
  'employee_single.menu.time_box_registration.overtime': 'Afspadsering',
  'employee_single.menu.time_registration': 'Timeregistrering',
  'employee_single.menu.work_hours_registration': 'Arbejdstid',
  'employee_single.profile.alert.success': 'Medarbejderens profil er gemt',
  'employee_single.profile.form.address': 'Adresse',
  'employee_single.profile.form.address.required': 'Adresse skal indtastes',
  'employee_single.profile.form.bank_account_number': 'Kontonummer',
  'employee_single.profile.form.bank_account_number.invalid': 'Kontonummer er ugyldigt',
  'employee_single.profile.form.bank_account_number.required': 'Kontonummer skal indtastes',
  'employee_single.profile.form.bank_info_title': 'Bankoplysninger',
  'employee_single.profile.form.bank_registration_number': 'Registreringsnummer',
  'employee_single.profile.form.bank_registration_number.invalid': 'Registreringsnummer er ugyldigt',
  'employee_single.profile.form.bank_registration_number.required': 'Registreringsnummer skal indtastes',
  'employee_single.profile.form.birth_date': 'Fødselsdato',
  'employee_single.profile.form.birth_date.invalid': 'Fødselsdato er ugyldig',
  'employee_single.profile.form.birth_date.required': 'Fødselsdato skal indtastes',
  'employee_single.profile.form.city': 'By',
  'employee_single.profile.form.city.required': 'By skal indtastes',
  'employee_single.profile.form.country': 'Land',
  'employee_single.profile.form.country.not_gb':
    'For det Forenede Kongerige, skal du enten vælge Storbritannien eller Nordirland',
  'employee_single.profile.form.country.placeholder': 'Vælg land',
  'employee_single.profile.form.country.required': 'Land skal vælges',
  'employee_single.profile.form.email': 'E-mail',
  'employee_single.profile.form.email.invalid': 'E-mail er ugyldig',
  'employee_single.profile.form.emergency_contact_title': 'Pårørende',
  'employee_single.profile.form.emergency_contact.country_code': 'Landekode',
  'employee_single.profile.form.emergency_contact.country_code.invalid': 'Landekode er ugyldig',
  'employee_single.profile.form.emergency_contact.country_code.required': 'Landekode skal vælges',
  'employee_single.profile.form.emergency_contact.email': 'E-mail',
  'employee_single.profile.form.emergency_contact.email.invalid': 'E-mail er ugyldig',
  'employee_single.profile.form.emergency_contact.name': 'Navn',
  'employee_single.profile.form.emergency_contact.phone_number': 'Telefonnummer',
  'employee_single.profile.form.emergency_contact.phone_number.invalid': 'Telefonnummer er ugyldigt',
  'employee_single.profile.form.emergency_contact.relation': 'Relation',
  'employee_single.profile.form.employee_number': 'Medarbejdernummer',
  'employee_single.profile.form.employee_number.in_use': 'Medarbejdernummer er i brug',
  'employee_single.profile.form.employee_number.invalid': 'Medarbejdernummer er ugyldigt',
  'employee_single.profile.form.employee_number.required': 'Medarbejdernummer skal indtastes',
  'employee_single.profile.form.gender': 'Køn',
  'employee_single.profile.form.gender.placeholder': 'Vælg køn',
  'employee_single.profile.form.gender.required': 'Køn skal vælges',
  'employee_single.profile.form.language': 'Sprog',
  'employee_single.profile.form.language.placeholder': 'Vælg sprog',
  'employee_single.profile.form.language.required': 'Sprog skal vælges',
  'employee_single.profile.form.name': 'Navn',
  'employee_single.profile.form.name.required': 'Navn skal indtastes',
  'employee_single.profile.form.national_id_type': 'Type',
  'employee_single.profile.form.national_id_type.placeholder': 'Vælg type',
  'employee_single.profile.form.national_id_type.required': 'Type skal vælges',
  'employee_single.profile.form.national_id_unlock': 'Lås feltet op for at rette i det',
  'employee_single.profile.form.national_id.look_up': 'Slå CPR-nummeret op i CPR',
  'employee_single.profile.form.postal_code': 'Postnr.',
  'employee_single.profile.form.postal_code.required': 'Postnr. skal indtastes',
  'employee_single.profile.form.tin': 'TIN',
  'employee_single.profile.form.tin.intro':
    'TIN (Tax Idenfication Number) er et internationalt skatteidentifikationsnummer, der skal angives for ansatte i andre EU-lande per 1. januar 2024.',
  'employee_single.profile.form.tin.read_more': 'Læs mere {link}.',
  'employee_single.profile.form.tin.read_more.link': 'på EUs hjemmeside',
  'employee_single.profile.form.transfer_destination_type': 'Overførselstype:',
  'employee_single.profile.form.transfer_destination_type.dk_account': 'Alm. bankoverførsel',
  'employee_single.profile.form.transfer_destination_type.easy_account': 'NemKonto',
  'employee_single.profile.form.transfer_destination_type.none': 'Udenlandsk konto - manuel udbetaling',
  'employee_single.profile.form.use_manual_transfer': 'Udenlandsk konto - manuel udbetaling',
  'employee_single.profile.title': 'Profil',
  'employee_single.rehire.alert.success.employee': '{name} er blevet genansat',
  'employee_single.rehire.alert.success.freelancer': '{name} er blevet genaktiveret',
  'employee_single.rehire.form.start_date.employee': 'Ansættelsesdato',
  'employee_single.rehire.form.start_date.freelancer': 'Genaktiveringsdato',
  'employee_single.rehire.form.start_date.required.employee': 'Ansættelsesdato skal vælges',
  'employee_single.rehire.form.start_date.required.freelancer': 'Genaktiveringsdato skal vælges',
  'employee_single.rehire.form.submit.employee': 'Genansæt medarbejder',
  'employee_single.rehire.form.submit.freelancer': 'Genaktivér freelancer',
  'employee_single.rehire.title.employee': 'Genansæt medarbejder',
  'employee_single.rehire.title.freelancer': 'Genaktivér freelancer',
  'employee_single.warning.employee_is_foreign_resident':
    'Medarbejderen bor i følge Skat ikke i Danmark.  Såfremt medarbejderen bor i udlandet, bør du rette medarbejderen til "CPR-nummer bosat i udlandet" under medarbejderens profile før næste lønkørsel.  Bor medarbejderen faktisk i Danmark, skal vedkommende melde flytning til Folkeregisteret.',
  'employee_single.warning.in_draft_state':
    'Medarbejderen er i kladdetilstand, de manglende felter skal udfyldes før vedkommende er klar til løn',
  'employee_single.warning.invalid_birth_date': 'Medarbejdens fødselsdato er ugyldig.',
  'employee_single.warning.start_balances_need_adjustment': 'Medarbejderens startsaldi kræver justering',

  'employee_terminate.alert.success.freelancer': '{name} er blevet skjult',
  'employee_terminate.alert.success.standard': '{name} er blevet opsagt',
  'employee_terminate.employed.freelancer': 'Hyret',
  'employee_terminate.employed.standard': 'Ansat',
  'employee_terminate.form.end_date.deadline_format': '{field} (tidligst {date})',
  'employee_terminate.form.end_date.freelancer': 'Skjuldato',
  'employee_terminate.form.end_date.not_before_immutable': '{field} kan ikke være før næste lønperiode er afsluttet',
  'employee_terminate.form.end_date.required': '{field} skal vælges',
  'employee_terminate.form.end_date.standard': 'Opsigelsesdato',
  'employee_terminate.form.submit.freelancer': 'Skjul freelanceren {name}',
  'employee_terminate.form.submit.standard': 'Opsig medarbejderen {name}',
  'employee_terminate.intro.freelancer': 'Skjul freelanceren fra oversigten over freelancere.',
  'employee_terminate.intro.standard.line_1': 'Opsig medarbejderen, og stop al betaling af løn m.v.',
  'employee_terminate.intro.standard.line_2': 'Vælg den dato som medarbejderen skal have løn til.',
  'employee_terminate.intro.standard.line_3': 'Medarbejderen vil blive gemt i systemet de næste 5 år jf. lovgivningen.',
  'employee_terminate.title.freelancer': 'Skjul freelancer',
  'employee_terminate.title.standard': 'Opsig medarbejder',

  'employee.add.base_data.address': 'Adresse',
  'employee.add.base_data.address.required': 'Adresse skal indtastes',
  'employee.add.base_data.birth_date': 'Fødselsdato',
  'employee.add.base_data.birth_date.invalid': 'Fødselsdato er ugyldig',
  'employee.add.base_data.birth_date.required': 'Fødselsdato skal indtastes',
  'employee.add.base_data.city': 'By',
  'employee.add.base_data.city.required': 'By skal indtastes',
  'employee.add.base_data.contract_template_id': 'Medarbejderskabelon',
  'employee.add.base_data.contract_template_id.none': 'Ingen skabelon',
  'employee.add.base_data.contract_template_id.placeholder': 'Vælg skabelon',
  'employee.add.base_data.country': 'Land',
  'employee.add.base_data.country.not_gb':
    'For det Forenede Kongerige, skal du enten vælge Storbritannien eller Nordirland',
  'employee.add.base_data.country.placeholder': 'Vælg land',
  'employee.add.base_data.country.required': 'Land skal vælges',
  'employee.add.base_data.department_id': 'Afdeling',
  'employee.add.base_data.department_id.only_accessible': 'Du må kun vælge en afdeling du har adgang til',
  'employee.add.base_data.department_id.placeholder': 'Vælg afdeling',
  'employee.add.base_data.department_id.required': 'Afdeling skal vælges',
  'employee.add.base_data.email': 'E-mail',
  'employee.add.base_data.email.invalid': 'E-mail er ugyldig',
  'employee.add.base_data.employee_number': 'Medarbejdernummer',
  'employee.add.base_data.employee_number.in_use': 'Medarbejdernummer er i brug',
  'employee.add.base_data.employee_number.invalid': 'Medarbejdernummer er ugyldigt',
  'employee.add.base_data.employee_number.required': 'Medarbejdernummer skal indtastes',
  'employee.add.base_data.employment_start_date': 'Ansættelsesdato i virksomheden',
  'employee.add.base_data.employment_start_date.required': 'Ansættelsesdato i virksomheden skal indtastes',
  'employee.add.base_data.gender': 'Køn',
  'employee.add.base_data.gender.placeholder': 'Vælg køn',
  'employee.add.base_data.gender.required': 'Køn skal vælges',
  'employee.add.base_data.name': 'Navn',
  'employee.add.base_data.name.required': 'Navn skal indtastes',
  'employee.add.base_data.national_id_type': 'Opret via',
  'employee.add.base_data.national_id_type.placeholder': 'Vælg type',
  'employee.add.base_data.national_id_type.required': 'Opret via skal vælges',
  'employee.add.base_data.national_id.look_up': 'Slå CPR-nummeret op i CPR',
  'employee.add.base_data.postal_code': 'Postnr.',
  'employee.add.base_data.postal_code.required': 'Postnr. skal indtastes',
  'employee.add.base_data.submit': 'Videre til kommunikation',
  'employee.add.base_data.tin': 'TIN',
  'employee.add.base_data.tin.intro':
    'TIN (Tax Idenfication Number) er et internationalt skatteidentifikationsnummer, der skal angives for ansatte i andre lande per 1. januar 2024.',
  'employee.add.base_data.tin.read_more': 'Læs mere {link}.',
  'employee.add.base_data.tin.read_more.link': 'på EUs hjemmeside',
  'employee.add.base_data.title.freelancer': 'Udfyld detaljerne for freelanceren',
  'employee.add.base_data.title.standard': 'Udfyld detaljerne for medarbejderen',
  'employee.add.communication.back': 'Tilbage',
  'employee.add.communication.language': 'Sprog',
  'employee.add.communication.language.required': 'Sprog skal vælges',
  'employee.add.communication.language.select': 'Vælg sprog',
  'employee.add.communication.pay_slip_transport': 'Hvordan skal lønsedlerne sendes?',
  'employee.add.communication.submit': 'Videre til finansielle oplysninger',
  'employee.add.communication.title': 'Kommunikation',
  'employee.add.financial_data.back': 'Tilbage',
  'employee.add.financial_data.bank_account_number': 'Kontonummer',
  'employee.add.financial_data.bank_account_number.invalid': 'Kontonummer er ugyldigt',
  'employee.add.financial_data.bank_account_number.required': 'Kontonummer skal indtastes',
  'employee.add.financial_data.bank_registration_number': 'Registreringsnummer',
  'employee.add.financial_data.bank_registration_number.invalid': 'Registreringsnummer er ugyldigt',
  'employee.add.financial_data.bank_registration_number.required': 'Registreringsnummer skal indtastes',
  'employee.add.financial_data.income_type': 'Indkomsttype',
  'employee.add.financial_data.income_type.lock':
    'Slå flere indkomsttyper til under virksomhed - avanceret, for at ændre dette felt',
  'employee.add.financial_data.income_type.placeholder': 'Vælg indkomsttype',
  'employee.add.financial_data.income_type.required': 'Indkomsttype skal vælges',
  'employee.add.financial_data.preferred_tax_card_type': 'Foretrukket skattekort',
  'employee.add.financial_data.preferred_tax_card_type.placeholder': 'Vælg skattekort',
  'employee.add.financial_data.preferred_tax_card_type.required': 'Foretrukket skattekort skal vælges',
  'employee.add.financial_data.submit': 'Gem',
  'employee.add.financial_data.title': 'Finansielle oplysninger',
  'employee.add.financial_data.transfer_destination_type': 'Overførselstype:',
  'employee.add.financial_data.transfer_destination_type.dk_account': 'Alm. bankoverførsel',
  'employee.add.financial_data.transfer_destination_type.easy_account': 'NemKonto',
  'employee.add.financial_data.transfer_destination_type.none': 'Udenlandsk konto - manuel udbetaling',
  'employee.add.financial_data.use_manual_transfer': 'Udenlandsk konto - manuel udbetaling',
  'employee.add.invite.department_id': 'Afdeling',
  'employee.add.invite.department_id.placeholder': 'Vælg afdeling',
  'employee.add.invite.email': 'E-mail',
  'employee.add.invite.email.invalid': 'E-mail er ugyldig',
  'employee.add.invite.email.required': 'E-mail skal indtastes',
  'employee.add.invite.employee_number': 'Medarbejdernummer',
  'employee.add.invite.employee_number.in_use': 'Medarbejdernummer er i brug',
  'employee.add.invite.employee_number.invalid': 'Medarbejdernummer er ugyldigt',
  'employee.add.invite.employee_number.required': 'Medarbejdernummer skal indtastes',
  'employee.add.invite.employment_start_date': 'Ansættelsesdato i virksomheden',
  'employee.add.invite.employment_start_date.required': 'Ansættelsesdato i virksomheden skal indtastes',
  'employee.add.invite.submit': 'Send invitation',
  'employee.add.invite.title': 'Invitér medarbejder',
  'employee.add.step_0.title': 'Trin 1',
  'employee.add.step_1.title': 'Trin 2',
  'employee.add.step_2.title': 'Trin 3',
  'employee.add.steps.0': 'Stamdata',
  'employee.add.steps.1': 'Kommunikation',
  'employee.add.steps.2': 'Finansielle oplysninger',
  'employee.add.steps.contract_book.intro': 'Herunder finder du de felter, vi ikke automatisk kunne udfylde.',
  'employee.add.steps.contract_book.missing': '[ikke udfyldt]',
  'employee.add.steps.contract_book.title': 'ContractBook-felter',
  'employee.add.steps.intro': 'Efter disse 3 trin, har du oprettet en medarbejder',
  'employee.add.steps.support_note':
    'Hvis du har problemer med at oprette en medarbejder, kan du altid kontakte {email}. Vi vil med glæde hjælpe dig!',
  'employee.add.steps.title': 'Tilføj medarbejder',
  'employee.employee_pay_type.commissioned': 'Provisionslønnet',
  'employee.employee_pay_type.hourly_paid': 'Timelønnet',
  'employee.employee_pay_type.salaried': 'Fastlønnet',
  'employee.employment_type.commissioned': 'Provisionslønnet',
  'employee.employment_type.fixed': 'Fastlønnet',
  'employee.employment_type.freelancer': 'Freelancer',
  'employee.employment_type.full_time': 'Fuldtid',
  'employee.employment_type.hourly': 'Timelønnet',
  'employee.employment_type.part_time': 'Deltid',
  'employee.national_id.cpr': 'CPR-nummer',
  'employee.national_id.cpr_foreign': 'CPR-nummer bosat i udlandet',
  'employee.national_id.cvr': 'CVR-nummer',
  'employee.national_id.no_cpr': 'Intet CPR-nummer',
  'employee.sidebar.actions.assets': 'Enheder',
  'employee.sidebar.actions.assets.lock': 'Opgradér til Premium for at kunne bestyre enheder for dine medarbejdere',
  'employee.sidebar.actions.balance_adjustments': 'Saldokorrektioner',
  'employee.sidebar.actions.bonus': 'Giv bonus',
  'employee.sidebar.actions.delete_leave': 'Slet orlov',
  'employee.sidebar.actions.documents': 'Dokumenter',
  'employee.sidebar.actions.documents.lock':
    'Opgradér til Premium for at kunne håndtere dokumenter for dine medarbejdere',
  'employee.sidebar.actions.e_income_text': 'Tekst til eIndkomst',
  'employee.sidebar.actions.extra_pension': 'Ekstra pension',
  'employee.sidebar.actions.free_text': 'Tekst på lønseddel',
  'employee.sidebar.actions.give_leave': 'Giv orlov',
  'employee.sidebar.actions.pay_check_advance': 'Forskud/nettotræk',
  'employee.sidebar.actions.reimbursement': 'Opret udlæg',
  'employee.sidebar.actions.salary_reduction': 'Bruttotræk',
  'employee.sidebar.actions.travel_allowance': 'Opret diæt',
  'employee.sidebar.contract_switcher.active': '{from} - {to} (gældende)',
  'employee.sidebar.contract_switcher.other': '{from} - {to}',
  'employee.sidebar.contract_switcher.title': 'Vælg kontrakt',
  'employee.sidebar.current_pay_slip.download': 'Hent',
  'employee.sidebar.current_pay_slip.title': 'Igangværende lønseddel',
  'employee.sidebar.details.address': 'Adresse',
  'employee.sidebar.details.city': 'By',
  'employee.sidebar.details.department': 'Afdeling',
  'employee.sidebar.details.department.none': 'Ingen',
  'employee.sidebar.details.email': 'E-mail',
  'employee.sidebar.details.emergency_contact': 'Pårørende',
  'employee.sidebar.details.employee_number': 'Medarbejdernummer',
  'employee.sidebar.details.phone_number': 'Telefon',
  'employee.sidebar.details.position': 'Stillingsbetegnelse',
  'employee.sidebar.employee_app.feature_lock': 'Opgradér til Basic, for at benytte medarbejder-app',
  'employee.sidebar.employee_app.invite': 'Invitér til medarbejder-app',
  'employee.sidebar.employee_app.invite_sent': 'Invitation til medarbejderappen blev sendt {at}.',
  'employee.sidebar.employee_app.loading': 'Indlæser app-information...',
  'employee.sidebar.employee_app.lock': 'Indtast e-mail-adresse for at invitere til medarbejder-app',
  'employee.sidebar.employee_app.remove_access': 'Fjern adgang til medarbejder-app',
  'employee.sidebar.employee_app.resend_invite': 'Gensend invitation',
  'employee.sidebar.employee_app.title': 'Medarbejder-app',
  'employee.sidebar.employee_invite.success': "Invitation til medarbejder-app'en blev sendt",
  'employee.sidebar.employee_switcher.title': 'Gå til medarbejder',
  'employee.sidebar.loading': 'Indlæser medarbejder...',
  'employee.sidebar.pay_slips': 'Lønsedler',
  'employee.sidebar.upload_profile_image.create': 'Upload profilbillede',
  'employee.sidebar.upload_profile_image.edit': 'Skift profilbillede',
  'employee.sidebar.upload_profile_image.uploading': 'Indlæser profilbillede...',

  'employees_excel_import.step_four.all_imported': 'Alle medarbejdere importeret.',
  'employees_excel_import.step_four.button.done': 'Færdig',
  'employees_excel_import.step_four.table.header.name': 'Medarbejder',
  'employees_excel_import.step_four.table.header.national_id': 'CPR-nummer',
  'employees_excel_import.step_four.table.header.state': 'Ændringsstatus',
  'employees_excel_import.step_four.table.header.state_message': 'Beskrivelse',
  'employees_excel_import.step_one.button.download': 'Hent Excel',
  'employees_excel_import.step_one.button.skip': 'Fortsæt til upload',
  'employees_excel_import.step_one.intro':
    'Hent Excel-filen med en skabelon for medarbejdere via knappen herunder. Hvis du allerede har hentet og udfyldt Excel-filen, kan du fortsætte direkte til upload af filen.',
  'employees_excel_import.step_three.button.back': 'Tilbage',
  'employees_excel_import.step_three.button.save': 'Opret medarbejdere',
  'employees_excel_import.step_three.importing': 'Gemmer...',
  'employees_excel_import.step_three.no_changes': 'Der var ingen ændringer',
  'employees_excel_import.step_three.table.employment_format.date': 'Dato: {date}',
  'employees_excel_import.step_three.table.employment_format.hourly': '{position} ({pay_type} til {amount}/time)',
  'employees_excel_import.step_three.table.employment_format.non_hourly': '{position} ({pay_type} til {amount})',
  'employees_excel_import.step_three.table.header.account': 'Kontooplysninger',
  'employees_excel_import.step_three.table.header.contact': 'Kontakt',
  'employees_excel_import.step_three.table.header.employment': 'Ansættelse',
  'employees_excel_import.step_three.table.header.name': 'Medarbejder',
  'employees_excel_import.step_three.table.header.national_id': 'CPR-nummer',
  'employees_excel_import.step_three.table.will_be_updated': 'Medarbejderen opdateres',
  'employees_excel_import.step_two.button.back': 'Tilbage',
  'employees_excel_import.step_two.button.upload': 'Upload Excel',
  'employees_excel_import.step_two.intro': 'Upload den udfyldte Excel-fil med dine medarbejdere via knappen herunder.',
  'employees_excel_import.step_two.loading': 'Indlæser...',
  'employees_excel_import.steps.1': 'Hent Excel',
  'employees_excel_import.steps.2': 'Upload Excel',
  'employees_excel_import.steps.3': 'Bekræft',
  'employees_excel_import.steps.4': 'Resultat',
  'employees_excel_import.title': 'Importer medarbejdere via Excel',

  'employees.awaiting.title': 'Afventer ansættelsesoprettelse',
  'employees.contract_book.error.creation_error':
    'Noget gik galt under oprettelse af ContractBook-kontrakten, prøv igen eller skriv til support.',
  'employees.contract_book.error.no_template':
    'Vi kunne ikke finde ContractBook-skabelonen, tjek din ContractBook-integration eller skriv til support.',
  'employees.contract_book.form.template_id': 'Skabelon',
  'employees.contract_book.form.template_id.placeholder': 'Vælg skabelon',
  'employees.contract_book.form.template_id.submit': 'Gå til ContractBook',
  'employees.contract_book.title': 'Tilføj medarbejder via ContractBook',
  'employees.header.add_by_contract_book': 'Tilføj via ContractBook',
  'employees.header.add_employee': 'Tilføj medarbejder',
  'employees.header.import_employees': 'Importer medarbejdere',
  'employees.header.add_employee_lock': 'Opgradér til Basic, for at oprette flere medarbejdere',
  'employees.header.batch': 'Massehandling',
  'employees.header.employee_templates': 'Medarbejderskabeloner',
  'employees.header.invite_employee': 'Invitér medarbejder',
  'employees.header.show_pay': 'Vis løn',
  'employees.hide': 'Skjul',
  'employees.on_leave.title': 'På orlov',
  'employees.show': 'Vis',
  'employees.table.action.delete': 'Slet',
  'employees.table.action.details': 'Se detaljer',
  'employees.table.action.edit': 'Redigér',
  'employees.table.action.give_leave': 'Giv orlov',
  'employees.table.action.terminate': 'Opsig',
  'employees.table.add_employment': 'Tilføj ansættelse',
  'employees.table.fill_in_information': 'Udfyld information',
  'employees.table.header.employee': 'Medarbejder',
  'employees.table.header.employment': 'Ansættelse',
  'employees.table.header.end_date': 'Opsagt',
  'employees.table.header.gross_pay': 'Løn brutto',
  'employees.table.header.start_date': 'Ansat',
  'employees.table.hourly_pay_format': '{amount}/time',
  'employees.table.pay_hidden': 'Skjult',
  'employees.table.termination_at': 'Opsiges {date}',
  'employees.terminated.title': 'Opsagte',
  'employees.title.employee.many': '{count} medarbejdere',
  'employees.title.employee.one': '{count} medarbejder',
  'employees.title.more_loading': 'flere indlæses...',

  'employment_details.card.actions.create_future_contract': 'Opret fremtidig kontrakt',
  'employment_details.card.actions.delete': 'Slet',
  'employment_details.card.actions.delete_contract': 'Slet kontrakt',
  'employment_details.card.actions.delete_contract.future': 'Slet fremtidig kontrakt',
  'employment_details.card.actions.mark_ready': 'Markér klar til løn',
  'employment_details.card.actions.mark_unready': 'Fjern klarmarkering til løn',
  'employment_details.card.actions.mark_unready.readied_by': 'Markeret klar af {name}.',
  'employment_details.card.actions.rehire': 'Genansæt',
  'employment_details.card.actions.terminate': 'Opsig',
  'employment_details.card.field.department': 'Afdeling',
  'employment_details.card.field.department.none': 'Ikke valgt',
  'employment_details.card.field.employee_dimension': 'Medarbejderdimension',
  'employment_details.card.field.employment_date': 'Ansættelsesdato',
  'employment_details.card.field.employment_position': 'Stilling',
  'employment_details.card.field.family_leave_fund': 'Barsel',
  'employment_details.card.field.income_type': 'Indkomsttype',
  'employment_details.card.field.period.future': 'Fremtidig kontrakt',
  'employment_details.card.field.period.past': 'Historisk kontrakt',
  'employment_details.card.field.period.valid': 'Gældende kontrakt',
  'employment_details.card.field.position': 'Stillingsbetegnelse',
  'employment_details.card.field.production_unit': 'Arbejdssted',
  'employment_details.card.title': 'Ansættelse',
  'employment_details.card.valid_period.end_date': '{from} - {to}',
  'employment_details.card.valid_period.no_end_date': '{from} - nu',
  'employment_details.edit.form.department_id': 'Afdeling',
  'employment_details.edit.form.department_id.none': 'Ingen',
  'employment_details.edit.form.department_id.placeholder': 'Vælg afdeling',
  'employment_details.edit.form.dimension_id': 'Medarbejderdimension',
  'employment_details.edit.form.dimension_id.none': 'Ingen',
  'employment_details.edit.form.dimension_id.placeholder': 'Vælg medarbejderdimension',
  'employment_details.edit.form.dimension_value_id.none': 'Ingen',
  'employment_details.edit.form.dimension_value_id.none_or_new': 'Ingen eller ny',
  'employment_details.edit.form.dimension_value_id.placeholder': 'Vælg værdi til {code}',
  'employment_details.edit.form.employment_start_date': 'Ansættelsesdato',
  'employment_details.edit.form.employment_start_date.not_before_contract':
    'Ansættelsesdato kan ikke være efter kontraktens start',
  'employment_details.edit.form.employment_start_date.required': 'Ansættelsesdato skal vælges',
  'employment_details.edit.form.family_leave_fund': 'Barsel',
  'employment_details.edit.form.family_leave_fund.placeholder': 'Vælg barsel',
  'employment_details.edit.form.future.valid_from': 'Gældende fra',
  'employment_details.edit.form.future.valid_from.note':
    'Man kan ikke ændre {em} på en fremtidig kontrakt, i stedet bør man slette den og oprette en ny med en anden dato.',
  'employment_details.edit.form.future.valid_from.note.em': 'gældende fra',
  'employment_details.edit.form.income_type': 'Indkomsttype',
  'employment_details.edit.form.income_type.lock':
    'Slå flere indkomsttyper til under virksomhed - avanceret, for at ændre dette felt',
  'employment_details.edit.form.income_type.placeholder': 'Vælg indkomsttype',
  'employment_details.edit.form.income_type.required': 'Indkomsttype skal vælges',
  'employment_details.edit.title': 'Rediger ansættelse',
  'employment_details.edit.warning.at_date': 'Ændringen træder i kraft den {date}',
  'employment_details.edit.warning.immediate': 'Ændringen træder i kraft med det samme',

  'employment_pay.card.employment.salaried.full_time': '{title} (fuldtid)',
  'employment_pay.card.employment.salaried.part_time': '{title} (deltid)',
  'employment_pay.card.fields.cycle': 'Udbetaling',
  'employment_pay.card.fields.employment': 'Ansættelse',
  'employment_pay.card.fields.weekly_hours': 'Arbejdstimer',
  'employment_pay.card.fields.weekly_hours.days.many': '{count} dage',
  'employment_pay.card.fields.weekly_hours.days.one': '{count} dag',
  'employment_pay.card.fields.weekly_hours.hours.many': '{count} timer',
  'employment_pay.card.fields.weekly_hours.hours.one': '{count} time',
  'employment_pay.card.fields.weekly_hours.weeks.many': '{count} uger',
  'employment_pay.card.fields.weekly_hours.weeks.one': '{count} uge',
  'employment_pay.card.table.frequency_format': 'kr. {frequency}',
  'employment_pay.card.table.frequency.hourly': 'per time',
  'employment_pay.card.table.frequency.piece': 'per stk',
  'employment_pay.card.table.header.rate': 'Løn',
  'employment_pay.card.table.header.title': 'Beskrivelse',
  'employment_pay.card.table.header.type': 'Løntype',
  'employment_pay.card.table.quantity_format': '{quantity} × {rate} ({total}) {frequency}',
  'employment_pay.card.table.rate_format': '{rate} {frequency}',
  'employment_pay.card.table.rate_format.with_supplement': '(med løntillæg)',
  'employment_pay.card.title': 'Løn',
  'employment_pay.edit.contract_payment.pay_type.description':
    'Fjern alle lønlinjer der ikke er variable løntillæg for at have en provisionslønnet medarbejder',
  'employment_pay.edit.contract_payment.salary_cycle_id.freelancer': 'Honorarudbetaling',
  'employment_pay.edit.contract_payment.salary_cycle_id.placeholder': 'Vælg cyklus',
  'employment_pay.edit.contract_payment.salary_cycle_id.required': 'Cyklus skal vælges',
  'employment_pay.edit.contract_payment.salary_cycle_id.standard': 'Lønudbetaling',
  'employment_pay.edit.contract_period_work_hours.period_work_hours': 'Timer for perioden',
  'employment_pay.edit.contract_period_work_hours.period_work_hours.invalid': 'Timetal er ugyldigt',
  'employment_pay.edit.contract_period_work_hours.period_work_hours.placeholder': 'Antal timer',
  'employment_pay.edit.contract_period_work_hours.period_work_hours.suffix': 'timer per periode',
  'employment_pay.edit.contract_worktime.add_week': 'Tilføj uge',
  'employment_pay.edit.contract_worktime.period_work_hours': 'Indberet andet timetal end beregnet',
  'employment_pay.edit.contract_worktime.period_work_hours_force_simple_method':
    'Brug altid simpel arbejdstimeberegning',
  'employment_pay.edit.contract_worktime.period_work_hours_force_simple_method.help.line_1':
    'For månedslønnede fastlønnede medarbejdere bruges normalt Skats beregningsmetode for at beregne antal arbejdstimer per måned.  Det er ugentlige arbejdstimer × (4+1/3).  Så for 37 ugentlige arbejdstimer (normaltid) er resultatet 160,33 timer, irrelevant om hvor mange timer man faktisk har arbejdet.',
  'employment_pay.edit.contract_worktime.period_work_hours_force_simple_method.help.line_2':
    'For andre fastlønnede, samt dem der kun får løn i en delmængde af en måned, bruges en simplere beregningsmetode, som er faktiske arbejdsdage × ugentlige arbejdstimer / arbejdsdage om ugen.  Så for en normal arbejdsuge med 37 ugentlige arbejdstimer (normaltid) i januar 2025 (hvor der er 22 arbejdsdage) vil resultatet være 162,8 timer.',
  'employment_pay.edit.contract_worktime.period_work_hours_force_simple_method.help.line_3':
    'Normalt bør man altid bruge Skats metode for at beregne arbejdstimer, hvis man er fastlønnet og månedslønnet.  Der kan dog være undtagelser, og med denne indstilling kan man tvinge systemet til altid at bruge den simple beregnemetode, selvom kravene for brugen af Skats metode er opfyldt.',
  'employment_pay.edit.contract_worktime.period_work_hours.invalid': 'Timetal er ugyldigt',
  'employment_pay.edit.contract_worktime.period_work_hours.placeholder': 'Antal timer',
  'employment_pay.edit.contract_worktime.period_work_hours.suffix': 'timer per periode',
  'employment_pay.edit.contract_worktime.remove_week': 'Fjern arbejdsuge',
  'employment_pay.edit.contract_worktime.weekly_hours': 'Arbejdstimer',
  'employment_pay.edit.contract_worktime.weekly_hours.invalid': 'Arbejdstimer er ugyldige',
  'employment_pay.edit.contract_worktime.weekly_hours.required': 'Arbejdstimer skal indtastes',
  'employment_pay.edit.contract_worktime.weekly_hours.suffix': 'timer per uge',
  'employment_pay.edit.contract_worktime.weekly_work_days': 'Arbejdsdage',
  'employment_pay.edit.contract_worktime.weekly_work_days.suffix': 'dage per uge',
  'employment_pay.edit.contract_worktime.work_cycle_anchor_date.description':
    'For at kunne vide præcist hvilke kalenderuger en arbejdscyklusuge dækker over, skal du vælge en dato der optræder i den første af ugerne.  Denne dato kaldes "ankerdatoen".',
  'employment_pay.edit.contract_worktime.work_cycle_anchor_date.required': 'Ankerdato er påkrævet',
  'employment_pay.edit.contract_worktime.work_cycle.week_no': '{number} uge',
  'employment_pay.edit.contract_worktime.work_cycle.which_week':
    'Denne uge vil f.eks. dække over kalenderuge {weekNo} ({start} – {end})',
  'employment_pay.edit.contract_worktime.work_days.note':
    'Ved andet end 5 ugentlige arbejdsdage, kan du vælge præcis hvilke ugedage hvor der arbejdes.',
  'employment_pay.edit.contract_worktime.work_days.note.multiple_weeks':
    'Vælg hvor mange uger, arbejdscyklusen skal dække over, og præcist hvilke ugedage for hver uge.  Når en cyklus slutter, starter den forfra i den rækkefølge vist her.',
  'employment_pay.edit.form.orphans.remove_orphaned_salary_parts': 'Fjern registrerede løndele',
  'employment_pay.edit.form.valid_from.at_date': 'Ændringen træder i kraft den {date}.',
  'employment_pay.edit.form.valid_from.now': 'Ændringen træder i kraft med det samme.',
  'employment_pay.edit.pay.add_row': 'Tilføj lønrække',
  'employment_pay.edit.pay.frequency.hourly': 'per time',
  'employment_pay.edit.pay.frequency.salary': '{cycle}',
  'employment_pay.edit.pay.frequency.supplement': 'per stk {cycle}',
  'employment_pay.edit.pay.frequency.supplement_varied': 'per stk',
  'employment_pay.edit.pay.header.quantity': 'Antal',
  'employment_pay.edit.pay.header.rate': 'Løn',
  'employment_pay.edit.pay.header.type': 'Løntype',
  'employment_pay.edit.pay.quantity': 'Antal',
  'employment_pay.edit.pay.quantity.invalid': 'Antal er ugyldigt',
  'employment_pay.edit.pay.rate': 'Løn',
  'employment_pay.edit.pay.rate.required': 'Løn skal indtastes',
  'employment_pay.edit.pay.rate.salaried_must_be_more_than_0': 'Grundløn skal være mere end 0',
  'employment_pay.edit.pay.rate.suffix': 'kr. {frequency}',
  'employment_pay.edit.pay.row_move_down': 'Ryk ned',
  'employment_pay.edit.pay.row_move_up': 'Ryk op',
  'employment_pay.edit.pay.salary_0_note':
    'Grundløn kan ikke sættes til 0 kr.; giv medarbejderen orlov i stedet, hvis hensigten er ikke at udbetale løn.',
  'employment_pay.edit.pay.salary_type_id.placeholder': 'Vælg type',
  'employment_pay.edit.pay.salary_type_id.required': 'Type skal vælges',
  'employment_pay.edit.pay.title': 'Beskrivelse',
  'employment_pay.edit.title': 'Redigér løn',

  'employment_vacation.card.edit.title': 'Redigér ferie',
  'employment_vacation.card.edit.warning.at_date': 'Ændringen træder i kraft den {date}',
  'employment_vacation.card.edit.warning.immediate': 'Ændringen træder i kraft med det samme',
  'employment_vacation.card.extra_vacation_day.format.annual': 'årlig',
  'employment_vacation.card.extra_vacation_day.format.monthly': 'månedlig',
  'employment_vacation.card.extra_vacation_day.format.no_suffix': '{days}',
  'employment_vacation.card.extra_vacation_day.format.with_suffix': '{days} ({suffix})',
  'employment_vacation.card.no_vacation_note': 'Denne medarbejder har direktørløn.',
  'employment_vacation.card.personal_days.format.annual': 'årlig',
  'employment_vacation.card.personal_days.format.monthly': 'månedlig',
  'employment_vacation.card.personal_days.format.no_suffix': '{days}',
  'employment_vacation.card.personal_days.format.with_suffix': '{days} ({suffix})',
  'employment_vacation.card.table.header.extra_vacation_days': 'Feriefridage',
  'employment_vacation.card.table.header.personal_days': 'Omsorgsdage',
  'employment_vacation.card.table.header.vacation_amount.paid': 'Ferietillæg',
  'employment_vacation.card.table.header.vacation_amount.unpaid': 'Feriepenge',
  'employment_vacation.card.table.header.vacation_days': 'Feriedage',
  'employment_vacation.card.title.none': 'Ferie',
  'employment_vacation.card.title.with_pay': 'Ferie med løn',
  'employment_vacation.card.title.without_pay': 'Ferie uden løn',
  'employment_vacation.card.vacation_amount': '{amount}%',

  'employment.alert.employee_ready': '{name} er nu klar til løn.',
  'employment.alert.employee_unready': '{name} er ikke længere klar til løn.',
  'employment.alert.success': '{name}s ansættelse er gemt',
  'employment.contract_warning.future': 'Dette er en fremtidig kontrakt, som først træder i kraft fra {date}.',
  'employment.contract_warning.has_future': 'Denne medarbejder har mindst én fremtidig kontrakt efter sin nuværende.',
  'employment.contract_warning.immutable.has_mutable': 'Dette er en historisk kontrakt, som ikke kan ændres, {link}.',
  'employment.contract_warning.immutable.has_mutable.link': 'klik her for den nuværende kontrakt',
  'employment.contract_warning.immutable.no_mutable': 'Dette er en historisk kontrakt, som ikke kan ændres.',
  'employment.no_atp_warning': 'Medarbejderen har {em} ATP.',
  'employment.no_atp_warning.button': 'Klik her for at give medarbejderen ATP',
  'employment.no_atp_warning.em': 'ikke',

  'error.base.bad_gateway':
    'Det eksterne system svarede ikke korrekt. Prøv eventuelt igen, og hvis fejlen bliver ved, så skriv til supporten.',
  'error.base.company_already_exists': 'Virksomheden eksisterer allerede.',
  'error.base.company_feature_missing':
    'Du har ikke adgang til denne funktion. Gå til Virksomhed > Prispakke for at skifte pakke.',
  'error.base.email_already_exists': 'E-mailadressen eksisterer allerede.',
  'error.base.gateway_timeout':
    'Det eksterne system svarede ikke tids nok. Prøv eventuelt igen, og hvis fejlen bliver ved, så skriv til supporten.',
  'error.base.internal_server_error': 'Der opstod en uventet fejl. Vi arbejder på sagen.',
  'error.base.pricing_package_locked': 'Prispakken kan ikke tilføjes/fjernes.',
  'error.base.request_too_large': 'Filen er for stor.',
  'error.base.unknown':
    'Der opstod en uventet fejl. Prøv igen, hvis det stadig fejler, så skriv til supporten. Så kigger vi på det.',
  'error.base.wrong_password': 'Adgangskoden er ikke korrekt.',
  'error.details.contract_update.orphaned_salary_parts': 'Kontraktopdateringen vil fjerne registrerede løndele',
  'error.details.time_registration_invalid.contract_does_not_allow_type':
    'Medarbejderen har ikke tidsregistreringens løntype på sin ansættelse.',
  'error.details.time_registration_invalid.dk_old_vacation_before_20200831':
    'Du kan ikke registrere ferie i september før den nye ferielov er trådt i kraft 1/9 2020.',
  'error.details.time_registration_invalid.no_leave_on_vacation_day':
    'Man kan ikke registrere fravær uden for arbejdsugen.',
  'error.details.time_registration_invalid.no_leave_on_vacation_day.not_use_adjusted_work_week_leave_rate':
    'Man kan ikke registrere fravær uden for den almindelige arbejdsuge.  Registrér kun fravær mandag til fredag.  Hvis du gerne ville kunne registrere efter den faktiske arbejdsuge, skal du slå {link} til.',
  'error.details.time_registration_invalid.no_leave_on_vacation_day.not_use_adjusted_work_week_leave_rate.link':
    'automatisk justering af fraværsværdi for arbejdsuge',
  'error.details.time_registration_invalid.other': 'Tidsregistreringen er ugyldig.',
  'error.invalid_employee_data': 'Medarbejderdata er ikke gyldige. Gå tilbage for at rette eller skriv til supporten.',
  'error.invalid_employee_data.account_number_at_most_10_digits': 'Kontonummeret må maksimalt være 10 tegn.',
  'error.invalid_employee_data.address_is_missing': 'Adresse skal udfyldes.',
  'error.invalid_employee_data.bad_formatted_email': 'Medarbejderens mailadresse er ugyldig.',
  'error.invalid_employee_data.city_is_missing': 'By skal udfyldes.',
  'error.invalid_employee_data.dk_foreign_residents_must_have_birth_date':
    'Fødselsdato skal angives for medarbejdere i udlandet.',
  'error.invalid_employee_data.dk_foreign_residents_must_have_gender': 'Køn skal vælges for medarbejdere i udlandet.',
  'error.invalid_employee_data.dk_foreign_residents_no_address_in_dk':
    'Medarbejdere i udlandet må ikke have adresse i Danmark.',
  'error.invalid_employee_data.dk_without_cpr_must_have_birth_date':
    'Fødselsdato skal angives for medarbejdere uden CPR-nummer.',
  'error.invalid_employee_data.dk_without_cpr_must_have_gender': 'Køn skal vælges for medarbejdere uden CPR-nummer.',
  'error.invalid_employee_data.dk_without_cpr_no_address_in_dk':
    'Medarbejdere uden CPR-nummer må ikke have adresse i Danmark.',
  'error.invalid_employee_data.dk_without_cpr_no_national_id':
    'Medarbejdere uden CPR-nummer kan ikke have et CPR- eller CVR-nummer.',
  'error.invalid_employee_data.missing_country': 'Land mangler at blive udfyldt.',
  'error.invalid_employee_data.name_is_missing': 'Navn skal udfyldes.',
  'error.invalid_employee_data.national_id_must_be_10_digits': 'CPR-nummer skal være 10 tegn.',
  'error.invalid_employee_data.national_id_must_be_8_digits': 'CVR-nummer skal være 8 tegn.',
  'error.invalid_employee_data.nemkonto_need_cpr': 'NemKonto kræver et CPR-nummer.',
  'error.invalid_employee_data.nemkonto_need_cvr': 'NemKonto kræver et CVR-nummer.',
  'error.invalid_employee_data.nemkonto_transfers_disabled':
    'NemKonto-overførsler er ikke slået til for denne virksomhed.',
  'error.invalid_employee_data.only_freelancers_can_have_cvr': 'Kun freenlancere må bruger CVR-nummer.',
  'error.invalid_employee_data.postal_code_is_missing': 'Postnummer skal udfyldes.',
  'error.invalid_employee_data.registration_number_at_most_4_digits': 'Registeringsnummeret må maksimalt være 4 tegn.',
  'error.message.access_token_not_privileged': 'Du har ikke adgang til denne funktion. Kontakt din administrator.',
  'error.message.account_mapping_not_permitted_for_this_accounting_integration':
    'Det er ikke tilladt at ændre i bogføringsmapningen for din regnskabsintegration.',
  'error.message.accounting_api_auth_invalid':
    'Din regnskabsintegration-forbindelse blev afvist, det kan f.eks. være fordi du har skiftet adgangskode. Opsæt integrationen igen.',
  'error.message.accounting_api_key_invalid': 'API-nøglen er ikke gyldig.',
  'error.message.accounting_integration_mapped_account_missing':
    'Kontonummeret {accountNumber} kunne ikke findes i kontoplanen, tjek at kontoplanen ikke har nogle ophørte konti.',
  'error.message.accounting_integration_missing_account_mapping':
    'En eller flere konti i Salary mangler at blive tilknyttet en konto i dit bogføringssystem',
  'error.message.accounting_integration_partial_draft':
    'Bogføringen blev kun delvist overført til kassekladden, du bør rydde op i din kassekladde før du forsøger at bogføre igen.',
  'error.message.accounting_integration_re_auth_setup_mismatch':
    'Det var ikke muligt at lave en simpel genetablisering af din integration.  Du bør i stedet bruge "skift regnskabsintegration" i stedet, og starte helt forfra.',
  'error.message.accounting_integration_request_failed_try_again':
    'Der var problemer med bogføringsintegrationen, prøv igen.',
  'error.message.accounting_integration_setup_not_found': 'Regnskabsintegrationen kunne ikke findes.',
  'error.message.an_account_in_e_conomic_used_by_salary_is_barred':
    'Kontoen {accountNumber} er låst i e-conomic, så der kan ikke bogføres på den.',
  'error.message.an_account_in_e_conomic_used_by_salary_is_barred_multiple':
    'Kontiene {accountNumbers} er låst i e-conomic, så der kan ikke bogføres på dem.',
  'error.message.asynchronous_task_staged_data_already_used':
    'Disse data er allerede blevet brugt til at gemme med, indlæs en ny portion data og prøv igen',
  'error.message.car_allowance_immutable':
    'Du kan ikke rette i registreringen, sikkert på grund af en godkendt løn. Prøv at genåbne lønnen og prøv igen, ellers skriv til supporten.',
  'error.message.car_allowance_invalid_method': 'En kørselsgodtgørelse findes allerede.',
  'error.message.coarse_car_allowance_already_exists':
    'Der er allerede en simpel kørselsregistrering. Skriv til supporten for at få den slettet.',
  'error.message.coarse_car_allowance_immutable':
    'Du kan ikke rette i registreringen, sikkert på grund af en godkendt løn. Prøv at genåbne lønnen og prøv igen, ellers skriv til supporten.',
  'error.message.coarse_time_registration_already_exists':
    'Der er allerede en simpel tidsregistrering. Skriv til supporten for at få den slettet.',
  'error.message.coarse_time_registration_immutable':
    'Tidsregistreringen kan ikke ændres i øjeblikket, hvilket normalt skyldes en godkendt løn. Hvis du vil foretage ændringen i denne periode skal lønnen åbnes igen.',
  'error.message.coarse_time_registration_more_than_one_contract_in_period':
    'Medarbejderen har to kontrakter i denne periode, så kan man ikke lave simple tidsregistrering for perioden.',
  'error.message.coarse_time_registration_period_is_approved':
    'Tidsregistreringen kan ikke ændres i øjeblikket, hvilket normalt skyldes en godkendt løn. Hvis du vil foretage ændringen i denne periode skal lønnen åbnes igen.',
  'error.message.company_accounting_integration_not_found': 'Integrationen kunne ikke findes.',
  'error.message.company_feature_missing':
    'Du har ikke adgang til denne funktion. Gå til Virksomhed > Prispakke for at skifte pakke.',
  'error.message.company_feature_not_enabled': 'Virksomheden har ikke adgang til denne funktionalitet.',
  'error.message.company_has_future_contracts':
    'Virksomheden har eksisterende fremtidige kontrakter, som bør slettes før du kan slå fremtidige kontrakter fra.',
  'error.message.company_is_frozen':
    'Virksomheden er ikke længere aktiv hos Salary. Kontakt support for at aktivere virksomheden igen.',
  'error.message.company_logo_format_invalid': 'Filen er ikke i et gyldigt format.',
  'error.message.company_logo_size_invalid': 'Filen er for stor, billedet må max være 60 KB.',
  'error.message.company_not_found': 'Virksomheden kunne ikke findes.',
  'error.message.company_payment_transfer_setting_not_allowed': 'Der er valgt en ugyldig betalingsindstilling.',
  'error.message.concurrency_issue_detected':
    'Siden var ikke opdateret korrekt - genindlæs siden. Hvis fejlen stadig forekommer skriv til supporten.',
  'error.message.contractbook_api_error':
    'Vi fik en ukendt fejl fra ContractBook. Prøv igen, hvis det stadig fejler, så skriv til supporten.',
  'error.message.contractbook_internal_api_error':
    'Vi fik en intern fejl fra ContractBook. Prøv igen, hvis det stadig fejler, så skriv til supporten.',
  'error.message.contractbook_invalid_api_key':
    'API-nøglen til ContractBook er ikke gyldig. Gå til Integrationer > ContractBook for at se indstillinger.',
  'error.message.data_provider_api_invalid_key': 'Dataudbydernøgle er ugyldig.',
  'error.message.department_not_found': 'Afdelingen kunne ikke findes.',
  'error.message.departmental_distribution_in_e_conomic_used_by_salary_is_barred':
    'Fordelingsnøglen {departmentalDistribution} er låst i e-conomic, så der kan ikke bogføres på den.',
  'error.message.document_unsupported_file_format': 'Ugyldig filtype.',
  'error.message.email_not_found': 'E-mail ikke fundet',
  'error.message.email_token_not_found': 'E-mail-link er ikke længere gyldigt.',
  'error.message.employee_batch_data_invalid':
    'Nogle af af massehandlingsparametrene er ugyldige; gældende fra skal f.eks. altid være efter nu.',
  'error.message.employee_cap_exceeded':
    'Du har nået det maksimale antal medarbejdere på gratis-pakken. Gå til Virksomhed > Prispakke for at skifte til Basic-pakken.',
  'error.message.employee_contract_immutable':
    'Ansættelsen kan ikke ændres i øjeblikket, hvilket normalt skyldes en godkendt løn. Hvis du vil rette ansættelsen i denne periode skal lønnen åbnes igen. Ellers kan ansættelsen rettes i næste periode.',
  'error.message.employee_contract_invalid': 'Ansættelsen er ikke gyldig.',
  'error.message.employee_contract_not_found': 'Ansættelsen kunne ikke findes.',
  'error.message.employee_contract_salary_period_overlap':
    'Kontrakter med samme løncyklus må ikke være gyldig i samme lønperiod.  Hvis du ikke har rettet i datoer, så kontakt support.',
  'error.message.employee_data_too_recent':
    'Medarbejderdata i Salary er blevet opdateret efter disse data var indhentet',
  'error.message.employee_employed_in_company': 'Medarbejder eksisterer allerede.',
  'error.message.employee_image_format_invalid': 'Filtypen for medarbejderens billede er ikke gyldig.',
  'error.message.employee_immutable': 'Medarbejderen kan ikke ændres.',
  'error.message.employee_is_director': 'Direktørlønnede kan ikke få feriepenge.',
  'error.message.employee_leave_invalid': 'Orlovsperioden er ikke gyldig.',
  'error.message.employee_not_found': 'Medarbejderen kunne ikke findes.',
  'error.message.employee_time_registration_already_exists': 'Der findes allerede en registrering for denne periode.',
  'error.message.employee_time_registration_excess_hour_per_day': 'Du kan ikke registrere mere end 24 timer pr. dag.',
  'error.message.employee_time_registration_immutable':
    'Tidsregistreringen kan ikke ændres i øjeblikket, hvilket normalt skyldes en godkendt løn. Hvis du vil foretage ændringen i denne periode skal lønnen åbnes igen.',
  'error.message.employee_time_registration_invalid_method':
    'Der er lavet en tidsregistering allerede, skriv til supporten hvis den skal rettes.',
  'error.message.employment_already_terminated': 'Medarbejderen er allerede opsagt.',
  'error.message.employment_invalid': 'Ansættelsen er ikke gyldig.',
  'error.message.employment_not_found': 'Ansættelsen kunne ikke findes.',
  'error.message.employment_number_invalid': 'Medarbejdernummer er ugyldigt.',
  'error.message.employment_number_is_not_unique': 'Medarbejdernummer er allerede i brug.',
  'error.message.employment_terminated_wrong_leave_type':
    'Der kan ikke tilføjes ferie med løn til en opsagt medarbejder.',
  'error.message.grandhood_api_user_already_exists':
    'Din e-mailadresse er allerede tilknyttet en bruger hos Grandhood, du bør selv kontakte Grandhood og tilføje din virksomhed.',
  'error.message.import_could_not_log_in': 'Brugernavn eller adgangskode er ikke korrekt.',
  'error.message.import_failed': 'Importen kunne ikke gennemføres.',
  'error.message.incorrect_format_for_staged_document':
    'Formatet passer ikke til den type import du har valgt, sørg for at formatet er korrekt.',
  'error.message.invalid_family_fund_for_company': 'Den valgte barselsordning kan ikke vælges for denne virksomhed.',
  'error.message.invalid_national_id': 'CPR-nummer er ikke gyldigt. Gå tilbage for at rette.',
  'error.message.invalid_transfer_destination': 'Medarbejderen har ingen modtagerkonto.',
  'error.message.leave_type_not_found': 'Ukendt fraværstype, prøv at vælg en anden eller skriv til supporten.',
  'error.message.login_throttled': 'Du har forsøgt at logge ind for mange i træk.  Vent.',
  'error.message.login_throttled.details':
    'Du har forsøgt at logge ind for mange i træk.  Vent til kl. {time} og prøv igen.',
  'error.message.mfa_wrong_response': 'Forkert kode, prøv igen og vær sikker på at koden ikke er udløbet.',
  'error.message.no_accounting_year_in_dinero_accounting_integration':
    'Der var intet gyldigt bogføringsår for bogføringsdatoen',
  'error.message.no_endpoint_for_inventio_accounting_integration':
    'Din Inventio-opsætning er ikke indstillet korrekt. Kontakt Inventio og sørg for at de kald Salary bruger har den samme nøgle, samt at dit SMARTalias er korrekt.',
  'error.message.no_environment_found_for_business_central_accounting_integration':
    'Vi kunne ikke finde produktionsmiljøet for din Business Central-konto.',
  'error.message.one_time_pay_immutable':
    'Det er ikke længere muligt at rette denne, hvilket kan forklares med en godkendt løn.',
  'error.message.password_must_be_at_least_8_characters': 'Adgangskoden skal være mindst 8 tegn',
  'error.message.password_must_not_be_listed_in_public_data_breach':
    'Adgangskoden findes i et offentligt læk af logindoplysninger',
  'error.message.payroll_approve_code_incorrect': 'Godkendelseskode er forkert',
  'error.message.payroll_approve_code_required': 'Godkendelseskode er påkrævet',
  'error.message.pension_definition_invalid': 'Pensionen er ikke gyldig.',
  'error.message.pension_definition_invalid_destination': 'Ukendt pensionsselskab, skriv til supporten for hjælp.',
  'error.message.pension_definition_invalid_union_agreement_number': 'Overenskomstkode må maksimalt være 5 cifre.',
  'error.message.period_in_e_conomic_used_by_salary_is_barred':
    'Perioden er låst i e-conomic, så der kan ikke bogføres på den.',
  'error.message.phone_number_invalid': 'Telefonnummer er ikke gyldigt.',
  'error.message.phone_number_is_used_for_mfa':
    'Telefonummeret bruges til tofaktorgodkendelse. For at kunne ændre det, skal du slå tofaktorgodkendelse fra først.',
  'error.message.pricing_package_campaign_code_invalid': 'Ugyldig kampagnekode.',
  'error.message.pricing_package_not_found': 'Prispakken kunne ikke findes.',
  'error.message.pro_feature_missing_for_dinero_accounting_integration':
    'Du skal have Dinero Pro for at kunne integrere med Dinero.',
  'error.message.production_unit_already_exists': 'Produktionsenheden eksisterer allerede.',
  'error.message.reimbursement_voucher_already_approved':
    'Du kan ikke redigere et godkendt udlæg. Fjern godkendelsen for at redigere det.',
  'error.message.reimbursement_voucher_has_no_disburse_amount':
    'Udlægget kan ikke godkendes, når dets beløb er på 0 kr.',
  'error.message.reimbursement_voucher_has_no_expense_category':
    'Et udlæg skal have en udlægskategori før den kan godkendes.',
  'error.message.reimbursement_voucher_unsupported_file_format': 'Filtypen er ikke tilladt.',
  'error.message.salary_definition_invalid':
    'Der mangler noget i din indtastning, prøv at udfyld alle felter ellers skriv til supporten.',
  'error.message.salary_definition_invalid.hourly_twice':
    'Man kan ikke have den samme timeløn flere end én gang på en kontrakt, vælg en anden timeløn for de andre linjer eller opret nye under virksomhed.',
  'error.message.salary_definition_invalid.supplement_varied_twice':
    'Man kan ikke have det samme variable løntillæg flere end én gang på en kontrakt, vælg et andet variabelt løntillæg for de andre linjer eller opret nye under virksomhed.',
  'error.message.salary_definition_not_found':
    'Der mangler noget i din indtastning, prøv at udfyld alle felter ellers skriv til supporten.',
  'error.message.salary_period_invalid':
    'Vi prøver at gemme på den forkerte lønperiode, skriv til supporten for hjælp.',
  'error.message.salary_type_is_in_use':
    'Satsen kan ikke ændres. Hvis du vil foretage ændringer, skal du oprette en ny sats, som du herefter opdaterer på dine medarbejdere.',
  'error.message.shipment_transfer_row_is_immutable': 'Vælg en gyldig dato i fremtiden.',
  'error.message.smart_api_not_active_for_inventio_accounting_integration':
    'For at kunne bruge Salarys Inventio-integration, skal du have aktiveret SMARTapi i Inventio.',
  'error.message.staged_data_incompatible': 'Disse data er gamle, og kan ikke bruges, hent data igen',
  'error.message.support_may_not_perform_this_action': 'Support må ikke foretage denne handling.',
  'error.message.tax_card_request_not_found': 'En skattekortforspørgelse kunne ikke findes.',
  'error.message.time_registration_api_invalid_key': 'API-nøgle til tidsregistreringsintegration er ugyldig.',
  'error.message.transaction_immutable': 'Beregninger er blevet indberettet, og kan derfor ikke slettes.',
  'error.message.unauthenticated_for_invalid_credentials':
    'Du kunne ikke logges ud, muligvis fordi du allerede er logget ud.',
  'error.message.unauthorized': 'Du har ikke adgang til denne funktion.',
  'error.message.user_data_invalid': 'Brugerdata er ikke gyldige. Ret og prøv igen, eller skriv til supporten.',
  'error.message.user_not_found': 'Brugeren kunne ikke findes.',
  'error.message.workflow_cannot_be_cancelled':
    'Ændringen kan ikke foretages i øjeblikket, hvilket normalt skyldes en godkendt løn. Hvis du vil foretage ændringen i denne periode skal lønnen åbnes igen.',
  'error.message.workflow_not_found':
    'Der er ikke nogen åben lønkørsel for perioden, kontakt support for at få lavet en lønkørsel.',
  'error.message.workflow_task_not_in_approval_state':
    'Der opstod en fejl fordi lønnen var ved at blive genberegnet. Prøv at opdater siden og godkend igen.',

  'expense_category.edit.form.accounting_account_id': 'Bogføringskonto',
  'expense_category.edit.form.accounting_account_id.no_account': 'Ingen konto',
  'expense_category.edit.form.accounting_account_id.no_longer_available.generic': 'Kontoen er ikke længere tilgængelig',
  'expense_category.edit.form.accounting_account_id.no_longer_available.select_note': '(ikke længere tilgængelig)',
  'expense_category.edit.form.accounting_account_id.no_longer_available.specific':
    'Kontoen {account} er ikke længere tilgængelig',
  'expense_category.edit.form.name': 'Beskrivelse',
  'expense_category.edit.form.name.required': 'Beskrivelse skal indtastes',
  'expense_category.edit.form.order': 'Rækkefølge',
  'expense_category.edit.form.order.invalid': 'Rækkefølge er ugyldigt',
  'expense_category.edit.form.order.required': 'Rækkefølge skal indtastes',
  'expense_category.edit.title.create': 'Opret udlægskategori',
  'expense_category.edit.title.edit': 'Rediger udlægskategori',
  'expense_category.tab.category.table.actions.delete': 'Slet udlægskategori',
  'expense_category.tab.category.table.actions.edit': 'Rediger udlægskategori',
  'expense_category.tab.category.table.header.account': 'Bogføringskonto',
  'expense_category.tab.category.table.header.name': 'Beskrivelse',
  'expense_category.tab.category.table.header.order': 'Rækkefølge',
  'expense_category.tab.category.table.no_account': 'Ingen konto',
  'expense_category.tab.category.title': 'Udlægskategorier',
  'expense_category.tab.employee.description':
    'Når vi bogfører udlæg betalt via firmakort, bliver de modposteret på banken. Her kan du vælge individuelle modkonti for medarbejdere, hvis de har deres egne firmakort.',
  'expense_category.tab.employee.table.actions.edit': 'Redigér firmakortkonto',
  'expense_category.tab.employee.table.header.account': 'Bogføringskonto',
  'expense_category.tab.employee.table.header.name': 'Beskrivelse',
  'expense_category.tab.employee.table.standard_account': 'Standard bankkonto',
  'expense_category.tab.employee.title': 'Firmakortkonto per medarbejder',
  'expense_category.tab.header.add_category': 'Tilføj udlægskategori',
  'expense_category.tab.header.reimbursement_vouchers': 'Udlægsoverblik',

  'external_salary_file_setting.alert.success': 'Ændringer til {name} er gemt',
  'external_salary_file_setting.edit.form.header.custom_title': 'Brugerdefineret titel',
  'external_salary_file_setting.edit.form.header.pay_part': 'Løndel',
  'external_salary_file_setting.edit.form.header.standard_title': 'Standard titel',
  'external_salary_file_setting.edit.form.variable_type_note':
    'Dette er et variabelt felt, hvor % erstattes af et tal.  Det tal vil sat for enden af den titel du angiver.',
  'external_salary_file_setting.header.description':
    'De eksterne løndele fra {name} bruger normalt {name}s standardtitel, men hvis du foretrækker at den bliver gemt med en anden title, kan du ændre den her.',
  'external_salary_file_setting.header.file_import': 'Til filimport',
  'external_salary_file_setting.header.title': 'Indstil titler for {name}s lønfilformat',

  'extra_pension.edit.form.account': 'Kontonr.',
  'extra_pension.edit.form.account.invalid': 'Kontonr. er ugyldigt',
  'extra_pension.edit.form.account.required': 'Kontonr. skal indtastes',
  'extra_pension.edit.form.amount': 'Beløb',
  'extra_pension.edit.form.amount.cannot_be_negative': 'Beløb må ikke være negativt',
  'extra_pension.edit.form.amount.invalid': 'Beløb er ugyldigt',
  'extra_pension.edit.form.amount.required': 'Beløb skal indtastes',
  'extra_pension.edit.form.amount.suffix': 'kr.',
  'extra_pension.edit.form.disposition_date': 'Lønseddelsdato',
  'extra_pension.edit.form.pension_company_id': 'Pensionsselskab',
  'extra_pension.edit.form.pension_company_id.none': 'Intet selskab',
  'extra_pension.edit.form.pension_company_id.required': 'Pensionsselskab skal vælges',
  'extra_pension.edit.form.pension_customer_id.required': '{type} skal indtastes',
  'extra_pension.edit.form.pension_list_id': 'Vælg en eksisterende pensionslinje at udfylde felterne med',
  'extra_pension.edit.form.pension_list_id.intro.many':
    'Medarbejderen har allerede pension. Du kan vælge at forudfylde felterne med en af disse pensionslinjer. Du kan altid rette i felterne efterfølgende.',
  'extra_pension.edit.form.pension_list_id.intro.one':
    'Medarbejderen har allerede pension. Du kan vælge at forudfylde felterne med denne pension. Du kan altid rette i felterne efterfølgende.',
  'extra_pension.edit.form.reporting_base_amount': 'Pensionsgrundlag',
  'extra_pension.edit.form.reporting_base_amount.invalid': 'Pensionsgrundlag er ugyldig',
  'extra_pension.edit.form.reporting_base_amount.max_amount': 'Maks 9.999.999.999,99 kr.',
  'extra_pension.edit.form.reporting_base_amount.note':
    'Pensionsgrundlag og procent bruges udelukkende til indrapportering hos pensionsselskabet, og har ingen indflydelse på beregningen.',
  'extra_pension.edit.form.reporting_base_amount.required': 'Pensionsgrundlag skal indtastes',
  'extra_pension.edit.form.reporting_base_amount.suffix': 'kr.',
  'extra_pension.edit.form.reporting_percentage': 'Procent',
  'extra_pension.edit.form.reporting_percentage.invalid': 'Procent er ugyldig',
  'extra_pension.edit.form.reporting_percentage.max_amount': 'Maks 99,99%',
  'extra_pension.edit.form.reporting_percentage.required': 'Procent skal indtastes',
  'extra_pension.edit.form.reporting_percentage.suffix': '%',
  'extra_pension.edit.form.scheme': 'Betalt af',
  'extra_pension.edit.form.scheme.required': 'Betalt af skal vælges',
  'extra_pension.edit.form.sort_code': 'Reg.nr.',
  'extra_pension.edit.form.sort_code.invalid': 'Reg.nr. er ugyldigt',
  'extra_pension.edit.form.sort_code.required': 'Reg.nr. skal indtastes',
  'extra_pension.edit.form.union_agreement_number': 'Overenskomstnummer',
  'extra_pension.edit.form.union_agreement_number.not_five_characters': 'Overenskomstnummer skal være fem tegn langt',
  'extra_pension.edit.form.union_agreement_number.tooltip': 'Overenskomstnummer er ikke påkrævet',
  'extra_pension.edit.intro':
    'Udover almindelig pensionsoptjening, kan du give dine medarbejdere ekstra pensionsopsparing her.',
  'extra_pension.edit.title.create': 'Opret ekstra pension',
  'extra_pension.edit.title.edit': 'Redigér ekstra pension',
  'extra_pension.edit.title.view': 'Se ekstra pension',
  'extra_pension.tab.header.add_new': 'Tilføj ekstra pension',
  'extra_pension.tab.header.approve_all': 'Godkend alle',
  'extra_pension.tab.header.hide_history': 'Tilbage',
  'extra_pension.tab.header.show_history': 'Vis historik',
  'extra_pension.tab.table.actions.delete': 'Slet ekstra pension',
  'extra_pension.tab.table.actions.edit': 'Redigér ekstra pension',
  'extra_pension.tab.table.actions.view': 'Gennemse ekstra pension',
  'extra_pension.tab.table.approved.approve': 'Godkend',
  'extra_pension.tab.table.approved.false': 'Nej',
  'extra_pension.tab.table.approved.remove': 'Fjern godkendelse',
  'extra_pension.tab.table.approved.true': 'Ja',
  'extra_pension.tab.table.header.amount': 'Beløb',
  'extra_pension.tab.table.header.approved': 'Godkendt',
  'extra_pension.tab.table.header.date': 'Dato',
  'extra_pension.tab.table.header.pension_info': 'Pensionsinfo',
  'extra_pension.tab.table.header.title': 'Tekst',
  'extra_pension.tab.table.no_company': 'Intet selskab',
  'extra_pension.tab.table.pension_info.account': 'Kontonr.',
  'extra_pension.tab.table.pension_info.company_name': 'Selskab',
  'extra_pension.tab.table.pension_info.customer_id': 'Nummer',
  'extra_pension.tab.table.pension_info.scheme': 'Betalt af',
  'extra_pension.tab.table.pension_info.sort_code': 'Reg.nr.',
  'extra_pension.tab.table.saving': 'Gemmer...',
  'extra_pension.tab.title.history': 'Historik for ekstra pension',
  'extra_pension.tab.title.standard': 'Ekstra pension',

  'family_leave.fund.barsel_dk': 'Barsel.dk',
  'family_leave.fund.barsel_dk_partial': 'Delvis Barsel.dk',
  'family_leave.fund.da_barsel': 'DA Barsel',
  'family_leave.fund.other': 'Andet',

  'form.button.save_changes': 'Gem ændringer',
  'form.button.save_setup': 'Gem opsætning',
  'form.field.current_password': 'Nuværende adgangskode',
  'form.field.email': 'E-mail',
  'form.field.name': 'Navn',
  'form.field.new_password': 'Ny adgangskode',
  'form.field.phone': 'Telefon',
  'form.field.phone_number': 'Telefonnummer',
  'form.field.phone_number_country_code': 'Landekode',
  'form.field.repeat_password': 'Gentag adgangskode',
  'form.field.signing_document.validation_code': 'SMS-kode',
  'form.message.your_changes_are_saved': 'Dine ændringer er gemt',
  'form.message.your_password_has_been_updated': 'Din adgangskode er opdateret',
  'form.validation.current_password_is_invalid': 'Nuværende adgangskode er ugyldig',
  'form.validation.current_password_is_required': 'Nuværende adgangskode skal indtastes',
  'form.validation.email_is_invalid': 'E-mail er ugyldig',
  'form.validation.email_is_required': 'E-mail skal indtastes',
  'form.validation.email_is_the_same_as_existing': 'E-mail er identisk med den eksisterende',
  'form.validation.fill_in_fields_correctly': 'Udfyld venligst de påkrævede felter korrekt',
  'form.validation.must_be_a_number': 'Skal være et tal',
  'form.validation.new_password_is_invalid': 'Ny adgangskode er ugyldig',
  'form.validation.new_password_is_required': 'Ny adgangskode skal indtastes',
  'form.validation.new_passwords_doesnt_match': 'De to nye adgangskoder er ikke ens',
  'form.validation.phone_number_country_code_is_invalid': 'Landekode er ugyldigt',
  'form.validation.phone_number_country_code_is_required': 'Landekode er påkrævet',
  'form.validation.phone_number_is_invalid': 'Telefonnummer er ugyldigt',
  'form.validation.phone_number_is_required': 'Telefonnummer er påkrævet',
  'form.validation.repeated_password_is_required': 'Gentagelse af ny adgangskode skal indtastes',
  'form.validation.signing_document.validation_code_is_required': 'SMS-kode er påkrævet',

  'free_text.actions.delete': 'Slet tekst',
  'free_text.actions.edit': 'Redigér tekst',
  'free_text.actions.view': 'Gennemse tekst',
  'free_text.form.disposition_date': 'Lønseddelsdato',
  'free_text.header.approve_all': 'Godkend alle',
  'free_text.header.create.e_income': 'Opret tekst til eIndkomst',
  'free_text.header.create.standard': 'Opret tekst på lønseddel',
  'free_text.header.show_history': 'Vis historik',
  'free_text.header.title.history.e_income': 'Historiske eIndkomsttekster',
  'free_text.header.title.history.standard': 'Historiske lønseddelstekster',
  'free_text.header.title.standard.e_income': 'eIndkomsttekster',
  'free_text.header.title.standard.standard': 'Lønseddelstekster',
  'free_text.header.view_history': 'Tilbage',
  'free_text.intro.e_income':
    'Skriv en tekst til eIndkomst. Teksten kommer også med på den lønseddel, som datoen gælder for.',
  'free_text.intro.standard':
    'Skriv en tekst til lønsedlen. Teksten kommer med på den lønseddel, som datoen gælder for.',
  'free_text.table.approved.approve': 'Godkend',
  'free_text.table.approved.false': 'Nej',
  'free_text.table.approved.remove': 'Fjern godkendelse',
  'free_text.table.approved.true': 'Ja',
  'free_text.table.header.approved': 'Godkendt',
  'free_text.table.header.date': 'Dato',
  'free_text.table.header.title': 'Tekst',
  'free_text.table.saving': 'Gemmer...',
  'free_text.title.e_income.create': 'Opret tekst til eIndkomst',
  'free_text.title.e_income.edit': 'Redigér tekst til eIndkomst',
  'free_text.title.e_income.view': 'Se tekst til eIndkomst',
  'free_text.title.standard.create': 'Opret tekst på lønseddel',
  'free_text.title.standard.edit': 'Redigér tekst på lønseddel',
  'free_text.title.standard.view': 'Se tekst på lønseddel',

  'freelancer.add.alert.success': '{name} er nu oprettet som freelancer',
  'freelancer.add.invite.form.department_id': 'Afdeling',
  'freelancer.add.invite.form.department_id.placeholder': 'Vælg afdeling',
  'freelancer.add.invite.form.email': 'E-mail',
  'freelancer.add.invite.form.email.invalid': 'E-mail er ugyldig',
  'freelancer.add.invite.form.email.required': 'E-mail skal indtastes',
  'freelancer.add.invite.form.employment_start_date': 'Startdato for freelancer',
  'freelancer.add.invite.form.employment_start_date.required': 'Startdato for freelancer skal indtastes',
  'freelancer.add.invite.form.submit': 'Send invitation',
  'freelancer.add.invite.title': 'Invitér freelancer',
  'freelancer.add.step_0.title': 'Trin 1',
  'freelancer.add.step_1.title': 'Trin 2',
  'freelancer.add.step_2.title': 'Trin 3',
  'freelancer.add.steps.0': 'Stamdata',
  'freelancer.add.steps.1': 'Kommunikation',
  'freelancer.add.steps.2': 'Finansielle oplysninger',
  'freelancer.add.steps.help_note':
    'Hvis du har problemer med at oprette en freelancer, kan du altid kontakte {email}. Vi vil med glæde hjælpe dig!',
  'freelancer.add.steps.intro': 'Efter disse 3 trin, har du oprettet en freelancer',
  'freelancer.add.steps.title': 'Tilføj freelancer',
  'freelancer.details.card.convert_to_employee': 'Gør til medarbejder',
  'freelancer.details.card.department': 'Afdeling',
  'freelancer.details.card.department.none': 'Ikke valgt',
  'freelancer.details.card.title': 'Kontrakt',
  'freelancer.details.edit.department_id': 'Afdeling',
  'freelancer.details.edit.department_id.none_or_new': 'Ingen eller ny',
  'freelancer.details.edit.department_id.placeholder': 'Vælg afdeling',
  'freelancer.details.edit.title': 'Redigér kontrakt',
  'freelancer.details.edit.valid_from.at_date': 'Ændringen træder i kraft den {date}.',
  'freelancer.details.edit.valid_from.now': 'Ændringen træder i kraft med det samme.',
  'freelancer.employment.alert.success': '{name}s kontrakt er gemt',
  'freelancer.fee.edit.amount': 'Beløb for honorar',
  'freelancer.fee.edit.amount.placeholder': 'Honorar',
  'freelancer.fee.edit.amount.required': 'Beløb for honorar skal indtastes',
  'freelancer.fee.edit.amount.suffix': 'kr.',
  'freelancer.fee.edit.category': 'Art',
  'freelancer.fee.edit.intro.editing': 'Giv din freelancer et honorar til udbetaling på en fremtidig faktura.',
  'freelancer.fee.edit.intro.not_editing': 'Dette er et historisk honorar og kan ikke redigeres.',
  'freelancer.fee.edit.title.create': 'Opret honorar',
  'freelancer.fee.edit.title.edit': 'Redigér honorar',
  'freelancer.fee.edit.title.view': 'Gennemse honorar',
  'freelancer.fee.edit.type': 'Type',
  'freelancer.fee.edit.type.required': 'Type skal vælges',
  'freelancer.fee.edit.without_payment': 'Kun indberetning',
  'freelancer.fee.header.approve_all': 'Godkend alle',
  'freelancer.fee.header.hide_history': 'Tilbage',
  'freelancer.fee.header.new_fee': 'Opret honorar',
  'freelancer.fee.header.show_history': 'Vis historik',
  'freelancer.fee.header.title': 'Honorar',
  'freelancer.fee.table.actions.delete': 'Slet honorar',
  'freelancer.fee.table.actions.edit': 'Redigér honorar',
  'freelancer.fee.table.actions.view': 'Gennemse honorar',
  'freelancer.fee.table.approved.approve': 'Godkend',
  'freelancer.fee.table.approved.false': 'Nej',
  'freelancer.fee.table.approved.remove': 'Fjern godkendelse',
  'freelancer.fee.table.approved.true': 'Ja',
  'freelancer.fee.table.header.amount': 'Beløb',
  'freelancer.fee.table.header.approved': 'Godkendt',
  'freelancer.fee.table.header.date': 'Dato',
  'freelancer.fee.table.header.title': 'Beskrivelse',
  'freelancer.fee.table.header.with_payment': 'Kun indberetning',
  'freelancer.fee.table.saving': 'Gemmer...',
  'freelancer.no_contract_card.button': 'Tilføj kontrakt',
  'freelancer.no_contract_card.error.no_cycle': 'Kunne ikke finde en løncyklus',
  'freelancer.no_contract_card.error.no_production_unit': 'Ingen produktionsenhed på virksomheden',
  'freelancer.no_contract_card.intro': 'Tilføj en kontrakt for at kunne udbetale løn til freelanceren.',
  'freelancer.no_contract_card.title': 'Denne freelancer har ingen kontrakt',
  'freelancer.pay_invoice.header.download_all': 'Hent alle gennemførte udbetalinger',
  'freelancer.pay_invoice.header.title': 'Udbetalinger',
  'freelancer.pay_invoice.table.completed': 'Ja',
  'freelancer.pay_invoice.table.header.completed': 'Gennemført',
  'freelancer.pay_invoice.table.header.disposition_date': 'Til disposition',
  'freelancer.pay_invoice.table.header.net_pay': 'Udbetaling netto',
  'freelancer.pay_invoice.table.header.period': 'Periode',
  'freelancer.pay.card.cycle': 'Udbetaling',
  'freelancer.pay.card.title': 'Honorar',
  'freelancer.pay.edit.title': 'Redigér honorar',
  'freelancer.pay.edit.valid_from.at_date': 'Ændringen træder i kraft den {date}.',
  'freelancer.pay.edit.valid_from.now': 'Ændringen træder i kraft med det samme.',
  'freelancer.sidebar.actions.delete': 'Slet freelancer',
  'freelancer.sidebar.actions.rehire': 'Genaktivér freelancer',
  'freelancer.sidebar.actions.terminate': 'Skjul freelancer',
  'freelancer.sidebar.employee_switcher.title': 'Gå til freelancer',
  'freelancer.single.alert.deleted': '{name} er blevet slettet',
  'freelancer.single.alert.leave': '{name} har fået orlov',
  'freelancer.single.alert.terminated': '{name} er blevet skjult',
  'freelancer.single.menu.employment': 'Kontrakt',
  'freelancer.single.menu.overview': 'Honorarer',
  'freelancer.single.menu.payments': 'Udbetalinger',
  'freelancer.single.menu.profile': 'Profil',
  'freelancer.single.menu.project_registrations': 'Tidsregistrering',

  'freelancers_overview.header.import_from_file': 'Importér fra fil',
  'freelancers_overview.table.actions.add_fee': 'Tilføj honorar',
  'freelancers_overview.table.actions.details': 'Honoraroversigt',
  'freelancers_overview.table.header.compensation': 'Sammenlagt honorar',
  'freelancers_overview.table.header.freelancer': 'Freelancer',
  'freelancers_overview.title': 'Honorarregistrering',

  'freelancers.awaiting.title': 'Afventer oprettelse',
  'freelancers.header.add_freelancer': 'Tilføj freelancer',
  'freelancers.header.add_freelancer_lock': 'Opgradér til Basic, for at oprette freelancere',
  'freelancers.header.invite_freelancer': 'Invitér freelancer',
  'freelancers.hide': 'Skjul',
  'freelancers.show': 'Vis',
  'freelancers.table.actions.delete': 'Slet',
  'freelancers.table.actions.details': 'Se detaljer',
  'freelancers.table.actions.edit': 'Redigér',
  'freelancers.table.actions.terminate': 'Skjul',
  'freelancers.table.add_contract': 'Tilføj kontrakt',
  'freelancers.table.fill_in_information': 'Udfyld information',
  'freelancers.table.header.freelancer': 'Freelancer',
  'freelancers.table.header.payout_this_year': 'Udbetalt i år til dato',
  'freelancers.terminated.title': 'Skjulte',
  'freelancers.title.freelancer.many': '{count} freelancere',
  'freelancers.title.freelancer.one': '{count} freelancer',
  'freelancers.title.searching_for': 'søger på "{query}"',

  'gender.female': 'Kvinde',
  'gender.male': 'Mand',
  'gender.unknown': 'Ukendt',

  'global_message.company_scheduled_for_deletion': 'Denne virksomhed bliver slettet {deletionScheduledAt}!',
  'global_message.company_scheduled_for_deletion.button': 'Tryk her for at annullere sletningen',

  'global_search.articles': 'Artikler',
  'global_search.employees': 'Medarbejdere',

  'header.account': 'Konto',
  'header.administration': 'HR',
  'header.all_companies': 'Alle virksomheder',
  'header.company': 'Virksomhed',
  'header.dashboard': 'Oversigt',
  'header.employees': 'Medarbejdere',
  'header.freelancers': 'Freelancere',
  'header.global_search': 'Søg',
  'header.integrations': 'Integrationer',
  'header.log_out': 'Log af',
  'header.more_menu_items': 'Flere menu punkter',
  'header.pay': 'Løn',
  'header.registrations': 'Registreringer',
  'header.settings': 'Indstillinger',
  'header.warning.is_payroll_locked':
    'Du kan ikke godkende løn!  Da du har ubetalte fakturaer er din virksomhed låst.  Gå til {link} for at løse problemet.',
  'header.warning.is_payroll_locked.link': 'fakturaoversigten',

  'help_modal.toggle_text': 'Klik for mere information',

  'income_type.denmark.b_income': 'B-indkomst',
  'income_type.denmark.contribution_free': '09 - Skattefri indkomst',
  'income_type.denmark.contribution_free_abroad': '09 - Skattefri indkomst (udland)',
  'income_type.denmark.contribution_free_am': '09 - Skattefri indkomst (med AM-bidrag)',
  'income_type.denmark.research_income': '08 - Forskerordning',
  'income_type.denmark.salary_income': '00 - A-indkomst/ansat',

  'integration_layout.menu.accounting': 'Regnskab',
  'integration_layout.menu.advisor': 'Find rådgiver',
  'integration_layout.menu.codan': 'Forsikring',
  'integration_layout.menu.contract_book': 'ContractBook',
  'integration_layout.menu.data_provider': 'Dataudbyder',
  'integration_layout.menu.loan': 'Lån',
  'integration_layout.menu.min_refusion': 'MinRefusion',
  'integration_layout.menu.pension': 'Pension',
  'integration_layout.menu.personalezonen': 'Personalepleje',
  'integration_layout.menu.rmindr': 'Rmindr',
  'integration_layout.menu.slack': 'Slack',

  'invoice_email.alert.success': 'Ændringer til {name} er gemt',
  'invoice_email.edit.form.intro.line_1':
    'Her kan du indtaste en e-mail-adresse der vil modtage fakturaer betalt gennem Salary.',
  'invoice_email.edit.form.intro.line_2': 'Efterlad blank for at slå fra.',
  'invoice_email.edit.form.invoice_email': 'E-mail på fakturamodtager',
  'invoice_email.edit.form.invoice_email.invalid': 'E-mail på fakturamodtager er ugyldig',
  'invoice_email.edit.title': 'Indstil fakturamodtager',

  'invoices.tab.header.active_card': 'Aktivt kort: {brand} sluttende på {last4} (udløber {expiry})',
  'invoices.tab.header.integration.change': 'Skift betalingskort',
  'invoices.tab.header.integration.create': 'Opret betalingskortaftale',
  'invoices.tab.header.invoice_email': 'Fakturamodtager',
  'invoices.tab.table.header.description': 'Beskrivelse',
  'invoices.tab.table.header.due_date': 'Forfaldsdato',
  'invoices.tab.table.header.invoice_date': 'Dato',
  'invoices.tab.table.header.status': 'Status',
  'invoices.tab.table.header.sub_total': 'Subtotal',
  'invoices.tab.table.header.total': 'Total',
  'invoices.tab.table.status.invoiced': 'Afventer betaling',
  'invoices.tab.table.status.paid': 'Betalt',
  'invoices.tab.table.status.pending': 'Kladde',
  'invoices.tab.table.status.rejected': 'Afvist',
  'invoices.tab.title': 'Fakturaer',
  'invoices.tab.warnings.payroll_locked':
    'Da du har ubetalte fakturaer, kan du ikke godkende løn.  Du kan oprette en betalingskortsaftale for at betale dine ubetalte fakturaer.  Du kan altid skifte tilbage til Overførselsservice for dine fakturaer.',

  'leave_registrations.calendar.approve_all_text.many':
    'Er du sikker på du vil godkende {count} fraværsregistreringer for hele {month}?',
  'leave_registrations.calendar.approve_all_text.one':
    'Er du sikker på du vil godkende {count} fraværsregistrering for hele {month}?',
  'leave_registrations.calendar.context_menu.format.no_note': '{days}',
  'leave_registrations.calendar.context_menu.format.with_note': '{days} ({note})',
  'leave_registrations.calendar.instruction_text':
    'Træk og slip fra et frit felt for at oprette nyt fravær. Tryk på eksisterende fravær for at godkende, redigere eller slette.',
  'leave_registrations.calendar.new_type': 'nyt fravær',
  'leave_registrations.calendar.tooltip_format': '{type} for {name} d. {date}',
  'leave_registrations.header.switch_to_calendar': 'Vis som kalender',
  'leave_registrations.header.switch_to_table': 'Vis som tabel',
  'leave_registrations.table.actions.edit': 'Redigér',
  'leave_registrations.table.actions.register': 'Registrér',
  'leave_registrations.table.all_approved': 'Alt godkendt',
  'leave_registrations.table.approve_leave': 'Godkend fravær',
  'leave_registrations.table.descriptor.borrowed': '{amount} lånt',
  'leave_registrations.table.descriptor.left': '{amount} tilbage',
  'leave_registrations.table.descriptor.used': '{amount} brugt',
  'leave_registrations.table.header.employee': 'Medarbejder',
  'leave_registrations.table.header.has_unapproved': 'Godkend',
  'leave_registrations.table.other_type': 'Andet fravær',
  'leave_registrations.table.unapproved_format': '{name}: {days}',
  'leave_registrations.title': 'Fraværsregistrering',

  'leave_type_setting.alert.success': 'Ændringer til fraværstype {type} er gemt',
  'leave_type_setting.leave_sub_type.table.actions.permit': 'Tillad',
  'leave_type_setting.leave_sub_type.table.actions.restrict': 'Fjern mulighed',
  'leave_type_setting.leave_sub_type.table.can_select.false': 'Nej',
  'leave_type_setting.leave_sub_type.table.can_select.true': 'Ja',
  'leave_type_setting.leave_sub_type.table.header.can_select': 'Kan vælges af medarbejder?',
  'leave_type_setting.leave_sub_type.table.header.configure': 'Indstil',
  'leave_type_setting.leave_sub_type.table.header.name': 'Underfraværstype',
  'leave_type_setting.leave_sub_type.table.header.parent_name': 'Fraværstype',
  'leave_type_setting.leave_type.table.actions.permit': 'Tillad',
  'leave_type_setting.leave_type.table.actions.restrict': 'Fjern mulighed',
  'leave_type_setting.leave_type.table.can_select.false': 'Nej',
  'leave_type_setting.leave_type.table.can_select.true': 'Ja',
  'leave_type_setting.leave_type.table.header.can_select': 'Kan vælges af medarbejder?',
  'leave_type_setting.leave_type.table.header.configure': 'Indstil',
  'leave_type_setting.leave_type.table.header.name': 'Fraværstype',
  'leave_type_setting.tab.header.back': 'Tilbage',
  'leave_type_setting.tab.sub_type_intro':
    'Nogle fraværstyper, har underfraværstyper. Her kan du indstille hvilke underfraværstyper medarbejderne kan vælge i appen.',
  'leave_type_setting.tab.title': 'Fraværstypeindstillinger',
  'leave_type_setting.tab.type_intro': 'Indstil hvilke fraværstyper medarbejderne kan vælge i appen.',

  'leave.sub_type.anniversary': 'Jubilæum',
  'leave.sub_type.child_s_first_sick_day': 'Barns første sygedag',
  'leave.sub_type.child_s_second_sick_day': 'Barns anden sygedag',
  'leave.sub_type.course': 'Kursus',
  'leave.sub_type.covid_19': 'COVID-19',
  'leave.sub_type.mourning_day': 'Sorgedag',
  'leave.sub_type.moving_day': 'Flyttedag',
  'leave.sub_type.paragraph_56': '§ 56-aftale',
  'leave.sub_type.parental_leave': 'Arbejdsgiverbetalt barsel',
  'leave.sub_type.parental_leave_after_2_weeks': 'Forældreorlov',
  'leave.sub_type.parental_leave_first_2_weeks': 'Fædreorlov',
  'leave.sub_type.parental_leave_last_4_weeks_before_term': '4 uger før termin (alm. forløb)',
  'leave.sub_type.parental_leave_sickness': 'Graviditetsbetinget sygdom',
  'leave.sub_type.time_off_in_lieu': 'Afspadsering',
  'leave.tab.balances.assignable.standard': '{used} brugt, {left} tilbage',
  'leave.tab.balances.assignable.with_borrowed': '{used} brugt, {left} tilbage, {borrowed} lånt',
  'leave.tab.balances.assignable.with_future': '{used} brugt ({future} senere), {left} tilbage',
  'leave.tab.balances.assignable.with_future_borrowed':
    '{used} brugt ({future} senere), {left} tilbage, {borrowed} lånt',
  'leave.tab.balances.not_assignable.standard': '{used} brugt',
  'leave.tab.balances.not_assignable.with_future': '{used} brugt ({future} senere)',
  'leave.tab.balances.unknown': 'Ukendt saldo',
  'leave.tab.confirm.bulk_delete': 'Er du sikker på du vil slette alle registreringer? De kan ikke genskabes.',
  'leave.tab.edit.button.approve': 'Godkend fraværsregistrering',
  'leave.tab.edit.button.delete': 'Slet fraværsregistrering',
  'leave.tab.edit.form.created_at': 'Fraværsregistrering oprettet {createdAt}.',
  'leave.tab.edit.form.date': 'Dato',
  'leave.tab.edit.form.date.required': 'Dato skal indtastes',
  'leave.tab.edit.form.days': 'Dage',
  'leave.tab.edit.form.days.above_1_note':
    'Værdier højere end 1 dag bør normalt kun bruges på medarbejdere, hvis arbejdsuge er mindre end 5 dage, for at svare til 5 feriedage per uge. Overvej i stedet at registere fraværet over flere datoer.',
  'leave.tab.edit.form.days.invalid': 'Dage er ugyldig',
  'leave.tab.edit.form.days.max_5': 'Dage må højest være 5',
  'leave.tab.edit.form.days.required': 'Dage skal indtastes',
  'leave.tab.edit.form.leave_duration': 'Varighed',
  'leave.tab.edit.form.leave_duration.required': 'Varighed skal vælges',
  'leave.tab.edit.form.leave_sub_type_id.day_off': 'Fridagstype',
  'leave.tab.edit.form.leave_sub_type_id.none': 'Ingen',
  'leave.tab.edit.form.leave_sub_type_id.other': 'Undertype',
  'leave.tab.edit.form.leave_sub_type_id.sick_day': 'Sygedagstype',
  'leave.tab.edit.form.leave_type_id': 'Type',
  'leave.tab.edit.form.leave_type_id.required': 'Type skal vælges',
  'leave.tab.edit.form.no_rate_adjustment': 'Justér {not} fraværsværdi',
  'leave.tab.edit.form.no_rate_adjustment.help.line_1':
    'Virksomheden justerer automatisk fraværsværdier efter arbejdsugen, dvs. 1 feriedag på en 4-dages-arbejdsuge bliver til 1,25 dage.  Dette sker også for andre værdier end 1.',
  'leave.tab.edit.form.no_rate_adjustment.help.line_2':
    'Vælger du ikke at justere, så gemmes værdien som den er skrevet.',
  'leave.tab.edit.form.no_rate_adjustment.not': 'ikke',
  'leave.tab.edit.form.note': 'Note',
  'leave.tab.edit.form.period': 'Periode',
  'leave.tab.edit.form.period.required': 'Periode skal indtastes',
  'leave.tab.edit.title.create': 'Opret fravær for {name}',
  'leave.tab.edit.title.edit': 'Redigér fravær for {name}',
  'leave.tab.header.add_new': 'Registrér fravær',
  'leave.tab.header.approve_all': 'Godkend alle',
  'leave.tab.header.bulk_delete': 'Slet alle',
  'leave.tab.header.link_to_calendar': 'Gå til virksomhedens fraværskalendar',
  'leave.tab.header.title': 'Ferie og fravær',
  'leave.tab.switch_to_calendar': 'Vis som kalender',
  'leave.tab.switch_to_table': 'Vis som tabel',
  'leave.tab.table.actions.delete': 'Slet registrering',
  'leave.tab.table.actions.edit': 'Redigér registrering',
  'leave.tab.table.approved.approve': 'Godkend',
  'leave.tab.table.approved.false': 'Nej',
  'leave.tab.table.approved.remove': 'Fjern godkendelse',
  'leave.tab.table.approved.true': 'Ja',
  'leave.tab.table.duration.format': '{description} ({extra})',
  'leave.tab.table.header.approved': 'Godkendt',
  'leave.tab.table.header.date': 'Dato',
  'leave.tab.table.header.description': 'Beskrivelse',
  'leave.tab.table.saving': 'Gemmer...',
  'leave.type.denmark_day_off_salaried.many': 'Fridage (arbejdsgiverbetalt)',
  'leave.type.denmark_day_off_salaried.one': 'Fridag (arbejdsgiverbetalt)',
  'leave.type.denmark_day_off.many': 'Fridage',
  'leave.type.denmark_day_off.one': 'Fridag',
  'leave.type.denmark_extra_vacation_accrual.many': 'Feriefridage (månedlig)',
  'leave.type.denmark_extra_vacation_accrual.one': 'Feriefridag (månedlig)',
  'leave.type.denmark_extra_vacation.many': 'Feriefridage',
  'leave.type.denmark_extra_vacation.one': 'Feriefridag',
  'leave.type.denmark_flex_time.many': 'Flekstid',
  'leave.type.denmark_flex_time.one': 'Flekstid',
  'leave.type.denmark_leave_day.many': 'Orlov',
  'leave.type.denmark_leave_day.one': 'Orlov',
  'leave.type.denmark_optional_vacation.many': 'Feriefridage (årlig)',
  'leave.type.denmark_optional_vacation.one': 'Feriefridag (årlig)',
  'leave.type.denmark_overtime.many': 'Afspadsering',
  'leave.type.denmark_overtime.one': 'Afspadsering',
  'leave.type.denmark_personal_day.many': 'Omsorgsdage (årlig)',
  'leave.type.denmark_personal_day.one': 'Omsorgsdag (årlig)',
  'leave.type.denmark_personal_time_accrual.many': 'Omsorgsdage (månedlig)',
  'leave.type.denmark_personal_time_accrual.one': 'Omsorgsdag (månedlig)',
  'leave.type.denmark_personal.many': 'Omsorgsdage',
  'leave.type.denmark_personal.one': 'Omsorgsdag',
  'leave.type.denmark_remote_work.many': 'Hjemmearbejde',
  'leave.type.denmark_remote_work.one': 'Hjemmearbejde',
  'leave.type.denmark_sick_day_paid_salaried.many': 'Sygedage (arbejdsgiverbetalt)',
  'leave.type.denmark_sick_day_paid_salaried.one': 'Sygedag (arbejdsgiverbetalt)',
  'leave.type.denmark_sick_day_paid.many': 'Sygedage',
  'leave.type.denmark_sick_day_paid.one': 'Sygedag',
  'leave.type.denmark_unpaid_day_off.many': 'Ubetalt fravær',
  'leave.type.denmark_unpaid_day_off.one': 'Ubetalt fravær',
  'leave.type.denmark_vacation_accrual.many': 'Feriedage med løn',
  'leave.type.denmark_vacation_accrual.one': 'Feriedag med løn',
  'leave.type.denmark_vacation_fund_with_pension.many': 'Feriedage uden løn',
  'leave.type.denmark_vacation_fund_with_pension.one': 'Feriedag uden løn',
  'leave.type.denmark_vacation_fund.many': 'Feriedage uden løn',
  'leave.type.denmark_vacation_fund.one': 'Feriedag uden løn',
  'leave.type.denmark_vacation_no_pay.many': 'Feriedage uden løn',
  'leave.type.denmark_vacation_no_pay.one': 'Feriedag uden løn',
  'leave.type.denmark_vacation_paid_additional.many': 'Feriedage med løn',
  'leave.type.denmark_vacation_paid_additional.one': 'Feriedag med løn',
  'leave.type.denmark_vacation_paid.many': 'Feriedage med løn',
  'leave.type.denmark_vacation_paid.one': 'Feriedag med løn',
  'leave.type.denmark_vacation.many': 'Feriedage',
  'leave.type.denmark_vacation.one': 'Feriedag',

  'loading.and': '{left} og {right}',
  'loading.comma': '{left}, {right}',
  'loading.loading': 'Indlæser {text}...',
  'loading.reducer.accounting_dimensions': 'bogføringsdimensioner',
  'loading.reducer.asynchronous_tasks': 'asynkrone resultater',
  'loading.reducer.available_accounting_integrations': 'tilgængelige bogføringsintegrationer',
  'loading.reducer.car_allowances': 'detaljerede kørselsgodtgørelser',
  'loading.reducer.coarse_car_allowances': 'simple kørselsgodtgørelser',
  'loading.reducer.coarse_salary_registrations': 'simple lønregistreringer',
  'loading.reducer.coarse_time_registrations': 'simple tidsregistreringer',
  'loading.reducer.company_account_plans': 'kontoplan',
  'loading.reducer.company_accounting_integration': 'bogføringsintegration',
  'loading.reducer.company_payment_integrations': 'betalingsindstillinger',
  'loading.reducer.company_users': 'virksomhedsbrugere',
  'loading.reducer.contract_templates': 'medarbejderskabeloner',
  'loading.reducer.contracts': 'kontrakter',
  'loading.reducer.cost_centers': 'omkostningssteder',
  'loading.reducer.employee_emergency_contacts': 'nødkontakt',
  'loading.reducer.employees': 'medarbejdere',
  'loading.reducer.employment_positions': 'stillinger',
  'loading.reducer.employments': 'ansættelser',
  'loading.reducer.leave_types': 'fraværstyper',
  'loading.reducer.one_time_pays': 'engangsregistreringer',
  'loading.reducer.pay_slips': 'lønsedler',
  'loading.reducer.pension_companies': 'pensionsselskaber',
  'loading.reducer.projects': 'projekter',
  'loading.reducer.reimbursement_vouchers': 'udlæg',
  'loading.reducer.remuneration': 'lønlinjer',
  'loading.reducer.salary_cycles': 'løncykluser',
  'loading.reducer.salary_registrations': 'detaljerede lønregistreringer',
  'loading.reducer.salary_types': 'lønsatser',
  'loading.reducer.swipes': 'swipes',
  'loading.reducer.tax_cards': 'skattekort',
  'loading.reducer.time_registrations': 'detaljerede tidsregistreringer',
  'loading.reducer.transfers': 'overførsler',
  'loading.reducer.user_invites': 'invitationer',
  'loading.saving': 'Gemmer {text}...',

  'login.channel.form.recovery_code': 'Engangskode',
  'login.channel.form.recovery_code.required': 'Engangskode skal indtastes',
  'login.channel.form.response': 'Kode',
  'login.channel.form.response.required': 'Kode skal indtastes',
  'login.channel.form.toggle_recovery_code': 'Benyt engangskode',
  'login.channel.form.trust_device': 'Husk enhed i 30 dage',
  'login.channel.mfa_channel': '{channel} tofaktorgodkendelseskode',
  'login.channel.recovery_code': 'engangskode',
  'login.channel.title': 'Indtast din {desc} herunder.',
  'login.form.create_new_user': 'Opret ny bruger',
  'login.form.email': 'E-mail',
  'login.form.email.required': 'E-mail skal indtastes',
  'login.form.employee_user_note': 'Er du medarbejder? {link}',
  'login.form.employee_user_note.link': 'Gå til medarbejder-siden',
  'login.form.forgot_password': 'Glemt adgangskode?',
  'login.form.password': 'Adgangskode',
  'login.form.password.required': 'Adgangskode skal indtastes',
  'login.form.remember': 'Husk mig',
  'login.form.submit': 'Log ind',
  'login.splash.subtitle': 'Let løn uden hårdt arbejde',
  'login.splash.title': 'Velkommen til Salary',
  'login.title': 'Log ind',

  'modal.confirm.cancel': 'Annullér',
  'modal.confirm.ok': 'OK',

  'modals.basic_upgrade.button': 'Opgradér til Basic',
  'modals.basic_upgrade.new_price_coming': '21 kr./lønsedel fra 1. marts 2024',
  'modals.basic_upgrade.price_per': 'Per lønseddel',
  'modals.basic_upgrade.subtitle': 'Få mere ud af Salary og gør din hverdag endnu lettere',
  'modals.basic_upgrade.title': 'Opgradér til Basic',
  'modals.premium_upgrade.button': 'Opgradér til Premium',
  'modals.premium_upgrade.price_per': 'per lønseddel',
  'modals.premium_upgrade.subtitle': 'Få mere ud af Salary og gør din hverdag endnu lettere',
  'modals.premium_upgrade.title': 'Opgradér til Premium',

  'national_id.invalid': 'CPR-nummer er ugyldigt',
  'national_id.look_up.error': 'Kunne ikke slå CPR-nummeret op',
  'national_id.look_up.hidden_address': 'CPR-nummeret har navne- og adressebeskyttelse',
  'national_id.look_up.not_found': 'CPR-nummeret kunne ikke findes',

  'nets_tab.disposition_date_change_reason.01': 'På foranledning ændret dispositionsdato',
  'nets_tab.disposition_date_change_reason.02': 'Ikke gennemført pga. manglende aftale eller overskridelse af maksimum',
  'nets_tab.disposition_date_change_reason.03': 'Ændret dispositionsdato på grund af tilbageførsel',
  'nets_tab.disposition_date_change_reason.04': 'Ændret dispositionsdato på grund af for sen aflevering/ikke bankdag',
  'nets_tab.error_code.1': 'Ingen NemKonto fundet til den indsendte identifikation (f.eks. CVR-nr, CPR-nr)',
  'nets_tab.error_code.2': 'CPR-nummer forkert opbygget',
  'nets_tab.error_code.3': 'CVR-nummer forkert opbygget',
  'nets_tab.error_code.4': 'SE-nummer forkert opbygget',
  'nets_tab.error_code.5': 'Produktionsstedsnummer forkert opbygget',
  'nets_tab.error_code.6': 'Indsendt SE-nr mangler CVR-nr.',
  'nets_tab.error_code.7':
    'Dispositionsdato for langt fremme i tiden (maksimum er 4 dage efter leverancens modtagelse)',
  'nets_tab.error_code.8': 'Nøgletype i OS6 recordens felt 10A udfyldt forkert',
  'nets_tab.error_code.9': 'Sektionsstart record manglede foran OS6-record',
  'nets_tab.error_code.d': 'Betalingsafsender har ikke gyldig aftale om behandling af NemKontobaserede betalinger',
  'nets_tab.error_code.k': 'Kontonummeret er ikke korrekt',
  'nets_tab.error_code.p': 'PBS-nummeret er ikke korrekt',
  'nets_tab.error_code.r': 'Registreringsnummeret er ikke korrekt',
  'nets_tab.error_code.s': 'Intet svar modtaget fra NemKonto indenfor tidsfrist (5 dage efter dispositionsdato)',
  'nets_tab.error_code.t': 'CPR-nummeret eller kundenummeret er ikke korrekt',
  'nets_tab.info_type.0': 'Standard',
  'nets_tab.info_type.100': 'Pension og forsikring',
  'nets_tab.info_type.150': 'Arbejdsmarkedspension',
  'nets_tab.info_type.200': 'Faglige kontingenter',
  'nets_tab.info_type.300': 'Offentlige betalinger',
  'nets_tab.info_type.400': 'Feriepenge',
  'nets_tab.info_type.800': 'ATP / Barselsfonde',
  'nets_tab.info_type.900': 'A-skat / arbejdsmarkedsbidrag',
  'nets_tab.limit_type.2.text': 'Begrænset',
  'nets_tab.limit_type.2.tool_tip':
    'Betalingsafsenders pengeinstitut har angivet et maksimum beløb for overførsler pr. betalingsdag. Hvis overførslerne overstiger maksimum beløbet, så bliver overførslerne ikke gennemført.',
  'nets_tab.limit_type.3.text': 'Ubegrænset',
  'nets_tab.limit_type.3.tool_tip': 'Betalingsafsenders pengeinstitut kontrollerer beløbet i eget system.',
  'nets_tab.limit_type.4.text': 'Begrænset',
  'nets_tab.limit_type.4.tool_tip':
    'Betalingsafsenders pengeinstitut har angivet et maksimum beløb. Betalingerne bliver gennemført uanset overførslerne overstiger maksimumbeløbet, men pengeinstituttet får meddelelse om overskridelsen.',
  'nets_tab.message.comment.amount': 'Beløb',
  'nets_tab.message.comment.error_code': 'Fejlkode',
  'nets_tab.message.comment.error_text_1': 'Fejloplysning 1',
  'nets_tab.message.comment.error_text_2': 'Fejloplysning 2',
  'nets_tab.message.comment.record_count': 'Antal poster',
  'nets_tab.message.comment.reference': 'Reference',
  'nets_tab.message.comment.retracted_amount': 'Tilbageført beløb',
  'nets_tab.message.comment.transfer_type': 'Overførselsart',
  'nets_tab.message.disposition_changed.account_number': 'Kontonr.',
  'nets_tab.message.disposition_changed.amount': 'Beløb',
  'nets_tab.message.disposition_changed.cvr': 'CVR-nummer',
  'nets_tab.message.disposition_changed.new_disposition_date': 'Ny dispositionsdato',
  'nets_tab.message.disposition_changed.next_bank_day': 'Næste bankdag',
  'nets_tab.message.disposition_changed.original_disposition_date': 'Oprindelig dispositionsdato',
  'nets_tab.message.disposition_changed.reason': 'Listenavn',
  'nets_tab.message.disposition_changed.record_count': 'Antal poster',
  'nets_tab.message.disposition_changed.registration_number': 'Reg.nr.',
  'nets_tab.message.disposition_changed.transaction_type': 'Overførselstype',
  'nets_tab.message.disposition_changed.transfer_type': 'Overførselsart',
  'nets_tab.message.double_delivery.account_number': 'Kontonr.',
  'nets_tab.message.double_delivery.amount': 'Beløb',
  'nets_tab.message.double_delivery.cvr': 'CVR-nummer',
  'nets_tab.message.double_delivery.cvr_supplier': 'CVR-nummer (dataleverandør)',
  'nets_tab.message.double_delivery.delivery_number': 'Leverancenummer',
  'nets_tab.message.double_delivery.disposition_date': 'Dispositionsdato',
  'nets_tab.message.double_delivery.record_count': 'Antal poster',
  'nets_tab.message.double_delivery.registration_number': 'Reg.nr.',
  'nets_tab.message.double_delivery.section_number': 'Sektionsnummer',
  'nets_tab.message.double_delivery.transfer_type': 'Overførselsart',
  'nets_tab.message.info.amount': 'Beløb',
  'nets_tab.message.info.cpr': 'CPR-nummer',
  'nets_tab.message.info.customer_number': 'Kundenummer',
  'nets_tab.message.info.error_code': 'Fejlkode',
  'nets_tab.message.info.info_type': 'Infotype',
  'nets_tab.message.info.pbs': 'PBS-nummer',
  'nets_tab.message.info.transfer_type': 'Overførselsart',
  'nets_tab.message.maximum_amount.limit_type': 'Dispositionsforholdskode',
  'nets_tab.message.maximum_amount.maximum_amount': 'Maksimumbeløb',
  'nets_tab.message.stop.account_number': 'Kontonr.',
  'nets_tab.message.stop.amount': 'Beløb',
  'nets_tab.message.stop.code': 'Stop-/slettekode',
  'nets_tab.message.stop.cvr': 'CVR-nummer',
  'nets_tab.message.stop.disposition_date': 'Dispositionsdato',
  'nets_tab.message.stop.name': 'Navn',
  'nets_tab.message.stop.record_count': 'Antal poster',
  'nets_tab.message.stop.registration_number': 'Reg.nr.',
  'nets_tab.message.stop.transfer_type': 'Overførselsart',
  'nets_tab.message.transaction_receipt.account_number': 'Kontonr.',
  'nets_tab.message.transaction_receipt.amount': 'Beløb',
  'nets_tab.message.transaction_receipt.bookkeeping_date': 'Bogføringsdato',
  'nets_tab.message.transaction_receipt.info_type': 'Infotype',
  'nets_tab.message.transaction_receipt.record_count': 'Antal poster',
  'nets_tab.message.transaction_receipt.record_type': 'Post-type',
  'nets_tab.message.transaction_receipt.registration_number': 'Reg.nr.',
  'nets_tab.message.transaction_receipt.rejected_amount': 'Beløb afvist',
  'nets_tab.message.transaction_receipt.rejected_record_count': 'Antal afviste poster',
  'nets_tab.message.transaction_receipt.transaction_type': 'Overførselstype',
  'nets_tab.message.transaction_receipt.transfer_type': 'Overførselsart',
  'nets_tab.record_type.7': 'Tilbageførsel (vil blive krediteret)',
  'nets_tab.record_type.8': 'Debiteret',
  'nets_tab.stop_code.1': 'Stopmeddelelse',
  'nets_tab.stop_code.3': 'Slettemeddelelse',
  'nets_tab.table.header.date': 'Dato',
  'nets_tab.table.header.info': 'Information',
  'nets_tab.table.header.title': 'Titel',
  'nets_tab.title': 'Beskeder fra Overførselsservice',
  'nets_tab.transaction_type.a': 'Almindelig overførsel',
  'nets_tab.transaction_type.h': 'Hurtig overførsel',
  'nets_tab.transfer_type.10': 'Løn',
  'nets_tab.transfer_type.30': 'Overført',
  'nets_tab.transfer_type.40': 'Ingen tekst',
  'nets_tab.transfer_type.50': 'Stående ordre',
  'nets_tab.transfer_type.60': 'Informationsoverførsel',
  'nets_tab.transfer_type.70': 'Overført',
  'nets_tab.transfer_type.80': 'Ingen tekst',
  'nets_tab.transfer_type.90': 'Pension',
  'nets_tab.transfer_type.91': 'Ingen tekst',

  'nets.message_type.comment': 'Anmærkningsoplysninger',
  'nets.message_type.disposition_change': 'Dispositionsdatoændring',
  'nets.message_type.double_delivery': 'Dobbeltindlæsning',
  'nets.message_type.info': 'Anmærkning om informationsoplysninger',
  'nets.message_type.maximum_amount': 'Oplysning om maksimumbeløb',
  'nets.message_type.stop': 'Stop-meddelelse',
  'nets.message_type.transaction_receipt': 'Overførselskvittering',

  'notifications.header.delete_all': 'Slet alle notifikationer',
  'notifications.header.title': 'Notifikationscenter',
  'notifications.table.actions.link': 'Gå til',
  'notifications.table.actions.remove': 'Fjern',
  'notifications.table.header.description': 'Beskrivelse',
  'notifications.table.header.status': 'Status',
  'notifications.warning.company_not_verified.description':
    'Afventer godkendelse af virksomhed. Skriv til {email} hvis det er over 24 timer siden du oprettede din virksomhed i Salary.',
  'notifications.warning.company_not_verified.description.email': 'Support',
  'notifications.warning.company_not_verified.status_text': 'Ingen lønkørsel',
  'notifications.warning.company_not_verified.title': 'Virksomhed ikke godkendt endnu',
  'notifications.warning.dinero_old_token.description':
    'Din Dinero-nøgle er udfaset. Hvis du ikke fornyer din Dinero-nøgle, kan din bogføring begynde at fejle. Det er Dinero der har besluttet at udfase de gamle nøgler.  For at skifte til den nye nøgle, gå til integrationer - regnskab, vælg "skift regnskabsintegration", vælg Dinero som regnskabssystem og følg vejledningen.',
  'notifications.warning.dinero_old_token.status_text': 'Bogføring måske ikke mulig',
  'notifications.warning.dinero_old_token.title': 'Din Dinero-nøgle er ved at udløbe',
  'notifications.warning.employee_contract_family_leave_not_changed.description':
    'Under opdatering af virksomhedens barsel, kunne barselsordningen ikke ændres for {name}.  Du bliver nødt til at tjekke medarbejderen manuelt.',
  'notifications.warning.employee_contract_family_leave_not_changed.status_text':
    'Medarbejder har forkert barselsordning',
  'notifications.warning.employee_contract_family_leave_not_changed.title':
    'Barselsordningen for {name} kunne ikke ændres',
  'notifications.warning.employee_empty_tax_card.description':
    'Vi har modtaget et tomt skattekort fra SKAT for din medarbejder. Det betyder vi trækker 55% i A-skat uden fradrag. Hvis din medarbejder forventer andet, skal de rette deres forskudsopgørelse hos SKAT. Herefter modtager vi automatisk et nyt skattekort fra SKAT.',
  'notifications.warning.employee_empty_tax_card.status_text': 'Intet fradrag og 55% skat',
  'notifications.warning.employee_empty_tax_card.title': 'Tomt skattekort for {name}',
  'notifications.warning.employee_invalid_national_id.description': 'Du skal rette det under profil på medarbejderen.',
  'notifications.warning.employee_invalid_national_id.status_text': 'Ugyldigt CPR-nummer',
  'notifications.warning.employee_invalid_national_id.title': 'Ugyldigt CPR-nummer for {name}',
  'notifications.warning.employee_is_foreign_resident.description':
    'SKAT oplyser, at medarbejderen {name} bor i udlandet.  Såfremt medarbejderen bor i udlandet, bør du rette medarbejderen til "CPR-nummer bosat i udlandet" under medarbejderens profile før næste lønkørsel.  Bor medarbejderen faktisk i Danmark, skal vedkommende melde flytning til Folkeregisteret.',
  'notifications.warning.employee_is_foreign_resident.status_text': 'Indberetningsadvis',
  'notifications.warning.employee_is_foreign_resident.title': 'Medarbejder bosat i udlandet',
  'notifications.warning.employee_missing_contract.description':
    'Tryk her for at oprette en ansættelse for medarbejderen.',
  'notifications.warning.employee_missing_contract.status_text': 'Ingen løn til medarbejder',
  'notifications.warning.employee_missing_contract.title': 'Manglende ansættelse for {name}',
  'notifications.warning.employee_missing_tax_card.description':
    'Salary har ikke modtaget noget skattekort for {name}, og der trækkes derfor 55% A-skat. Tjek eventuelt at CPR-nummer er korrekt, eller forsøg at bestille skattekort igen.',
  'notifications.warning.employee_missing_tax_card.status_text': 'Intet fradrag og 55% skat',
  'notifications.warning.employee_missing_tax_card.title': 'Manglende skattekort for {name}',
  'notifications.warning.error_in_shipment_transfer.description':
    'En overførsel til {name} er fejlet.  Overførselservice oplyser følgende begrundelse: {response}',
  'notifications.warning.error_in_shipment_transfer.status_text': 'Overførsel fejlet',
  'notifications.warning.error_in_shipment_transfer.title': 'Overførsel til medarbejder fejlet',
  'notifications.warning.fix_credit_card_integration.description.contact_issuer':
    'Salary kunne ikke betale fakturaen, da der var problemer med betalingskortet. Du bedes kontakte din kortudbyder, og derefter prøve igen.',
  'notifications.warning.fix_credit_card_integration.description.default':
    'Salary kunne ikke betale fakturaen, da der var problemer med betalingskortet.',
  'notifications.warning.fix_credit_card_integration.description.new_card':
    'Salary kunne ikke betale fakturaen, da der var problemer med betalingskortet. Du bedes oprette en ny betalingskortaftale.',
  'notifications.warning.fix_credit_card_integration.description.try_again':
    'Salary kunne ikke betale fakturaen, da der var problemer med betalingskortet. Ret fejlen, og prøv igen.',
  'notifications.warning.fix_credit_card_integration.description.with_note_from_issuer':
    '{description} Fejl fra kortudbyder: {error}',
  'notifications.warning.fix_credit_card_integration.status_text': 'Faktura ikke betalt',
  'notifications.warning.fix_credit_card_integration.title': 'Kortbetaling fejlet',
  'notifications.warning.invalid_production_unit.description':
    'SKAT oplyser, at produktionsenhedsnummeret for {name} ikke er gyldigt. Du skal rette dette før næste lønkørsel.',
  'notifications.warning.invalid_production_unit.status_text': 'Indberetningsadvis',
  'notifications.warning.invalid_production_unit.title': 'Ugyldigt produktionsenhedsnummer',
  'notifications.warning.nets_onboarding_failed.description':
    'Opsætningen af Overførselsservice blev afvist. Tjek at du har indtastet det rigtige registrerings- og kontonummer, og prøv igen. Hvis du fortsat får fejl, så kontakt support.',
  'notifications.warning.nets_onboarding_failed.status_text': 'Ingen automatisk overførsel',
  'notifications.warning.nets_onboarding_failed.title': 'Opsætning af Overførselsservice fejlede',
  'notifications.warning.not_registered_as_employer.description':
    'Vi har efter lønkørslen fået besked fra SKAT om, at virksomheden ikke er tilmeldt som arbejdsgiver. Indberetningen er derfor afvist. Du skal tilmelde virksomheden til SKAT og AM på virk.dk og herefter skrive til Salarys support, så vi kan genindberette for dig.',
  'notifications.warning.not_registered_as_employer.status_text': 'Ingen indberetning foretaget',
  'notifications.warning.not_registered_as_employer.title': 'Manglende registrering som arbejdsgiver',
  'notifications.warning.voucher_booking_failed.description':
    'Kontrollér at alle felter er tilknyttet i integrationen med dit bogføringssystem, og bogfør igen.',
  'notifications.warning.voucher_booking_failed.status_text': 'Bogføring ikke mulig',
  'notifications.warning.voucher_booking_failed.title': 'Bilag kunne ikke bogføres',
  'notifications.warning.voucher_missing_account.description':
    'Salary kan ikke foretage bogføring af lønkørslen, da følgende konti ikke er sat op i bogføringsintegrationen: {accounts}',
  'notifications.warning.voucher_missing_account.status_text': 'Bogføring ikke mulig',
  'notifications.warning.voucher_missing_account.title': 'Manglende bogføringskonto',

  'one_time_pay.category.type_0000': 'Ingen',
  'one_time_pay.category.type_0050': '50 - Købe- og tegningsretter til aktier samt anparter jf. LL § 16',
  'one_time_pay.category.type_0051': '51 - Købe- og tegningsretter jf. LL § 28',
  'one_time_pay.category.type_0061': '61 - Personalegoder til hovedaktionærer samt udloddet fri bil som udbytte',
  'one_time_pay.category.type_0079': '79 - Selvstændigt erhverv',
  'one_time_pay.category.type_0082': '82 - Vederlag til private dagplejere',
  'one_time_pay.category.type_0097': '97 - Skattefrie uddelinger og legater',
  'one_time_pay.category.type_0099':
    '99 - Skattepligtige almennyttige uddelinger fra almennyttige skattepligtige fonde og foreninger',
  'one_time_pay.category.type_0100':
    '100 - Skattepligtige IKKE-almennyttige uddelinger fra almennyttige skattepligtige fonde og foreninger',
  'one_time_pay.category.type_0103': '103 - Arbejdsgiverbetalt sygedagpenge',
  'one_time_pay.category.type_0104': '104 - G-Dage',
  'one_time_pay.type.anniversary_bonus': 'Jubilæumsgratiale og fratrædelsesgodtgørelse',
  'one_time_pay.type.atp': 'ATP',
  'one_time_pay.type.benefit_in_kind': 'Medarbejderaktie §7P (Indtægtsart 0101)',
  'one_time_pay.type.bonus': 'Almindelig',
  'one_time_pay.type.bonus_no_pension': 'Almindelig (uden pension)',
  'one_time_pay.type.bonus_no_vacation': 'Almindelig (uden ferieberettiget løn)',
  'one_time_pay.type.bonus_no_vacation_and_pension': 'Almindelig (uden ferieberettiget løn eller pension)',
  'one_time_pay.type.car_allowance': 'Kørselsgodtgørelse',
  'one_time_pay.type.car_simple': 'Fri bil',
  'one_time_pay.type.compensation': 'AM-bidragspligtig B-indkomst (Felt 36)',
  'one_time_pay.type.compensation_no_am': 'AM-bidragsfri B-indkomst (Felt 38)',
  'one_time_pay.type.free_phone': 'Fri telefon',
  'one_time_pay.type.free_text': 'Tekst på lønseddel',
  'one_time_pay.type.fritvalg': 'Fritvalgskonto',
  'one_time_pay.type.fritvalg_increase': 'Forøgelse af fritvalgskonto',
  'one_time_pay.type.fritvalg_increase_no_pension': 'Forøgelse af fritvalgskonto (uden pension)',
  'one_time_pay.type.fritvalg_increase_no_vacation': 'Forøgelse af fritvalgskonto (uden ferieberettiget løn)',
  'one_time_pay.type.fritvalg_increase_no_vacation_and_pension':
    'Forøgelse af fritvalgskonto (uden ferieberettigt løn eller pension)',
  'one_time_pay.type.g_dage': 'AM-bidragsfri indkomst',
  'one_time_pay.type.grant': 'Skattefrit legat (Felt 38, Art 97)',
  'one_time_pay.type.gross_pay_reduction': 'Bruttotræk',
  'one_time_pay.type.gross_pay_reduction_no_pension': 'Bruttotræk (uden pensionsgrundlag)',
  'one_time_pay.type.gross_pay_reduction_no_vacation': 'Bruttotræk (uden feriegrundlag)',
  'one_time_pay.type.gross_pay_reduction_no_vacation_and_pension': 'Bruttotræk (uden ferie- og pensionsgrundlag)',
  'one_time_pay.type.gross_vacation_money': 'Bruttoferiepenge',
  'one_time_pay.type.honorary_gift': 'Hædersgave (Felt 39)',
  'one_time_pay.type.honorary_gift_benefit_in_kind': 'Hædersgave (som naturalie)',
  'one_time_pay.type.overtime_payout': 'Udbetaling af afspadsering',
  'one_time_pay.type.paid_vacation': 'Betalt ferie',
  'one_time_pay.type.pay_check_advanced': 'Forskud/nettotræk',
  'one_time_pay.type.reimbursement': 'Udlæg',
  'one_time_pay.type.sh_dage': 'SH-dage',
  'one_time_pay.type.sh_dage_increase': 'Forøgelse af SH-dage',
  'one_time_pay.type.sh_dage_increase_no_pension': 'Forøgelse af SH-dage (uden pension)',
  'one_time_pay.type.sh_dage_increase_no_vacation': 'Forøgelse af SH-dage (uden ferieberettiget løn)',
  'one_time_pay.type.sh_dage_increase_no_vacation_and_pension':
    'Forøgelse af SH-dage (uden ferieberettigt løn eller pension)',
  'one_time_pay.type.tax_deduction_modification_decrease': 'Reducering af skattefradrag',
  'one_time_pay.type.tax_deduction_modification_increase': 'Forøgelse af skattefradrag',
  'one_time_pay.type.tax_free_income': 'Skattefri indkomst',
  'one_time_pay.type.tax_free_income_abroad': 'Skattefri indkomst for udlandsdanskere',
  'one_time_pay.type.travel_allowance': 'Diæt',
  'one_time_pay.type.vacation_fund_payment': 'Sygeferiepenge',
  'one_time_pay.type.vacation_money_amount': 'Feriepenge udbetalt beløb',
  'one_time_pay.type.vacation_money_days': 'Feriepenge udbetalt dage',
  'one_time_pay.type.work_hours': 'Arbejdstimer',

  'one_time_pays.header.import_excel': 'Importér Excel',
  'one_time_pays.import.alert.success.fees': 'Honorarerne blev gemt',
  'one_time_pays.import.alert.success.standard': 'Tillæggene, m.fl. blev gemt',
  'one_time_pays.import.step_0.buttons.continue': 'Fortsæt til upload',
  'one_time_pays.import.step_0.buttons.download': 'Hent Excel',
  'one_time_pays.import.step_0.intro':
    'Hent Excel-filen med en liste over dine medarbejdere via knappen herunder. Hvis du allerede har hentet og udfyldt Excel-filen, kan du fortsætte direkte til upload af filen.',
  'one_time_pays.import.step_1.buttons.back': 'Tilbage',
  'one_time_pays.import.step_1.buttons.upload': 'Upload Excel',
  'one_time_pays.import.step_1.importing': 'Indlæser...',
  'one_time_pays.import.step_1.intro': 'Upload den udfyldte Excel-fil med dine medarbejdere via knappen herunder.',
  'one_time_pays.import.step_2.buttons.back': 'Tilbage',
  'one_time_pays.import.step_2.buttons.save.fee': 'Gem honorarer',
  'one_time_pays.import.step_2.buttons.save.standard': 'Gem tillæggene',
  'one_time_pays.import.step_2.importing': 'Gemmer...',
  'one_time_pays.import.step_2.result.header.amount': 'Beløb',
  'one_time_pays.import.step_2.result.header.category': 'Art',
  'one_time_pays.import.step_2.result.header.employee': 'Medarbejder',
  'one_time_pays.import.step_2.result.header.title': 'Tekst',
  'one_time_pays.import.step_2.result.header.type': 'Type',
  'one_time_pays.import.step_3.buttons.done': 'Færdig',
  'one_time_pays.import.step_3.result.header.employee': 'Medarbejder',
  'one_time_pays.import.step_3.result.header.state': 'Ændringsstatus',
  'one_time_pays.import.step_3.result.header.state_message': 'Beskrivelse',
  'one_time_pays.import.step_3.success.fee': 'Alle honorarer importeret.',
  'one_time_pays.import.step_3.success.standard': 'Alle tillæg importeret.',
  'one_time_pays.import.steps.0': 'Hent Excel',
  'one_time_pays.import.steps.1': 'Upload Excel',
  'one_time_pays.import.steps.2': 'Bekræft',
  'one_time_pays.import.steps.3': 'Resultat',
  'one_time_pays.import.title': 'Importér Excel',
  'one_time_pays.table.add': 'Tilføj',
  'one_time_pays.table.approve_amount': 'Godkendt {amount}',
  'one_time_pays.table.header.advanced': 'Forskud',
  'one_time_pays.table.header.bonus': 'Bonus',
  'one_time_pays.table.header.employee': 'Medarbejder',
  'one_time_pays.table.header.reimbursement': 'Udlæg',
  'one_time_pays.table.header.travel': 'Diæt',
  'one_time_pays.title': 'Løntillæg/fradrag',

  'otp_form.accounting_text': 'Tekst til separat bogføringslinje (efterlad blank for ikke at være separat)',
  'otp_form.amount.calculated_amount': 'Beregnet beløb: {amount}',
  'otp_form.amount.invalid': 'Beløb for {description} er ugyldigt',
  'otp_form.amount.not_negative': 'Beløb for {description} må ikke være negativt',
  'otp_form.amount.rate': 'Sats',
  'otp_form.amount.rate.invalid': 'Sats er ugyldig',
  'otp_form.amount.rate.not_negative': 'Sats må ikke være negativ',
  'otp_form.amount.rate.required': 'Sats skal indtastes',
  'otp_form.amount.rate.suffix': 'kr.',
  'otp_form.amount.required': 'Beløb for {description} skal indtastes',
  'otp_form.amount.suffix': 'kr.',
  'otp_form.amount.title': 'Beløb for {description}',
  'otp_form.amount.units': 'Antal',
  'otp_form.amount.units.invalid': 'Antal er ugyldigt',
  'otp_form.amount.units.not_negative': 'Antal må ikke være negativt',
  'otp_form.amount.units.required': 'Antal skal indtastes',
  'otp_form.amount.use_rate': 'Brug sats og antal i stedet',
  'otp_form.approved.approved': 'Godkendt?',
  'otp_form.approved.checked': 'Godkendt',
  'otp_form.approved.unchecked': 'Kladde',
  'otp_form.cost_center': 'Omkostningssted',
  'otp_form.debit_company_accounting_account_id': 'Anden debitkonto',
  'otp_form.debit_company_accounting_account_id.no_longer_available.option_note': '(ikke længere tilgængelig)',
  'otp_form.disposition_date.default_title': 'Udbetalingsdato',
  'otp_form.disposition_date.picker': 'Datovælger',
  'otp_form.disposition_date.required': '{title} skal vælges',
  'otp_form.title': 'Tekst på lønseddel',
  'otp_form.title.count': '{length}/{max} tegn',
  'otp_form.title.required': 'Tekst på lønseddel skal indtastes',
  'otp_form.title.too_long': 'Tekst på lønseddel må maks. være {limit} tegn',
  'otp_form.title.warning':
    'Du er ved at bruge flere tegn end der er plads til på lønsedlen; resten vil blive skåret fra.',

  'password_request.completed': 'Tjek din e-mail for ny adgangskode.',
  'password_request.form.email': 'E-mail',
  'password_request.form.email.required': 'E-mail skal indtastes',
  'password_request.form.submit': 'Anmod om ny adgangskode',
  'password_request.intro': 'Indtast din e-mail og modtag din nye adgangskode indenfor 2 minutter.',
  'password_request.title': 'Glemt adgangskode',

  'password_reset.alert.success': 'Din adgangskoden er blevet gemt. Log ind herunder.',
  'password_reset.form.password': 'Adgangskode',
  'password_reset.form.password.at_least_8_characters': 'Adgangskode skal være mindst 8 tegn',
  'password_reset.form.password.required': 'Adgangskode skal indtastes',
  'password_reset.form.submit': 'Godkend ny adgangskode',
  'password_reset.title': 'Opret ny adgangskode',

  'pay_check_advance.edit.form.amount': 'forskud/nettotræk',
  'pay_check_advance.edit.form.disposition_date': 'Hvornår skal forskuddet/nettotrækket trækkes fra lønnen?',
  'pay_check_advance.edit.form.disposition_date.placeholder': 'Trækdato',
  'pay_check_advance.edit.title.create': 'Registrér forskud/nettotræk',
  'pay_check_advance.edit.title.edit': 'Redigér forskud/nettotræk',
  'pay_check_advance.header.add_pay_check_advance': 'Registrér forskud/nettotræk',
  'pay_check_advance.header.approve_all': 'Godkend alle',
  'pay_check_advance.header.hide_history': 'Tilbage',
  'pay_check_advance.header.show_history': 'Vis historik',
  'pay_check_advance.table.actions.delete': 'Slet forskud/nettotræk',
  'pay_check_advance.table.actions.edit': 'Redigér forskud/nettotræk',
  'pay_check_advance.table.actions.view': 'Gennemse forskud/nettotræk',
  'pay_check_advance.table.amount_format': '{units} à {rate}',
  'pay_check_advance.table.approved.approve': 'Godkend',
  'pay_check_advance.table.approved.false': 'Nej',
  'pay_check_advance.table.approved.remove': 'Fjern godkendelse',
  'pay_check_advance.table.approved.true': 'Ja',
  'pay_check_advance.table.header.amount': 'Beløb',
  'pay_check_advance.table.header.approved': 'Godkendt',
  'pay_check_advance.table.header.date': 'Dato',
  'pay_check_advance.table.header.title': 'Beskrivelse',
  'pay_check_advance.table.saving': 'Gemmer...',
  'pay_check_advance.title.history': 'Historik for forskud/nettotræk',
  'pay_check_advance.title.standard': 'Forskud/nettotræk',

  'pay_roll.approval_type.automatic': 'Automatisk',
  'pay_roll.approval_type.manual': 'Manuelt',
  'pay_roll.approval_type.no_approval': 'Automatisk',
  'pay_roll.single.accounting.header.accounting_voucher': 'Hent bogføringsrapport',
  'pay_roll.single.accounting.header.accounting_voucher.csv': 'Hent som CSV',
  'pay_roll.single.accounting.header.accounting_voucher.excel': 'Hent som Excel',
  'pay_roll.single.accounting.header.accounting_voucher.pdf': 'Hent som PDF',
  'pay_roll.single.accounting.header.automatic': 'Automatisk via: {type}',
  'pay_roll.single.accounting.header.employees_report': 'Hent lønrapport',
  'pay_roll.single.accounting.header.employees_report.excel': 'Hent som Excel',
  'pay_roll.single.accounting.header.employees_report.pdf': 'Hent som PDF',
  'pay_roll.single.accounting.header.income_tax_report': 'Hent eIndkomst-rapport',
  'pay_roll.single.accounting.header.income_tax_report.excel': 'Hent som Excel',
  'pay_roll.single.accounting.header.income_tax_report.pdf': 'Hent som PDF',
  'pay_roll.single.accounting.header.manual': 'Manuelt',
  'pay_roll.single.accounting.header.settings': 'Indstillinger',
  'pay_roll.single.accounting.header.title': 'Bogføring',
  'pay_roll.single.accounting.table.header.account_number': 'Konto',
  'pay_roll.single.accounting.table.header.credit': 'Kredit (kr.)',
  'pay_roll.single.accounting.table.header.debit': 'Debet (kr.)',
  'pay_roll.single.accounting.table.header.description': 'Beskrivelse',
  'pay_roll.single.accounting.table.header.vat': 'Moms (kr.)',
  'pay_roll.single.accounting.table.unknown_account': 'Ukendt kontonummer',
  'pay_roll.single.approve.approval_code': 'Godkendelseskode',
  'pay_roll.single.approve.create_stripe_integration_button': 'Opret betalingskortaftale',
  'pay_roll.single.approve.intro':
    'Indtil lønnen er kørt, kan du genåbne den og få nye rettelser med. Når lønnen er kørt, kan der ikke rettes længere.',
  'pay_roll.single.approve.intro.no_deadline': 'Denne løn kører færdig 5 minutter efter godkendelsen.',
  'pay_roll.single.approve.no_payment_integration.intro.line_1':
    'Betalingskort er påkrævet for at betale fakturaer til Salary, når der ikke findes en Overførselsservice-aftale.',
  'pay_roll.single.approve.no_payment_integration.intro.line_2':
    'Bemærk at løn, skat og andre overførsler ikke foregår via betalingskort.',
  'pay_roll.single.approve.no_payment_integration.title': 'Du kan ikke godkende løn uden betalingskort',
  'pay_roll.single.approve.partial_approval.many':
    'Når du har godkendt lønnen, skal den stadig godkendes af {count} andre før lønnen er endelig godkendt.',
  'pay_roll.single.approve.partial_approval.one':
    'Når du har godkendt lønnen, skal den stadig godkendes af en anden før lønnen er endelig godkendt.',
  'pay_roll.single.approve.payroll_locked.intro.line_1':
    'Da du har ubetalte fakturaer kan du ikke godkende løn.  Du bliver nødt til at betale dine fakturaer før du kan godkende løn.',
  'pay_roll.single.approve.payroll_locked.intro.line_2':
    'Du kan oprette en betalingskortsaftale med det samme, for at få betalt dine fakturaer.  Tjek {link} for at se dine ubetalte fakturaer.',
  'pay_roll.single.approve.payroll_locked.intro.line_2.link': 'fakturaoversigten',
  'pay_roll.single.approve.payroll_locked.title': 'Du kan ikke godkende løn pga. ubetalte fakturaer',
  'pay_roll.single.approve.select_other_approvers':
    'Vælg hvilke andre brugere som skal have notifikation om at lønnen nu er delvist godkendt:',
  'pay_roll.single.approve.submit': 'Godkend løn',
  'pay_roll.single.approve.title.finally': 'Vil du endeligt godkende lønnen?',
  'pay_roll.single.approve.title.partial': 'Vil du delvist godkende lønnen?',
  'pay_roll.single.delete.intro':
    'Når lønnen er slettet, vil du skulle lave en hel ny lønkørsel for samme periode for at køre den igen.',
  'pay_roll.single.delete.submit': 'Slet løn',
  'pay_roll.single.delete.title': 'Vil du slette lønnen?',
  'pay_roll.single.delete.warning_about_other_approved':
    'Der er mindst én anden åbn godkendt lønkørsel for samme lønperiode.  Vær opmærksom på, at når du sletter denne lønkørsel, genberegnes de godkendte lønkørsler ikke.  Du bør derfor tjekke, at de stadig ser korrekte ud; og hvis ikke, så genåbne dem.',
  'pay_roll.single.details.approved_by': 'Godkendt af',
  'pay_roll.single.details.approved_by.at': '{time}',
  'pay_roll.single.details.approved_by.none': 'Ingen',
  'pay_roll.single.details.disposition_date': 'Disposition',
  'pay_roll.single.details.frequency': 'Cyklus',
  'pay_roll.single.details.period': 'Periode',
  'pay_roll.single.details.status': 'Status',
  'pay_roll.single.details.status.see_receipt': 'Se svar',
  'pay_roll.single.details.title': 'Oversigt',
  'pay_roll.single.details.total_transfer': 'Beløb',
  'pay_roll.single.details.type': 'Type',
  'pay_roll.single.details.type.manual': 'Ekstraordinær lønkørsel',
  'pay_roll.single.details.type.negation': 'Tilbageførsel af lønkørsel',
  'pay_roll.single.details.type.ordinary': 'Almindelig lønkørsel',
  'pay_roll.single.details.type.terminated_employments': 'Opsigelse af funktionærer',
  'pay_roll.single.deviations.currency_format': '{percentage} % ({currency})',
  'pay_roll.single.deviations.header.deviation': 'Afvigelse',
  'pay_roll.single.deviations.header.name': 'Medarbejder',
  'pay_roll.single.deviations.header.type': 'Type',
  'pay_roll.single.deviations.hours_format': '{percentage} % ({hours} timer)',
  'pay_roll.single.deviations.settings': 'Indstillinger',
  'pay_roll.single.deviations.tax_card_format': 'Fra {old} til {new}',
  'pay_roll.single.deviations.title': 'Afvigelser',
  'pay_roll.single.deviations.total': 'I alt: {total}',
  'pay_roll.single.edit_disposition_date.disposition_date': 'Dispositionsdato',
  'pay_roll.single.edit_disposition_date.disposition_date.not_before_date': 'Dispositionsdato må ikke være før {date}',
  'pay_roll.single.edit_disposition_date.disposition_date.required': 'Dispositionsdato skal vælges',
  'pay_roll.single.edit_disposition_date.intro':
    'Du er ved at rette den skattemæssige dispositionsdato for lønnen. Hvis du sætter dispositionsdatoen til en anden måned, vil lønnen skattemæssigt høre til den nye måned/år. Spørg supporten hvis du har spørgsmål.',
  'pay_roll.single.edit_disposition_date.submit': 'Ændr dispositionsdatoen',
  'pay_roll.single.edit_disposition_date.title': 'Ændring af dispositionsdato for lønkørslen',
  'pay_roll.single.fix_national_id.errors.employee_update_failed': 'Et CPR-nummer kunne ikke rettes.',
  'pay_roll.single.fix_national_id.errors.extra_payroll_failed': 'Den ekstraordinære lønkørsel blev ikke oprettet.',
  'pay_roll.single.fix_national_id.errors.negate_payroll_failed': 'Tilbagerulningen kunne ikke gennemføres.',
  'pay_roll.single.fix_national_id.errors.otp_creation_failed': 'Et nettotræk kunne ikke oprettes.',
  'pay_roll.single.fix_national_id.extra_pay_roll_link': 'Gå til den extraordinære løn',
  'pay_roll.single.fix_national_id.header.name': 'Medarbejder',
  'pay_roll.single.fix_national_id.header.new_national_id': 'Indtast nyt CPR-nummer',
  'pay_roll.single.fix_national_id.header.old_national_id': 'Nuværende CPR-nummer',
  'pay_roll.single.fix_national_id.intro.part_1.many':
    '{count} medarbejdere har ugyldige CPR-numre, de skal rettes før der kan indberettes løn til SKAT.',
  'pay_roll.single.fix_national_id.intro.part_1.one':
    'En medarbejder har ugyldigt CPR-nummer, de skal rettes før der kan indberettes løn til SKAT.',
  'pay_roll.single.fix_national_id.intro.part_2.many':
    'Udfyld de korrekte CPR-numre for medarbejderne her, så ruller vi deres lønsedler tilbage, retter deres CPR-numre, og laver en ekstraordinær lønkørsel med deres nye CPR-numre.',
  'pay_roll.single.fix_national_id.intro.part_2.one':
    'Udfyld det korrekte CPR-nummer for medarbejderen her, så ruller vi lønsedlen tilbage, retter CPR-nummeret, og laver en ekstraordinær lønkørsel med det nye CPR-nummer.',
  'pay_roll.single.fix_national_id.intro.part_3.many':
    'Vi foretager et nettotræk på medarbejderne for indeværende lønperiode for at sikre, at der ikke dobbeltudbetales løn.',
  'pay_roll.single.fix_national_id.intro.part_3.one':
    'Vi foretager et nettotræk på medarbejderen for indeværende lønperiode for at sikre, at der ikke dobbeltudbetales løn.',
  'pay_roll.single.fix_national_id.intro.part_4': 'Efterlades et felt blankt, bliver medarbejderen ikke rettet.',
  'pay_roll.single.fix_national_id.negate_pay_roll_link': 'Gå til tilbagerulningen',
  'pay_roll.single.fix_national_id.submit.many': 'Ret medarbejdere',
  'pay_roll.single.fix_national_id.submit.one': 'Ret medarbejder',
  'pay_roll.single.fix_national_id.title': 'Ret CPR-numre',
  'pay_roll.single.invoice.download_pdf': 'Hent som PDF',
  'pay_roll.single.invoice.header.description': 'Beskrivelse',
  'pay_roll.single.invoice.header.total': 'Total',
  'pay_roll.single.invoice.header.unit_count': 'Antal',
  'pay_roll.single.invoice.header.unit_price': 'À pris',
  'pay_roll.single.invoice.title': 'Faktura',
  'pay_roll.single.invoice.total': 'Pris eks. moms: {total}',
  'pay_roll.single.main.awaiting.less_than_250': 'Lønnen beregnes, den vil være klar om lidt.',
  'pay_roll.single.main.awaiting.lower_note': 'Du kan godt forlade denne side mens lønnen beregnes.',
  'pay_roll.single.main.awaiting.more_than_250': 'Lønnen beregnes, det kan tage op til en halv time.',
  'pay_roll.single.main.buttons.approve': 'Godkend',
  'pay_roll.single.main.buttons.delete': 'Slet',
  'pay_roll.single.main.buttons.negate': 'Tilbagefør lønsedler',
  'pay_roll.single.main.buttons.recreate': 'Gendan',
  'pay_roll.single.main.buttons.reject': 'Annullér',
  'pay_roll.single.main.buttons.resend_skat': 'Genindberet',
  'pay_roll.single.main.buttons.resend_skat.confirm': 'Er du sikker på du vil genindberette din lønkørsel til SKAT?',
  'pay_roll.single.main.buttons.restart': 'Genberegn',
  'pay_roll.single.main.buttons.revert_approval': 'Genåbn',
  'pay_roll.single.main.buttons.review': 'Klargør til godkendelse',
  'pay_roll.single.main.buttons.switch_to_manual': 'Skift til manuel overførsel',
  'pay_roll.single.main.buttons.switch_to_nets': 'Skift til Overførselsservice',
  'pay_roll.single.main.header.approved': 'Godkendt og vil køre færdig',
  'pay_roll.single.main.header.automatic_approval': 'Godkendes automatisk {time}, {link}',
  'pay_roll.single.main.header.automatic_approval.link': 'se indstillinger',
  'pay_roll.single.main.header.finished': 'Afsluttet',
  'pay_roll.single.main.header.partial_approved.many': 'Lønnen er delvist godkendt og mangler {count} godkendere',
  'pay_roll.single.main.header.partial_approved.one': 'Lønnen er delvist godkendt og mangler {count} godkender',
  'pay_roll.single.main.header.reviewed': 'Lønnen er blevet klargjort til godkendelse',
  'pay_roll.single.main.notification.notifications.hidden.many':
    'Du har {count} notifikationer, klik her for at se dem',
  'pay_roll.single.main.notification.notifications.hidden.one': 'Du har {count} notifikation, klik her for at se dem',
  'pay_roll.single.main.notification.notifications.shown.many': 'Du har {count} notifikationer',
  'pay_roll.single.main.notification.notifications.shown.one': 'Du har {count} notifikation',
  'pay_roll.single.main.title': 'Løn for {name}',
  'pay_roll.single.manual.intro.line_1': 'Det vil sige du skal foretage lønoverførslerne selv.',
  'pay_roll.single.manual.intro.line_2': 'Skriv til supporten hvis du har spørgsmål om manuel betaling.',
  'pay_roll.single.manual.submit': 'Skift til manuel overførsel',
  'pay_roll.single.manual.title': 'Skift til manuel overførsel',
  'pay_roll.single.manual.warning': 'OBS: Du er ved at skifte fra automatisk til manuel lønoverførsel!',
  'pay_roll.single.negate.intro': 'Du kan vælge lønsedler at tilbageføre.',
  'pay_roll.single.negate.submit': 'Tilbagefør valgte lønsedler',
  'pay_roll.single.negate.title': 'Tilbagefør lønsedler',
  'pay_roll.single.nets.intro.line_1':
    'Det vil sige at pengene først overføres mindst 2 bankdage efter lønnens dispositionsdato. Så dine medarbejdere vil altså ikke modtage lønnen i morgen.',
  'pay_roll.single.nets.intro.line_2': 'Skriv til supporten hvis du har spørgsmål om manuel betaling.',
  'pay_roll.single.nets.submit': 'Skift til Overførselsservice',
  'pay_roll.single.nets.title': 'Skift til Overførselsservice',
  'pay_roll.single.nets.warning':
    'OBS: Du er ved at skifte fra manuel til automatisk overførsel efter dispositionsdatoen!',
  'pay_roll.single.pay_slips.actions.download_payslip': 'Hent lønseddel',
  'pay_roll.single.pay_slips.actions.edit': 'Redigér',
  'pay_roll.single.pay_slips.actions.resend': 'Gensend lønseddel til medarbejderens e-mail',
  'pay_roll.single.pay_slips.download_all_pdf': 'Hent alle som PDF',
  'pay_roll.single.pay_slips.download_all_zip': 'Hent alle som zipfil',
  'pay_roll.single.pay_slips.error.cannot_resend': 'Kunne ikke gensende lønseddel',
  'pay_roll.single.pay_slips.header.department': 'Afdeling',
  'pay_roll.single.pay_slips.header.gross_pay': 'Bruttoløn',
  'pay_roll.single.pay_slips.header.name': 'Navn',
  'pay_roll.single.pay_slips.header.net_pay': 'Nettoløn',
  'pay_roll.single.pay_slips.title': 'Lønsedler',
  'pay_roll.single.pay_slips.total': 'I alt: {total}',
  'pay_roll.single.reject.intro':
    'Når lønnen er annulleret, kan du indtil deadline, kontakte support for at åbne den igen.',
  'pay_roll.single.reject.submit': 'Annullér løn',
  'pay_roll.single.reject.title': 'Vil du annullere lønnen?',
  'pay_roll.single.review.approvers.error.at_least_one': 'Mindst én modtager skal vælges.',
  'pay_roll.single.review.approvers.intro':
    'Vælg hvilke brugere – der kan godkende – som skal have notifikation om at lønnen nu er klar til godkendelse:',
  'pay_roll.single.review.approvers.sms_note': 'Beskeden bliver sendt med SMS.',
  'pay_roll.single.review.intro':
    'Når lønnen er klargjort, bliver lønnen låst og kan kun genåbnes af en der kan godkende lønnen.',
  'pay_roll.single.review.submit': 'Læg klar til godkendelse',
  'pay_roll.single.review.title': 'Er lønnen klar til godkendelse?',
  'pay_roll.single.tax_report_modal.boolean.false': 'Nej',
  'pay_roll.single.tax_report_modal.boolean.true': 'Ja',
  'pay_roll.single.tax_report_modal.disposition_date': 'Dispositionsdato',
  'pay_roll.single.tax_report_modal.employee_number': 'Medarbejdernummer',
  'pay_roll.single.tax_report_modal.income_details': 'Indkomstoplysninger',
  'pay_roll.single.tax_report_modal.income_type': 'Indkomsttype',
  'pay_roll.single.tax_report_modal.name': 'Navn',
  'pay_roll.single.tax_report_modal.period': 'Periode',
  'pay_roll.single.tax_report_modal.period.post_paid': 'bagudlønnet',
  'pay_roll.single.tax_report_modal.period.pre_paid': 'forudlønnet',
  'pay_roll.single.tax_report_modal.production_unit': 'Produktionsenhed',
  'pay_roll.single.tax_report_modal.termination_date': 'Opsigelsesdato',
  'pay_roll.single.tax_report_modal.termination_title': 'Opsigelse',
  'pay_roll.single.tax_report_modal.termination_vacation_days': 'Feriedage',
  'pay_roll.single.tax_report_modal.termination_vacation_net': 'Feriepenge',
  'pay_roll.single.tax_report_modal.termination_year': 'Ferieår',
  'pay_roll.single.tax_report_modal.title': 'Indberetning til SKAT',
  'pay_roll.single.tax_report_receipt.code': 'Kode',
  'pay_roll.single.tax_report_receipt.information': 'Information',
  'pay_roll.single.tax_report_receipt.text': 'Tekst',
  'pay_roll.single.tax_report_receipt.title': 'Svar fra SKAT',
  'pay_roll.single.tax_reports.actions.show_details': 'Vis detaljer',
  'pay_roll.single.tax_reports.automatic_via': 'Automatisk via: {entity}',
  'pay_roll.single.tax_reports.automatic_via.entity': 'SKAT',
  'pay_roll.single.tax_reports.header.name': 'Navn',
  'pay_roll.single.tax_reports.header.national_id': 'CPR-nummer',
  'pay_roll.single.tax_reports.title': 'Indberetning',
  'pay_roll.single.time_registrations.actions.edit': 'Redigér',
  'pay_roll.single.time_registrations.header.name': 'Navn',
  'pay_roll.single.time_registrations.header.work_days': 'Dage',
  'pay_roll.single.time_registrations.header.work_hours': 'Timer',
  'pay_roll.single.time_registrations.title': 'Tidsregistrering',
  'pay_roll.single.time_registrations.total.many': 'I alt: {total} timer',
  'pay_roll.single.time_registrations.total.one': 'I alt: {total} time',
  'pay_roll.single.timeline.status.approved': 'Godkendt den {date}',
  'pay_roll.single.timeline.status.awaiting_execution': 'Kører den {date}',
  'pay_roll.single.timeline.status.cancelled': 'Annulleret',
  'pay_roll.single.timeline.status.default': 'Kører den {date}',
  'pay_roll.single.timeline.status.expected': 'Forventes den {date}',
  'pay_roll.single.timeline.status.failed': 'Fejlet',
  'pay_roll.single.timeline.status.partial_failed': 'Delvist fejlet',
  'pay_roll.single.timeline.status.pending': 'Afventer godkendelse',
  'pay_roll.single.timeline.status.pending_with_deadline': 'Afventer godkendelse (senest {time})',
  'pay_roll.single.timeline.status.rejected': 'Afvist',
  'pay_roll.single.timeline.status.success': 'Gennemført',
  'pay_roll.single.timeline.status.success_format': '{status} den {date}',
  'pay_roll.single.timeline.status.success.e_indkomst': 'Sendt',
  'pay_roll.single.timeline.status.success.e_indkomst_reply': 'Modtaget',
  'pay_roll.single.timeline.title': 'Tidslinje',
  'pay_roll.single.toggle.hide': 'Skjul',
  'pay_roll.single.toggle.show': 'Vis',
  'pay_roll.single.transfers_modal.easy_account': 'NemKonto',
  'pay_roll.single.transfers_modal.header.account_details': 'Kontonummer',
  'pay_roll.single.transfers_modal.header.amount': 'Beløb',
  'pay_roll.single.transfers_modal.header.payment_date': 'Betalingsdato',
  'pay_roll.single.transfers_modal.header.recipient': 'Modtager',
  'pay_roll.single.transfers_modal.title': 'Overførsler',
  'pay_roll.single.transfers.header.amount': 'Beløb',
  'pay_roll.single.transfers.header.description': 'Beskrivelse',
  'pay_roll.single.transfers.header.method': 'Metode',
  'pay_roll.single.transfers.header.payment_date': 'Betalingsdato',
  'pay_roll.single.transfers.header.recipient': 'Modtager',
  'pay_roll.single.transfers.nets_note':
    'Overførslerne trækkes bankdagen før betalingsdatoen (pånær dem for A-skat og AM-bidrag, som trækkes på betalingsdatoen).',
  'pay_roll.single.transfers.nets_note.has_non_nets':
    'Overførslerne via Overførselsservice trækkes bankdagen før betalingsdatoen (pånær dem for A-skat og AM-bidrag, som trækkes på betalingsdatoen).',
  'pay_roll.single.transfers.see_details': 'Se kontonumre',
  'pay_roll.single.transfers.title': 'Overførsler',
  'pay_roll.single.transfers.total': 'I alt: {total}',
  'pay_roll.single.warnings.title': 'Notifikationer',
  'pay_roll.single.warnings.total': 'I alt: {total}',
  'pay_roll.single.warnings.warning.employee_empty_tax_card':
    'Salary har modtaget et tomt skattekort for {name}, og der trækkes derfor 55% skat. Medarbejderen skal rette i sin forskudopgørelse, hvorefter vi automatisk modtager et nyt skattekort.',
  'pay_roll.single.warnings.warning.employee_has_assets': '{name} mangler at aflevere enheder ved afskedligelse.',
  'pay_roll.single.warnings.warning.employee_has_leave_differences':
    '{name} har brugt ferie fra en anden kontraktperiode med anden værdi.',
  'pay_roll.single.warnings.warning.employee_invalid_national_id.fix_national_id': 'Ret CPR-numre',
  'pay_roll.single.warnings.warning.employee_invalid_national_id.many':
    'Medarbejderne {names} har ugyldige CPR-numre, brug knappen til at løse problemet.',
  'pay_roll.single.warnings.warning.employee_invalid_national_id.one':
    'Medarbejderen {names} har et ugyldigt CPR-nummer, brug knappen til at løse problemet.',
  'pay_roll.single.warnings.warning.employee_missing_tax_card':
    'Salary har ikke modtaget noget skattekort for {name}, og der trækkes derfor 55% skat. Tjek eventuelt at CPR-nummer er korrekt, eller forsøg at bestille skattekort igen. Hvis du lige har oprettet medarbejderen, så skal du bare vente 30 minutter.',
  'pay_roll.single.warnings.warning.employees_sharing_transfer_destination':
    'Følgende medarbejdre har samme reg./kontonummer:',
  'pay_roll.single.warnings.warning.employees_sharing_transfer_destination.unknown':
    'Flere medarbejdere har samme reg./kontonummer',
  'pay_roll.single.warnings.warning.excess_vacation_taken.paid_vacation.many':
    '{name} bliver trukket {amount} ({count} dage) for for meget afholdt betalt ferie eller ubetalt fravær',
  'pay_roll.single.warnings.warning.excess_vacation_taken.paid_vacation.one':
    '{name} bliver trukket {amount} ({count} dag) for for meget afholdt betalt ferie eller ubetalt fravær',
  'pay_roll.single.warnings.warning.excess_vacation_taken.unpaid_vacation.many':
    '{name} bliver trukket {amount} ({count} dage) for ferie uden løn',
  'pay_roll.single.warnings.warning.excess_vacation_taken.unpaid_vacation.one':
    '{name} bliver trukket {amount} ({count} dag) for ferie uden løn',
  'pay_roll.single.warnings.warning.forced_nets_transfer':
    'Du har skiftet lønkørslen til at bruge Overførselservice efter godkendelsesdeadlinen. Overførslerne vil først blive overført næstkommende bankdag efter godkendelse, og vil derfor først blive modtaget den efterfølgende bankdag.',
  'pay_roll.single.warnings.warning.insufficient_payslip_vouchers': 'Manglende klippekort til lønsedler: {missing}',
  'pay_roll.single.warnings.warning.manual_transfers.line_1':
    'Denne løn indeholder overførsler, som skal overføres manuelt. Du skal selv overføre dem via din netbank.',
  'pay_roll.single.warnings.warning.manual_transfers.line_2': 'Du kan tjekke listen af overførsler under Overførsler.',
  'pay_roll.single.warnings.warning.manual_transfers.line_3': 'Følgende overførselstyper skal overføres manuelt:',
  'pay_roll.single.warnings.warning.manual_transfers.out_of_format': '{type} ({manual} ud af {total} overførsler)',
  'pay_roll.single.warnings.warning.manual_transfers.switch_to_nets': 'Skift til Overførselservice',
  'pay_roll.single.warnings.warning.manual_transfers.switch_to_nets_line':
    'Nogle af de manuelle overførsler kan skiftes til at bruge Overførselservice.',
  'pay_roll.single.warnings.warning.need_credit_card_integration':
    'Tryk her for at tilknytte betalingskort til at betale fakturaer fra Salary. (Selve løn og skat betales ikke via betalingskort.)',
  'pay_roll.single.warnings.warning.promissory_note':
    'Følgende medarbejdere har overført nettotræk, penge som de skylder til en efterfølgende løn:',
  'pay_roll.single.warnings.warning.terminated_employees_in_period':
    'Følgende medarbejdere får ferie udbetalt for opsagt funktionær:',
  'pay_roll.single.warnings.warning.terminated_employees_in_period.row.many':
    '{name} får udbetalt for {count} dage ({paid_out})',
  'pay_roll.single.warnings.warning.terminated_employees_in_period.row.one':
    '{name} får udbetalt for {count} dag ({paid_out})',
  'pay_roll.single.warnings.warning.tin_missing.many':
    '{count} medarbejdere mangler TIN udfyldt, det kan betyde at SKAT afviser indberetningen, tjek følgende medarbejdere: {names}.',
  'pay_roll.single.warnings.warning.tin_missing.one':
    '{count} medarbejder mangler TIN udfyldt, det kan betyde at SKAT afviser indberetningen, tjek følgende medarbejder: {names}.',
  'pay_roll.single.warnings.warning.tin_no_match.many':
    '{count} medarbejdere har et ugyldigt TIN, det kan betyde at SKAT afviser indberetningen, tjek følgende medarbejdere: {names}.',
  'pay_roll.single.warnings.warning.tin_no_match.one':
    '{count} medarbejder har et ugyldigt TIN, det kan betyde at SKAT afviser indberetningen, tjek følgende medarbejder: {names}.',
  'pay_roll.single.warnings.warning.unapproved_car_allowances':
    'Følgende medarbejdere har kørselsregistreringer i lønperioden, som ikke er godkendt: {names}',
  'pay_roll.single.warnings.warning.unapproved_leave_registrations':
    'Følgende medarbejdere har fravær i lønperioden, som ikke er godkendt: {names}',
  'pay_roll.single.warnings.warning.unapproved_one_time_pays':
    'Følgende medarbejdere/freelancere har engangsbetalinger i lønperioden, som ikke er godkendt: {names}',
  'pay_roll.single.warnings.warning.unapproved_reimbursements':
    'Følgende medarbejdere har udlæg i lønperioden, som ikke er godkendt: {names}',
  'pay_roll.single.warnings.warning.unapproved_time_registrations':
    'Følgende medarbejdere har tidsregistreringer i lønperioden, som ikke er godkendt: {names}',
  'pay_roll.single.warnings.warning.unknown_employee': 'en medarbejder',
  'pay_roll.single.warnings.warning.unready_employees_on_payroll':
    'Der er medarbejdere på lønkørslen der ikke er markeret klar: {names}',
  'pay_roll.single.warnings.warning.voucher_missing_account':
    'Salary kan ikke foretage bogføring af lønkørslen, da følgende konti ikke er sat op i bogføringsintegrationen: {accounts}',
  'pay_roll.status.approved': 'Godkendt',
  'pay_roll.status.automatic_approval': 'Godkendes automatisk',
  'pay_roll.status.awaiting': 'Afventer',
  'pay_roll.status.awaiting_approval': 'Afventer godkendelse',
  'pay_roll.status.cancelled': 'Annulleret',
  'pay_roll.status.failed': 'Fejlet',
  'pay_roll.status.partial_approved': 'Delvist godkendt',
  'pay_roll.status.partial_failed': 'Delvist fejlet',
  'pay_roll.status.reviewed': 'Klargjort',
  'pay_roll.status.success': 'Gennemført',
  'pay_roll.status.will_complete': 'Gennemføres',
  'pay_roll.task.type.accounting': 'Bogføring',
  'pay_roll.task.type.e_indkomst': 'Indberetning til SKAT',
  'pay_roll.task.type.e_indkomst_reply': 'Svar fra SKAT',
  'pay_roll.task.type.run_pay_roll': 'Kørsel af løn',
  'pay_roll.task.type.transport': 'Udsendelse af lønsedler',
  'pay_roll.task.type.zero_tax_report': 'Kørsel af 0-indberetning',

  'pay_rolls.active.title': 'Løn',
  'pay_rolls.add.alert.success': 'Ekstraordinær lønkørsel oprettet',
  'pay_rolls.add.form.salary_cycle_id': 'Vælg løncyklus',
  'pay_rolls.add.form.salary_period_id': 'Vælg lønperiode',
  'pay_rolls.add.form.submit': 'Start ekstraordinær lønkørsel',
  'pay_rolls.add.form.type': 'Type',
  'pay_rolls.add.form.type.extra': 'Ekstraordinær lønkørsel',
  'pay_rolls.add.form.type.zero_tax_report': 'Nulindberetning',
  'pay_rolls.add.intro': 'Lav en lønkørsel med bestemte medarbejdere for en bestemt periode.',
  'pay_rolls.add.title': 'Opret ekstraordinær løn',
  'pay_rolls.create_manual_pay_roll': 'Opret ekstraordinær løn',
  'pay_rolls.dormant.button_text': 'Begynd at køre lønkørsler automatisk igen',
  'pay_rolls.dormant.description':
    'Denne virksomhed har ikke kørt løn i længere tid, derfor starter Salary ikke længere automatisk nye lønkørsler.',
  'pay_rolls.header.amount': 'Beløb',
  'pay_rolls.header.period': 'Periode',
  'pay_rolls.header.state': 'Status',
  'pay_rolls.header.type': 'Type',
  'pay_rolls.inactive.title': 'Tidligere lønkørsler',
  'pay_rolls.not_approved.description':
    'Skriv til Salary Support hvis din virksomhed ikke er godkendt indenfor 24 timer.',
  'pay_rolls.not_approved.support_link': 'Skriv til support',
  'pay_rolls.not_approved.title': 'Når din virksomhed er godkendt vil den næste lønkørsel fremgå her',
  'pay_rolls.table.deadline': 'senest',
  'pay_rolls.table.run_at': 'kører',
  'pay_rolls.type.manual': 'Ekstraordinær lønkørsel',
  'pay_rolls.type.negation': 'Tilbageførsel af lønkørsel',
  'pay_rolls.type.terminated_employments': 'Opsigelse af funktionærer',
  'pay_rolls.type.zero_tax_report': 'Nulindberetning',

  'pay_slip_fields.alert.success': 'Ændringer til {name} er gemt',
  'pay_slip_fields.link.form.pay_slip_questions_link': 'Link på lønseddel',
  'pay_slip_fields.link.form.pay_slip_questions_link.required': 'Link på lønseddel skal indtastes',
  'pay_slip_fields.link.form.pay_slip_questions_visible': 'Slå hjælpelink til på lønseddel',
  'pay_slip_fields.link.form.submit': 'Gem ændringer til link på lønseddel',
  'pay_slip_fields.link.title': 'Link på lønseddel',
  'pay_slip_fields.logo.form.button.create': 'Tilføj logo',
  'pay_slip_fields.logo.form.button.delete': 'Fjern logo',
  'pay_slip_fields.logo.form.button.edit': 'Ret logo',
  'pay_slip_fields.logo.form.note': 'Ret firmalogoet på dine medarbejderes lønsedler. Billedet må max. være 60 KB.',
  'pay_slip_fields.logo.title.create': 'Tilføj firmalogo',
  'pay_slip_fields.logo.title.edit': 'Ret firmalogo',

  'pay_slips_form.pay_slip_transport_e_boks': 'Sendes til e-Boks',
  'pay_slips_form.pay_slip_transport_e_boks.lock': 'Opgradér til Basic, for at sende til e-Boks',
  'pay_slips_form.pay_slip_transport_e_boks.price_note': 'Koster {price} per lønseddel',
  'pay_slips_form.pay_slip_transport_email': 'Sendes på e-mail',
  'pay_slips_form.pay_slip_transport_email.lock': 'Indtast e-mail-adresse for at slå lønsedler via e-mail til',
  'pay_slips_form.pay_slip_transport_mit_dk': 'Sendes til mit.dk',
  'pay_slips_form.pay_slip_transport_mit_dk.lock': 'Opgradér til Basic, for at sende til mit.dk',
  'pay_slips_form.pay_slip_transport_sms': 'Sendes til SMS',
  'pay_slips_form.pay_slip_transport_sms.feature_lock': 'Opgradér til Basic, for at sende via SMS',
  'pay_slips_form.pay_slip_transport_sms.lock': 'Indtast telefonnummer for at slå lønsedler via SMS til',

  'pay_slips_tab.header.download_all': 'Hent alle gennemførte lønsedler',
  'pay_slips_tab.pdf_password.allow_password_on_payslip': 'Tillad brugerdefineret kodeord til lønseddel',
  'pay_slips_tab.pdf_password.missing': 'Kodeordet skal være sat',
  'pay_slips_tab.pdf_password.password': 'Kodeord',
  'pay_slips_tab.pdf_password.password_header': 'Angiv brugerdefineret kodeord til lønseddel',
  'pay_slips_tab.pdf_password.password_on_payslip': 'Kodeord på lønseddel',
  'pay_slips_tab.pdf_password.save': 'Gem kodeord',
  'pay_slips_tab.pdf_password.set_password': 'Sæt kodeord',
  'pay_slips_tab.resend.error': 'Kunne ikke gensende lønseddel',
  'pay_slips_tab.resend.success': 'Lønsedlen er blevet gensendt til medarbejderens e-mail',
  'pay_slips_tab.table.actions.download': 'Hent',
  'pay_slips_tab.table.actions.payroll': 'Gå til lønkørslen',
  'pay_slips_tab.table.actions.resend': 'Gensend lønseddel til medarbejderens e-mail',
  'pay_slips_tab.table.header.disposition_date': 'Til disposition',
  'pay_slips_tab.table.header.gross_pay': 'Løn brutto',
  'pay_slips_tab.table.header.period': 'Periode',
  'pay_slips_tab.table.header.settled': 'Gennemført',
  'pay_slips_tab.table.settled.true': 'Ja',
  'pay_slips_tab.title': 'Lønsedler',

  'payment_method.manual': 'Manuelt',
  'payment_method.nets': 'Overførselsservice',
  'payment_method.stripe': 'Betalingskort',
  'payment_method.zimpler': 'Zimpler',

  'payroll_approve.login.login_again': 'Log ind igen',
  'payroll_approve.login.login_with_sms_code': 'Log ind med SMS-kode',
  'payroll_approve.login.submit': 'Log ind',
  'payroll_approve.page.card.approve_deadline': 'Godkendes senest {time}',
  'payroll_approve.page.card.approve.approval_code': 'Godkendelseskode',
  'payroll_approve.page.card.approve.approve': 'Godkend',
  'payroll_approve.page.card.approve.reject': 'Afvis',
  'payroll_approve.page.card.download_payslips': 'Hent alle lønsedler som PDF',
  'payroll_approve.page.card.log_out': 'Log ud',
  'payroll_approve.page.card.pay_roll_for': 'Lønkørsel for',
  'payroll_approve.page.card.pay_roll_status': 'Lønstatus: {status}',
  'payroll_approve.page.error.unknown_company': 'Ukendt virksomhed',
  'payroll_approve.page.error.unknown_pay_roll': 'Ukendt lønkørsel',
  'payroll_approve.pay_slips.table.header.gross_pay': 'Bruttoløn',
  'payroll_approve.pay_slips.table.header.name': 'Navn',
  'payroll_approve.pay_slips.table.header.net_pay': 'Nettoløn',
  'payroll_approve.reopen.intro': 'Du er ved at afvise lønkørslen. Du kan beskrive hvorfor du afviser lønkørslen.',
  'payroll_approve.reopen.message': 'Besked',
  'payroll_approve.reopen.reopen': 'Afvis',

  'pension_definition.scheme.atp': 'ATP',
  'pension_definition.title.no_pension_company': '{scheme}betalt pension (overførsel)',
  'pension_definition.title.no_pension_company.net': '53A - {scheme}betalt pension (overførsel)',
  'pension_definition.title.pension_company': '{scheme}betalt pension ({name})',
  'pension_definition.title.pension_company.net': '53A - {scheme}betalt pension ({name})',

  'pension.card.edit.form.account': 'Kontonr.',
  'pension.card.edit.form.account.invalid': 'Kontonr. er ugyldigt',
  'pension.card.edit.form.account.required': 'Kontonr. skal indtastes',
  'pension.card.edit.form.add_row': 'Tilføj pensionsrække',
  'pension.card.edit.form.company': 'Selskab',
  'pension.card.edit.form.company.no_company': 'Intet selskab',
  'pension.card.edit.form.company.required': 'Pensionsselskab skal vælges',
  'pension.card.edit.form.customer_id.required': '{name} skal indtastes',
  'pension.card.edit.form.has_net_pension': 'Nettopension',
  'pension.card.edit.form.has_pension.false': 'Skal ikke modtage pension',
  'pension.card.edit.form.has_pension.true': 'Skal modtage pension',
  'pension.card.edit.form.include_vacation_in_pension_basis': 'Inkludér ferie i pensionsgrundlaget',
  'pension.card.edit.form.scheme': 'Betalt af',
  'pension.card.edit.form.scheme.required': 'Betalt af skal vælges',
  'pension.card.edit.form.sort_code': 'Reg.nr.',
  'pension.card.edit.form.sort_code.invalid': 'Reg.nr. er ugyldigt',
  'pension.card.edit.form.sort_code.required': 'Reg.nr. skal indtastes',
  'pension.card.edit.form.table.company': 'Selskab',
  'pension.card.edit.form.table.customer_id': 'Nummer',
  'pension.card.edit.form.table.rate': 'Indbetaling',
  'pension.card.edit.form.table.scheme': 'Betalt af',
  'pension.card.edit.form.table.union_agreement_number': 'Overenskomst',
  'pension.card.edit.form.union_agreement_number': 'Nummer',
  'pension.card.edit.form.union_agreement_number.not_five_characters': 'Overenskomstnummer skal være fem tegn langt',
  'pension.card.edit.form.union_agreement_number.tooltip.line_1': 'Overenskomstnummer er ikke påkrævet.',
  'pension.card.edit.form.union_agreement_number.tooltip.line_2':
    "Feltet skal være 5 tegn langt, du kan foranstille feltet med 0'er.",
  'pension.card.edit.form.valid_from.at_date': 'Ændringen træder i kraft den {date}.',
  'pension.card.edit.form.valid_from.now': 'Ændringen træder i kraft med det samme.',
  'pension.card.edit.form.value.fixed': 'Beløb',
  'pension.card.edit.form.value.fixed.short': 'kr.',
  'pension.card.edit.form.value.invalid': '{suffix} er ugyldig',
  'pension.card.edit.form.value.percentage': 'Procent',
  'pension.card.edit.form.value.percentage.short': '%',
  'pension.card.edit.form.value.required': '{suffix} skal indtastes',
  'pension.card.edit.title': 'Redigér pension',
  'pension.card.table.header.company': 'Selskab',
  'pension.card.table.header.number': 'Nummer',
  'pension.card.table.header.rate': 'Indbetaling',
  'pension.card.table.header.scheme': 'Betalt af',
  'pension.card.table.net': '(Netto)',
  'pension.card.table.suffix.fixed': 'kr.',
  'pension.card.table.suffix.percentage': '%',
  'pension.card.table.transfer': 'Overførsel',
  'pension.card.title': 'Pension',
  'pension.customer_type.account_id': 'Kontonummer',
  'pension.customer_type.agreement_id': 'Aftalenummer',
  'pension.customer_type.employer_id': 'Firmanummer',
  'pension.customer_type.member_id': 'Medlemsnummer',
  'pension.customer_type.national_id': 'CPR-nummer',
  'pension.customer_type.number': 'Nummer',
  'pension.scheme.employee': 'Medarbejder',
  'pension.scheme.employer': 'Arbejdsgiver',

  'person_verification.error.unable_to_find_url': 'Uventet fejl, kontakt Salary.',
  'person_verification.identity_already_verified': 'Din identitet er allerede bekræftet',
  'person_verification.sign_with_mitid': 'Bekræft med MitID',
  'person_verification.signed': 'Tak for bekræfte din identitet.',
  'person_verification.title': 'Bekræft din identitet',

  'preview.close': 'Luk dokument',
  'preview.download_pdf': 'Hent PDF',

  'pricing_campaign.form_description': 'Har du en kampagnekode, kan du indløse den her.',
  'pricing_campaign.form_title': 'Indløs kampagnekode',
  'pricing_campaign.form.code': 'Kampagnekode',
  'pricing_campaign.form.submit': 'Indløs',
  'pricing_campaign.form.validation.code_required': 'Kampagnekode skal indtastes',

  'pricing_package.action.downgrade.button': 'Nedgradér',
  'pricing_package.action.downgrade.contact': 'Skriv til support',
  'pricing_package.action.upgrade.button': 'Opgradér',
  'pricing_package.alert.success': 'Ændringer til {name} er gemt',
  'pricing_package.package.automatic.active.subtitle':
    'Hvis du vil nedgradere til gratis, skal du skrive til support. Vær opmærksom på at alle inkluderede integrationer og funktioner fjernes.',
  'pricing_package.package.automatic.active.title':
    'Du er Basic-kunde, der automatiserer løn samt minimerer fejl og manuelt arbejde',
  'pricing_package.package.automatic.feature_1.line_1':
    'Mulighed for at sende lønsedler til medarbejdernes mit.dk & e-Boks.',
  'pricing_package.package.automatic.feature_1.title': 'mit.dk & e-Boks',
  'pricing_package.package.automatic.feature_2.line_1':
    'Invitér dine medarbejder til vores medarbejder-app, så de kan registrere timer, kørsel og ferie samt se deres lønsedler.',
  'pricing_package.package.automatic.feature_2.title': 'Medarbejder-app',
  'pricing_package.package.automatic.feature_3.line_1':
    'Undgå at glemme en lønkørsel. Med automatisk lønkørsel, køres lønnen automatisk i slutningen af lønperioden.',
  'pricing_package.package.automatic.feature_3.title': 'Automatisk lønkørsel',
  'pricing_package.package.automatic.feature_4.line_1': 'Lad Salary bogføre alle dine lønposter for dig automatisk.',
  'pricing_package.package.automatic.feature_4.title': 'Regnskabsintegration',
  'pricing_package.package.automatic.read_more_url': 'https://salary.dk/priser/',
  'pricing_package.package.automatic.subtitle':
    'Til dig der vil automatisere løn samt minimere fejl og manuelt arbejde',
  'pricing_package.package.automatic.title': 'Opgradér til Basic for kun {price}',
  'pricing_package.package.automatic.title_price': '{price}/lønseddel',
  'pricing_package.package.premium.active.subtitle':
    'Du kan nedgradere til Basic, når du vil, men du skal være opmærksom på at Premium-funktionerne bliver fjernet.',
  'pricing_package.package.premium.active.title':
    'Du er Premium-kunde, der giver dig alle funktioner og fordele - fuld digitalisering og effektivisering',
  'pricing_package.package.premium.feature_1.line_1':
    'Kontakt vores telefonsupport i åbningstiden fra 9—15, og få hjælp til Salary.',
  'pricing_package.package.premium.feature_1.title': 'Telefonsupport',
  'pricing_package.package.premium.feature_2.line_1':
    'Administrér udlevering og tilbagelevering af alle din virksomheds enheder, uanset om det er er mobiltelefoner, værktøj eller noget helt tredje.  Vi giver dig et samlet overblik.',
  'pricing_package.package.premium.feature_2.title': 'Enhedshåndtering',
  'pricing_package.package.premium.feature_3.line_1':
    'Opsæt virksomhedsskabeloner og unikke skabeloner for dine medarbejdere, således at deres arbejdstimer automatisk registreres korrekt, til gavn og glæde for både dine medarbejdere og virksomheden.',
  'pricing_package.package.premium.feature_3.title': 'Arbejdstidsskabeloner',
  'pricing_package.package.premium.feature_4.line_1':
    "Jeres ansattes udlæg indsamles nemt via medarbejder-app'en i Salary, og tilføjes automatisk på lønsedlen, samt overføres til bogføring, når udlægget er godkendt og skal udbetales.",
  'pricing_package.package.premium.feature_4.title': 'Udlægshåndtering',
  'pricing_package.package.premium.read_more_url': 'https://salary.dk/priser/',
  'pricing_package.package.premium.subtitle':
    'Til dig der vil have alle funktioner og fordele samt ønsker digitalisering og effektivisering',
  'pricing_package.package.premium.title': 'Opgradér til Premium for {price}',
  'pricing_package.package.premium.title_price': '{price}/lønseddel',
  'pricing_package.package.reimbursement_voucher.intro.line_1':
    "Jeres ansattes udlæg indsamles nemt via medarbejder-app'en i Salary, og tilføjes automatisk på lønsedlen når udlægget er godkendt og skal udbetales.",
  'pricing_package.package.reimbursement_voucher.intro.read_more': 'Læs mere',
  'pricing_package.package.reimbursement_voucher.intro.read_more_url':
    'https://blog.salary.dk/digitaliser-jeres-udlaeg-og-slip-for-kroellede-kvitteringer/',
  'pricing_package.package.reimbursement_voucher.pricing.per_1': 'måned',
  'pricing_package.package.reimbursement_voucher.pricing.per_2': 'virksomhed',
  'pricing_package.package.reimbursement_voucher.title': 'Udlægshåndtering',
  'pricing_package.package.reimbursement_voucher.title_note': '(kræver Basic)',
  'pricing_package.package.swipe.intro.line_1':
    'Med Swipe kan dine ansatte få udbetalt deres optjente løn, når de har lyst. Det kræver kun ét swipe fra mobilen – og lønnen er på vej.',
  'pricing_package.package.swipe.intro.line_2': 'Du sætter rammerne, og får gladere medarbejdere.',
  'pricing_package.package.swipe.link_text': 'Slås til under virksomhed - overførsler',
  'pricing_package.package.swipe.pricing.per_1': 'medarbejder',
  'pricing_package.package.swipe.pricing.per_2': 'lønperiode',
  'pricing_package.package.swipe.title': 'Swipe',
  'pricing_package.package.swipe.title_note': '(kræver Basic og Overførselsservice)',
  'pricing_package.package.work_hours_automatic.intro.line_1':
    'I stedet for at oprette arbejdstidsregistreringer manuelt, kan I gøre det automatisk med skabeloner.  Man betaler kun for de medarbejdere der bruger skabeloner.',
  'pricing_package.package.work_hours_automatic.intro.read_more': 'Læs mere',
  'pricing_package.package.work_hours_automatic.intro.read_more_url':
    'https://blog.salary.dk/digitaliser-jeres-udlaeg-og-slip-for-kroellede-kvitteringer/',
  'pricing_package.package.work_hours_automatic.pricing.per_1': 'medarbejder',
  'pricing_package.package.work_hours_automatic.pricing.per_2': 'måned',
  'pricing_package.package.work_hours_automatic.title': 'Arbejdstidsskabelon',
  'pricing_package.package.work_hours_automatic.title_note': '(kræver Basic)',
  'pricing_package.pricing.per': 'per {unit}',

  'production_units.alert.success': 'Ændringer til {name} er gemt',
  'production_units.form.active': 'Aktiv',
  'production_units.form.add_row': 'Tilføj produktionsenhed',
  'production_units.form.name': 'Beskrivelse',
  'production_units.form.name.required': 'Beskrivelse skal indtastes',
  'production_units.form.number': 'Nummer',
  'production_units.form.number.invalid': 'Nummer er ugyldigt',
  'production_units.form.number.required': 'Nummer skal indtastes',
  'production_units.title': 'Produktionsenheder',

  'projects.edit.form.description': 'Beskrivelse',
  'projects.edit.form.employees.none': 'Ingen medarbejdere valgt...',
  'projects.edit.form.employees.select': 'Vælg medarbejdere',
  'projects.edit.form.external_id': 'Ekstern reference',
  'projects.edit.form.hourly_rate': 'Timesats',
  'projects.edit.form.hourly_rate.invalid': 'Timesats er ugyldig',
  'projects.edit.form.hourly_rate.suffix': 'kr.',
  'projects.edit.form.name': 'Projektnavn',
  'projects.edit.form.name.required': 'Projektnavn skal indtastes',
  'projects.edit.form.period_note':
    'Per standard er et projekt altid gældende, men du kan slå start- og slutdatoer til, som tidsafgrænser projekterne.',
  'projects.edit.form.valid_from': 'Startdato',
  'projects.edit.form.valid_from_enabled': 'Slå startdato til',
  'projects.edit.form.valid_to': 'Slutdato',
  'projects.edit.form.valid_to_enabled': 'Slå slutdato til',
  'projects.edit.title.child.create': 'Opret underprojekt til {name}',
  'projects.edit.title.child.edit': 'Redigér underprojekt til {name}',
  'projects.edit.title.standard.create': 'Opret projekt',
  'projects.edit.title.standard.edit': 'Redigér projekt',
  'projects.employee_select.submit': 'Bekræft valg af medarbejdere',
  'projects.employee_select.title': 'Vælg medarbejdere til projektet',
  'projects.header.new_project': 'Opret nyt hovedprojekt',
  'projects.header.new_project.lock': 'Opgradér til Premium for at kunne bruge projekter',
  'projects.header.title': 'Projekter',
  'projects.table.actions.add_child': 'Tilføj underprojekt',
  'projects.table.actions.delete': 'Slet projekt',
  'projects.table.actions.edit': 'Redigér projekt',
  'projects.table.empty': 'Ingen projekter endnu...',
  'projects.table.external_id': 'Eksternt ID',
  'projects.table.header.employees': 'Medarbejdere',
  'projects.table.header.project': 'Projekt',
  'projects.table.hourly_rate': 'Timesats',

  'register.form.already_user': 'Har du allerede en bruger? {link}.',
  'register.form.already_user.link': 'Log ind her',
  'register.form.email': 'E-mail',
  'register.form.email.invalid': 'E-mail er ugyldig',
  'register.form.email.required': 'E-mail skal indtastes',
  'register.form.name': 'Navn',
  'register.form.name.required': 'Navn skal indtastes',
  'register.form.password': 'Adgangskode',
  'register.form.password.at_least_8_characters': 'Adgangskode skal være mindst 8 tegn',
  'register.form.password.required': 'Adgangskode skal indtastes',
  'register.form.submit': 'Tilmeld dig gratis',
  'register.form.terms': 'Jeg accepterer {terms}',
  'register.form.terms.required': 'Betingelser skal accepteres',
  'register.form.terms.terms': 'betingelserne',
  'register.presentation.points.0': 'Automatisk indberetning og betaling til SKAT, ATP, feriekonto, pension',
  'register.presentation.points.1': 'Automatisk bogføring i førende regnskabsprogrammer',
  'register.presentation.points.2': 'Intuitiv og brugervenlig oplevelse, hele vejen igennem',
  'register.presentation.points.3': 'Medarbejder-app til fravær, tidsregistrering og kørsel',
  'register.presentation.points.4': 'Gratis 0-indberetning, helt automatisk',
  'register.presentation.points.5': 'Fuldt overblik over ferie',
  'register.presentation.points.6': 'Lynhurtig support',
  'register.presentation.points.7': 'Ingen binding',
  'register.presentation.title': 'Med Salary får du...',
  'register.title': 'Danmarks letteste lønsystem',

  'registration_form.cost_center.cost_center_id.cost_center': 'Omkostningssted',
  'registration_form.cost_center.cost_center_id.department': 'Afdeling',

  'registration_method_app_alert.button.car_allowance': 'Skift til detaljeret',
  'registration_method_app_alert.button.salary_registration': 'Skift til detaljeret',
  'registration_method_app_alert.button.time_registration': 'Skift til detaljeret',
  'registration_method_app_alert.message.car_allowance':
    'Medarbejderen har simpel kørselsregistrering, og kan derfor ikke selv registrere kørsel i appen.',
  'registration_method_app_alert.message.salary_registration':
    'Medarbejderen har simpel lønregistrering, og kan derfor ikke selv registrere løntillæg i appen.',
  'registration_method_app_alert.message.time_registration':
    'Medarbejderen har simpel tidsregistrering, og kan derfor ikke selv registrere timer i appen.',

  'registrations.general.delete_hidden_registrations': 'Klik her for at slette dem',
  'registrations.general.hidden_coarse_registrations':
    'Der gemmer sig {amount} {descriptor} til en værdi af {value}. Da medarbejderen bruger detaljeret registrering, kan du ikke redigere dem.',
  'registrations.general.hidden_detailed_registrations':
    'Der gemmer sig {amount} {descriptor} til en værdi af {value}. Da medarbejderen bruger simpel registrering, kan du ikke redigere dem.',
  'registrations.general.switch_to_coarse': 'Skift til simpel',
  'registrations.general.switch_to_detailed': 'Skift til detaljeret',
  'registrations.layout.awaiting_approval': 'Afventer godkendelse',
  'registrations.layout.car_allowances': 'Kørselsregnskab',
  'registrations.layout.freelancers': 'Honorarregistrering',
  'registrations.layout.leave_registrations': 'Fraværsregistrering',
  'registrations.layout.one_time_pays': 'Løntillæg/fradrag',
  'registrations.layout.reimbursement_vouchers': 'Udlægsoverblik',
  'registrations.layout.salary_registrations': 'Lønregistrering',
  'registrations.layout.swipes': 'Swipe™',
  'registrations.layout.time_registrations': 'Tidsregistrering',
  'registrations.layout.work_hours': 'Arbejdstid',

  'reimbursement_settings.alert.success': 'Ændringer til udlægsindstillinger er gemt',
  'reimbursement_settings.company.form.auto_approval_limit': 'Generel autogodkendelsesgrænse per udlæg',
  'reimbursement_settings.company.form.auto_approval_limit.invalid':
    'Generel autogodkendelsesgrænse per udlæg er ugyldig',
  'reimbursement_settings.company.form.auto_approval_limit.note.immediate_pay_no_limit':
    'Hvis 0 kr. eller ikke sat, så vil alle udlæg blive autogodkendt (pånær ekspresudbetalinger).',
  'reimbursement_settings.company.form.auto_approval_limit.note.immediate_pay_with_limit':
    'Hvis 0 kr. eller ikke sat, så vil alle udlæg blive autogodkendt (pånær ekspresudbetalinger der overstiger ekspresudbetalingsgrænsen på {amount}).',
  'reimbursement_settings.company.form.auto_approval_limit.note.no_immediate_pay':
    'Hvis 0 kr. eller ikke sat, så vil alle udlæg blive autogodkendt.',
  'reimbursement_settings.company.form.auto_approval_limit.suffix': 'kr.',
  'reimbursement_settings.company.form.auto_approve': 'Autogodkendelse for udlæg',
  'reimbursement_settings.employee.form.auto_approval_limit': 'Autogodkendelsesgrænse per udlæg',
  'reimbursement_settings.employee.form.auto_approval_limit.company_limit': 'Virksomhedens generelle grænse ({amount})',
  'reimbursement_settings.employee.form.auto_approval_limit.no_limit': 'Ingen grænse',
  'reimbursement_settings.employee.form.auto_approval_limit.remove': 'Fjern',
  'reimbursement_settings.employee.form.auto_approval_limit.required': 'Autogodkendelsesgrænse per udlæg er påkrævet',
  'reimbursement_settings.employee.form.auto_approval_limit.save': 'Gem',
  'reimbursement_settings.employee.form.auto_approval_limit.suffix': 'kr.',
  'reimbursement_settings.employee.header.amount': 'Autogodkendelsesgrænse per udlæg',
  'reimbursement_settings.employee.header.employee': 'Medarbejder',
  'reimbursement_settings.employee.note.line_1.immediate_pay_no_limit':
    'Du kan indstille autogodkendelsegrænser per udlæg per medarbejder, som trumfer den generelle grænse for virksomheden, også hvis grænsen på en medarbejder er højere end den for virksomheden (ekspresudbetalinger skal dog altid godkendes).',
  'reimbursement_settings.employee.note.line_1.immediate_pay_with_limit':
    'Du kan indstille autogodkendelsegrænser per udlæg per medarbejder, som trumfer den generelle grænse for virksomheden, også hvis grænsen på en medarbejder er højere end den for virksomheden (autogodkendelsesgrænsen på {amount} for ekspresudbetalinger vil dog altid gælde).',
  'reimbursement_settings.employee.note.line_1.no_immediate_pay':
    'Du kan indstille autogodkendelsegrænser per udlæg per medarbejder, som trumfer den generelle grænse for virksomheden, også hvis grænsen på en medarbejder er højere end den for virksomheden.',
  'reimbursement_settings.employee.note.line_2':
    'Hvis du sætter grænsen til 0 kr., skal alle udlæg for den pågældende medarbejder manuelt godkendes. For at fjerne den individuelle grænse igen, klik på fjern-knappen.',
  'reimbursement_settings.employee.note.line_3': 'Klik på beløbene for at ændre dem.',
  'reimbursement_settings.employee.title': 'Autogodkendelsesgrænser per medarbejder',
  'reimbursement_settings.header.back': 'Tilbage',
  'reimbursement_settings.header.immediate_pay': 'Ekspresudbetalingsindstillinger',
  'reimbursement_settings.title': 'Udlægsindstillinger',

  'reimbursement_vouchers.edit.form.amount': 'Beløb',
  'reimbursement_vouchers.edit.form.amount.invalid': 'Beløb er ugyldigt',
  'reimbursement_vouchers.edit.form.approved': 'Godkendt',
  'reimbursement_vouchers.edit.form.approved.false': 'Nej',
  'reimbursement_vouchers.edit.form.approved.true': 'Ja',
  'reimbursement_vouchers.edit.form.card_note': 'Beskrivelse af firmakort til regnskab',
  'reimbursement_vouchers.edit.form.card_note.placeholder': 'Beskrivelse',
  'reimbursement_vouchers.edit.form.cost_center_id.cost_center': 'Omkostningssted',
  'reimbursement_vouchers.edit.form.cost_center_id.cost_center.none': 'Intet omkostningssted',
  'reimbursement_vouchers.edit.form.cost_center_id.department': 'Afdeling',
  'reimbursement_vouchers.edit.form.cost_center_id.department.none': 'Egen afdeling',
  'reimbursement_vouchers.edit.form.currency': 'Valutakode',
  'reimbursement_vouchers.edit.form.currency.invalid': 'Valutakode er ugyldig',
  'reimbursement_vouchers.edit.form.currency.required': 'Valutakode skal vælges',
  'reimbursement_vouchers.edit.form.date': 'Dato',
  'reimbursement_vouchers.edit.form.date.required': 'Dato skal indtastes',
  'reimbursement_vouchers.edit.form.disburse_amount': 'Beløb i danske kroner',
  'reimbursement_vouchers.edit.form.disburse_amount.required': 'Beløb i danske kroner skal indtastes',
  'reimbursement_vouchers.edit.form.disburse_amount.suffix': 'kr.',
  'reimbursement_vouchers.edit.form.disburseable': 'Udlægstype',
  'reimbursement_vouchers.edit.form.disburseable.false': 'Firmakort (kun bogføring)',
  'reimbursement_vouchers.edit.form.disburseable.true': 'Eget udlæg (til udbetaling)',
  'reimbursement_vouchers.edit.form.expense_category_id': 'Udgiftskategori',
  'reimbursement_vouchers.edit.form.expense_category_id.required': 'Udgiftskategori skal vælges',
  'reimbursement_vouchers.edit.form.foreign_amount': 'Udenlandsk beløb',
  'reimbursement_vouchers.edit.form.immediate_pay': 'Ekspresudbetaling',
  'reimbursement_vouchers.edit.form.is_foreign_currency': 'Udenlandsk valuta',
  'reimbursement_vouchers.edit.form.is_foreign_currency.false': 'Nej',
  'reimbursement_vouchers.edit.form.is_foreign_currency.true': 'Ja',
  'reimbursement_vouchers.edit.form.note': 'Note',
  'reimbursement_vouchers.edit.form.ready': 'Klar til godkendelse',
  'reimbursement_vouchers.edit.form.ready.false': 'Kladde',
  'reimbursement_vouchers.edit.form.ready.true': 'Klar',
  'reimbursement_vouchers.edit.form.voucher.download': 'Hent bilag',
  'reimbursement_vouchers.edit.form.voucher.title': 'Kvittering',
  'reimbursement_vouchers.edit.form.warning.older_than_12_months':
    'NB: Kvitteringsdatoen er mere end 12 måneder gammel.',
  'reimbursement_vouchers.edit.title.create': 'Opret udlæg',
  'reimbursement_vouchers.edit.title.edit': 'Redigér udlæg',
  'reimbursement_vouchers.header.approve_all': 'Godkend alle',
  'reimbursement_vouchers.header.disable_feature': 'Slå udlæg fra',
  'reimbursement_vouchers.header.enable_feature': 'Slå udlæg til',
  'reimbursement_vouchers.header.enable_feature.lock':
    'Opgradér til Premium eller slå tillægsmodulet til i Basic-pakken for at kunne bruge udlæg',
  'reimbursement_vouchers.header.expense_categories': 'Udlægskategorier',
  'reimbursement_vouchers.header.history.back': 'Tilbage',
  'reimbursement_vouchers.header.history.show': 'Vis historik',
  'reimbursement_vouchers.header.new_voucher': 'Opret nyt udlæg',
  'reimbursement_vouchers.header.report': 'Rapport',
  'reimbursement_vouchers.header.settings': 'Indstillinger',
  'reimbursement_vouchers.new.form.employee.disabled': '{name} (kan ikke selv oprette udlæg)',
  'reimbursement_vouchers.new.form.employee.placeholder': 'Medarbejder',
  'reimbursement_vouchers.new.form.employee.terminated': '{name} (opsagt)',
  'reimbursement_vouchers.new.form.upload': 'Upload udlæg',
  'reimbursement_vouchers.new.intro':
    'Før du kan oprette et nyt udlæg, skal du vælge en medarbejder, som udlægget skal tilknyttes.',
  'reimbursement_vouchers.new.title': 'Opret udlæg',
  'reimbursement_vouchers.report_modal.report.download': 'Hent bogføringsrapport',
  'reimbursement_vouchers.report_modal.title': 'Udlægsrapporter',
  'reimbursement_vouchers.report_modal.zip.download': 'Hent',
  'reimbursement_vouchers.report_modal.zip.from': 'Fra-dato',
  'reimbursement_vouchers.report_modal.zip.intro':
    'Zipfilen indeholder alle udlægsfilerne samt deres bogføringsbilag i en csvfil ved siden af dem.',
  'reimbursement_vouchers.report_modal.zip.title': 'Zipfil',
  'reimbursement_vouchers.report_modal.zip.to': 'Til-dato',
  'reimbursement_vouchers.state.approved': 'Godkendt',
  'reimbursement_vouchers.state.booking.booked': 'Bogført',
  'reimbursement_vouchers.state.booking.daybooked': 'Overført til kassekladde',
  'reimbursement_vouchers.state.booking.failed': 'Fejlet bogføring',
  'reimbursement_vouchers.state.booking.ready': 'Afventer bogføring',
  'reimbursement_vouchers.state.default': 'Behandles',
  'reimbursement_vouchers.state.ready': 'Afventer godkendelse',
  'reimbursement_vouchers.table.actions.approve': 'Godkend',
  'reimbursement_vouchers.table.actions.book_again': 'Bogfør igen',
  'reimbursement_vouchers.table.actions.delete': 'Slet',
  'reimbursement_vouchers.table.actions.edit': 'Redigér',
  'reimbursement_vouchers.table.actions.remove_approval': 'Fjern godkendelse',
  'reimbursement_vouchers.table.actions.send_back': 'Send tilbage',
  'reimbursement_vouchers.table.amount.cost_center_note': 'Bogføres i',
  'reimbursement_vouchers.table.amount.disburseable': 'til udbetaling',
  'reimbursement_vouchers.table.amount.immediate_pay': 'Til ekspresudbetaling',
  'reimbursement_vouchers.table.amount.not_disburseable': 'kun bogføring',
  'reimbursement_vouchers.table.book.approve': 'Godkend nu',
  'reimbursement_vouchers.table.book.book_again': 'Bogfør igen',
  'reimbursement_vouchers.table.category.missing': 'Mangler kategori',
  'reimbursement_vouchers.table.date.received': 'Modtaget',
  'reimbursement_vouchers.table.header.amount': 'Beløb',
  'reimbursement_vouchers.table.header.date': 'Kvitteringsdato',
  'reimbursement_vouchers.table.header.employee': 'Medarbejder',
  'reimbursement_vouchers.table.header.expense_category': 'Udgiftskategori',
  'reimbursement_vouchers.table.header.state': 'Status',
  'reimbursement_vouchers.title.active': 'Udlægsoverblik',
  'reimbursement_vouchers.title.history': 'Historisk udlægsoverblik',

  'reimbursements.edit.form.amount': 'udlæg',
  'reimbursements.edit.form.disposition_date': 'Hvornår skal udlægget udbetales?',
  'reimbursements.edit.form.disposition_date.placeholder': 'Udbetalingsdato',
  'reimbursements.edit.intro': 'Vær opmærksom på at du selv skal kunne dokumentere bilag overfor SKAT.',
  'reimbursements.edit.title.create': 'Opret udlæg',
  'reimbursements.edit.title.edit': 'Redigér udlæg',
  'reimbursements.edit.title.view': 'Se udlæg',
  'reimbursements.header.approve_all': 'Godkend alle',
  'reimbursements.header.hide_history': 'Tilbage',
  'reimbursements.header.new_reimbursement': 'Opret udlæg',
  'reimbursements.header.new_reimbursement_voucher': 'Upload udlæg',
  'reimbursements.header.new_reimbursement_voucher.lock':
    'Opgradér til Premium eller slå tillægsmodulet til i Basic-pakken for at kunne bruge udlæg med vedhæftede filer',
  'reimbursements.header.new_reimbursement_voucher.lock_disabled':
    'Slå udlægshåndtering til under registrering > udlæg, for at kunne bruge udlæg med vedhæftede filer',
  'reimbursements.header.reimbursement_vouchers_note': 'For at se udlæg med vedhæftede filer, se {link}.',
  'reimbursements.header.reimbursement_vouchers_note.link': 'udlægshåndtering',
  'reimbursements.header.show_history': 'Vis historik',
  'reimbursements.table.actions.delete': 'Slet udlæg',
  'reimbursements.table.actions.edit': 'Redigér udlæg',
  'reimbursements.table.actions.view': 'Gennemse udlæg',
  'reimbursements.table.amount_format': '{units} à {rate}',
  'reimbursements.table.approved.approve': 'Godkend',
  'reimbursements.table.approved.false': 'Nej',
  'reimbursements.table.approved.remove': 'Fjern godkendelse',
  'reimbursements.table.approved.true': 'Ja',
  'reimbursements.table.header.amount': 'Beløb',
  'reimbursements.table.header.approved': 'Godkendt',
  'reimbursements.table.header.date': 'Dato',
  'reimbursements.table.header.title': 'Beskrivelse',
  'reimbursements.table.saving': 'Gemmer...',
  'reimbursements.title.history': 'Historik for udlæg',
  'reimbursements.title.standard': 'Udlæg',

  'report_button.done': ' (klar)',
  'report_button.download': 'Hent {type}',
  'report_button.waiting': 'Rapporten bygges...',

  'report_dst.automatic.form.da_union_code': 'Dansk Arbejdsgiver-foreningskode',
  'report_dst.automatic.form.da_union_code.invalid': 'Dansk Arbejdsgiver-foreningskode er ugyldigt',
  'report_dst.automatic.form.enable_absence_statistics': 'Fraværsstatistik',
  'report_dst.automatic.form.enable_quarterly_salary_statistics': 'Kvartalvis statistik',
  'report_dst.automatic.form.enable_yearly_salary_statistics': 'Årlig statistik',
  'report_dst.automatic.note.line_1':
    'Her kan du indstille hvornår ofte rapporten skal indsendes, samt koden til Dansk Arbejdersgiver-foreningen og hvorvidt der også skal sendes en fraværsrapport. Er både årlig og kvartalvis rapporter slået fra, laves ingen automatisk rapport.',
  'report_dst.automatic.note.line_2':
    'Der indberettes kun for medarbejdere med DISCO-08 kode, du tilføjer DISCO-08 kode under ansættelse på medarbejderen.',
  'report_dst.automatic.title': 'Automatisk rapport til Danmarks Statistik',
  'report_dst.manual.form.period': 'Periode',
  'report_dst.manual.form.period.q1': 'Første kvartal',
  'report_dst.manual.form.period.q2': 'Andet kvartal',
  'report_dst.manual.form.period.q3': 'Tredje kvartal',
  'report_dst.manual.form.period.q4': 'Fjerde kvartal',
  'report_dst.manual.form.period.yearly': 'Hele året',
  'report_dst.manual.form.year': 'År',
  'report_dst.manual.title': 'Rapport til Danmarks Statistik',

  'report_name.assets': 'Enheder',
  'report_name.car_allowance': 'Kørselsgodtgørelse',
  'report_name.employees': 'Medarbejdere',
  'report_name.employments': 'Ansættelser',
  'report_name.flex_registrations': 'Fleksregistreringer',
  'report_name.leave_balances': 'Feriesaldi',
  'report_name.leave_differences': 'Feriedifference',
  'report_name.leave_registrations': 'Fraværsregistreringer',
  'report_name.one_time_pays': 'Engangsregistreringer',
  'report_name.overtime_registrations': 'Afspadseringsregistreringer',
  'report_name.pay_roll_accounting': 'Bogføringsrapport',
  'report_name.pay_roll_employees': 'Lønrapport',
  'report_name.pay_roll_transfers': 'Lønoverførsler',
  'report_name.period_employees': 'Lønrapport, medarbejderniveau',
  'report_name.period_employees_salary_types': 'Lønrapport, medarbejderniveau, løntyper',
  'report_name.period_employees_vacation_transition': 'Ferierapport for overgangsåret',
  'report_name.period_income_tax_report': 'eIndkomstrapport',
  'report_name.period_payslip_count_report': 'Antal lønsedler for virksomhedsgruppe',
  'report_name.project_registrations': 'Projektregistreringer',
  'report_name.reimbursement_vouchers_accounting': 'Udlægsbogføring',
  'report_name.reimbursements': 'Udlæg',
  'report_name.salary_accounts_monthly_filtered_1': 'Lønrapport, virksomhedsniveau',
  'report_name.salary_receipts': 'Lønkvittering',
  'report_name.salary_registrations': 'Lønregistreringer',
  'report_name.start_balances': 'Startsaldi',
  'report_name.time_registrations': 'Tidsregistreringer',
  'report_name.work_hours_registrations': 'Arbejdstid',

  'report_tab.form.download_excel': 'Hent Excel',
  'report_tab.form.download_pdf': 'Hent PDF',
  'report_tab.form.from': 'Fra',
  'report_tab.form.from.required': 'Fradato skal indtastes',
  'report_tab.form.pay_roll_id': 'Lønkørsel',
  'report_tab.form.report': 'Vælg rapport',
  'report_tab.form.to': 'Til',
  'report_tab.form.to.required': 'Tildato skal indtastes',
  'report_tab.header.dst_automatic': 'Automatisk rapport til Danmarks Statistik',
  'report_tab.header.dst_manual': 'Rapport til Danmarks Statistik',
  'report_tab.header.tax_cards': 'Aktive skattekort',
  'report_tab.title': 'Rapporter',

  'request_deletion.alert.success': 'Sletningsanmodning sendt',
  'request_deletion.form.comment': 'Skriv en eventuel kommentar til din sletningsanmodning',
  'request_deletion.form.confirmed':
    'Jeg er indforstået med at ved at slette min virksomhed, kan jeg ikke få mine data tilbage',
  'request_deletion.form.submit': 'Anmod om sletning',
  'request_deletion.note.line_1':
    'Du kan anmode om at få din virksomhed helt slettet. Når virksomheden er slettet, vil du ikke kunne gendanne dens data.',
  'request_deletion.note.line_2':
    'Selve sletningen bliver foretaget manuelt, og din virksomhed vil derfor ikke nødvendigvis blive slettet lige med det samme.',
  'request_deletion.title': 'Anmod om sletning af virksomhed',

  'request_import.alert.success': 'Flytteanmodning sendt, du vil høre fra os',
  'request_import.form.date_request': 'Første lønkørsel i Salary',
  'request_import.form.date_request.note':
    'Vi kan ikke garantere flytning til den ønskede måned, men melder hurtigt tilbage hvad der kan lade sig gøre.',
  'request_import.form.password': 'Adgangskode',
  'request_import.form.password.required': 'Adgangskode skal indtastes',
  'request_import.form.pay_roll_system': 'Nuværende lønsystem',
  'request_import.form.pay_roll_system_text': 'Skriv navnet på dit lønsystem',
  'request_import.form.pay_roll_system_text.required': 'Du skal skrive et navn på lønsystemet',
  'request_import.form.pay_roll_system.other': 'Andet',
  'request_import.form.pay_roll_system.required': 'Lønsystem skal vælges',
  'request_import.form.submit': 'Bestil flytteservice',
  'request_import.form.username': 'Brugernavn',
  'request_import.form.username.required': 'Brugernavn skal indtastes',
  'request_import.title': 'Bestil flytteservice fra et andet lønsystem',

  'responsive_note_modal.ok': 'OK',
  'responsive_note_modal.text.line_1':
    'Du har nu været igennem den del af Salary der er optimeret til små skærme, men resten af Salary fungerer kun optimalt på en større skærm.',
  'responsive_note_modal.text.line_2':
    'Du er dog velkommen til at fortsætte med at bruge Salary på din telefon, den mulighed forhindrer vi dig ikke i.',
  'responsive_note_modal.title': 'Salary er ikke optimeret til små skærme',

  'route.register': 'Opret bruger',

  'salary_cycle.biweekly.period_a': 'periode A',
  'salary_cycle.biweekly.period_a_start': 'periode A (næste start: {start})',
  'salary_cycle.biweekly.period_b': 'periode B',
  'salary_cycle.biweekly.period_b_start': 'periode B (næste start: {start})',
  'salary_cycle.frequency_per.biweekly': 'Per 2 uger',
  'salary_cycle.frequency_per.monthly': 'Per måned',
  'salary_cycle.frequency_per.monthly_offset': 'Per måned (fra den {from}. til den {to}.)',
  'salary_cycle.frequency_per.weekly': 'Per uge',
  'salary_cycle.frequency.biweekly': '2 uger',
  'salary_cycle.frequency.monthly': 'Måned',
  'salary_cycle.frequency.weekly': 'Uge',
  'salary_cycle.paid.postpaid': 'Bagudlønnet',
  'salary_cycle.paid.prepaid': 'Forudlønnet',

  'salary_reduction.edit.form.amount': 'bruttotræk',
  'salary_reduction.edit.form.disposition_date': 'Hvornår skal bruttotrækket trækkes fra lønnen?',
  'salary_reduction.edit.form.disposition_date.placeholder': 'Trækdato',
  'salary_reduction.edit.form.use_b_income': 'Lav bruttotrækket på B-indkomst',
  'salary_reduction.edit.form.use_b_income_am': 'Med AM-bidrag',
  'salary_reduction.edit.form.with_pension': 'Justér pensionsgrundlag',
  'salary_reduction.edit.form.with_vacation': 'Justér feriegrundlag',
  'salary_reduction.edit.title.create': 'Registrér bruttotræk',
  'salary_reduction.edit.title.edit': 'Redigér bruttotræk',
  'salary_reduction.edit.title.view': 'Se bruttotræk',
  'salary_reduction.tab.header.add_new': 'Registrér bruttotræk',
  'salary_reduction.tab.header.approve_all': 'Godkend alle',
  'salary_reduction.tab.header.hide_history': 'Tilbage',
  'salary_reduction.tab.header.show_history': 'Vis historik',
  'salary_reduction.tab.table.actions.delete': 'Slet bruttotræk',
  'salary_reduction.tab.table.actions.edit': 'Redigér bruttotræk',
  'salary_reduction.tab.table.actions.view': 'Gennemse bruttotræk',
  'salary_reduction.tab.table.amount.format': '{units} à {rate}',
  'salary_reduction.tab.table.approved.approve': 'Godkend',
  'salary_reduction.tab.table.approved.false': 'Nej',
  'salary_reduction.tab.table.approved.remove': 'Fjern godkendelse',
  'salary_reduction.tab.table.approved.true': 'Ja',
  'salary_reduction.tab.table.header.amount': 'Beløb',
  'salary_reduction.tab.table.header.approved': 'Godkendt',
  'salary_reduction.tab.table.header.date': 'Dato',
  'salary_reduction.tab.table.header.title': 'Beskrivelse',
  'salary_reduction.tab.table.income_type.a': 'A-indkomst',
  'salary_reduction.tab.table.income_type.b': 'B-indkomst',
  'salary_reduction.tab.table.saving': 'Gemmer...',
  'salary_reduction.tab.title.history': 'Historik for bruttotræk',
  'salary_reduction.tab.title.standard': 'Bruttotræk',

  'salary_registration.coarse_salary_registration.many': 'simple lønregisteringer',
  'salary_registration.coarse_salary_registration.one': 'simpel lønregistering',
  'salary_registration.detailed_salary_registration.many': 'detaljerede lønregisteringer',
  'salary_registration.detailed_salary_registration.one': 'detaljeret lønregistering',
  'salary_registration.salary_registration.many': 'lønregistreringer',
  'salary_registration.salary_registration.one': 'lønregistrering',

  'salary_registrations_tab.coarse.alert.success': '{name}s lønregistreringer er gemt',
  'salary_registrations_tab.coarse.form.description': 'Antal',
  'salary_registrations_tab.coarse.form.description_rate': 'Sats',
  'salary_registrations_tab.coarse.form.description_rate.title': 'Sats ({title})',
  'salary_registrations_tab.coarse.form.description.title': 'Antal ({title})',
  'salary_registrations_tab.coarse.form.quantity': 'Antal',
  'salary_registrations_tab.coarse.form.quantity.invalid': '{description} er ugyldig',
  'salary_registrations_tab.coarse.form.quantity.required': '{description} skal indtastes',
  'salary_registrations_tab.coarse.form.quantity.suffix': 'stk',
  'salary_registrations_tab.coarse.form.rate': 'Sats',
  'salary_registrations_tab.coarse.form.rate.invalid': '{description} er ugyldig',
  'salary_registrations_tab.coarse.form.rate.required': '{description} skal indtastes',
  'salary_registrations_tab.coarse.form.rate.suffix': 'kr.',
  'salary_registrations_tab.coarse.of_rate': 'à {rate}',
  'salary_registrations_tab.coarse.period_range': '{from} - {to}',
  'salary_registrations_tab.coarse.select_period': 'Vælg periode',
  'salary_registrations_tab.detailed.delete_all_confirm':
    'Er du sikker på du vil slette alle udestående registreringer? De kan ikke genskabes.',
  'salary_registrations_tab.detailed.edit.form.date': 'Dato',
  'salary_registrations_tab.detailed.edit.form.date.not_before_employment': 'Dato kan ikke være før ansættelsesdatoen',
  'salary_registrations_tab.detailed.edit.form.date.required': 'Dato skal indtastes',
  'salary_registrations_tab.detailed.edit.form.note': 'Note',
  'salary_registrations_tab.detailed.edit.form.quantity': 'Antal',
  'salary_registrations_tab.detailed.edit.form.quantity.invalid': 'Antal er ugyldigt',
  'salary_registrations_tab.detailed.edit.form.quantity.required': 'Antal skal indtastes',
  'salary_registrations_tab.detailed.edit.form.quantity.suffix': 'stk',
  'salary_registrations_tab.detailed.edit.form.salary_type_id': 'Type',
  'salary_registrations_tab.detailed.edit.form.salary_type_id.required': 'Type skal vælges',
  'salary_registrations_tab.detailed.edit.title.create': 'Opret lønregistrering',
  'salary_registrations_tab.detailed.edit.title.edit': 'Rediger lønregistrering',
  'salary_registrations_tab.detailed.header.add_registration': 'Registrér løn',
  'salary_registrations_tab.detailed.header.approve_all': 'Godkend alle',
  'salary_registrations_tab.detailed.header.delete_all': 'Slet alle udestående',
  'salary_registrations_tab.detailed.history.table.header.date': 'Dato',
  'salary_registrations_tab.detailed.history.table.header.description': 'Beskrivelse',
  'salary_registrations_tab.detailed.history.table.header.note': 'Note',
  'salary_registrations_tab.detailed.history.table.header.quantity': 'Antal',
  'salary_registrations_tab.detailed.table.actions.delete': 'Slet registrering',
  'salary_registrations_tab.detailed.table.actions.edit': 'Redigér registrering',
  'salary_registrations_tab.detailed.table.approve': 'Godkend',
  'salary_registrations_tab.detailed.table.approved.false': 'Nej',
  'salary_registrations_tab.detailed.table.approved.true': 'Ja',
  'salary_registrations_tab.detailed.table.header.approved': 'Godkendt',
  'salary_registrations_tab.detailed.table.header.date': 'Dato',
  'salary_registrations_tab.detailed.table.header.description': 'Beskrivelse',
  'salary_registrations_tab.detailed.table.header.note': 'Note',
  'salary_registrations_tab.detailed.table.header.quantity': 'Antal',
  'salary_registrations_tab.detailed.table.remove_approval': 'Fjern godkendelse',
  'salary_registrations_tab.detailed.table.saving': 'Gemmer...',
  'salary_registrations_tab.header.detailed': 'Detaljeret',
  'salary_registrations_tab.header.hide_history': 'Tilbage',
  'salary_registrations_tab.header.show_history': 'Vis historik',
  'salary_registrations_tab.title.history': 'Historiske lønregistreringer',
  'salary_registrations_tab.title.standard': 'Lønregistreringer',

  'salary_registrations.delete.intro.many':
    'Du er ved at slette alle detaljerede lønregistreringer på virksomheden, som ikke er kørt på en løn. Der er tale om {count} lønregistreringer. De kan ikke genskabes.',
  'salary_registrations.delete.intro.one':
    'Du er ved at slette alle detaljerede lønregistreringer på virksomheden, som ikke er kørt på en løn. Der er tale om {count} lønregistrering. De kan ikke genskabes.',
  'salary_registrations.delete.submit': 'Slet alle',
  'salary_registrations.delete.title': 'Slet alle ikke-kørte lønregistreringer',
  'salary_registrations.modal_edit.title': 'Lønregistrering for {name}',
  'salary_registrations.table.action.edit': 'Redigér',
  'salary_registrations.table.action.register': 'Registrér',
  'salary_registrations.table.all_approved': 'Alle tillæg godkendt',
  'salary_registrations.table.approve_hours': 'Godkend alle tillæg',
  'salary_registrations.table.coarse.title': 'Simpel lønregistrering',
  'salary_registrations.table.detailed.delete_all': 'Slet alle lønregistreringer',
  'salary_registrations.table.detailed.title': 'Detaljeret lønregistrering',
  'salary_registrations.table.header.employee': 'Medarbejder',
  'salary_registrations.table.header.quantity': 'Antal',
  'salary_registrations.table.header.rate': 'Sats',
  'salary_registrations.table.header.salary': 'Løn',
  'salary_registrations.table.loading': 'Indlæser...',
  'salary_registrations.table.saving': 'Gemmer...',
  'salary_registrations.table.select_period': 'Vælg periode',

  'salary_types.hourly.edit.form.day.holiday': 'Helligdag',
  'salary_types.hourly.edit.form.include_in_pension_basis': 'Inkludér i pensionsgrundlag',
  'salary_types.hourly.edit.form.include_in_sh_fritvalg_basis': 'Inkludér i SH-dage/Fritvalg-grundlag',
  'salary_types.hourly.edit.form.supplement.add_row': 'Tilføj række',
  'salary_types.hourly.edit.form.supplement.amount.fixed': 'Beløb',
  'salary_types.hourly.edit.form.supplement.amount.fixed.percentage': 'Procent skal indtastes',
  'salary_types.hourly.edit.form.supplement.amount.fixed.required': 'Beløb skal indtastes',
  'salary_types.hourly.edit.form.supplement.amount.percentage': 'Procent',
  'salary_types.hourly.edit.form.supplement.day': 'Dag',
  'salary_types.hourly.edit.form.supplement.day.required': 'Dag skal vælges',
  'salary_types.hourly.edit.form.supplement.end_time': 'Slut',
  'salary_types.hourly.edit.form.supplement.end_time.required': 'Slut skal vælges',
  'salary_types.hourly.edit.form.supplement.header.amount': 'Værdi',
  'salary_types.hourly.edit.form.supplement.header.day': 'Dag',
  'salary_types.hourly.edit.form.supplement.header.end_time': 'Slut',
  'salary_types.hourly.edit.form.supplement.header.start_time': 'Start',
  'salary_types.hourly.edit.form.supplement.header.title': 'Beskrivelse',
  'salary_types.hourly.edit.form.supplement.start_time': 'Start',
  'salary_types.hourly.edit.form.supplement.start_time.required': 'Start skal vælges',
  'salary_types.hourly.edit.form.supplement.title': 'Beskrivelse',
  'salary_types.hourly.edit.form.supplement.title.required': 'Beskrivelse skal indtastes',
  'salary_types.hourly.edit.form.title': 'Beskrivelse',
  'salary_types.hourly.edit.form.title.required': 'Beskrivelse skal indtastes',
  'salary_types.hourly.edit.note.line_1':
    'Bemærk: Du skal kun indtaste tillæggene dine medarbejdere skal have udover deres timeløn.',
  'salary_types.hourly.edit.note.line_2':
    'Deres individuelle timeløn sætter du på medarbejderen under ansættelse - løn.',
  'salary_types.hourly.edit.title.create': 'Opret timesats',
  'salary_types.hourly.edit.title.edit': 'Redigér timesats',
  'salary_types.supplement_varied.edit.form.include_in_pension_basis': 'Inkludér i pensionsgrundlag',
  'salary_types.supplement_varied.edit.form.include_in_sh_fritvalg_basis': 'Inkludér i SH-dage/Fritvalg-grundlag',
  'salary_types.supplement_varied.edit.form.include_in_vacation_basis': 'Inkludér i feriegrundlag',
  'salary_types.supplement_varied.edit.form.title': 'Beskrivelse',
  'salary_types.supplement_varied.edit.form.title.required': 'Beskrivelse skal indtastes',
  'salary_types.supplement_varied.edit.title.create': 'Opret variabelt løntillæg',
  'salary_types.supplement_varied.edit.title.edit': 'Redigér variabelt løntillæg',
  'salary_types.tab.header.add_hourly': 'Tilføj timesats',
  'salary_types.tab.header.add_supplement_varied': 'Tilføj variable løntillæg',
  'salary_types.tab.header.show_active': 'Vis aktive',
  'salary_types.tab.header.show_inactive': 'Vis inaktive',
  'salary_types.tab.table.actions.hourly.edit': 'Redigér timelønssats',
  'salary_types.tab.table.actions.hourly.reactivate': 'Genaktivér timelønssats',
  'salary_types.tab.table.actions.hourly.remove': 'Fjern timelønssats',
  'salary_types.tab.table.actions.supplement_varied.edit': 'Redigér variabelt løntillæg',
  'salary_types.tab.table.actions.supplement_varied.reactivate': 'Genaktivér variabelt løntillæg',
  'salary_types.tab.table.actions.supplement_varied.remove': 'Fjern variabelt løntillæg',
  'salary_types.tab.table.active.false': 'Ikke aktiv',
  'salary_types.tab.table.active.true': 'Aktive',
  'salary_types.tab.table.class.hourly': 'Timelønsats',
  'salary_types.tab.table.class.supplement_varied': 'Variabelt løntillæg',
  'salary_types.tab.table.header.class': 'Type',
  'salary_types.tab.table.header.include_in_pension_basis': 'Pensionsgrundlag?',
  'salary_types.tab.table.header.include_in_sh_fritvalg_basis': 'SH-dage/Fritvalgsgrundlag?',
  'salary_types.tab.table.header.include_in_vacation_basis': 'Feriegrundlag?',
  'salary_types.tab.table.header.status': 'Status',
  'salary_types.tab.table.header.title': 'Beskrivelse',
  'salary_types.tab.table.included.false': 'Nej',
  'salary_types.tab.table.included.true': 'Ja',
  'salary_types.tab.table.with_supplements.many': '(med {count} løntillæg)',
  'salary_types.tab.table.with_supplements.one': '(med {count} løntillæg)',
  'salary_types.tab.title': 'Timesatser og variable løntillæg',

  'search.not_found': 'Intet resultat',

  'signing_document.description': 'Virksomheden {name} ({nationalID}) har bedt dig underskrive et dokument.',
  'signing_document.document_is_signed': 'Dokumentet er underskrevet',
  'signing_document.error.error_during_callback': 'Kunne ikke gemme din underskrift, kontakt Salary.',
  'signing_document.error.missing_callback_parameters': 'Der mangler felter fra MitID, kontakt Salary.',
  'signing_document.error.unable_to_complete_signing':
    'Kunne ikke underskrive dokumentet, tjek at din kode er korrekt.',
  'signing_document.error.unable_to_fetch_document': 'Ude af stand til hente dokumentet, kontakt Salary.',
  'signing_document.error.unable_to_start_signing': 'Ude af stand til at starte processen, kontakt Salary.',
  'signing_document.error.unknown_error': 'Ukendt fejl fra MitID ({error}), kontakt Salary.',
  'signing_document.error.user_cancelled': 'MitID-godkendelse annulleret af brugeren.',
  'signing_document.get_document': 'Hent dokument',
  'signing_document.instructions':
    'Du underskriver dokumentet ved at indtaste dit telefonnummer, herefter får du en kode, som du skal indtaste for at bekræfte underskrivningen.',
  'signing_document.request_validation_code': 'Anmod om SMS-kode',
  'signing_document.restart_flow': 'Klik her for at prøve igen',
  'signing_document.sign_document': 'Underskriv dokument med SMS-kode',
  'signing_document.sign_with_mitid': 'Underskriv med MitID',
  'signing_document.signature_type.criipto': 'MitID',
  'signing_document.signature_type.none': 'intet',
  'signing_document.signature_type.sms': 'SMS',
  'signing_document.title': 'Du har et dokument til underskrift',

  'slack_integration.alert.saved': 'Slack-opsætning gemt',
  'slack_integration.error.unknown': 'Opsætningen kunne ikke gemmes',
  'slack_integration.form.add_row': 'Tilføj kanal',
  'slack_integration.form.channel_id': 'KanalID',
  'slack_integration.form.channel_id.required': 'KanalID er påkrævet',
  'slack_integration.form.event_types.anniversaries': 'Jubilæum',
  'slack_integration.form.event_types.birthdays': 'Fødselsdage',
  'slack_integration.form.event_types.leave': 'Orlov',
  'slack_integration.form.language_code': 'Sprog',
  'slack_integration.form.language_code.invalid': 'Sprog er ugyldig',
  'slack_integration.form.language_code.required': 'Sprog skal vælges',
  'slack_integration.form.slack_token': 'Slack-polet',
  'slack_integration.form.slack_token.required': 'Slack-poletten er påkrævet',
  'slack_integration.form.submit': 'Gem',
  'slack_integration.new_integration': 'Opret integration',
  'slack_integration.new_integration.lock': 'Opgradér til Basic for at integrere med Slack.',
  'slack_integration.title': 'Opsætning af Slack-integration',
  'slack_integration.subtitle.no.integration':
    'Forbind jeres Slack-workspace og få besked om fravær og jubilæer, direkte i de relevante Slack-kanaler.',
  'slack_integration.subtitle.with.integration':
    'Jeres Slack-workspace er integreret og I kan nu vælge af få besked om fravær og jubilæer, direkte i de relevante Slack-kanaler.',
  'slack_integration.guide.title': 'Sådan forbinder du til Slack:',
  'slack_integration.guide.text.1': '1. Klik på knappen "opret integration" for at påbegynde integrationsprocessen.',
  'slack_integration.guide.text.2':
    '2. Angiv navnet på din Slack workspace. Hvis du er i tvivl om dit workspace-navn, kan du finde vejledning på Slacks hjemmeside. Log herefter ind på din Slack-konto.',
  'slack_integration.guide.text.3':
    '3. Giv Salary Bot de nødvendige tilladelser til at få adgang til din Slack workspace.',
  'slack_integration.guide.text.4':
    '4. Efter godkendelse vil du blive dirigeret tilbage til Salary. Her kan du konfigurere fraværstyper og hvilke kanaler de skal sendes til.',
  'slack_integration.guide.text.5':
    'Har du problemer med at finde kanal ID eller andet ved opsætningen, kan du læse vores hjælpeartikel {link}',
  'slack_integration.guide.text.link': 'her.',

  'source_type.api_client': 'API-klient',
  'source_type.business': 'Alm. bruger',
  'source_type.employee': 'Medarbejder',
  'source_type.external_import': 'Dataimport',
  'source_type.file_import': 'Filimport',
  'source_type.support': 'Support',
  'source_type.system': 'Systemet',

  'sso.page.no': 'Nej',
  'sso.page.title': 'Er du sikker på du vil forbinde til {name}?',
  'sso.page.yes': 'Ja',

  'staged_import.error.company_not_found': 'Ukendt virksomhed',
  'staged_import.error.employee_not_found':
    'Medarbejdernummeret kunne ikke knyttes med et i Salary; sørg for de er helt ens, tjek f.eks. for foranstillede nuller',
  'staged_import.error.unknown': 'Der forekom en ukendt fejl i indlæsningen af filen',
  'staged_import.import_type_file.danloen_salary': 'Danløn-fil',
  'staged_import.import_type_file.dataloen_salary': 'Dataløn-fil',
  'staged_import.import_type_file.excel_file': 'Excel-fil',
  'staged_import.import_type_file.proloen_salary': 'Proløn-fil',
  'staged_import.import_type_file.workfeed_salary': 'Workfeed-fil',
  'staged_import.period_form.select_cycle': 'Vælg cyklus for tidsregistreringerne',
  'staged_import.period_form.select_period': 'Vælg periode for tidsregistreringerne',
  'staged_import.status.failure': 'Fejl',
  'staged_import.status.no_change': 'Ingen ændring',
  'staged_import.status.success': 'Succes',
  'staged_import.step.0.continue.template': 'Fortsæt til skabelon',
  'staged_import.step.0.continue.upload': 'Fortsæt til upload',
  'staged_import.step.0.external_format_note':
    '{nb} Du er ved at vælge et ikke-Salary-format. Ikke alle dele i eksterne formater er understøttet. Vi anbefaler kun brugen af eksterne formater, hvis du bruger et eksternt system som eksporterer i et af disse formater.',
  'staged_import.step.0.external_format_note.nb': 'NB:',
  'staged_import.step.0.external_salary_file_setting.note.danloen':
    'Du kan indstille teksterne for Danløns løndele {link}.',
  'staged_import.step.0.external_salary_file_setting.note.link': 'her',
  'staged_import.step.0.external_salary_file_setting.note.proloen':
    'Du kan indstille teksterne for Proløns løndele {link}.',
  'staged_import.step.0.import_type_select.type.danloen_salary': 'Danløn-format',
  'staged_import.step.0.import_type_select.type.dataloen_salary': 'Dataløn-format',
  'staged_import.step.0.import_type_select.type.excel_hours': 'Tidsregistreringer (Salary-format)',
  'staged_import.step.0.import_type_select.type.freelancer_bonus': 'Honorar til freelancere (Salary-format)',
  'staged_import.step.0.import_type_select.type.proloen_salary': 'Proløn-format',
  'staged_import.step.0.import_type_select.type.workfeed_salary': 'Workfeed-CSV-fil i særligt Salary-format',
  'staged_import.step.0.intro.line_1': 'Vælg format til at uploade timer og/eller løndele med.',
  'staged_import.step.0.intro.line_2':
    '{title} kan bruges til at registrere timer for dine medarbejdere i et Excel-ark.',
  'staged_import.step.0.intro.line_2.title': 'Tidsregistreringer',
  'staged_import.step.0.intro.line_3':
    '{title} kan bruges til at give honorar til mange freelancere på én gang i et Excel-ark.',
  'staged_import.step.0.intro.line_3.title': 'Honorar til freelancere',
  'staged_import.step.0.intro.line_4':
    'Når du bruger et af Salarys egne formater, vil du blive bedt om at hente en skabelon, som du skal udfylde.',
  'staged_import.step.0.intro.line_5':
    'Du kan også bruge {danloen}s, {dataloen}s eller {proloen}s format. Salary understøtter de fleste af disse formaters løndele, og vil konvertere dem til typer passende i Salary.',
  'staged_import.step.0.intro.line_5.danloen': 'Danløn',
  'staged_import.step.0.intro.line_5.dataloen': 'Dataløn',
  'staged_import.step.0.intro.line_5.proloen': 'Proløn',
  'staged_import.step.0.intro.line_6':
    'På sidste trin vil du kunne se alle informationerne fra den fil du har uploadet før du faktisk vælger at gemme dem.',
  'staged_import.step.0.select_period': 'Vælg periode for tidsregistreringerne',
  'staged_import.step.0.title': 'Trin 1',
  'staged_import.step.0.workfeed_format_note':
    'Når du importerer fra Workfeeds CSV-fil, er det vigtigt, at du bruger den særlige Salary-udgave.',
  'staged_import.step.1.button.back': 'Tilbage',
  'staged_import.step.1.button.continue': 'Fortsæt til upload',
  'staged_import.step.1.button.download_template': 'Hent Excel-fil',
  'staged_import.step.1.intro.employees':
    'Hent Excel-filen med en liste over dine medarbejdere via knappen herunder. Hvis du allerede har hentet og udfyldt Excel-filen, kan du fortsætte direkte til upload af filen.',
  'staged_import.step.1.intro.freelancers':
    'Hent Excel-filen med en liste over dine freelancere via knappen herunder. Hvis du allerede har hentet og udfyldt Excel-filen, kan du fortsætte direkte til upload af filen.',
  'staged_import.step.1.title': 'Trin 2',
  'staged_import.step.2.button.back': 'Tilbage',
  'staged_import.step.2.button.upload': 'Upload {type}',
  'staged_import.step.2.check_box.delete_unsettled':
    'Fjern ikke-lønkørte tidsregistreringer før importen gemmes (anbefalet)',
  'staged_import.step.2.intro.danloen_salary': 'Upload Danløn-filen via knappen herunder.',
  'staged_import.step.2.intro.dataloen_salary': 'Upload Dataløn-filen via knappen herunder.',
  'staged_import.step.2.intro.excel_hours': 'Upload den udfyldte Excel-fil med dine medarbejdere via knappen herunder.',
  'staged_import.step.2.intro.freelancers_bonus':
    'Upload den udfyldte Excel-fil med dine freelancere via knappen herunder.',
  'staged_import.step.2.intro.proloen_salary': 'Upload Proløn-filen via knappen herunder.',
  'staged_import.step.2.intro.workfeed_salary': 'Upload Workfeed-filen via knappen herunder.',
  'staged_import.step.2.loading.async':
    'Da filen er meget stor, kan det godt tage lang tid.  Filen behandles, vent venligst...',
  'staged_import.step.2.loading.not_async': 'Filen behandles...',
  'staged_import.step.2.title': 'Trin 3',
  'staged_import.step.3.button.approve': 'Godkend',
  'staged_import.step.3.button.back': 'Tilbage',
  'staged_import.step.3.intro.line_1':
    'Her kan du gennemse der blev løftet fra filen du uploadede, samt eventuelle fejl i den, så de kan rettes.',
  'staged_import.step.3.intro.line_2':
    'Er du tilfreds med ændringerne der vil blive foretaget, klik på godkend-knappen.',
  'staged_import.step.3.loading.async':
    'Da der er mange data der skal gemmes, kan det godt tage lang tid.  Data gemmes, vent venligst...',
  'staged_import.step.3.loading.not_async': 'Data gemmes...',
  'staged_import.step.3.title': 'Trin 4a',
  'staged_import.step.4.button.done': 'Færdig',
  'staged_import.step.4.title': 'Trin 4b',
  'staged_import.steps.0.summary': 'Vælg import type',
  'staged_import.steps.1.summary': 'Hent skabelon',
  'staged_import.steps.2.summary': 'Upload fil',
  'staged_import.steps.3.summary': 'Gennemse og godkend',
  'staged_import.steps.4.summary': 'Resultat',
  'staged_import.steps.intro': 'Importér timer, løndele, osv. i løbet af få trin',
  'staged_import.steps.title': 'Løndataimport',

  'start_balances.alert.success': '{name}s startsaldi er gemt',
  'start_balances.form.a_income': 'A-indkomst',
  'start_balances.form.a_income.invalid': 'A-indkomst er ugyldig',
  'start_balances.form.a_income.required': 'A-indkomst skal indtastes',
  'start_balances.form.a_income.suffix': 'kr.',
  'start_balances.form.a_tax': 'A-skat',
  'start_balances.form.a_tax.invalid': 'A-skat er ugyldig',
  'start_balances.form.a_tax.required': 'A-skat skal indtastes',
  'start_balances.form.a_tax.suffix': 'kr.',
  'start_balances.form.accrual_days_carry_over_title': 'Feriedage optjent 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.accrual_days_earned_title': 'Feriedage optjent 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.accrual_days_taken_title': 'Feriedage afholdt 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.accrual_days_taken_title.year_end': 'Feriedage afholdt 1/9 {start_year} - 31/12 {end_year}',
  'start_balances.form.am_income': 'AM-indkomst',
  'start_balances.form.am_income.invalid': 'AM-indkomst er ugyldig',
  'start_balances.form.am_income.required': 'AM-indkomst skal indtastes',
  'start_balances.form.am_income.suffix': 'kr.',
  'start_balances.form.am_tax': 'AM-bidrag',
  'start_balances.form.am_tax.invalid': 'AM-bidrag er ugyldig',
  'start_balances.form.am_tax.required': 'AM-bidrag skal indtastes',
  'start_balances.form.am_tax.suffix': 'kr.',
  'start_balances.form.car_allowance': 'Kørselsgodtgørelse i år',
  'start_balances.form.car_allowance.invalid': 'Kørselsgodtgørelse i år er ugyldig',
  'start_balances.form.car_allowance.required': 'Kørselsgodtgørelse i år skal indtastes',
  'start_balances.form.car_allowance.suffix': 'km',
  'start_balances.form.disposition_date': 'Gyldig per',
  'start_balances.form.disposition_date.required': 'Gyldig per skal vælges',
  'start_balances.form.employee_atp': 'ATP, medarbejder',
  'start_balances.form.employee_atp.invalid': 'ATP, medarbejder er ugyldig',
  'start_balances.form.employee_atp.required': 'ATP, medarbejder skal indtastes',
  'start_balances.form.employee_atp.suffix': 'kr.',
  'start_balances.form.employee_paid_pension': 'Medarbejderbetalt pension',
  'start_balances.form.employee_paid_pension.invalid': 'Medarbejderbetalt pension er ugyldig',
  'start_balances.form.employee_paid_pension.required': 'Medarbejderbetalt pension skal indtastes',
  'start_balances.form.employee_paid_pension.suffix': 'kr.',
  'start_balances.form.employer_atp': 'ATP, arbejdsgiver',
  'start_balances.form.employer_atp.invalid': 'ATP, arbejdsgiver er ugyldig',
  'start_balances.form.employer_atp.required': 'ATP, arbejdsgiver skal indtastes',
  'start_balances.form.employer_atp.suffix': 'kr.',
  'start_balances.form.employer_paid_pension': 'Arbejdsgiverbetalt pension',
  'start_balances.form.employer_paid_pension.invalid': 'Arbejdsgiverbetalt pension er ugyldig',
  'start_balances.form.employer_paid_pension.required': 'Arbejdsgiverbetalt pension skal indtastes',
  'start_balances.form.employer_paid_pension.suffix': 'kr.',
  'start_balances.form.extra_vacation_accrual_days_earned': 'Feriefridage tildelt',
  'start_balances.form.extra_vacation_accrual_days_earned.invalid': 'Feriefridage tildelt er ugyldig',
  'start_balances.form.extra_vacation_accrual_days_earned.required': 'Feriefridage tildelt skal indtastes',
  'start_balances.form.extra_vacation_accrual_days_earned.suffix': 'dage',
  'start_balances.form.extra_vacation_accrual_days_taken': 'Feriefridage afholdt',
  'start_balances.form.extra_vacation_accrual_days_taken.invalid': 'Feriefridage afholdt er ugyldig',
  'start_balances.form.extra_vacation_accrual_days_taken.required': 'Feriefridage afholdt skal indtastes',
  'start_balances.form.extra_vacation_accrual_days_taken.suffix': 'dage',
  'start_balances.form.fritvalg': 'Fritvalg',
  'start_balances.form.fritvalg.invalid': 'Fritvalg er ugyldig',
  'start_balances.form.fritvalg.required': 'Fritvalg skal indtastes',
  'start_balances.form.fritvalg.suffix': 'kr.',
  'start_balances.form.fund_days_earned_title': 'Ubetalte feriedage optjent 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.fund_days_taken_title': 'Ubetalte feriedage afholdt 1/9 {start_year} - 31/12 {end_year}',
  'start_balances.form.fund_vacation_net_title': 'Feriepenge optjent 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.generic_vacation.invalid': '{title} er ugyldig',
  'start_balances.form.generic_vacation.required': '{title} skal indtastes',
  'start_balances.form.generic_vacation.suffix.currency': 'kr.',
  'start_balances.form.generic_vacation.suffix.days': 'dage',
  'start_balances.form.great_prayer_day_earned': 'Store Bededagstillæg',
  'start_balances.form.great_prayer_day_earned.invalid': 'Store Bededagstillæg er ugyldigt',
  'start_balances.form.great_prayer_day_earned.required': 'Store Bededagstillæg skal indtastes',
  'start_balances.form.great_prayer_day_earned.suffix': 'kr.',
  'start_balances.form.overtime_balance': 'Afspadsingssaldo',
  'start_balances.form.overtime_balance.invalid': 'Afspadsingssaldo er ugyldig',
  'start_balances.form.overtime_balance.required': 'Afspadsingssaldo skal indtastes',
  'start_balances.form.overtime_balance.suffix': 'timer',
  'start_balances.form.pension_title': 'Pension',
  'start_balances.form.personal_time_accrual_days_earned': 'Omsorgsdage tildelt',
  'start_balances.form.personal_time_accrual_days_earned.invalid': 'Omsorgsdage tildelt er ugyldig',
  'start_balances.form.personal_time_accrual_days_earned.required': 'Omsorgsdage tildelt skal indtastes',
  'start_balances.form.personal_time_accrual_days_earned.suffix': 'dage',
  'start_balances.form.personal_time_accrual_days_taken': 'Omsorgsdage afholdt',
  'start_balances.form.personal_time_accrual_days_taken.invalid': 'Omsorgsdage afholdt er ugyldig',
  'start_balances.form.personal_time_accrual_days_taken.required': 'Omsorgsdage afholdt skal indtastes',
  'start_balances.form.personal_time_accrual_days_taken.suffix': 'dage',
  'start_balances.form.salary': 'Bruttoløn',
  'start_balances.form.salary_title': 'Indkomst',
  'start_balances.form.salary.invalid': 'Bruttoløn er ugyldig',
  'start_balances.form.salary.required': 'Bruttoløn skal indtastes',
  'start_balances.form.salary.suffix': 'kr.',
  'start_balances.form.sh_dage': 'SH-dage',
  'start_balances.form.sh_dage.invalid': 'SH-dage er ugyldig',
  'start_balances.form.sh_dage.required': 'SH-dage skal indtastes',
  'start_balances.form.sh_dage.suffix': 'kr.',
  'start_balances.form.supplement_earned_title.not_summer': 'Ferietillæg optjent 1/9 {start_year} - 31/5 {end_year}',
  'start_balances.form.supplement_earned_title.summer': 'Ferietillæg optjent 1/6 {start_year} - 31/8 {end_year}',
  'start_balances.form.tax_title': 'Skat',
  'start_balances.form.vacation_basis_carry_over_title': 'Ferieberettiget løn 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.vacation_basis_title': 'Ferieberettiget løn 1/9 {start_year} - 31/8 {end_year}',
  'start_balances.form.vacation_optional_days_available_this_year': 'Feriefridage tildelt for i år',
  'start_balances.form.vacation_optional_days_available_this_year.invalid': 'Feriefridage tildelt for i år er ugyldig',
  'start_balances.form.vacation_optional_days_available_this_year.required':
    'Feriefridage tildelt for i år skal indtastes',
  'start_balances.form.vacation_optional_days_available_this_year.suffix': 'dage',
  'start_balances.form.vacation_optional_days_taken_this_year': 'Feriefridage afholdt i dette ferieår',
  'start_balances.form.vacation_optional_days_taken_this_year.invalid':
    'Feriefridage afholdt i dette ferieår er ugyldig',
  'start_balances.form.vacation_optional_days_taken_this_year.required':
    'Feriefridage afholdt i dette ferieår skal indtastes',
  'start_balances.form.vacation_optional_days_taken_this_year.suffix': 'dage',
  'start_balances.form.vacation_personal_days_days_available_this_year': 'Omsorgsdage tildelt for i år',
  'start_balances.form.vacation_personal_days_days_available_this_year.invalid':
    'Omsorgsdage tildelt for i år er ugyldig',
  'start_balances.form.vacation_personal_days_days_available_this_year.required':
    'Omsorgsdage tildelt for i år skal indtastes',
  'start_balances.form.vacation_personal_days_days_available_this_year.suffix': 'dage',
  'start_balances.form.vacation_personal_days_days_taken_this_year': 'Omsorgsdage afholdt i år',
  'start_balances.form.vacation_personal_days_days_taken_this_year.invalid': 'Omsorgsdage afholdt i år er ugyldig',
  'start_balances.form.vacation_personal_days_days_taken_this_year.required': 'Omsorgsdage afholdt i år skal indtastes',
  'start_balances.form.vacation_personal_days_days_taken_this_year.suffix': 'dage',
  'start_balances.form.vacation_title': 'Ferie',
  'start_balances.form.work_days': 'Arbejdsdage i år',
  'start_balances.form.work_days.invalid': 'Arbejdsdage i år er ugyldige',
  'start_balances.form.work_days.required': 'Arbejdsdage i år skal indtastes',
  'start_balances.form.work_days.suffix': 'dage',
  'start_balances.form.work_hours': 'Arbejdstimer i år',
  'start_balances.form.work_hours.invalid': 'Arbejdstimer i år er ugyldige',
  'start_balances.form.work_hours.required': 'Arbejdstimer i år skal indtastes',
  'start_balances.form.work_hours.suffix': 'timer',
  'start_balances.intro': 'Indtast startsaldi.',
  'start_balances.title': 'Startsaldi',

  'stripe.alert.success': 'Betalingskortaftalen blev gemt',
  'stripe.edit.fee_explanation.line_1': 'Der er et kortgebyr på {fee} + moms per lønkørsel.',
  'stripe.edit.fee_explanation.line_2': 'Du kan undgå dette gebyr ved {link}.',
  'stripe.edit.fee_explanation.line_2_link': 'at bruge Overførselsservice',
  'stripe.edit.form.card_number': 'Kortnummer',
  'stripe.edit.form.cvc': 'CVC',
  'stripe.edit.form.expiry': 'Udløbsdato',
  'stripe.edit.form.submit': 'Gem kort',
  'stripe.edit.title.create': 'Opret betalingskortaftale',
  'stripe.edit.title.edit': 'Skift betalingskortaftale',
  'stripe.error_code.call_issuer': 'Kontakt din kortudbyder.',
  'stripe.error_code.currency_not_supported': 'Kortet understøtter ikke danske kroner.',
  'stripe.error_code.expired_card': 'Kortet er udløbet.',
  'stripe.error_code.incorrect_number': 'Ugyldigt kortnummer.',
  'stripe.error_code.insufficient_funds': 'Der var ikke nok penge på kortet.',
  'stripe.error_code.pickup_card': 'Kortet kan ikke bruges til denne betaling.',
  'stripe.form.expiry.placeholder': 'MM / ÅÅ',

  'supplement.type.denmark_fritvalg': 'Fritvalg med pension uden feriepenge',
  'supplement.type.denmark_fritvalg_amount': 'Fritvalg med pension uden feriepenge fast beløb',
  'supplement.type.denmark_fritvalg_amount_direct_pay':
    'Fritvalg med pension uden feriepenge fast beløb direkte udbetalt',
  'supplement.type.denmark_fritvalg_direct_pay': 'Fritvalg med pension uden feriepenge direkte udbetalt',
  'supplement.type.denmark_fritvalg_no_pension': 'Fritvalg uden pension uden feriepenge',
  'supplement.type.denmark_fritvalg_no_pension_amount': 'Fritvalg uden pension uden feriepenge fast beløb',
  'supplement.type.denmark_fritvalg_no_pension_amount_direct_pay':
    'Fritvalg uden pension uden feriepenge fast beløb direkte udbetalt',
  'supplement.type.denmark_fritvalg_no_pension_direct_pay': 'Fritvalg uden pension uden feriepenge direkte udbetalt',
  'supplement.type.denmark_fritvalg_with_vacation': 'Fritvalg med pension med feriepenge',
  'supplement.type.denmark_fritvalg_with_vacation_amount': 'Fritvalg med pension med feriepenge fast beløb',
  'supplement.type.denmark_fritvalg_with_vacation_amount_direct_pay':
    'Fritvalg med pension med feriepenge fast beløb direkte udbetalt',
  'supplement.type.denmark_fritvalg_with_vacation_direct_pay': 'Fritvalg med pension med feriepenge direkte udbetalt',
  'supplement.type.denmark_fritvalg_with_vacation_no_pension': 'Fritvalg uden pension med feriepenge',
  'supplement.type.denmark_fritvalg_with_vacation_no_pension_amount': 'Fritvalg uden pension med feriepenge fast beløb',
  'supplement.type.denmark_fritvalg_with_vacation_no_pension_amount_direct_pay':
    'Fritvalg uden pension med feriepenge fast beløb direkte udbetalt',
  'supplement.type.denmark_fritvalg_with_vacation_no_pension_direct_pay':
    'Fritvalg uden pension med feriepenge direkte udbetalt',
  'supplement.type.denmark_great_prayer_day_supplement': 'Store Bededagstillæg',
  'supplement.type.denmark_sh_dage': 'SH-Dage med pension uden feriepenge',
  'supplement.type.denmark_sh_dage_amount': 'SH-Dage med pension uden feriepenge fast beløb',
  'supplement.type.denmark_sh_dage_amount_direct_pay':
    'SH-Dage med pension uden feriepenge fast beløb direkte udbetalt',
  'supplement.type.denmark_sh_dage_direct_pay': 'SH-Dage med pension uden feriepenge direkte udbetalt',
  'supplement.type.denmark_sh_dage_no_pension': 'SH-Dage uden pension uden feriepenge',
  'supplement.type.denmark_sh_dage_no_pension_amount': 'SH-Dage uden pension uden feriepenge fast beløb',
  'supplement.type.denmark_sh_dage_no_pension_amount_direct_pay':
    'SH-Dage uden pension uden feriepenge fast beløb direkte udbetalt',
  'supplement.type.denmark_sh_dage_no_pension_direct_pay': 'SH-Dage uden pension uden feriepenge direkte udbetalt',
  'supplement.type.denmark_sh_dage_with_vacation': 'SH-Dage med pension med feriepenge',
  'supplement.type.denmark_sh_dage_with_vacation_amount': 'SH-Dage med pension med feriepenge fast beløb',
  'supplement.type.denmark_sh_dage_with_vacation_amount_direct_pay':
    'SH-Dage med pension med feriepenge fast beløb direkte udbetalt',
  'supplement.type.denmark_sh_dage_with_vacation_direct_pay': 'SH-Dage med pension med feriepenge direkte udbetalt',
  'supplement.type.denmark_sh_dage_with_vacation_no_pension': 'SH-Dage uden pension med feriepenge',
  'supplement.type.denmark_sh_dage_with_vacation_no_pension_amount': 'SH-Dage uden pension med feriepenge fast beløb',
  'supplement.type.denmark_sh_dage_with_vacation_no_pension_amount_direct_pay':
    'SH-Dage uden pension med feriepenge fast beløb direkte udbetalt',
  'supplement.type.denmark_sh_dage_with_vacation_no_pension_direct_pay':
    'SH-Dage uden pension med feriepenge direkte udbetalt',
  'supplement.type.denmark_vacation_supplement': 'Ferietillæg',
  'supplement.type.denmark_vacation_supplement_special': 'Horesta ferietillæg',
  'supplement.type.short_name.denmark_fritvalg': 'Fritvalg',
  'supplement.type.short_name.denmark_sh_dage': 'SH-dage',

  'support_tab.access.false': 'Nej',
  'support_tab.access.provide': 'Giv support adgang til denne virksomhed',
  'support_tab.access.remove': 'Fjern supports adgang til denne virksomhed',
  'support_tab.access.title': 'Support har adgang',
  'support_tab.access.until': 'Frem til {time}',
  'support_tab.alert.success': 'Ændringer til {name} er gemt',
  'support_tab.employee_import.button': 'Importér medarbejdere via Excel',
  'support_tab.employee_import.button.lock': 'Opgradér til Basic, for at importere medarbejdere via Excel',
  'support_tab.header.data_provider_terms': 'Databehandleraftale',
  'support_tab.header.terms': 'Vilkår',
  'support_tab.request_deletion.button': 'Anmod om sletning af virksomhed',
  'support_tab.request_import.button': 'Bestil flytteservice fra andet lønsystem',
  'support_tab.request_import.button.lock': 'Opgradér til Basic, for at bestille flytteservice',
  'support_tab.title': 'Support',

  'swipe_employees.header.back': 'Tilbage',
  'swipe_employees.invalid_table.header.employee': 'Navn',
  'swipe_employees.invalid_table.header.employee_type': 'Medarbejdertype',
  'swipe_employees.invalid_table.header.swipe_count': 'Antal Swipes',
  'swipe_employees.invalid.description':
    'Disse medarbejdere kan ikke bruge Swipe, da deres ansættelsestype gør de ikke må bruge Swipe. {link}.',
  'swipe_employees.invalid.link_text': 'Du kan vælge hvilke ansættelsestyper må bruge Swipe under Swipes indstillinger',
  'swipe_employees.invalid.title': 'Andre medarbejdere',
  'swipe_employees.valid_table.has_swipe.disable': 'Slå fra',
  'swipe_employees.valid_table.has_swipe.disabled': 'Nej',
  'swipe_employees.valid_table.has_swipe.enable': 'Slå til',
  'swipe_employees.valid_table.has_swipe.enabled': 'Ja',
  'swipe_employees.valid_table.header.employee': 'Navn',
  'swipe_employees.valid_table.header.has_swipe': 'Kan Swipe?',
  'swipe_employees.valid_table.header.swipe_count': 'Antal Swipes',
  'swipe_employees.valid.description': 'Her kan du slå til og fra per medarbejder om de må bruge Swipe.',
  'swipe_employees.valid.title': 'Medarbejderoversigt for Swipe',

  'swipes.header.approve_all': 'Godkend alle ({amount} i alt)',
  'swipes.header.employees': 'Medarbejdere',
  'swipes.header.history.back': 'Tilbage',
  'swipes.header.history.show': 'Vis historik',
  'swipes.state.approved': 'Godkendt',
  'swipes.state.awaiting_approval': 'Afventer godkendelse',
  'swipes.state.paid': 'Udbetalt',
  'swipes.table.actions.approve': 'Godkend',
  'swipes.table.actions.delete': 'Slet',
  'swipes.table.approve_now': 'Godkend nu',
  'swipes.table.header.amount': 'Beløb',
  'swipes.table.header.date': 'Dato',
  'swipes.table.header.employee': 'Medarbejder',
  'swipes.table.header.state': 'Status',
  'swipes.title.active': 'Swipe™ overblik',
  'swipes.title.history': 'Historisk Swipe™ overblik',

  'tax_card.preferred.main_card': 'Hovedkort',
  'tax_card.preferred.secondary_card': 'Bikort',
  'tax_card.request_type.auto_re_request':
    'Anmodning oprettet af systemet pga. manglende skattekort for indeværende år',
  'tax_card.request_type.changed_employment_details': 'Ændring af ansættelsesoplysninger',
  'tax_card.request_type.changed_employment_period': 'Ændring af ansættelsesperiode',
  'tax_card.request_type.changed_preferred_tax_card_type': 'Ændring af foretrukket skattekort',
  'tax_card.request_type.new_employee': 'Oprettelse af medarbejder',
  'tax_card.request_type.re_request': 'Oprettelse af anmodning manuelt',
  'tax_card.request_type.unsubscribe': 'Afmelding af opdateringer om skattekort-ændringer',
  'tax_card.tab.alert.success.request':
    'Vi henter det bestilte skattekort nu, der kan gå op til en time før skat sender det til os. Hvis du allerede har godkendt en løn skal du genåbne den for at vi bruger det nye skattekort på medarbejderen.',
  'tax_card.tab.alert.success.tax_card': '{name}s skattekort er gemt',
  'tax_card.tab.alert.success.tax_percentage': '{name}s skattekort er gemt',
  'tax_card.tab.edit.form.valid_from': 'Gyldig fra',
  'tax_card.tab.edit.form.valid_from.required': 'Gyldig fra skal vælges',
  'tax_card.tab.edit.title': 'Ret skattekort',
  'tax_card.tab.header.increase_percentage': 'Øg skatteprocent',
  'tax_card.tab.header.request_new_tax_card': 'Anmod om nyt skattekort',
  'tax_card.tab.header.select_tax_card': 'Vælg kort',
  'tax_card.tab.increase_percentage.form.extra_tax_percentage': 'Ekstra skatteprocent',
  'tax_card.tab.increase_percentage.form.extra_tax_percentage.invalid': 'Ekstra skatteprocent er ugyldig',
  'tax_card.tab.increase_percentage.form.extra_tax_percentage.required': 'Ekstra skatteprocent skal indtastes',
  'tax_card.tab.increase_percentage.form.extra_tax_percentage.suffix': '%',
  'tax_card.tab.increase_percentage.intro':
    'Du skal kun skrive det, der skal lægges til den nuværende sats. Eksempel: Hvis nuværende skatteprocent er på 40% og medarbejderen vil betale 45% i alt, så skal du skrive 5 i boksen nedenunder.',
  'tax_card.tab.increase_percentage.title': 'Øg skatteprocent',
  'tax_card.tab.request_table.header.request_type': 'Igangsat via',
  'tax_card.tab.request_table.header.sent_time': 'Sendt',
  'tax_card.tab.table.active.false': 'Nej',
  'tax_card.tab.table.active.true': 'Ja',
  'tax_card.tab.table.header.active': 'Aktiv',
  'tax_card.tab.table.header.deduction_monthly': 'Md. fr.',
  'tax_card.tab.table.header.income_year': 'År',
  'tax_card.tab.table.header.max_a_income_free_card': 'Fribeløb',
  'tax_card.tab.table.header.percentage': 'Træk%',
  'tax_card.tab.table.header.tax_card_type': 'Type',
  'tax_card.tab.table.header.valid_from': 'Gyldig fra',
  'tax_card.tab.title.requests': 'Anmodninger',
  'tax_card.tab.title.standard': 'Skattekort',
  'tax_card.type.free': 'Frikort',
  'tax_card.type.gross': 'Bruttotrækkort',
  'tax_card.type.gross_no_retainment': 'Bruttotrækkort med separat trækprocent',
  'tax_card.type.none': 'Intet skattekort',
  'tax_card.type.primary': 'Hovedkort',
  'tax_card.type.primary_no_retainment': 'Hovedkort med separat trækprocent',
  'tax_card.type.secondary': 'Bikort',
  'tax_card.type.secondary_no_retainment': 'Bikort med separat trækprocent',

  'tax_report_field_number.0011': 'Ej automatisk beregning af befordringsfradrag',
  'tax_report_field_number.0013': 'A-indkomst, hvoraf der skal betales arbejdsmarkedsbidrag',
  'tax_report_field_number.0014': 'A-indkomst, hvoraf der ikke skal betales arbejdsmarkedsbidrag',
  'tax_report_field_number.0015': 'Indeholdt A-skat',
  'tax_report_field_number.0016': 'Indeholdt arbejdsmarkedsbidrag (am-bidrag)',
  'tax_report_field_number.0019': 'Værdi af fri bil til rådighed',
  'tax_report_field_number.0020': 'Værdi af fri telefon mm.',
  'tax_report_field_number.0021': 'Værdi af fri kost og logi',
  'tax_report_field_number.0022':
    'Indskud i arbejdsgiveradministreret pensionsordning i svensk pensionsselskab/-institut eller anden godkendt udenlandsk pensionsordning',
  'tax_report_field_number.0023': 'Am-bidrag (8 pct.) af indskud indberettet under feltnr. 22',
  'tax_report_field_number.0024': 'Godkendelsesnummer for anden godkendt udenlandsk pensionsordning ',
  'tax_report_field_number.0025': 'Skattefri del af udbetaling fra anden godkendt udenlandsk pensionsordning ',
  'tax_report_field_number.0026': 'Værdi af arbejdsgiverbetalt sundhedsforsikring og -behandling',
  'tax_report_field_number.0036': 'B-indkomst, hvoraf der skal betales arbejdsmarkedsbidrag',
  'tax_report_field_number.0037': 'Offentlige tilskud og godtgørelser til virksomhed',
  'tax_report_field_number.0038': 'B-indkomst, hvoraf der ikke skal betales arbejdsmarkedsbidrag',
  'tax_report_field_number.0039': 'Hædersgaver',
  'tax_report_field_number.0040': 'Naturalieydelser',
  'tax_report_field_number.0041': 'Bruttoindskud i medarbejderinvesteringsselskab',
  'tax_report_field_number.0042': 'Am-bidrag af indskud i medarbejderinvesteringsselskab',
  'tax_report_field_number.0043': 'Cvr-nr. på medarbejderinvesteringsselskab',
  'tax_report_field_number.0045': 'Sats-type for ATP-bidrag',
  'tax_report_field_number.0046': 'ATP-bidrag',
  'tax_report_field_number.0047': 'OP-bidrag',
  'tax_report_field_number.0048': 'Skattefri rejse- og befordringsgodtgørelse',
  'tax_report_field_number.0050': 'Værdi af fri helårsbolig',
  'tax_report_field_number.0051': 'Værdi af fri sommerbolig',
  'tax_report_field_number.0052': 'Værdi af fri lystbåd',
  'tax_report_field_number.0053': 'Værdi af fri medie-/radiolicens',
  'tax_report_field_number.0055': 'Værdi af andre personalegoder, der overstiger bundgrænse. ',
  'tax_report_field_number.0056': 'Værdi af andre personalegoder, uden bundgrænse. ',
  'tax_report_field_number.0057': 'A-indkomst fra aldersopsparing',
  'tax_report_field_number.0058': 'A-skat af aldersopsparing',
  'tax_report_field_number.0059': 'Foreløbig A-skat af aldersopsparing',
  'tax_report_field_number.0063': 'Fri befordring mellem hjem og arbejde mv.',
  'tax_report_field_number.0068': 'Yderligere tekstoplysninger',
  'tax_report_field_number.0069': 'Jubilæumsgratiale og fratrædelsesgodtgørelse',
  'tax_report_field_number.0070': 'Den del af 0069, der er indbetalt til pensionsordning.',
  'tax_report_field_number.0071': 'Den del af 0069, der er tingsgaver',
  'tax_report_field_number.0083': 'Sødage',
  'tax_report_field_number.0088': 'Fritaget for udligningsskat',
  'tax_report_field_number.0089': 'Bidrag til obligatorisk udenlandsk social sikring',
  'tax_report_field_number.0091': 'Gruppelivsforsikring i pensionsindskud',
  'tax_report_field_number.0096': 'Sundhedsforsikring i pensionsindskud',
  'tax_report_field_number.0098': 'Tilbagebetaling af kontanthjælp',
  'tax_report_field_number.0099': 'Pinkode',
  'tax_report_field_number.0113': 'A-indkomst, udbetalt som feriepenge',
  'tax_report_field_number.0114': 'YR-kontonummer - ikke-kommunale ydelser',
  'tax_report_field_number.0115': 'YR-kontonummer - kommunale ydelser',
  'tax_report_field_number.0143': 'RUT-nr. på udenlandsk arbejdsgiver vedr. AFU',
  'tax_report_field_number.0147': 'Lønmodtagers pensionsandel',
  'tax_report_field_number.0148': 'Arbejdsgivers pensionsandel',
  'tax_report_field_number.0157': 'Indbetaling på ikke-grønlandsk pensionsordning',
  'tax_report_field_number.0158': 'Skat af indbetaling på ikke-grønlandsk pensionsordning',
  'tax_report_field_number.0198': 'Bruttoferiepenge - timelønnede',
  'tax_report_field_number.0200': 'Antal løntimer',
  'tax_report_field_number.0201': 'Bruttoferiepenge',
  'tax_report_field_number.0202': 'Nettoferiepenge - timelønnede',
  'tax_report_field_number.0203': 'Optjente feriedage - timelønnede',
  'tax_report_field_number.0209': 'Se-nr. på feriepengeudbetaler',
  'tax_report_field_number.0210': 'Bruttoferiepenge - fortsættende funktionær',
  'tax_report_field_number.0211': 'Feriedage - fortsættende funktionær',
  'tax_report_field_number.0214': 'YR-ydelsesperiode ',
  'tax_report_field_number.0215': 'YR-periode for tilskud og refusion',
  'tax_report_field_number.0216': 'YR-udløsende cpr-nr.',
  'tax_report_field_number.0218': 'Cvr-/se-nr. på udbetalende FerieGarantiOrdning',
  'tax_report_field_number.0220': 'Ingen forhold mellem løn og timer',
  'tax_report_field_number.0248': 'Opsparing til søgne- og helligdags betaling',
  'tax_report_field_number.0249': 'Opsparing til feriefridage',
  'tax_report_field_number.0400': 'Am-bidrag af tilsagnsordning',
  'tax_report_field_number.20004': 'Nettoferiepenge for fratrædende funktionærer (for alle år, der er indberettet)',
  'tax_report_field_number.20005': 'Restferiedage fratrædende funktionærer (for alle år, der er indberettet)',
  'tax_report_field_number.20006': 'Bruttoferiepenge for fratrædende funktionærer (for alle år, der er indberettet)',

  'time_registration_tab.coarse.alert.success': 'Tidsregistreringen er gemt',
  'time_registration_tab.coarse.form.hours_warning':
    'Du har indtastet mere end 200 timer i et af felterne. Ignorer denne besked, hvis de indtastede tal er korrekte.',
  'time_registration_tab.coarse.form.reuse_registration': 'Genbrug timer og dage',
  'time_registration_tab.coarse.form.reuse_registration.use_standard_days': 'Genbrug timer og standard antal dage',
  'time_registration_tab.coarse.form.use_standard_days': 'Benyt standard antal dage',
  'time_registration_tab.coarse.form.work_days': 'Antal dage',
  'time_registration_tab.coarse.form.work_days.invalid': 'Antal dage er ugyldige',
  'time_registration_tab.coarse.form.work_days.must_be_integer': 'Antal dage skal være et heltal',
  'time_registration_tab.coarse.form.work_days.required': 'Antal dage er påkrævet',
  'time_registration_tab.coarse.form.work_days.suffix': 'dage',
  'time_registration_tab.coarse.form.work_hours': 'Antal timer',
  'time_registration_tab.coarse.form.work_hours.format': '{title} - {rate}',
  'time_registration_tab.coarse.form.work_hours.info': 'Hundrededele timer (halve timer er f.eks. 0,5 timer).',
  'time_registration_tab.coarse.form.work_hours.invalid': '{description} er ugyldige',
  'time_registration_tab.coarse.form.work_hours.required': '{description} er påkrævet',
  'time_registration_tab.coarse.form.work_hours.suffix': 'timer',
  'time_registration_tab.coarse.form.work_hours.with_title': 'Antal timer ({title})',
  'time_registration_tab.coarse.modal.title': 'Tidsregistrering for {name}',
  'time_registration_tab.coarse.prevent_period_change.line_1':
    'Herunder registreres tid for hele perioden {start} til {end}.',
  'time_registration_tab.coarse.prevent_period_change.line_2':
    'Hvis du vil registrere for en tidligere periode, skal du gå til {link} på medarbejderen.',
  'time_registration_tab.coarse.prevent_period_change.line_2.link': 'tidsregistrering',
  'time_registration_tab.coarse.select_period': 'Vælg periode',
  'time_registration_tab.coarse.table.actions.edit': 'Redigér registrering',
  'time_registration_tab.coarse.table.header.days': 'Dage',
  'time_registration_tab.coarse.table.header.description': 'Beskrivelse',
  'time_registration_tab.coarse.table.header.hours': 'Timer',
  'time_registration_tab.coarse.table.header.period': 'Periode',
  'time_registration_tab.detailed.add_new.flex_time': 'Registrér flekstid',
  'time_registration_tab.detailed.add_new.hours': 'Registrér tid',
  'time_registration_tab.detailed.add_new.overtime': 'Registrér afspadsering',
  'time_registration_tab.detailed.add_new.project': 'Registrér projekttid',
  'time_registration_tab.detailed.approve_all': 'Godkend alle',
  'time_registration_tab.detailed.balance_note.flex_time': 'Saldo på flekstimer, som afventer lønkørsel.',
  'time_registration_tab.detailed.balance_note.hours': 'Antal arbejdstimer, som afventer udbetaling.',
  'time_registration_tab.detailed.balance_note.overtime': 'Saldo på afspadsering, som afventer lønkørsel.',
  'time_registration_tab.detailed.balance_note.project': 'Saldo på samlede projekttimer, som afventer lønkørsel.',
  'time_registration_tab.detailed.confirm.bulk_delete':
    'Er du sikker på du vil slette alle registreringer? De kan ikke genskabes.',
  'time_registration_tab.detailed.delete_bulk': 'Slet alle',
  'time_registration_tab.detailed.edit.button.approve': 'Godkend tidsregistrering',
  'time_registration_tab.detailed.edit.button.delete': 'Slet tidsregistrering',
  'time_registration_tab.detailed.edit.form.break_minutes': 'Pause',
  'time_registration_tab.detailed.edit.form.break_minutes.invalid': 'Pause er ugyldigt',
  'time_registration_tab.detailed.edit.form.break_minutes.longer_before_total':
    'Pause må ikke være længere tid end muligt',
  'time_registration_tab.detailed.edit.form.break_minutes.must_be_integer': 'Pause skal være et heltal',
  'time_registration_tab.detailed.edit.form.break_minutes.suffix': 'minutter',
  'time_registration_tab.detailed.edit.form.created_at': 'Tidsregistrering oprettet {createdAt}.',
  'time_registration_tab.detailed.edit.form.date': 'Dato',
  'time_registration_tab.detailed.edit.form.date.required': 'Dato skal indtastes',
  'time_registration_tab.detailed.edit.form.date.within_employment': 'Dato kan ikke være før ansættelsesdatoen',
  'time_registration_tab.detailed.edit.form.end': 'Slut',
  'time_registration_tab.detailed.edit.form.end.after_start': 'Slut skal være efter start',
  'time_registration_tab.detailed.edit.form.end.invalid': 'Slut er ugyldigt',
  'time_registration_tab.detailed.edit.form.end.required': 'Slut skal indtastes',
  'time_registration_tab.detailed.edit.form.hours': 'Antal timer',
  'time_registration_tab.detailed.edit.form.hours.note': 'Hundrededele timer (halve timer er f.eks. 0,5 timer).',
  'time_registration_tab.detailed.edit.form.hours.required': 'Antal timer skal indtastes',
  'time_registration_tab.detailed.edit.form.hours.suffix': 'timer',
  'time_registration_tab.detailed.edit.form.is_using': 'Brugt eller optjent?',
  'time_registration_tab.detailed.edit.form.is_using.checked': 'Brugt',
  'time_registration_tab.detailed.edit.form.is_using.unchecked': 'Optjent',
  'time_registration_tab.detailed.edit.form.minutes': 'Minutter',
  'time_registration_tab.detailed.edit.form.minutes.invalid': 'Minutter er ugyldige',
  'time_registration_tab.detailed.edit.form.minutes.note': 'Kun hele minutter',
  'time_registration_tab.detailed.edit.form.minutes.required': 'Minutter er påkrævet',
  'time_registration_tab.detailed.edit.form.minutes.suffix': 'minutter',
  'time_registration_tab.detailed.edit.form.note': 'Note',
  'time_registration_tab.detailed.edit.form.project_id': 'Projekt',
  'time_registration_tab.detailed.edit.form.project_id.none_available':
    'Ingen projekter tilgængelige ved den valgte dato',
  'time_registration_tab.detailed.edit.form.project_id.required': 'Projekt skal vælges',
  'time_registration_tab.detailed.edit.form.salary_type_id': 'Type',
  'time_registration_tab.detailed.edit.form.salary_type_id.required': 'Type skal vælges',
  'time_registration_tab.detailed.edit.form.start': 'Start',
  'time_registration_tab.detailed.edit.form.start.before_end': 'Start skal være før slut',
  'time_registration_tab.detailed.edit.form.start.format_example': 'Format TT:MM, f.eks. 16:15',
  'time_registration_tab.detailed.edit.form.start.invalid': 'Start er ugyldigt',
  'time_registration_tab.detailed.edit.form.start.required': 'Start skal indtastes',
  'time_registration_tab.detailed.edit.form.use_minutes': 'Indtast i minutter',
  'time_registration_tab.detailed.edit.title.flex_time.create': 'Opret flekstid',
  'time_registration_tab.detailed.edit.title.flex_time.edit': 'Redigér flekstid',
  'time_registration_tab.detailed.edit.title.hours.create': 'Opret timeregistrering',
  'time_registration_tab.detailed.edit.title.hours.edit': 'Redigér timeregistrering',
  'time_registration_tab.detailed.edit.title.overtime.create': 'Opret afspadsering',
  'time_registration_tab.detailed.edit.title.overtime.edit': 'Redigér afspadsering',
  'time_registration_tab.detailed.edit.title.project.create': 'Opret projekttidsregistrering',
  'time_registration_tab.detailed.edit.title.project.edit': 'Redigér projekttidsregistrering',
  'time_registration_tab.detailed.edit.title.work_hours.create': 'Opret arbejdstid',
  'time_registration_tab.detailed.edit.title.work_hours.edit': 'Redigér arbejdstid',
  'time_registration_tab.detailed.history.table.header.date': 'Dato',
  'time_registration_tab.detailed.history.table.header.description': 'Beskrivelse',
  'time_registration_tab.detailed.history.table.header.hours': 'Timer',
  'time_registration_tab.detailed.history.table.header.note': 'Note',
  'time_registration_tab.detailed.history.table.title.earned': '{hours} optjent',
  'time_registration_tab.detailed.history.table.title.used': '{hours} brugt',
  'time_registration_tab.detailed.table.actions.delete': 'Slet registrering',
  'time_registration_tab.detailed.table.actions.edit': 'Redigér registrering',
  'time_registration_tab.detailed.table.approved': 'Godkendt',
  'time_registration_tab.detailed.table.approved.approve': 'Godkend',
  'time_registration_tab.detailed.table.approved.false': 'Nej',
  'time_registration_tab.detailed.table.approved.remove': 'Fjern godkendelse',
  'time_registration_tab.detailed.table.approved.true': 'Ja',
  'time_registration_tab.detailed.table.date': 'Dato',
  'time_registration_tab.detailed.table.description': 'Beskrivelse',
  'time_registration_tab.detailed.table.hours': 'Timer',
  'time_registration_tab.detailed.table.note': 'Note',
  'time_registration_tab.detailed.table.saving': 'Gemmer...',
  'time_registration_tab.detailed.table.title.earned': '{hours} optjent',
  'time_registration_tab.detailed.table.title.rate_format': '{title} ({rate})',
  'time_registration_tab.detailed.table.title.used': '{hours} brugt',
  'time_registration_tab.header.detailed.hours': 'Detaljeret tidsregistrering',
  'time_registration_tab.header.detailed.other': 'Registreringer i app',
  'time_registration_tab.header.go_to_calendar.hours': 'Gå til virksomhedens timekalender',
  'time_registration_tab.header.hide_history': 'Tilbage',
  'time_registration_tab.header.show_history': 'Vis historik',
  'time_registration_tab.title.history.flex_time': 'Historiske flekstidsregistreringer',
  'time_registration_tab.title.history.hours': 'Historiske timesregistreringer',
  'time_registration_tab.title.history.overtime': 'Historisk afspadsering',
  'time_registration_tab.title.history.project': 'Historisk tidsregistreringer',
  'time_registration_tab.title.standard.flex_time': 'Flekstidsregistreringer',
  'time_registration_tab.title.standard.hours': 'Timesregistreringer',
  'time_registration_tab.title.standard.overtime': 'Afspadsering',
  'time_registration_tab.title.standard.project': 'Tidsregistreringer',

  'time_registration.class.flex': 'Flekstimer',
  'time_registration.class.hours': 'Arbejdstimer',
  'time_registration.class.leave': 'Fravær',
  'time_registration.class.overtime': 'Afspaderingstimer',
  'time_registration.class.project_hours': 'Projekttimer',
  'time_registration.class.work_hours': 'Arbejdstid',
  'time_registration.coarse_time_registration.many': 'simple tidsregistreringer',
  'time_registration.coarse_time_registration.one': 'simpel tidsregistrering',
  'time_registration.detailed_time_registration.many': 'detaljerede tidsregistreringer',
  'time_registration.detailed_time_registration.one': 'detaljeret tidsregistrering',
  'time_registration.leave_duration.full_day': 'Hel dag',
  'time_registration.leave_duration.half_day': 'Halv dag',
  'time_registration.leave_duration.other': 'Anden værdi',
  'time_registration.leave_duration.quarter_day': 'Kvart dag',
  'time_registration.leave_duration.three_quarters_day': 'Trekvart dag',
  'time_registration.time_registration.many': 'tidsregistreringer',
  'time_registration.time_registration.one': 'tidsregistrering',

  'time_registrations.calendar.approve_all_text.many':
    'Er du sikker på du vil godkende {count} tidsregistreringer for hele {month}?',
  'time_registrations.calendar.approve_all_text.one':
    'Er du sikker på du vil godkende {count} tidsregistrering for hele {month}?',
  'time_registrations.calendar.context_menu.hours_format.no_note': '{hours}',
  'time_registrations.calendar.context_menu.hours_format.with_note': '{hours} ({note})',
  'time_registrations.calendar.context_menu.time_format.no_note': '{start} - {end}',
  'time_registrations.calendar.context_menu.time_format.with_note': '{start} - {end} ({note})',
  'time_registrations.calendar.instruction_text':
    'Tryk på et frit felt for at oprette en ny tidsregistrering. Tryk på en eksisterende tidsregistrering for at godkende, redigere eller slette.',
  'time_registrations.calendar.legend_hours': 'Timer',
  'time_registrations.calendar.new_type': 'ny tidsregistrering',
  'time_registrations.calendar.title': 'Detaljeret tidsregistrering',
  'time_registrations.calendar.tooltip_format_hours': 'Timer for {name} d. {date}',
  'time_registrations.calendar.tooltip_format_named': '{salary} for {name} d. {date}',
  'time_registrations.header.import_from_file': 'Importér fra fil',
  'time_registrations.header.switch_to_calendar': 'Vis som kalender',
  'time_registrations.header.switch_to_table': 'Vis som tabel',
  'time_registrations.tab.delete_modal.intro.many':
    'Du er ved at slette alle detaljerede tidsregistreringer på virksomheden, som ikke er kørt igennem på en løn. Der er tale om {count} tidsregistreringer. De kan ikke genskabes.',
  'time_registrations.tab.delete_modal.intro.one':
    'Du er ved at slette alle detaljerede tidsregistreringer på virksomheden, som ikke er kørt igennem på en løn. Der er tale om {count} tidsregistrering. De kan ikke genskabes.',
  'time_registrations.tab.delete_modal.submit': 'Slet alle',
  'time_registrations.tab.delete_modal.title': 'Slet alle ikke-kørte tidsregistreringer',
  'time_registrations.table.actions.edit': 'Redigér',
  'time_registrations.table.actions.register': 'Registrér',
  'time_registrations.table.all_approved': 'Alle timer godkendt',
  'time_registrations.table.approve_hours': 'Godkend timer',
  'time_registrations.table.detailed.delete_all': 'Slet alle tidsregistreringer',
  'time_registrations.table.detailed.title': 'Detaljeret tidsregistrering',
  'time_registrations.table.header.employee': 'Medarbejder',
  'time_registrations.table.header.hours': 'Timer',
  'time_registrations.table.header.rate': 'Sats',
  'time_registrations.table.header.salary': 'Løn',
  'time_registrations.table.loading': 'Indlæser...',
  'time_registrations.table.saving': 'Gemmer...',
  'time_registrations.table.select_period': 'Vælg periode',
  'time_registrations.table.simple.title': 'Simpel tidsregistrering',

  'transfer_easy_account.alert.success': 'Ændringer til {name} er gemt',
  'transfer_easy_account.form.enable_easy_account_transfers': 'Slå NemKonto-overførsler til',
  'transfer_easy_account.header.enable_all_employees': 'Slå NemKonto til for alle medarbejdere',
  'transfer_easy_account.intro.line_1':
    'Når du har NemKonto slået til bruger Salary dine medarbejderes CPR-numre istedet for deres kontonumre til at overføre løn.',
  'transfer_easy_account.intro.line_2':
    'Deadline for løngodkendelse rykkes 2 timer tidligere, da Overførselsservice skal bruge tid på at slå kontonumrene op i NemKonto registereret. Derudover koster det 71 øre per overførsel.',
  'transfer_easy_account.intro.line_3': 'Du skal sidst men ikke mindst udfylde denne formular hos Overførselsservice:',
  'transfer_easy_account.subtitle': 'NemKonto-overførsler',
  'transfer_easy_account.title': 'NemKonto',

  'transfer_immediate_pay.alert.success': 'Ændringer til {name} er gemt',
  'transfer_immediate_pay.description.immediate_pay':
    '{immediate} giver mulighed for at overføre en medarbejders udlæg med det samme via Overførselsservice.',
  'transfer_immediate_pay.description.immediate_pay.title': 'Ekspresoverførsler',
  'transfer_immediate_pay.description.payment_service.with_zimpler':
    'Når der bruges Overførselsservice, vil overførslen først ske ved næste Overførselsservice-kørsel (hver formiddag på en bankdag), og pengene vil derfor først være på medarbejderens bankkonto den næste bankdag efter kørslen.',
  'transfer_immediate_pay.description.payment_service.without_zimpler':
    'Da der bruges Overførselsservice, så vil overførslen først ske ved næste Overførselsservice-kørsel (hver formiddag på en bankdag), og pengene vil derfor først være på medarbejderens bankkonto den næste bankdag efter kørslen.',
  'transfer_immediate_pay.description.swipe_cost':
    '{cost} per medarbejder der har brugt den per periode, hvis man bruger Basic-pakken, og er gratis i Premium-pakken.',
  'transfer_immediate_pay.description.swipe_cost.cost': 'Swipe koster 10 kr.',
  'transfer_immediate_pay.description.swipe.title': 'Swipe',
  'transfer_immediate_pay.description.swipe.with_zimpler':
    'Med {title} kan dine medarbejdere via deres medarbejder-app bede om at få deres løn udbetalt med det samme via Zimpler eller Overførselsservice.',
  'transfer_immediate_pay.description.swipe.without_zimpler':
    'Med {title} kan dine medarbejdere via deres medarbejder-app bede om at få deres løn udbetalt med det samme via Overførselsservice.',
  'transfer_immediate_pay.description.zimpler':
    'Når der bruges Zimpler til overførslerne, kan pengene modtages inden for fem minutter.',
  'transfer_immediate_pay.form.auto_approve_time_registration_hours': 'Slå autogodkendelse af timer til',
  'transfer_immediate_pay.form.auto_approve_time_registration_hours.note':
    'Swipe-forløbet afhænger meget af om hvorvidt du har autogodkendelse af timer slået til, da dine timelønnede ville skulle vente på en manuel godkendelse af deres timer, hvis autogodkendelse af timer ikke er slået til.',
  'transfer_immediate_pay.form.enable_always_current_pay_roll_run': 'Sørg for der altid er en lønkørsel for dags dato',
  'transfer_immediate_pay.form.enable_always_current_pay_roll_run.note.line_1':
    'Når man har skæve perioder, vil der være dage, hvor perioden for den nuværende lønkørsel er overstået, mens dens dispositionsdato er i fremtiden. Når der ikke er en aktiv lønkørsel, så kan man ikke registrere Swipes.',
  'transfer_immediate_pay.form.enable_always_current_pay_roll_run.note.line_2':
    'Her kan man slå til om man vil starte en ny lønkørsel, så snart perioden for de forrige ender. Det vil dog betyde at man vil have to åbne lønkørsler samtidigt.',
  'transfer_immediate_pay.form.enable_immediate_pay': 'Slå ekspresoverførsler til',
  'transfer_immediate_pay.form.enable_limit': 'Slå autogodkendelse til',
  'transfer_immediate_pay.form.enable_limit.note':
    'Du kan slå autogodkendelse til, med en øvre grænse per medarbejder for hver periode. Uden autogodkendelse, skal alle ekspresoverførsler og Swipes fra dine medarbejdere manuelt godkendes.',
  'transfer_immediate_pay.form.enable_swipe': 'Slå Swipe til',
  'transfer_immediate_pay.form.enable_swipe_fee': 'Slå medarbejdergebyr til',
  'transfer_immediate_pay.form.enable_swipe_fee.note.with_zimpler_fee':
    'Normalt er brugen af Swipe uden meromkostninger for medarbejderen, udover gebyret fra Zimpler. Du kan dog slå et medarbejdergebyr til, så medarbejderen skal betale for at bruge Swipe. Gebyret optræder som et nettotræk på lønnen.',
  'transfer_immediate_pay.form.enable_swipe_fee.note.without_zimpler':
    'Normalt er brugen af Swipe uden meromkostninger for medarbejderen. Du kan dog slå et medarbejdergebyr til, så medarbejderen skal betale for at bruge Swipe. Gebyret optræder som et nettotræk på lønnen.',
  'transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out':
    'Øvre grænse for godkendelse per medarbejder per periode',
  'transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.invalid': 'Øvre grænse er ugyldig',
  'transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.note.many':
    'Med dine nuværende indstillinger, vil dine {count} medarbejdere sammenlagt kunne automatisk få op til {amount} udbetalt i løbet af en periode.',
  'transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.note.one':
    'Med dine nuværende indstillinger, vil din ene medarbejder sammenlagt kunne automatisk få op til {amount} udbetalt i løbet af en periode.',
  'transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.required': 'Øvre grænse skal udfyldes',
  'transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.suffix': 'kr.',
  'transfer_immediate_pay.form.swipe_employment_type.note':
    'Det er muligt at begrænse hvilken type af medarbejder der skal have lov til at kunne bruge Swipe, ved at slå typen herunder til eller fra. At slå ingen til svarer til at slå Swipe fra. Lige nu kan {employees} bruge Swipe. Du kan {link}.',
  'transfer_immediate_pay.form.swipe_employment_type.note.employees.many': '{count} medarbejdere',
  'transfer_immediate_pay.form.swipe_employment_type.note.employees.one': '{count} medarbejder',
  'transfer_immediate_pay.form.swipe_employment_type.note.link': 'justere Swipe for individuelle medarbejdere her',
  'transfer_immediate_pay.form.swipe_fee_cost': 'Medarbejdergebyr',
  'transfer_immediate_pay.form.swipe_fee_cost_cap': 'Øvre grænse for gebyr',
  'transfer_immediate_pay.form.swipe_fee_cost_cap.invalid': 'Øvre grænse for gebyr er ugyldig',
  'transfer_immediate_pay.form.swipe_fee_cost_cap.suffix': 'kr.',
  'transfer_immediate_pay.form.swipe_fee_cost_frequency': 'Hvor ofte skal gebyret trækkes?',
  'transfer_immediate_pay.form.swipe_fee_cost_frequency.each': 'Hver gang',
  'transfer_immediate_pay.form.swipe_fee_cost_frequency.period': 'Én gang per lønperiode for medarbejderen',
  'transfer_immediate_pay.form.swipe_fee_cost.fixed': 'kr.',
  'transfer_immediate_pay.form.swipe_fee_cost.invalid': 'Medarbejdergebyr er ugyldig',
  'transfer_immediate_pay.form.swipe_fee_cost.note.cap':
    'Du kan også sætte en øvre grænse for gebyret, således at hvis gebyret baseret på en procentsats ville overstige grænsen, bliver gebyret kun sat til grænsen.',
  'transfer_immediate_pay.form.swipe_fee_cost.note.frequency':
    'Du kan enten vælge om gebyret skal trækkes hver gang, medarbejderen swiper, eller kun en enkel gang per lønperiode for medarbejderen.',
  'transfer_immediate_pay.form.swipe_fee_cost.note.type':
    'Du kan enten vælge et kronebeløb eller en procentsats som gebyr. Medarbejderen vil kun se det beløb de kan Swipe med gebyret uden at de sammenlagt overstiger deres udestående nettoløn. Har de f.eks. 1.000 kr. i nettoløn, 50 kr. i gebyr, vil der står 950 kr. som de kan swipe.',
  'transfer_immediate_pay.form.swipe_fee_cost.percentage': '%',
  'transfer_immediate_pay.form.swipe_fee_cost.required': 'Medarbejdergebyr skal indtastes',
  'transfer_immediate_pay.form.swipe_instant_cost_payer.employee': 'Medarbejderbetalt',
  'transfer_immediate_pay.form.swipe_instant_cost_payer.employer': 'Arbejdsgiverbetalt',
  'transfer_immediate_pay.form.swipe_instant_cost_payer.note.line_1':
    'Straksudbetalinger med Zimpler koster 5 kr. stykket. Per standard er det dig som arbejdsgiver der betaler omkostningen. Her kan du vælge, om du vil lægge omkostningerne over på medarbejderen. Alternativt kan du dele omkostningerne ligeligt (2,5 kr. hver) mellem jer.',
  'transfer_immediate_pay.form.swipe_instant_cost_payer.note.line_2':
    'Medarbejderen vil få omkostningen at vide, og vil kunne vælge om de skal bruge straks- eller snartudbetalingen (via Overførselsservice), som er omkostningsfri.',
  'transfer_immediate_pay.form.swipe_instant_cost_payer.shared': 'Delt mellem arbejdsgiver og medarbejder',
  'transfer_immediate_pay.form.swipe_notification_at': 'Tidspunkt for Swipe-notifikation',
  'transfer_immediate_pay.form.swipe_notification_at.between_0_and_23':
    'Tidspunkt skal være mellem 00 og 23, begge inklusiv',
  'transfer_immediate_pay.form.swipe_notification_at.note.line_1':
    'For notifikationer om Swipe-anmodninger der ikke er godkendt, kan du vælge hvornår på dagen (hvilken time) du får sendt denne besked til alle på virksomheden der modtager denne notifikation.',
  'transfer_immediate_pay.form.swipe_notification_at.note.line_2':
    'Under Virksomhed - Løn - Notifikationer kan du vælge om du vil have disse notifikationer.',
  'transfer_immediate_pay.form.swipe_notification_at.required': 'Tidspunkt skal vælges',
  'transfer_immediate_pay.header.reimbursement_settings': 'Udlægsindstillinger',
  'transfer_immediate_pay.header.transfers': 'Tilbage',
  'transfer_immediate_pay.title.immediate': 'Ekspresoverførsler',
  'transfer_immediate_pay.title.immediate_and_swipe': 'Ekspresoverførsler & Swipe',
  'transfer_immediate_pay.title.swipe': 'Swipe',

  'transfer_report.from_date': 'Fra',
  'transfer_report.title': 'Overførselsrapport',
  'transfer_report.to_date': 'Til',

  'transfer_settings.alert.success': 'Ændringer til {name} er gemt',
  'transfer_settings.form.transfer_manual': 'Overføres manuelt',
  'transfer_settings.form.transfer_via': 'Overføres via {integration}',
  'transfer_settings.header.back': 'Tilbage',
  'transfer_settings.title': 'Overførselsindstillinger',
  'transfer_settings.transfer_bank_account.change': 'Skift overførselsintegration',
  'transfer_settings.transfer_bank_account.create': 'Opret Overførselsservice-aftale',
  'transfer_settings.transfer_from.title': 'Overføres fra konto:',
  'transfer_settings.transfers_handled.automatic': 'Automatisk',
  'transfer_settings.transfers_handled.manual': 'Manuelt',
  'transfer_settings.transfers_handled.title': 'Overførsler foretages:',
  'transfer_settings.transfers_via.payment_service': 'Overførselsservice',
  'transfer_settings.transfers_via.title': 'Overføres via:',

  'transfer.destination_type.dk_account': 'Dansk bankkonto',
  'transfer.destination_type.dk_nemkonto': 'NemKonto',
  'transfer.destination_type.dk_nemkonto_cvr': 'NemKonto (CVR)',
  'transfer.destination_type.none': 'Udenlandsk bankkonto',
  'transfer.setting_integration.manual': 'Manuelt',
  'transfer.setting_integration.nets': 'Overførselsservice',
  'transfer.setting_integration.stripe': 'Betalingskort',
  'transfer.setting_integration.zimpler': 'Zimpler',
  'transfer.type.dk_a_tax': 'A-skat',
  'transfer.type.dk_am_contribution': 'AM-bidrag',
  'transfer.type.dk_atp': 'ATP',
  'transfer.type.dk_flf': 'Barsel',
  'transfer.type.dk_flf_company_zero': 'Barsel-nul-indberetning',
  'transfer.type.dk_flf_da_barsel': 'DA Barsel',
  'transfer.type.dk_forenede_gruppeliv': 'Forenede Gruppeliv',
  'transfer.type.dk_vacation_fund': 'Feriepenge',
  'transfer.type.dk_vacation_fund_custom': 'Feriekasse',
  'transfer.type.dk_vacation_fund_special_supplement': 'Feriekasse (særtillæg)',
  'transfer.type.freelance_fee': 'Honorar',
  'transfer.type.immediate_pay': 'Ekspresudbetaling',
  'transfer.type.invoice': 'Faktura',
  'transfer.type.pension': 'Pension',
  'transfer.type.pension_dk_account': 'Pension (kontooverførsel)',
  'transfer.type.salary': 'Løn',

  'transfers_tab.header.early_payments': 'Overførsel af Skat m.fl.',
  'transfers_tab.header.immediate_pay': 'Swipe',
  'transfers_tab.header.nemkonto': 'NemKonto',
  'transfers_tab.header.transfer_report': 'Hent Excel',
  'transfers_tab.header.transfer_settings': 'Skift overførselsindstillinger',
  'transfers_tab.saving': 'Gemmer...',
  'transfers_tab.table.action.complete': 'Markér som manuelt betalt',
  'transfers_tab.table.action.edit_date': 'ret dato',
  'transfers_tab.table.action.remove': 'Fjern',
  'transfers_tab.table.action.switch_to_manual': 'Skift til manuel',
  'transfers_tab.table.action.switch_to_nets': 'Skift til Overførselsservice',
  'transfers_tab.table.action.switch_to_stripe.full': 'Skift til betalingskort',
  'transfers_tab.table.action.switch_to_stripe.simple': 'betalingskort',
  'transfers_tab.table.action.switch_wrapper.both': '{nets_link} eller {stripe_link}',
  'transfers_tab.table.action.switch_wrapper.nets': '{nets_link}',
  'transfers_tab.table.action.switch_wrapper.stripe': '{stripe_link}',
  'transfers_tab.table.action.try_again': 'Kør overførsel igen',
  'transfers_tab.table.completed.failed': 'Fejlet',
  'transfers_tab.table.completed.false': 'Nej',
  'transfers_tab.table.completed.true': 'Ja',
  'transfers_tab.table.header.amount': 'Beløb',
  'transfers_tab.table.header.completed': 'Gennemført?',
  'transfers_tab.table.header.payment_date': 'Betalingsdato',
  'transfers_tab.table.header.status': 'Status',
  'transfers_tab.table.header.type': 'Type',
  'transfers_tab.table.status.approved': 'Godkendt',
  'transfers_tab.table.status.completed': 'Gennemført',
  'transfers_tab.table.status.failed': 'Fejlet',
  'transfers_tab.table.status.pending': 'Afventer',
  'transfers_tab.title': 'Overførsler',

  'transfers.date.form.date': 'Betalingsdato',
  'transfers.date.form.date.required': 'Betalingsdato skal vælges',
  'transfers.date.title': 'Ret betalingsdato',

  'travel_allowance.edit.form.amount': 'diæt',
  'travel_allowance.edit.form.disposition_date': 'Hvornår skal diæten udbetales?',
  'travel_allowance.edit.form.disposition_date.placeholder': 'Udbetalingsdato',
  'travel_allowance.edit.intro': 'Vær opmærksom på at du selv skal kunne dokumentere bilag overfor SKAT.',
  'travel_allowance.edit.title.create': 'Opret diæt',
  'travel_allowance.edit.title.edit': 'Redigér diæt',
  'travel_allowance.header.add_travel_allowance': 'Opret diæt',
  'travel_allowance.header.approve_all': 'Godkend alle',
  'travel_allowance.header.hide_history': 'Tilbage',
  'travel_allowance.header.show_history': 'Vis historik',
  'travel_allowance.header.title.history': 'Historik for diæt',
  'travel_allowance.header.title.standard': 'Diæt',
  'travel_allowance.table.actions.delete': 'Slet diæt',
  'travel_allowance.table.actions.edit': 'Redigér diæt',
  'travel_allowance.table.actions.view': 'Gennemse diæt',
  'travel_allowance.table.amount.format': '{units} à {rate}',
  'travel_allowance.table.approved.approve': 'Godkend',
  'travel_allowance.table.approved.false': 'Nej',
  'travel_allowance.table.approved.remove': 'Fjern godkendelse',
  'travel_allowance.table.approved.true': 'Ja',
  'travel_allowance.table.header.amount': 'Beløb',
  'travel_allowance.table.header.approved': 'Godkendt',
  'travel_allowance.table.header.date': 'Dato',
  'travel_allowance.table.header.title': 'Beskrivelse',
  'travel_allowance.table.saving': 'Gemmer...',

  'user_notifications.alert.success': 'Dine notifikationsindtillinger til {name} er gemt',
  'user_notifications.form.notification_automatic_zero_tax_report_done':
    'Giv besked, når en nulindberetning er gennemført.',
  'user_notifications.form.notification_employee_updated_banking':
    'Send en notifikation når en medarbejder har opdateret sine bankoplysninger igennem medarbejder-appen.',
  'user_notifications.form.notification_pay_roll_approval_upcoming':
    'Giv besked via SMS dagen før en lønkørsel med Overførselsservice-overførsler skal godkendes.',
  'user_notifications.form.notification_pay_roll_automatically_started':
    'Giv besked, når en ny automatisk lønkørsel åbner.',
  'user_notifications.form.notification_pay_roll_deadline_upcoming':
    'Giv besked nogle dage før en lønkørsel skal godkendes.',
  'user_notifications.form.notification_pay_roll_done': 'Giv besked, når lønkørsel er gennemført.',
  'user_notifications.form.notification_swipe_awaiting_approval':
    'Send en notifikation når der er Swipe-anmodninger der afventer godkendelse.',
  'user_notifications.form.notification_transfer_info':
    'Giv besked ved Overførselsservice-fejl (f.eks. forkert kontonummer) eller -info.',
  'user_notifications.form.section.title': 'Dine virksomhedsnotifikationer',
  'user_notifications.header.back': 'Tilbage',
  'user_notifications.title': 'Dine virksomhedsnotifikationer',

  'user_survey.step_0.help.least': 'Ikke sandsynligt',
  'user_survey.step_0.help.most': 'Meget sandsynligt',
  'user_survey.step_0.intro.line_1': 'Hej {name}',
  'user_survey.step_0.intro.line_1.user_default': 'bruger',
  'user_survey.step_0.intro.line_2': 'Hvor sandsynligt er det, at du vil anbefale Salary til en ven eller kollega?',
  'user_survey.step_0.next': 'Videre',
  'user_survey.step_0.reject': 'Nej tak',
  'user_survey.step_1.intro': 'Hvad kan vi gøre for at gøre Salary endnu bedre for dig?',
  'user_survey.step_1.next': 'Indsend',
  'user_survey.step_1.previous': 'Tilbage',
  'user_survey.step_2.intro': 'Tak for din tid!  Din tilbagemelding er meget værdifuld for os.',
  'user_survey.title': 'Spørgeskema',

  'user.company_type.accountant': 'Revisor',
  'user.company_type.approve_only': 'Kun løngodkender',
  'user.company_type.book_keeper': 'Bogholder',
  'user.company_type.business': 'Medarbejder',
  'user.company_type.department_manager': 'Afdelingsleder',
  'user.company_type.external_salary_administrator': 'Ekstern lønadministrator',
  'user.company_type.internal_salary_administrator': 'Intern lønadministrator',
  'user.company_type.read_only': 'Kun læseadgang',
  'user.department_permission.approve_objects': 'Godkende emner',
  'user.department_permission.edit_objects': 'Redigere emner',
  'user.department_permission.hire_fire': 'Ændre i ansættelser',
  'user.department_permission.see_salary_rates': 'Se lønsatser',
  'user.permission.admin': 'Administrator',
  'user.permission.approve_only': 'Kun løngodkender',
  'user.permission.approve_pay_roll': 'Godkend løn',
  'user.permission.manager': 'Bruger',
  'user.permission.review_pay_roll': 'Klargør løn',

  'vacation_calendar.alert.success': 'Ændringer til {name} er gemt',
  'vacation_calendar.date_format': '{day}/{month}',
  'vacation_calendar.form.active.checked': 'Feriedag',
  'vacation_calendar.form.active.unchecked': 'Arbejdsdag',
  'vacation_calendar.form.add_day.action': 'Tilføj feriedag',
  'vacation_calendar.form.add_day.intro':
    'Du kan tilføje en feriedag til kalenderen som ikke allerede fremgår her. Året i kalendervælgeren bliver ignoreret, og det er kun datoen der bliver gemt.',
  'vacation_calendar.form.add_day.note':
    'Den tilføjede feriedag bliver først gemt på feriekalenderen, når du har trykket på "Gem ændringer" nedenfor.',
  'vacation_calendar.form.add_day.placeholder': 'Dag',
  'vacation_calendar.form.add_day.title': 'Tilføj feriedag til kalender',
  'vacation_calendar.header.back': 'Tilbage',
  'vacation_calendar.intro.line_1':
    'Feriekalenderen afgør hvilke dage der tæller som en feriedag, udover lørdag og søndag, som per standard aldrig er arbejdsdage.',
  'vacation_calendar.intro.line_2':
    'Den normale feriekalender i Salary følger de danske hellidage. Her kan du ændre på feriekalenderen, samt tilføje flere dage som feriedage.',
  'vacation_calendar.named_day_format': '{name} ({date})',
  'vacation_calendar.named_days.christmas_eve': 'Juleaftensdag',
  'vacation_calendar.named_days.constitution_day': 'Grundlovsdag',
  'vacation_calendar.named_days.first_day_of_christmas': '1. juledag',
  'vacation_calendar.named_days.mayday': '1. maj',
  'vacation_calendar.named_days.new_years_day': 'Nytårsdag',
  'vacation_calendar.named_days.new_years_eve': 'Nytårsaftensdag',
  'vacation_calendar.named_days.second_day_of_christmas': '2. juledag',
  'vacation_calendar.title': 'Tilbage',

  'vacation_form.extra_vacation_accrual': 'Optjening',
  'vacation_form.extra_vacation_accrual.monthly': 'Månedlig',
  'vacation_form.extra_vacation_accrual.yearly': 'Årlig',
  'vacation_form.extra_vacation_days': 'Feriefridage',
  'vacation_form.extra_vacation_days.invalid': 'Feriefridage er ugyldige',
  'vacation_form.extra_vacation_days.required': 'Feriefridage skal indtastes',
  'vacation_form.extra_vacation_days.suffix': 'dage per år',
  'vacation_form.funds.10250218': 'MFD Feriekasse',
  'vacation_form.funds.19003981': 'Danske Slagtermestre',
  'vacation_form.funds.31378028': '3F Feriekasse',
  'vacation_form.funds.34498458': 'Feriefonden f Hotel- & Rest.erhv.',
  'vacation_form.funds.81831718': 'Bager- og Konditormestre i Danmark',
  'vacation_form.funds.own': 'Eget CVR-nummer',
  'vacation_form.great_prayer_supplement': 'Store Bededagstillæg',
  'vacation_form.has_vacation_fund': 'Anden ferieindeberetter',
  'vacation_form.has_vacation_fund.lock':
    'Slå tillad anden ferieindberetter til under virksomhed - avanceret, for at ændre dette felt',
  'vacation_form.horesta_vacation_supplement': 'Særligt ferietillæg',
  'vacation_form.horesta_vacation_supplement_amount': 'Tillæg',
  'vacation_form.horesta_vacation_supplement_amount.invalid': 'Særferietillæg er ugyldigt',
  'vacation_form.horesta_vacation_supplement_amount.required': 'Særferietillæg skal indtastes',
  'vacation_form.horesta_vacation_supplement_amount.suffix': '%',
  'vacation_form.manual_vacation_fund': 'Manuel feriepengeudbetaling',
  'vacation_form.overtime_rate': 'Afspadseringssats',
  'vacation_form.overtime_rate.invalid': 'Afspadseringssats er ugyldigt',
  'vacation_form.overtime_rate.required': 'Afspadseringssats skal indtastes',
  'vacation_form.overtime_rate.suffix': 'kr./time',
  'vacation_form.personal_days': 'Omsorgsdage',
  'vacation_form.personal_days_accrual': 'Optjening',
  'vacation_form.personal_days_accrual.monthly': 'Månedlig',
  'vacation_form.personal_days_accrual.yearly': 'Årlig',
  'vacation_form.personal_days.invalid': 'Omsorgsdage er ugyldige',
  'vacation_form.personal_days.required': 'Omsorgsdage skal indtastes',
  'vacation_form.personal_days.suffix': 'dage per år',
  'vacation_form.time_bank': 'Timebank',
  'vacation_form.time_bank.flex': 'Flekstid',
  'vacation_form.time_bank.none': 'Ingen timebank',
  'vacation_form.time_bank.overtime': 'Afspadsering',
  'vacation_form.use_atp': 'ATP',
  'vacation_form.use_day_laborer': 'Brug anden ferieberegning',
  'vacation_form.use_day_laborer.days': 'Brug ferieberegning per dag',
  'vacation_form.use_day_laborer.hours': 'Brug ferieberegning per time',
  'vacation_form.use_day_laborer.lock':
    'Slå tillad ferieberegning per time/dag til under virksomhed - avanceret, for at ændre dette felt',
  'vacation_form.use_day_laborer.none': 'Brug almindelig ferieberegning',
  'vacation_form.vacation_days': 'Feriedage',
  'vacation_form.vacation_days.invalid': 'Feriedage er ugyldige',
  'vacation_form.vacation_days.must_be_between': 'Feriedage skal være mellem {min} og {max}',
  'vacation_form.vacation_days.required': 'Feriedage skal indtastes',
  'vacation_form.vacation_days.suffix': 'dage per år',
  'vacation_form.vacation_fund_cvr': 'CVR-nummer på indberetter',
  'vacation_form.vacation_fund_cvr.required': 'CVR-nummer skal vælges',
  'vacation_form.vacation_money': 'Feriepenge',
  'vacation_form.vacation_money.invalid': 'Feriepenge er ugyldigt',
  'vacation_form.vacation_money.must_be_12_5': 'Feriepenge kan kun være 12,5%',
  'vacation_form.vacation_money.required': 'Feriepenge skal indtastes',
  'vacation_form.vacation_money.restricted_rate': 'Feriepenge skal enten være 12%, 12,5% eller højere end 12,5%',
  'vacation_form.vacation_money.suffix': '%',
  'vacation_form.vacation_supplement': 'Ferietillæg',
  'vacation_form.vacation_supplement_in_pension_basis': 'Inkluder ferietillæg i pensionsgrundlag',
  'vacation_form.vacation_supplement.invalid': 'Ferietillæg er ugyldigt',
  'vacation_form.vacation_supplement.required': 'Ferietillæg skal indtastes',
  'vacation_form.vacation_supplement.suffix': '%',
  'vacation_form.vacation_type.none': 'Direktørløn',
  'vacation_form.vacation_type.none.tooltip': 'Ingen lovpligtig ferie',
  'vacation_form.vacation_type.paid': 'Ferie med løn',
  'vacation_form.vacation_type.paid.tooltip': 'Mindst 25 feriedage og 1% ferietillæg',
  'vacation_form.vacation_type.unpaid': 'Ferie uden løn',
  'vacation_form.vacation_type.unpaid.tooltip': 'Mindst 25 feriedage og 12,5% feriepenge',

  'vacation_settings.alert.success': 'Ændringer til {name} er gemt',
  'vacation_settings.form.automatic_optional_vacation_transfer_limit':
    'Antal af automatiske overførte månedligt optjente omsorgs/feriefridage til nyt ferieår',
  'vacation_settings.form.automatic_optional_vacation_transfer_limit.invalid':
    'Antal af automatiske overførte månedligt optjente omsorgs/feriefridage til nyt ferieår er ugyldigt',
  'vacation_settings.form.automatic_vacation_transfer_limit':
    'Antal af automatiske overførte betalte feriedage til nyt ferieår',
  'vacation_settings.form.automatic_vacation_transfer_limit.invalid':
    'Antal af automatiske overførte betalte feriedage til nyt ferieår er ugyldigt',
  'vacation_settings.form.leave_types_cycles_title': 'Ferieår for årlige fraværstyper',
  'vacation_settings.form.limit_i_calendar_to_departments': 'Adgang til fraværskalender for medarbejdere',
  'vacation_settings.form.limit_i_calendar_to_departments.all': 'Alle ansatte i virksomheden',
  'vacation_settings.form.limit_i_calendar_to_departments.department': 'Alle ansatte i samme afdeling',
  'vacation_settings.form.limit_i_calendar_to_departments.employee': 'Egen fraværskalender',
  'vacation_settings.form.limit_i_calendar_to_departments.required':
    'Adgang til fraværskalender for medarbejdere skal vælges',
  'vacation_settings.form.vacation_excess_limit': 'Antal feriedage medarbejdere må låne',
  'vacation_settings.form.vacation_excess_limit.invalid': 'Antal feriedage medarbejdere må låne er ugyldigt',
  'vacation_settings.header.back': 'Tilbage',
  'vacation_settings.header.vacation_calendar': 'Feriekalender',
  'vacation_settings.title': 'Ferieindstillinger',

  'validate_national_id.cpr.in_use': 'CPR-nummer er i brug',
  'validate_national_id.cpr.invalid': 'CPR-nummer er ugyldigt',
  'validate_national_id.cpr.required': 'CPR-nummer skal indtastes',
  'validate_national_id.cvr.in_use': 'CVR-nummer er i brug',
  'validate_national_id.cvr.invalid': 'CVR-nummer er ugyldigt',
  'validate_national_id.cvr.required': 'CVR-nummer skal indtastes',

  'voucher_date_modal.form.date': 'Bogføringsdato',
  'voucher_date_modal.intro': 'Du kan ændre på den dato som Salary har lavet på din bogføring.',
  'voucher_date_modal.title': 'Redigér bogføringsdato',

  'vouchers.header.back': 'Tilbage',
  'vouchers.header.filter.only_failed': 'Vis kun fejlet',
  'vouchers.header.filter.payroll': 'Lønkørsler',
  'vouchers.header.filter.reimbursement_vouchers': 'Udlæg',
  'vouchers.header.filter.transfer': 'Overførsler',
  'vouchers.header.title': 'Bogføring',
  'vouchers.modal.header.account_number': 'Konto',
  'vouchers.modal.header.credit': 'Kredit',
  'vouchers.modal.header.debit': 'Debet',
  'vouchers.modal.header.description': 'Beskrivelse',
  'vouchers.modal.header.vat': 'Moms',
  'vouchers.modal.title': 'Bogføringslinjer',
  'vouchers.modal.unknown_account': 'Ukendt kontonummer',
  'vouchers.table.actions.csv': 'CSV',
  'vouchers.table.actions.details': 'Bogføringslinjer',
  'vouchers.table.actions.details.tooltip': 'Vis bogføringslinjer',
  'vouchers.table.actions.pdf': 'PDF',
  'vouchers.table.actions.pdf.tooltip': 'Hent som PDF',
  'vouchers.table.change_date': 'Ret dato',
  'vouchers.table.header.date': 'Dato',
  'vouchers.table.header.status': 'Status',
  'vouchers.table.header.type': 'Type',
  'vouchers.table.rebook': 'Bogfør igen',
  'vouchers.table.status.booked.automated': 'Automatisk bogført',
  'vouchers.table.status.booked.manual': 'Bogføres manuelt',
  'vouchers.table.status.booking_failed': 'Bogføring fejlede',
  'vouchers.table.status.daybooked': 'Overført til kassekladde',
  'vouchers.table.status.manual': 'Bogføres manuelt',
  'vouchers.table.status.pending.at_date': 'Bogføres den {date}',
  'vouchers.table.status.pending.unapproved': 'Kladde',
  'vouchers.table.status.pending.within_15_minutes': 'Bogføres indenfor 15 minutter',
  'vouchers.table.type.pay_roll': 'Lønkørsel',
  'vouchers.table.type.reimbursement': 'Udlæg',
  'vouchers.table.type.transfer': 'Overførsel',

  'work_hours_registration_tab.alert.success': 'Arbejdstid er gemt',
  'work_hours_registration_tab.alert.template_deleted': 'Skabelon slettet',
  'work_hours_registration_tab.alert.template_none_saved': 'Medarbejderen har nu ingen arbejdstidsskabelon',
  'work_hours_registration_tab.alert.template_saved': 'Skabelon gemt',
  'work_hours_registration_tab.detailed.table.actions.delete': 'Slet registrering',
  'work_hours_registration_tab.detailed.table.actions.edit': 'Redigér registrering',
  'work_hours_registration_tab.detailed.table.date': 'Dato',
  'work_hours_registration_tab.detailed.table.hours': 'Timer',
  'work_hours_registration_tab.detailed.table.note': 'Note',
  'work_hours_registration_tab.header.help.work_hours.line_1':
    'Arbejdstid er ajourføring af timer for fastlønnede.  De er udelukkende til dokumentation af hvor mange timer fastlønnede medarbejdere faktisk har arbejdet.',
  'work_hours_registration_tab.header.help.work_hours.line_2':
    'Arbejdstid har ingen indflydelse på lønnen, og optræder udelukkende i en rapport.',
  'work_hours_registration_tab.simple_form.company_template_intro':
    'Her kan du udfylde en virksomhedsskabelon, som bliver anvendt på alle medarbejdere i oversigten, medmindre de i forvejen har en unik medarbejderskabelon.  Virksomhedsskabelonen kan altid erstattes på den enkelte medarbejder, hvis de skal have en anderledes skabelonuge eller ingen skabelon.',
  'work_hours_registration_tab.simple_form.delete': 'Slet skabelon',
  'work_hours_registration_tab.simple_form.delete_with_company': 'Brug virksomhedsskabelon',
  'work_hours_registration_tab.simple_form.intro': 'Indtast antal timer arbejdet per dag.',
  'work_hours_registration_tab.simple_form.leave_on_date': 'Fravær på dato.',
  'work_hours_registration_tab.simple_form.leave_on_date.item': '{amount} {name}',
  'work_hours_registration_tab.simple_form.leave_on_date.note.has_templates':
    'Skabeloner vil ikke gemme arbejdstid på datoen, da der er registeret følgende fravær:',
  'work_hours_registration_tab.simple_form.leave_on_date.note.no_templates':
    'Der er registeret følgende fravær på datoen:',
  'work_hours_registration_tab.simple_form.none': 'Ingen skabelon til medarbejder',
  'work_hours_registration_tab.simple_form.submit': 'Gem uge',
  'work_hours_registration_tab.simple_form.submit.template': 'Gem skabelon',
  'work_hours_registration_tab.simple_form.time_based.break': 'Pause',
  'work_hours_registration_tab.simple_form.time_based.break.invalid': 'Pause er ugyldig',
  'work_hours_registration_tab.simple_form.time_based.break.longer_before_total':
    'Pause må ikke være længere tid end muligt',
  'work_hours_registration_tab.simple_form.time_based.break.must_be_integer': 'Pause skal være et heltal',
  'work_hours_registration_tab.simple_form.time_based.break.suffix': 'minutter',
  'work_hours_registration_tab.simple_form.time_based.end': 'Slut',
  'work_hours_registration_tab.simple_form.time_based.header.break': 'Pause',
  'work_hours_registration_tab.simple_form.time_based.header.day': 'Ugedag',
  'work_hours_registration_tab.simple_form.time_based.header.end': 'Sluttidspunkt',
  'work_hours_registration_tab.simple_form.time_based.header.start': 'Starttidspunkt',
  'work_hours_registration_tab.simple_form.time_based.start': 'Start',
  'work_hours_registration_tab.simple_form.unlock': 'Opret skabelon til medarbejder',
  'work_hours_registration_tab.template': 'Skabelon',
  'work_hours_registration_tab.template.lock': 'Opgradér til Premium for at kunne lave arbejdstidsskabeloner',
  'work_hours_registration_tab.title': 'Arbejdstid',
  'work_hours_registration_tab.violation.average_rule_violation':
    'Over 4 måneder, har gennemsnittet været mere end 48 timer per uge.',
  'work_hours_registration_tab.violation.day_off_violation': 'På en uge har der ikke været den påkrævede hviledag.',
  'work_hours_registration_tab.violation.go_to_week': 'Skift til ugen',
  'work_hours_registration_tab.violation.rest_hours_violation':
    'På en periode på 24 timer har der ikke været de påkrævet 11 timers hviletid.',
  'work_hours_registration_tab.week.detailed_toggle': 'Detaljeret',
  'work_hours_registration_tab.week.new_registration': 'Ny registrering',
  'work_hours_registration_tab.week.next': 'Næste uge',
  'work_hours_registration_tab.week.previous': 'Forrige uge',
  'work_hours_registration_tab.week.summary': 'Ugentlige timer: {summary}',
  'work_hours_registration_tab.week.summary.count.many': '{number} timer',
  'work_hours_registration_tab.week.summary.count.one': '{number} time',
  'work_hours_registration_tab.week.this_week': 'Denne uge',
  'work_hours_registration_tab.week.title': 'Uge {week_number} ({week_from} - {week_to})',
  'work_hours_registration_tab.week.title.template': 'Skabelonuge',

  'work_hours.delete_all.delete_all': 'Slet også tilbage i tid',
  'work_hours.delete_all.intro.line_1':
    'Du er ved at slette arbejdstidsregistreringer fra indeværende uge og fremad (altså fra og inklusiv {from}).  Du kan også vælge at slette alle arbejdstidsregistreringer, også tilbage i tid.',
  'work_hours.delete_all.intro.line_2': 'Vær opmærksom på at når de er slettet, kan de ikke genskabes!',
  'work_hours.delete_all.submit.include_past': 'Slet alle arbejdstidsregistreringer',
  'work_hours.delete_all.submit.normal': 'Slet alle arbejdstidsregistreringer fra indeværende uge og frem',
  'work_hours.delete_all.title': 'Slet alle arbejdstidsregistrering?',
  'work_hours.header.delete_all': 'Slet alle',
  'work_hours.header.enable_work_hours': 'Slå arbejdstid til',
  'work_hours.header.enable_work_hours_auto': 'Slå skabeloner til',
  'work_hours.header.enable_work_hours_auto.lock': 'Opgradér til Premium for at kunne lave arbejdstidsskabeloner',
  'work_hours.header.enable_work_hours_start_end': 'Registér med fra-/til-felter',
  'work_hours.header.enable_work_hours_warnings': 'Slå advarsler til',
  'work_hours.header.enable_work_hours_warnings.lock':
    'Opgradér til Premium for at kunne slå arbejdstids-afvigelsesadvarsler til',
  'work_hours.header.templates': 'Skabeloner',
  'work_hours.table.header.employee': 'Medarbejder',
  'work_hours.table.header.registrations_count': 'Antal registreringer',
  'work_hours.table.header.total_month_hours': 'Månedlige arbejdstimer',
  'work_hours.table.header.total_week_hours': 'Ugentlige arbejdstimer',
  'work_hours.table.title': 'Arbejdstid',
  'work_hours.table.violations.average_rule_violation': 'På 4 måneder er gennemsnittet mere end 48 timer per uge.',
  'work_hours.table.violations.day_off_violation': 'Der mangler en ugentligt hviledage.',
  'work_hours.table.violations.rest_hours_violation': 'Der er mindre end 11 timers hviletid på en periode på 24 timer.',
  'work_hours.table.week.next': 'Næste uge',
  'work_hours.table.week.previous': 'Forrige uge',
  'work_hours.table.week.this_week': 'Denne uge',
  'work_hours.table.week.title': 'Uge {week_number} ({week_from} - {week_to})',
  'work_hours.table.work_hours_not_enabled':
    'Arbejdstidsfunktionen er ikke slået til.  Du kan slå den til i toppen af denne side.',

  'payslip_import.loading.timeout.text': 'Dette kan tage op til flere minutter. Vent venligst og bliv på siden.',
  'payslip_import.title': 'IMPORTER MEDARBEJDER LØNSEDLER',
  'payslip_import.step.1.intro.line_1': 'Vælg format til at uploade lønsedler med.',
  'payslip_import.step.1.intro.line_2.title': 'lønseddel format',
  'payslip_import.step.1.intro.line_2': 'Når du har valgt det korrekte {title} kan du trykke på fortsæt.',
  'payslip_import.step.1.intro.line_3':
    'På sidste trin vil du kunne se alle informationerne fra filen du har uploadet før du faktisk vælger at gemme dem.',
  'payslip_import.step.1.import_type_select.type.dataloen': 'Dataløn format',
  'payslip_import.step.1.import_type_select.type.danloen': 'Danløn format',
  'payslip_import.step.1.import_type_select.type.zenegy': 'Zenegy format',
  'payslip_import.step.1.import_type_select.type.prolon': 'Proløn format',
  'payslip_import.step.1.import_type_select.type.visma': 'Visma format',
  'payslip_import.step.1.import_type_select.type.letlon': 'Letløn format',
  'payslip_import.step.1.import_type_select.type.lessor': 'Lessor format',
  'payslip_import.step.1.import_type_select.type.intect': 'Intect format',
  'payslip_import.step.1.import_type_select.type.ekspreslon': 'EkspresLøn format',
  'payslip_import.step.1.continue.upload': 'Fortsæt til upload',
  'payslip_import.step.2.title': 'Upload PDF fil',
  'payslip_import.step.2.intro.line_1':
    'Upload PDF filen der indeholder lønsedlerne her på denne side og så kan du fortsætte for at se dataen efterfølgende',
  'payslip_import.step.2.intro.line_2.title': 'en PDF fil af gangen',
  'payslip_import.step.2.intro.line_2': 'Du kan kun uploade {title}, så saml lønsedlerne i en enkelt fil',
  'payslip_import.step.2.button.proceed': 'Fortsæt til godkendelse',
  'payslip_import.step.2.button.process': 'Indlæs fil',
  'payslip_import.step.2.button.upload': 'Upload fil',
  'payslip_import.step.2.button.upload.another': 'Upload en anden fil',
  'payslip_import.step.2.button.upload.success': 'Filen blev uploadet korrekt: ',
  'payslip_import.step.2.button.upload.error': 'Upload fejl, prøv igen, tjek lønseddel typen i det tidligere trin',
  'payslip_import.step.2.button.staging.error': 'Indlæsningsfejl, gå tilbage og tjek lønseddel typen',
  'payslip_import.step.2.button.staging.success': 'Indlæsning af filen gennemført: ',
  'payslip_import.step.3.title': 'Godkend & opret',
  'payslip_import.step.3.intro.line_1':
    'Herunder kan du se al den information der blev løftet fra den fil der blev uploadet',
  'payslip_import.step.3.intro.line_2':
    "Hvis du er tilfreds med hvordan det ser ud kan du trykke på 'Opret' knappen og så vil medarbejderne blive oprettet.",
  'payslips_import.step.3.button.done': 'Opret',
  'payslips_import.step.3.button.cancel': 'Annuller',
  'payslips_import.step.3.button.finish': 'Afslut',
  'payslip_import.step.3.intro.line_3': 'OBS: Ikke godkendte medarbejdere vil blive fjernet fra oprettelsen',
  'payslip_import.step.3.tooltip.approved': 'Har du godkendt alle der skal oprettes?',
  'payslip_import.step.3.tooltip.not.approved': 'Godkend medarbejdere først',
  'payslip_import.step.3.all.creations.failed':
    'Alle oprettelserne fejlede. Kontakt kundeservice for hjælp. Mulige årsager:',
  'payslip_import.step.3.all.creations.failed.reason.nemkonto': 'NemKonto overførsler uden Overførselsservice',
  'payslip_import.step.3.all.creations.failed.reason.exists': 'Medarbejdere allerede oprettet',
  'payslip_import.step.3.some.creations.failed': 'Nogle oprettelser fejlede. Kontakt kundeservice for hjælp.',
  'payslip_import.step.3.creations.async':
    'Det tager længere end forventet. Vent et par minutter, så vil du kunne se de oprettede medarbejdere under "Medarbejdere" fanen. Hvis de ikke dukker op, så kontakt venligst kundeservice for yderligere hjælp.',

  'payslip_import.steps.title': 'Lønseddel import',
  'payslip_import.steps.intro': 'Importer start saldi, løn data, personlig data, osv.',
  'payslip_import.steps.1.summary': 'Vælg import type',
  'payslip_import.steps.2.summary': 'Upload fil',
  'payslip_import.steps.3.summary': 'Godkend & opret import',
  'payslip_import.steps.4.summary': 'Chose import type',
  'payslip_import.steps.5.summary': 'Chose import type',

  'payslip_import.employees.table.header.name': 'Navn',
  'payslip_import.employees.table.header.name.error': `Lønseddel {indexNumber} kunne ikke importeres`,
  'payslip_import.employees.table.header.national.id': 'CPR',
  'payslip_import.employees.table.header.show.info': 'Vis information',
  'payslip_import.employees.table.header.remove.employee': 'Fjern fra import',
  'payslip_import.employees.table.header.approved': 'Godkendt',
  'payslip_import.employees.table.title': 'Import overblik',
  'payslip_import.employees.table.row.checkbox.label': 'Bekræft godkendelse',
  'payslip_import.employees.table.title.button': 'Opret',
  'payslip_import.employees.table.row.button.show': 'Vis',
  'payslip_import.employees.table.row.button.hide': 'Skjul',
  'payslip_import.employees.table.row.button.remove': 'Fjern',
  'payslip_import.employees.table.row.info.title.leave': 'Ferie',
  'payslip_import.employees.table.row.info.title.benefits': 'Personale goder',
  'payslip_import.employees.table.row.info.title.pension': 'Pension',
  'payslip_import.employees.table.row.info.title.supplements': 'Tillæg',
  'payslip_import.employees.table.row.info.title.start.balances': 'Start saldi',
  'payslip_import.employees.table.row.info.title.personal.info': 'Personlig information',
  'payslip_import.employees.table.row.info.title.salary': 'Løn',
  'payslip_import.employees.table.row.info.subtitle.address': 'Adresse',
  'payslip_import.employees.table.row.info.subtitle.zip.code': 'Post nummer',
  'payslip_import.employees.table.row.info.subtitle.city': 'By',
  'payslip_import.employees.table.row.info.subtitle.salary.type': 'Løn type',
  'payslip_import.employees.table.row.info.subtitle.salary.amount': 'Løn mængde',
  'payslip_import.employees.table.row.info.subtitle.leave.type': 'Ferie type',
  'payslip_import.employees.table.row.info.subtitle.leave.max': 'Max antal dage optjent per år',
  'payslip_import.employees.table.row.info.subtitle.benefits.amount': 'Antal af goder',
  'payslip_import.employees.table.row.info.subtitle.supplements.amount': 'Antal af tillæg',
  'payslip_import.employees.table.row.info.subtitle.pension.lines': 'Har pension',
  'payslip_import.employees.table.row.info.subtitle.pension.companies': 'Pension kasser',
  'payslip_import.employees.table.row.info.subtitle.start.balances.aincome': 'A-indkomst',
  'payslip_import.employees.table.row.info.subtitle.start.balances.atax': 'A-skat',
  'payslip_import.employees.table.row.info.subtitle.start.balances.amincome': 'AM-indkomst',
  'payslip_import.employees.table.row.info.subtitle.start.balances.amcontribution': 'AM-bidrag',
  'payslip_import.employees.table.row.info.subtitle.start.balances.atp.employee': 'ATP medarbejder',
  'payslip_import.employees.table.row.info.subtitle.start.balances.atp.employer': 'ATP arbejdsgiver',
  'payslip_import.employees.table.row.info.subtitle.start.balances.hours.worked': 'Timer arbejdet',
  'payslip_import.employees.table.row.info.subtitle.start.balances.great.prayer': 'St bededags tillæg',

  'payslip_import.employees.table.row.info.field.empty': 'Tom værdi',
  'payslip_import.employees.table.row.info.field.true': 'Ja',
  'payslip_import.employees.table.row.info.field.false': 'Nej',
  'payslip_import.employees.table.row.info.field.denmark.vacation.accrual': 'Ferie med løn',
  'payslip_import.employees.table.row.info.field.denmark.vacation.fund': 'Ferie uden løn',
  'payslip_import.employees.table.row.info.field.normal.hours': 'Timelønnet',
  'payslip_import.employees.table.row.info.field.monthly.salary': 'Månedslønnet',
  'payslip_import.employees.table.row.info.field.ceo.salary': 'Ikke ferieberettiget løn',

  'global_search.advanced_settings': 'Indstillinger',
  'global_search.sites': 'Sider',

  'accounting_tab.description': 'Her kan du se og ændre hvordan dit regnskabsprogram er forbundet med Salary',
  'accounting_tab.dimensions.departments.description':
    'Normalt samles bogføringslinjer for hele virksomheden, så der f.eks. kun bogføres én stor løn-postering. Men du kan godt få posteringerne inddelt per afdeling i stedet',
  'accounting_integration.integration_type.description':
    'Forbind dit regnskabsprogram. Alt regnskabsmæssigt vil helt automatisk overføres til dit regnskabsprogram.',
  'accounting_tab.settings.description':
    'Her kan du slå advancerede bogføringindstillinger til og fra, afhængig af dit behov.',
  'vouchers.header.description': 'Her kan du se status og type på dine bogføringer samt hente dem.',
  'integration_layout.menu.contract_book.description': 'Her kan du integrerer, ændre og se kontrakter i contractbook.',
  'integration_layout.menu.data_provider.description':
    'Medarbejdere, tidsregistreringer og/eller fraværsregistreringer kan importeres fra en dataudbyder, såsom et tidsregistreringssystem eller et personalehåndteringssystem.',
  'integration_layout.menu.codan.description':
    'Salary samarbejder med Codan om den lovpligtige arbejdsskadeforsikring.',
  'integration_layout.menu.min_refusion.description': 'Forbind MinRefusion til Salary og modtag refusion automatisk.',
  'integration_layout.menu.rmindr.description': 'Undgå bøder, få overblik over dine frister.',
  'integration_layout.menu.slack.description': 'Opsætning af Slack-integration.',
  'slack_integration.button.delete': 'Slet Slack integrationen',
  'integration_layout.menu.advisor.description': 'Find din revisor eller bogholder med Ageras.',
  'company.menu.company.description':
    'Her kan du se et overblik over din virksomhed samt ændre stamdata og slå autogodkendelser til og fra.',
  'vacation_settings.description': 'Her kan du sætte antallet af feriedage medarbejder får overført og låne.',
  'vacation_settings.header.vacation_calendar.description':
    'Feriekalenderen afgør hvilke dage der tæller som en feriedag, udover lørdag og søndag, som per standard aldrig er arbejdsdage.',
  'company.edit.header.production_units.description': 'Her kan du tilføje og fjerne produktionsenheder.',
  'company.edit.header.users.description': 'Oversigt over brugere med adgang til denne virksomhedsprofil.',
  'company.menu.pay.description':
    'Sæt løn til automatisk godkendelse samt indstille nul-indberetning og andre lønrelaterede funktioner.',
  'nets_tab.description': 'Se beskeder fra overførelsesservice.',
  'deviation.header.description':
    'Her kan du vælge hvilke typer af afvigelse du vil noteres om, samt ekstra indstillinger for dem.',
  'salary_types.tab.description': 'Sæt timesatser og variable tillæg for medarbejdere.',
  'user_notifications.description': 'Bestem hvornår du får virksomhedsnotifikationer.',
  'company.menu.invoices.description': 'Oversigt over fakturaer betalt gennem Salary.',
  'company.menu.transfers.description': 'Få et overblik over alle overførsler lavet gennem Salary.',
  'transfer_immediate_pay.title.immediate_and_swipe.description':
    'Ekspresoverførsler giver mulighed for at overføre en medarbejders udlæg med det samme via Overførselsservice og swipe giver dine medarbejdere mulighed for at få løn med det samme.',
  'reimbursement_settings.description': 'Bestem hvilke typer overførsler der skal laves manuelt.',
  'transfer_easy_account.description':
    'Når du har NemKonto slået til bruger Salary dine medarbejderes CPR-numre istedet for deres kontonumre til at overføre løn.',
  'transfer_settings.description': 'Her kan du se og ændre virksomhedens overførselsindstillinger.',
  'transfer_settings.transfer_bank_account.change.description':
    'Her kan du få hjælp til at oprette en overførselsaftale.',
  'company.menu.departments.description': 'Opret afdelinger til din virksomhed.',
  'company.menu.api_keys.description': 'Opret og fjern API nøgler til Salary.',
  'company.menu.pricing.description': 'Se din nuværende prispakke og se hvordan du skifter den.',
  'company.menu.support.description':
    'Giv support adgang til din virksomhed, bestil flytteservice fra et andet lønsystem, anmod om sletning af virksomhed.',
  'company.menu.reports.description': 'Vælg ud fra en liste af forskellige rapporter.',
  'report_tab.header.tax_cards.description':
    'Få et overblik over hvilke aktive skattekort Salary har over virksomhedens medarbejdere',
  'administration_layout.menu.documents.description': 'Her kan du uploade, dele, og slette dokumenter',
  'administration_layout.menu.assets.description':
    'Hold styr på virksomhedens enheder, hvem der har hvad og hvilke enheder der skal returneres for opsagte medarbejdere.',
  'administration_layout.menu.projects.description':
    'Opret og tilpas projekter og hvilke medarbejdere der er tilknyttet.',
  'documents.title.history.description': 'Få et overblik over virksomhedens dokumenter.',
  'asset_category.header.description': 'Her kan du se de forskellige enhedskategorier din virksomhed har.',
  'approve_tab.time_registrations.description':
    'Her kan du se dine medarbejders simple eller detaljerede tidsregistrering.',
  'registrations.layout.awaiting_approval.description': 'Her kan du se registreringer der afventer godkendelse.',
  'registrations.layout.leave_registrations.description': 'Her kan du se dine medarbejderes fraværsregistrering.',
  'registrations.layout.car_allowances.description':
    'Her kan du se simpelt eller detaljeret kørselsregnskab for dine medarbejdere.',
  'registrations.layout.work_hours.description': 'Her kan du se arbejdstiden for virksomhedens medarbejdere.',
  'registrations.layout.one_time_pays.description':
    'Her kan du se og tilføje løntillæg/fradrag for virksomhedens medarbejdere.',
  'registrations.layout.reimbursement_vouchers.description': 'Her kan du se et udlægsoverblik for virksomheden.',
  'reimbursement_vouchers.header.expense_categories.description': 'Her kan du se virksomhedens udlægskategorier.',
  'transfer_immediate_pay.header.reimbursement_settings.description':
    'Her kan du ændre eller se virksomhedens udlægsindstillinger.',
  'freelancers_overview.description': 'Her kan du se og ændre honoarregistrering for freelancere',
  'freelancers_overview_add.description': 'Her kan du se, ændre og tilføje til virksomhedens freelancere.',
  'freelancers.header.invite_freelancer.description': 'Her kan du invitere freelancere til virksomheden.',
  'freelancers.header.add_freelancer.description': 'Her kan du tilføje freelancere.',
  'header.employees.description': 'Her kan du se et overblik over virksomhedens medarbejdere.',
  'employee.add.steps.description': 'Her kan du tilføje medarbejdere til virksomheden.',
  'employee.add.invite.description': 'Her kan du invitere medarbejdere til virksomheden.',
  'employee_batch.wizard.steps.description': 'Her kan du fortage en massehandling for virksomhedens medarbejdere.',
  'header.pay.description': 'Her kan du se en oversigt over lønkørsler.',
  'pay_rolls.add.description': 'Her kan du oprette ekstraordinær løn.',
  'account.profile.description': 'Her kan du se din Salary profil og ændre navn, telefon og sprogindstillinger.',
  'account.email.description': 'Her kan du se og ændre den email du har tilknyttet din Salary profil.',
  'account.add_new_company.description': 'Her kan du tilføje en ny virksomhed til din Salary konto.',
  'account.password.description': 'Her kan du ændre dit kodeord til Salary.',
  'account.mfa.description': 'Her kan du slå tofaktorgodkendelse til.',
  'account.mitid.description': 'Her kan du bekræfte din identitet med MitID.',
  'account.terminate.description': 'Her kan du anmode om at få opsagt din Salary konto.',
  'general.back.button': 'Tilbage',
  'payroll.filter.selected.value.all.year': 'Hele året',
  'payroll.filter.selected.value.all': 'Vis alle',
  'payroll.filter.label': 'Dispositionsdato filtrer',
}
