import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { getSlackIntegration, putSlackIntegration, SlackConfiguration } from '../../api/slack-integrations'
import Company from '../../model/company'
import { AlertReducer } from '../../reducers/alerts'
import { formatError, isRequestError } from '../../utils/error-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import FeatureLock from '../widgets/FeatureLock'
import LoadingOverlay from '../widgets/LoadingOverlay'
import SlackIntegrationForm, { SlackIntegrationResult } from './SlackIntegrationForm'

import './SlackIntegration.css'

type Props = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  company: Company

  alerts: AlertReducer
}

export default function SlackIntegration(props: Props): ReactElement | null {
  const [configuration, setConfiguration] = useState<Partial<SlackConfiguration>>({})
  const [connectURL, setConnectURL] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const { company } = props

  useEffectOnce(() => {
    setLoading(true)
    getSlackIntegration(company.id)
      .then((res) => {
        if (!res) {
          return
        }
        setConfiguration(res.data)
        setConnectURL(res.data.slackConnectURL)
      })
      .catch(() => {
        return // do nothing
      })
      .finally(() => setLoading(false))
  })

  const handleSubmit = (values: SlackIntegrationResult) => {
    putSlackIntegration(company.id, values)
      .then((res) => {
        if (!res) {
          setError(new Error(t('slack_integration.error.unknown')))
        }
        setConfiguration(res.data)
        props.addAlert('success', t('slack_integration.alert.saved'), { timeout: 5 })
      })
      .catch((e) => {
        if (isRequestError(e)) {
          setError(e)
        }
      })
  }

  const deleteConfiguration = () => {
    putSlackIntegration(company.id, null)
      .then(() => {
        setConfiguration({})
        props.addAlert('success', t('slack_integration.alert.saved'), { timeout: 5 })
      })
      .catch((e) => {
        if (isRequestError(e)) {
          setError(e)
        }
      })
  }

  if (loading) {
    return <LoadingOverlay />
  }

  return (
    <Card className="slack-integration">
      {error && <Alert type="error" message={formatError(error)} />}
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      <Title>{t('slack_integration.title')}</Title>
      {configuration.slackToken ? (
        <p className="slack-integration-subtitle">{t('slack_integration.subtitle.with.integration')}</p>
      ) : (
        <p className="slack-integration-subtitle">{t('slack_integration.subtitle.no.integration')}</p>
      )}
      {!!configuration.slackToken && (
        <SlackIntegrationForm configuration={configuration} onSubmit={handleSubmit} onDelete={deleteConfiguration} />
      )}{' '}
      {!configuration.slackToken && connectURL && (
        <TitleMenu>
          <FeatureLock featureType="Slack Integration" description={t('slack_integration.new_integration.lock')}>
            <Button
              className="slack_integration-button"
              type="primary"
              size="extra-large"
              onClick={() => {
                window.open(connectURL, '_blank')
              }}
            >
              {t('slack_integration.new_integration')}
            </Button>
          </FeatureLock>
        </TitleMenu>
      )}
      <div className="slack-integration-text-wrapper">
        <Subtitle>{t('slack_integration.guide.title')}</Subtitle>
        <p>{t('slack_integration.guide.text.1')}</p>
        <p>{t('slack_integration.guide.text.2')}</p>
        <p>{t('slack_integration.guide.text.3')}</p>
        <p>{t('slack_integration.guide.text.4')}</p>
        <strong>
          <p>
            {tx('slack_integration.guide.text.5', {
              link: (
                <Link
                  to={'https://help.salary.dk/da/articles/11000964-sadan-integrere-du-med-slack'}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  {t('slack_integration.guide.text.link')}
                </Link>
              ),
            })}
          </p>
        </strong>
      </div>
    </Card>
  )
}
